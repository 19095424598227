import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'components/shared';

const NotFound: React.FC = () => {
  return (
    <Container>
      <Text size={36} weight={400}>
        404 Page Not Found
      </Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.surface4};
`;

export default NotFound;
