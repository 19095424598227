import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.surface3};
    margin-top: 20px;
    max-height: 100vh;
    box-sizing: border-box;
    min-width: 782px;
    overflow: auto;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    /* firefox */
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.accentPrimary} black;
    /* firefox */

    ::-webkit-scrollbar {
      width: 2.6px;
      border-radius: 20px;
    }

    /* Track */

    ::-webkit-scrollbar-track {
      width: 4px;
      background: black;
    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
      background: linear-gradient(
        0deg,
        ${theme.colors.accentPrimary} 57.14%,
        ${theme.colors.accentTertiary} 100%
      );
    }

    /* Handle on hover */

    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.accentPrimary};
    }

    /* @media (max-width: 656px) {
      min-width: 448px;
    } */

    @media (max-width: 820px) {
      min-width: max(300px, 100vw);
    }
  `};
`;

type gridAreas = 'displayName' | 'video' | 'mic' | 'options' | 'settings' | 'speaker';

export const SectionContainer = styled.div<{ gridArea: gridAreas }>`
  grid-area: ${(props) => props.gridArea};
  background-color: ${({ theme }) => theme.colors.surface4};
  padding: 11px;
  border-radius: 7px;
  gap: 11px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const TopRow = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  outline: none;
  width: 23px;
  height: 23px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;

  :active {
    box-shadow: 0 0 8px ${({ theme }) => theme.colors.surface2};
    transform: translate(1px, 1px);
  }
`;

export const SectionIconContainer = styled.div`
  display: flex;
  align-items: center;
  /* margin: 0 0 10px 0; */

  > span {
    padding-left: 9px;
  }
`;

const cssHasSpeaker = css`
  grid-template-areas:
    'mic          video'
    'speaker      video'
    'options      video'
    'displayName  displayName';
  grid-template-rows: auto;
`;

const cssNoSpeaker = css`
  grid-template-areas:
    'mic           video'
    'options       video'
    'displayName   video';
  grid-template-rows: auto;
`;

export const MiddleRow = styled.div<{ hasAudioOutput: boolean }>`
  display: grid;
  grid-gap: 10px;

  ${(props) => (props.hasAudioOutput ? cssHasSpeaker : cssNoSpeaker)};
  grid-template-columns: 1fr 479px;

  @media (max-width: 820px) {
    display: flex;
    flex-direction: column;
    > div:nth-child(1) {
      order: 1;
    }

    > div:last-child {
      order: 2;
    }

    > div :not(:nth-child(1), :last-child) {
      order: 3;
    }
  }
`;

export const DisplayNameInput = styled.input<{ hasError: boolean }>`
  font-size: 12px;
  font-weight: 700;
  border: none;
  color: ${({ theme }) => theme.colors.text100};
  background-color: ${({ theme }) => theme.colors.surface2};
  height: 40px;
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: ${(props) =>
    `1px solid ${
      props.hasError ? props.theme.colors.alertError : props.theme.colors.accentPrimary
    }`};

  ::selection {
    background-color: ${({ theme }) => theme.colors.accentPrimary};
  }
`;

export const ErrorContainer = styled.div`
  margin-top: -7px;
  display: flex;
  gap: 7px;
  align-items: center;
`;

export const VideoWrapper1 = styled.div`
  align-self: center;
  width: 100%;
  max-width: 457px;
  min-height: 257px;
  height: 257px;
`;
export const VideoWrapper2 = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
`;
export const VideoWrapper3 = styled.div`
  position: absolute;
  inset: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;

  > img {
    width: 100%;
  }

  > video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: black;
    transform: scale(-1, 1);
  }
`;

export const WebcamOptionsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const WebcamOption = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MicVerifyContainer = styled.div`
  display: flex;
  align-items: center;
  height: 43px;
  background-color: ${({ theme }) => theme.colors.surface5};
  border-radius: 5px;
  /* margin: 0 0 8px 0; */
`;

export const MicRoundWrapper = styled.div`
  margin-left: 15px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.surface4};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SwitchOptionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContinueButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.accentPrimary};
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  width: 100%;

  :active:not(:disabled) {
    /* box-shadow: inset 5px 5px 10px 1px rgba(0, 0, 0, 0.1); */
    box-shadow: ${({ theme }) => `0 0 8px ${theme.colors.accentPrimary}`};
    transform: translateY(2px);
  }

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const BottomRowLinks = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > * {
    cursor: pointer !important;
  }
`;

export const MediaOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background-color: ${({ theme }) => theme.colors.surface6};
  padding: 5px 0 5px 0;
`;

export const MediaOptionsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: ${({ theme }) => theme.colors.surface4};
  cursor: pointer;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  :hover {
    background-color: ${({ theme }) => theme.colors.accentPrimary};
  }
  :active {
    transform: translate(1px, 1px);
  }
`;
