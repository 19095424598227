import { ILocalStorageKeys } from 'constants/storage';

const storage: Pick<typeof localStorage, 'getItem' | 'setItem' | 'removeItem'> = (() => {
  const map = new Map<string, string>();

  return {
    getItem(key) {
      try {
        return localStorage.getItem(key);
      } catch (error) {
        return map.has(key) ? map.get(key)! : null;
      }
    },
    setItem(key, value) {
      try {
        localStorage.setItem(key, value);
      } catch (error) {
        map.set(String(key), String(value));
      }
    },
    removeItem(key) {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        map.delete(key);
      }
    },
  };
})();

export function getLocalStorage<T>(key: ILocalStorageKeys): T | null {
  try {
    const serializedState = storage.getItem(key);
    return serializedState ? JSON.parse(serializedState) : null;
  } catch (err) {
    console.warn(err);
    return null;
  }
}

export const saveLocalStorage = (key: ILocalStorageKeys, value: any) => {
  try {
    storage.setItem(key, JSON.stringify(value || null));
  } catch (err) {
    console.warn(err);
  }
};

export const removeFromLocalStorage = (key: ILocalStorageKeys) => {
  try {
    storage.removeItem(key);
  } catch (err) {
    console.warn(err);
  }
};
