import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Text } from 'components/shared';
import { ModalHeader } from 'components/shared';
import { CamOff, MicOff } from 'components/shared/Icons';
import { closeModal } from 'store/slices/ui';
import { useReactiveRoom } from 'contexts/LiveKitContext';
import { useServices } from 'hooks/useServices';
import { getSessionId } from 'store/slices/session';

interface Props {
  media: 'audio' | 'video';
}

const UnmuteRequest: React.FC<Props> = ({ media }) => {
  const dispatch = useDispatch();
  const { colors } = useTheme();
  const { setOwnCameraEnabled, setOwnMicEnabled, localParticipantId } = useReactiveRoom();
  const { sessionService } = useServices();
  const sessionId = useSelector(getSessionId);

  const handleClose = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    sessionService.updateParticipantMetadata(
      sessionId,
      localParticipantId,
      media === 'audio'
        ? {
            audioUnmuteRequested: false,
          }
        : {
            videoUnmuteRequested: false,
          }
    );
    dispatch(closeModal());
  };

  const handleStartMedia = () => {
    if (media === 'audio') {
      setOwnMicEnabled(true);
    }

    if (media === 'video') {
      setOwnCameraEnabled(true);
    }

    handleClose();
  };

  return (
    <Container>
      <ModalHeader showCloseButton onDismissModal={handleClose} />
      <BodyContainer>
        <IconContainer>
          {media === 'audio' && <MicOff width={18} height={18} fill={colors.text100} />}
          {media === 'video' && <CamOff width={18} height={18} fill={colors.text100} />}
        </IconContainer>
        <Text size={14} weight={400} color={colors.text100} textAlign='center'>
          {media === 'audio' && 'The host is asking you to unmute your mic'}
          {media === 'video' && 'The host is asking you turn on your video'}
        </Text>
        <ButtonsGrid>
          <Button color='black' onClick={handleClose} style={{ width: '150px', padding: '13px' }}>
            <Text size={14} weight={700} style={{ textTransform: 'uppercase' }}>
              Ignore
            </Text>
          </Button>
          <Button
            color={colors.accentPrimary}
            onClick={handleStartMedia}
            style={{ width: '150px', padding: '13px' }}
          >
            <Text size={14} weight={700} style={{ textTransform: 'uppercase' }}>
              {media === 'audio' && 'Unmute'}
              {media === 'video' && 'Start my video'}
            </Text>
          </Button>
        </ButtonsGrid>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
  text-align: center;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.surface2};
  min-width: 200px;
  width: auto;
`;

const IconContainer = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.accentPrimary};
`;

const BodyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 0 10px 10px 10px;
`;

const ButtonsGrid = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 9px;
`;

export default UnmuteRequest;
