import { actions, assign, createMachine } from 'xstate';
import { createDefaultServices } from 'hooks/useServices';
import { TSecretToken } from 'interfaces/newType';
import store from 'store';
import { Destination, PROVIDERS, GroupedDestinations, StatusType } from 'interfaces/destinations';
import { setAllDestinations, updateDestination } from 'store/slices/destinations';
import DestinationAuthService from 'services/DestinationAuth/DestinationAuthService';
import { TStrategies } from 'services/DestinationAuth/interfaces';
import { groupDestinations } from 'utils/destination';

const { escalate } = actions;

const INIT_DESTINATION_MACHINE_ID = 'initDestinationMachine';

type Context = {
  sessionId: string;
  secretToken: TSecretToken;
  error?: string;
  maestroUser: any;
  groupedDestinations: GroupedDestinations;
};

const services = createDefaultServices();

const setupServices = async (ctx: Context) => {
  services.destinationService.setCredentials(ctx.sessionId, ctx.secretToken);
};

const syncMaestroDestinations = async (ctx: Context) => {
  const { sessionId, maestroUser } = ctx;
  if (maestroUser) {
    // maybe we can do one less call if we manage to handle this in the fetchDestinationsCompleteData method
    await services.sessionService.upsertDestinations(sessionId, maestroUser.destinations);
  }
};

const fetchDestinationsCompleteData = async () => {
  const {
    session: { isLive },
    siteConfig,
  } = store.getState();
  const { destinationService } = services;
  const { data: allDest, error: e1 } = await destinationService.getAllDestinations();
  if (!allDest) throw e1;

  const { data: verified, error: e2 } = await destinationService.batchVerifyAndRefresh(allDest);
  if (!verified) throw e2;

  // update destinations with the new tokens (and possibly refresh token for twitch)
  if (verified.updated.length > 0) {
    const { error: e3 } = await destinationService.updateDestinations(verified.updated);
    if (e3) throw e3;
  }

  const destinationAuthService = new DestinationAuthService(siteConfig.data.destinations.providers);

  const destinationsPromise = allDest.map(async (dest) => {
    const failed = verified.failed.find((e) => e.id === dest.id);
    if (failed) {
      const d = destinationService.fromServiceDestination(dest);
      d.status = StatusType.lost_connection;
      return d;
    }

    // we add more info such as image url and other things not saved in the backend
    const { data: destination, error: e4 } = await destinationAuthService.getDestinationInfo(
      dest.provider as TStrategies,
      {
        destination: destinationService.fromServiceDestination(
          verified.updated.find((e) => e.id === dest.id) || dest, // it is either updated or unchanged
          isLive
        ),
      }
    );
    if (!destination) {
      console.error(e4);
      const d = destinationService.fromServiceDestination(dest);
      d.status = StatusType.lost_connection;
      return d;
    }
    return destination;
  });

  const destinations = await Promise.all(destinationsPromise);

  return groupDestinations(destinations);
};

const syncDestinationsByProvider = async (ctx: Context, destinations: Destination[]) => {
  const { siteConfig } = store.getState();

  const destinationAuthService = new DestinationAuthService(siteConfig.data.destinations.providers);

  return Promise.all(
    destinations.map(async (destination) => {
      try {
        if (destination.status === 'lost_connection') return destination;

        const { rtmpUrl, streamKey } = await destinationAuthService.buildRtmp(
          destination.provider as TStrategies,
          {
            provider: destination.provider,
            accessToken: destination.accessToken,
            metadata: destination.metadata,
          }
        );

        const newDestination = {
          ...destination,
          rtmp: {
            rtmpUrl,
            streamKey,
          },
        };

        store.dispatch(updateDestination(newDestination, true));

        return newDestination;
      } catch (err) {
        console.warn(err);
        return { ...destination, status: StatusType.lost_connection };
      }
    })
  );
};

const syncDestinations = async (ctx: Context) => {
  const { groupedDestinations } = ctx;
  return {
    ...groupedDestinations,
    [PROVIDERS.twitch]: await syncDestinationsByProvider(
      ctx,
      groupedDestinations[PROVIDERS.twitch]
    ),
    [PROVIDERS.youtube]: await syncDestinationsByProvider(
      ctx,
      groupedDestinations[PROVIDERS.youtube]
    ),
    [PROVIDERS.facebook]: await syncDestinationsByProvider(
      ctx,
      groupedDestinations[PROVIDERS.facebook]
    ),
  };
};

const persistDestinations = async (ctx: Context) => {
  const { groupedDestinations } = ctx;
  store.dispatch(setAllDestinations(groupedDestinations));
};

export const initDestinationsMachine = createMachine<Context>(
  {
    id: INIT_DESTINATION_MACHINE_ID,
    context: {
      sessionId: '',
      secretToken: '' as TSecretToken,
      error: '',
      maestroUser: { destinations: [] },
      groupedDestinations: {
        [PROVIDERS.twitch]: [],
        [PROVIDERS.youtube]: [],
        [PROVIDERS.maestro]: [],
        [PROVIDERS.facebook]: [],
      },
    },
    initial: 'SETUP_SERVICES',
    states: {
      SETUP_SERVICES: {
        invoke: {
          id: 'setupServices',
          src: 'setupServices',
          onDone: {
            target: 'SYNC_MAESTRO_DESTINATIONS',
          },
          onError: {
            target: 'ERROR',
          },
        },
      },
      SYNC_MAESTRO_DESTINATIONS: {
        invoke: {
          id: 'syncMaestroDestinations',
          src: 'syncMaestroDestinations',
          onDone: {
            target: 'FETCH_DESTINATIONS_COMPLETE_DATA',
          },
          onError: {
            target: 'ERROR',
          },
        },
      },
      FETCH_DESTINATIONS_COMPLETE_DATA: {
        invoke: {
          id: 'fetchDestinationsCompleteData',
          src: 'fetchDestinationsCompleteData',
          onDone: {
            target: 'SYNC_DESTINATIONS',
            actions: assign({
              groupedDestinations: (_, event) => event.data,
            }),
          },
          onError: {
            target: 'ERROR',
          },
        },
      },
      SYNC_DESTINATIONS: {
        invoke: {
          id: 'syncDestinations',
          src: 'syncDestinations',
          onDone: {
            target: 'PERSIST_DESTINATIONS',
            actions: assign({
              groupedDestinations: (_, event) => event.data,
            }),
          },
          onError: {
            target: 'ERROR',
          },
        },
      },
      PERSIST_DESTINATIONS: {
        invoke: {
          id: 'persistDestinations',
          src: 'persistDestinations',
          onDone: { target: 'SUCCESS' },
          onError: {
            target: 'ERROR',
          },
        },
      },
      ERROR: {
        entry: escalate((_, event) => event.data),
        type: 'final',
      },
      SUCCESS: {
        type: 'final',
      },
    },
  },
  {
    services: {
      setupServices,
      syncMaestroDestinations,
      fetchDestinationsCompleteData,
      syncDestinations,
      persistDestinations,
    },
    actions: {},
  }
);
