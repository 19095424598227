import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useSelector } from 'react-redux';

import { getHideDisplayNames } from 'store/slices/session';
import { useParticipantById } from 'hooks/useParticipantById';
import BaseVideoCard from 'components/VideoCard/BaseVideoCard';
import { Mic, MicOff } from 'components/shared/Icons';

interface Props {
  idx: number;
  id: string;
  isSpotlight: boolean;
  shouldDisplayTalkingStatus: boolean;
}

const GuestStageVideoCard: React.FC<Props> = ({
  idx,
  id,
  isSpotlight,
  shouldDisplayTalkingStatus = true,
}) => {
  const { colors } = useTheme();

  const hideDisplayNames = useSelector(getHideDisplayNames);
  const participant = useParticipantById(id);

  const isSpotlightAndRightColumn = isSpotlight && idx > 0;

  if (!participant) return <></>;

  return (
    <BaseVideoCard
      id={`guest-stage:${participant.id}`}
      displayName={participant.displayName}
      video={participant.video}
      audio={participant.audio}
      isDraggable={false}
      isSpotlightAndRightColumn={isSpotlightAndRightColumn}
      hideDisplayNames={hideDisplayNames}
      isScreenShare={participant.isScreenShare}
      isSelf={participant.isSelf}
      isStage={true}
      isMirrored={!participant.isScreenShare && !participant.isIngress}
      shouldDisplayTalkingStatus={shouldDisplayTalkingStatus}
      participantId={id}
    >
      {({ iconSize }) => (
        <MediaButton alwaysShow={!participant.audio.isEnabled} isSmall={isSpotlightAndRightColumn}>
          {participant.audio.isEnabled ? (
            <Mic width={iconSize} height={iconSize} fill={colors.text100} />
          ) : (
            <MicOff width={iconSize} height={iconSize} fill={colors.text100} />
          )}
        </MediaButton>
      )}
    </BaseVideoCard>
  );
};

const MediaButton = styled.button<{ alwaysShow?: boolean; isSmall: boolean }>`
  position: relative;
  display: ${(props) => (props.alwaysShow ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: ${(props) => props.theme.colors.surface4};
  cursor: default;
  width: ${(props) => (props.isSmall ? '20px' : '35px')};
  height: ${(props) => (props.isSmall ? '20px' : '35px')};
  border: none;
  border-radius: 50%;
`;

export default GuestStageVideoCard;
