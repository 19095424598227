import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { Destination, FacebookFormValues, FacebookStreamToData } from 'interfaces/destinations';
import ButtonRow from './ButtonRow';
import FormInput from './FormInput';
import { Select, Text } from 'components/shared';
import { updateDestination } from 'store/slices/destinations';
import { Option } from 'components/shared/Select';
import { useServices } from 'hooks/useServices';

interface Props {
  destination: Destination;
  onClose: Function;
}

//NOTE: we can use Yup to make this validation instead.
const validate = (values: FacebookFormValues) => {
  let errors: Partial<FacebookFormValues> = {};
  if (!values.title) {
    errors.title = 'Missing required field';
  }
  return errors;
};

const FacebookForm: React.FC<Props> = ({ destination, onClose }) => {
  const [streamTo, setStreamTo] = useState<Option>();
  const [streamOption, setStreamOptions] = useState<Option[]>([]);
  const { destinationService } = useServices();
  const dispatch = useDispatch();

  const flattenStreamToOptions = useCallback((dest: Destination) => {
    const { facebookProfile, facebookPages } = dest.data as FacebookStreamToData; //TODO: get data when fetching from BE
    const profileOption = { label: facebookProfile.name, value: facebookProfile.id };
    const pagesOption = facebookPages.map(({ id, name }) => ({ label: name, value: id }));
    return [profileOption, ...pagesOption];
  }, []);

  useEffect(() => {
    const options = flattenStreamToOptions(destination);
    const found = options.find(
      (opt) => opt.value === (destination.metadata as FacebookFormValues).streamTo
    );
    setStreamTo(found);
    setStreamOptions(options);
  }, [destination, flattenStreamToOptions]);

  const onSubmit = async (
    values: FacebookFormValues,
    { setSubmitting }: FormikHelpers<FacebookFormValues>
  ) => {
    const formValues = { ...values, streamTo: streamTo?.value };
    const editedDestination = { ...destination, metadata: formValues };
    const res = await destinationService.updateDestination(editedDestination);
    if (!res.error) {
      dispatch(updateDestination(editedDestination));
    } else {
      console.debug(res.error); //TODO: handle error?
    }
    setSubmitting(false);
    onClose();
  };

  const getInitialValues = (): any => {
    //NOTE: this is running every time an option is selected.
    const metadata = destination.metadata as FacebookFormValues;
    const selected = flattenStreamToOptions(destination).find(
      (opt) => opt.value === metadata.streamTo
    );

    return {
      streamTo: selected,
      title: metadata.title,
      description: metadata.description,
    };
  };

  return (
    <Formik initialValues={getInitialValues()} validate={validate} onSubmit={onSubmit}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <SelectContainer>
            <Text size={14} weight={500}>
              Stream to
            </Text>
            <Select
              isDisabled={true}
              data-testid='destFBStreamToDropdown'
              name='streamTo'
              height={40}
              fontSize={12}
              options={streamOption}
              value={streamTo}
              onChange={(e) => {
                if (e) setStreamTo(e);
              }}
            />
          </SelectContainer>

          <FormInput
            data-testid='destFBTitle'
            label='Title (required)'
            name='title'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            hasError={errors.title && touched.title ? true : false}
            error={errors.title}
          />
          <FormInput
            data-testid='destFBDescription'
            label='Description'
            name='description'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            hasError={errors.description && touched.description ? true : false}
            error={errors.description}
          />

          <ButtonRow
            destination={destination}
            isSubmitting={isSubmitting}
            onClose={onClose}
          ></ButtonRow>
        </Form>
      )}
    </Formik>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export default FacebookForm;
