import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import { useEffect, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MicrophoneSettings,
  MicrophoneSettingsFormValue,
} from 'components/Modals/Settings/MicrophoneSettings';
import {
  SpeakerSettings,
  SpeakerSettingsFormValue,
} from 'components/Modals/Settings/SpeakerSettings';
import {
  SessionOptionsSettings,
  SessionOptionsSettingsFormValues,
} from 'components/Modals/Settings/SessionOptionsSettings';
import {
  DisplayNameSettings,
  DisplayNameSettingsFormValues,
  displayNameValidateFn,
} from 'components/Modals/Settings/DisplayNameSettings';
import {
  CameraSettings,
  SettingsType,
  CameraSettingsFormValues,
} from 'components/Modals/Settings/CameraSettings';
import {
  getHideDisplayNames,
  getSessionDisplayName,
  getSessionIsHost,
  getSessionOutputDeviceId,
  setSessionDisplayName,
  setSessionHideDisplayNames,
  setSessionMediaConstraints,
  setSessionMediaEnabled,
  setSessionOutputDeviceId,
} from 'store/slices/session';
import { saveLocalStorage } from 'utils/storage';
import { LOCAL_STORAGE_KEYS } from 'constants/storage';
import { closeModal } from 'store/slices/ui';
import { ContinueButton, MiddleRow } from 'components/Modals/Settings/styles';
import { Text } from 'components/shared';
import { MediaConstraints } from 'interfaces/session';
import { Device, DeviceId, DevicesByType, MediaSourcesService } from 'services/MediaSourcesService';
import { useMediaSources } from 'hooks/useMediaSources';
import { RESOLUTION_OPTIONS } from 'utils/webrtc';
import { useMediaDeviceSelect } from '@livekit/components-react';

type Props = {
  type: SettingsType;
  handleNext: () => void;
};

type Values = MicrophoneSettingsFormValue &
  SpeakerSettingsFormValue &
  SessionOptionsSettingsFormValues &
  DisplayNameSettingsFormValues &
  CameraSettingsFormValues;

const getSubmitLabel = (type: Props['type'], isHost: boolean) => {
  if (type === 'init') {
    if (isHost) return 'CONTINUE';
    return 'ENTER WAITING ROOM';
  }
  if (type === 'config') return 'SAVE';
  return 'CONTINUE';
};

export const SettingsForm: FC<Props> = ({ type, handleNext }) => {
  const {
    devicesByType,
    mediaConstraints,
    supportsOutputAudioDeviceSelection,
    mediaEnabled,
    mediaStream,
  } = useMediaSources();
  const dispatch = useDispatch();
  const sessionOutputDeviceId = useSelector(getSessionOutputDeviceId);
  const isHost = useSelector(getSessionIsHost);
  const displayName = useSelector(getSessionDisplayName);
  const hideDisplayNames = useSelector(getHideDisplayNames);

  const { setActiveMediaDevice: setActiveMic } = useMediaDeviceSelect({
    kind: 'audioinput',
  });

  const { setActiveMediaDevice: setActiveCam } = useMediaDeviceSelect({
    kind: 'videoinput',
  });

  const { setActiveMediaDevice: setActiveSpeaker } = useMediaDeviceSelect({
    kind: 'audiooutput',
  });

  const handleSubmit = async (values: Values) => {
    const { displayName, audioOutput, enableDisplayNames } = values;
    dispatch(setSessionDisplayName(displayName));
    dispatch(setSessionOutputDeviceId(audioOutput.value));
    if (isHost) dispatch(setSessionHideDisplayNames(!enableDisplayNames));
    dispatch(setSessionMediaConstraints(mediaConstraints));

    saveLocalStorage(LOCAL_STORAGE_KEYS.MEDIA_CONSTRAINTS, mediaConstraints);
    saveLocalStorage(LOCAL_STORAGE_KEYS.DISPLAY_NAME, displayName);
    saveLocalStorage(LOCAL_STORAGE_KEYS.AUDIO_OUTPUT, audioOutput.value);

    if (type === 'init') {
      dispatch(setSessionMediaEnabled(mediaEnabled));
      MediaSourcesService.stopTracks(mediaStream);
      handleNext();
      dispatch(closeModal());
      return;
    }

    setActiveMic(mediaConstraints.audio.deviceId as string);
    setActiveCam(mediaConstraints.video.deviceId as string);
    setActiveSpeaker(audioOutput.value);

    dispatch(closeModal());
  };

  return (
    <Formik<Values>
      initialValues={getInitialValuesFromMediaConstraints({
        sessionOutputDeviceId,
        mediaConstraints,
        devicesByType,
        displayName,
        enableDisplayNames: !hideDisplayNames,
      })}
      onSubmit={handleSubmit}
      validate={(values: Values) => ({
        ...displayNameValidateFn(values),
      })}
    >
      {({ isValid, isSubmitting }) => (
        <FormikForm>
          <MediaConstraintObserver />
          <MiddleRow hasAudioOutput={supportsOutputAudioDeviceSelection}>
            <MicrophoneSettings />
            {supportsOutputAudioDeviceSelection && <SpeakerSettings />}
            <SessionOptionsSettings />
            <DisplayNameSettings />
            <CameraSettings type={type} />
          </MiddleRow>
          <ContinueButton
            data-testid='continueButton'
            type='submit'
            disabled={!isValid || isSubmitting}
          >
            <Text weight={700} size={14}>
              {getSubmitLabel(type, isHost)}
            </Text>
          </ContinueButton>
        </FormikForm>
      )}
    </Formik>
  );
};

const getInitialDevice = (devices: Device[], deviceId: DeviceId) => {
  if (devices.length) return findDevice(devices, deviceId) ?? devices[0];
  return MediaSourcesService.emptyDevice;
};

const findDevice = (devices: Device[], deviceId: DeviceId) =>
  devices.find(
    ({ value }) =>
      MediaSourcesService.extractMediaConstraintValue(value) ===
      MediaSourcesService.extractMediaConstraintValue(deviceId)
  );

const getInitialValuesFromMediaConstraints = ({
  sessionOutputDeviceId,
  displayName,
  mediaConstraints,
  devicesByType,
  enableDisplayNames,
}: {
  sessionOutputDeviceId: string;
  displayName: string;
  mediaConstraints: MediaConstraints;
  devicesByType: DevicesByType;
  enableDisplayNames: boolean;
}) => {
  const audioSource = getInitialDevice(devicesByType.audioinput, mediaConstraints.audio.deviceId);

  const videoSource = getInitialDevice(devicesByType.videoinput, mediaConstraints.video.deviceId);

  const audioOutput = getInitialDevice(devicesByType.audiooutput, sessionOutputDeviceId);

  const videoResolution =
    RESOLUTION_OPTIONS.find(
      ({ value }) =>
        MediaSourcesService.extractMediaConstraintValue(value.width) ===
        MediaSourcesService.extractMediaConstraintValue(mediaConstraints.video.width)
    ) ?? RESOLUTION_OPTIONS[0];

  return {
    displayName,
    enableDisplayNames,
    enableEchoCancellation: Boolean(mediaConstraints.audio.echoCancellation),
    enableStereoAudio: mediaConstraints.audio.channelCount === 2,
    videoResolution,
    audioOutput,
    audioSource,
    videoSource,
  };
};

const MediaConstraintObserver: FC = () => {
  const {
    values: {
      enableStereoAudio,
      enableEchoCancellation,
      audioSource,
      videoSource,
      videoResolution,
    },
  } = useFormikContext<Values>();
  const { setMediaConstraints, setMediaEnabled } = useMediaSources();

  const updateConstraints = useCallback(
    <T extends keyof MediaConstraints>(key: T, value: Partial<MediaConstraints[T]>) => {
      setMediaConstraints((mediaConstraints) => {
        const constraints = {
          ...mediaConstraints,
          [key]: {
            ...mediaConstraints[key],
            ...value,
          },
        };
        return constraints;
      });
    },
    [setMediaConstraints]
  );

  useEffect(() => {
    updateConstraints('audio', {
      channelCount: enableStereoAudio ? 2 : 1,
      echoCancellation: false,
    });
  }, [enableStereoAudio, updateConstraints]);

  useEffect(() => {
    updateConstraints('audio', {
      echoCancellation: enableEchoCancellation,
      channelCount: 1,
    });
  }, [enableEchoCancellation, updateConstraints]);

  useEffect(() => {
    if (!videoSource) return;

    updateConstraints('video', {
      deviceId: {
        exact: videoSource.value,
      },
    });
    setMediaEnabled((state) => ({ ...state, video: true }));
  }, [videoSource, updateConstraints, setMediaEnabled]);

  useEffect(() => {
    updateConstraints('video', {
      height: videoResolution.value.height,
      width: videoResolution.value.width,
      aspectRatio: videoResolution.value.aspectRatio,
    });
  }, [videoResolution, updateConstraints]);

  useEffect(() => {
    if (!audioSource) return;

    updateConstraints('audio', {
      deviceId: audioSource.value,
    });
    setMediaEnabled((state) => ({ ...state, audio: true }));
  }, [audioSource, setMediaEnabled, updateConstraints]);

  return null;
};
