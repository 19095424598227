import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Host from 'views/Host';
import Guest from 'views/Guest';
import { getSessionIsHost } from 'store/slices/session';
import { DeviceNotAuthorizedAlert } from 'components/DeviceNotAuthorizedAlert/DeviceNotAuthorizedAlert';

//not the best place... this code is basically the App.tsx wrapper
const Studio: React.FC = () => {
  const isHost = useSelector(getSessionIsHost);

  return (
    <Container>
      <DeviceNotAuthorizedAlert />
      {isHost ? <Host /> : <Guest />}
    </Container>
  );
};

const Container = styled.div`
  animation: fadein 2s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export default Studio;
