import styled, { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { getNeedHelpUrl } from 'store/slices/siteConfig';
import { Help } from 'components/shared/Icons';
import { Text } from 'components/shared';

// NOTE: this component is almost the same as PrivacyPolicyLink. We could create one "WhiteLabelLink" component if we
// ever need to have another component like this one and use just one. But for now I think this repetition is fine.
const NeedHelpLink = () => {
  const needHelpUrl = useSelector(getNeedHelpUrl);
  const { colors } = useTheme();

  return (
    <PrivacyPolicyContainer>
      <Help width={13} height={13} fill={colors.highlightPrimary} />
      <Text weight={500} size={14} color={colors.highlightPrimary}>
        <CustomLink target='_blank' rel='noreferrer' href={needHelpUrl}>
          Need Help?
        </CustomLink>
      </Text>
    </PrivacyPolicyContainer>
  );
};

const PrivacyPolicyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  position: absolute;
  gap: 4px;
`;

const CustomLink = styled.a`
  :visited {
    color: ${({ theme }) => theme.colors.highlightPrimary};
  }
  text-decoration: underline;
`;

export default NeedHelpLink;
