import { FC, useEffect, useState } from 'react';
import StageStreamArea from 'views/shared/Stage/StageStreamArea';
import { setUserId } from 'store/slices/user';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

export const Composite: FC = () => {
  const dispatch = useDispatch();
  const token = useSearchParams()[0]?.get('token');

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    dispatch(setUserId('COMPOSITOR'));

    if (token) {
      setIsReady(true);
      console.log('START_RECORDING');
    }
  }, [dispatch, token]);

  if (!isReady || !token) {
    return null;
  }

  return (
    <PreventStallAnimation>
      <StageStreamArea streamToken={token} compositeMode={true} />
    </PreventStallAnimation>
  );
};

/**
 * When an admin goes live with no one on stage or with no tracks published
 * (camera and mic turned off), multiple blank frames are sent to the transcoder.
 * In some scenarios, due to the very low bitrate caused by us ingesting a static
 * image, this can cause the transcoder to stall early since it thinks the encoder
 * (Studio) is dead. So we display a short 4.5s pulsing animation at the beginning
 * to work around that. This has been escalated to Mux's engineering team and _may_
 * become unnecessary in the future.
 */
const PreventStallAnimation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  background-color: #000;
  overflow: hidden;

  @keyframes pulse {
    0% {
      transform: scale(0);
      opacity: 0;
      border-width: 0;
    }

    30%,
    70% {
      transform: scale(1);
      opacity: 1;
      border-width: 10vw;
    }

    50% {
      transform: scale(0.9);
    }

    100% {
      transform: scale(0);
      opacity: 0;
      border-width: 0;
    }
  }

  &::after {
    content: ' ';
    display: block;
    width: 110vw;
    height: 110vw;
    left: -5vw;
    top: -27.5vw;
    pointer-events: none;
    position: fixed;
    z-index: 1;
    animation: pulse 1.5s ease-in-out 0s 3;
    background: ${({ theme }) => theme.colors.accentPrimary};
    box-sizing: border-box;
    border-radius: 50%;
    border: 10vw solid color-mix(in srgb, ${({ theme }) => theme.colors.accentPrimary} 60%, black);
    outline: 10vw solid color-mix(in srgb, ${({ theme }) => theme.colors.accentPrimary} 30%, black);
    opacity: 0;
    transform: scale(0);
  }
`;
