import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';

import { Switch, Text } from 'components/shared';
import { AlertTriangle, Settings } from 'components/shared/Icons';
import { Destination, PROVIDERS, StatusType } from 'interfaces/destinations';
import { fmtDestName, getComponentsMap, presentDestinationStatus } from 'utils/destination';
import DestinationIcon from './DestinationIcon';
import LoadingEllipsis from 'components/LoadingEllipsis';
import FormChoice from './DestinationForms/FormChoice';
import { setDestinationStatus, updateDestination } from 'store/slices/destinations';
import { getSessionId, getSessionIsLive } from 'store/slices/session';
import { useServices } from 'hooks/useServices';
import DestinationErrorMessage from './DestinationErrorMessage';
import { useLoginDestination } from './useLoginDestination';
import { DefaultDestinationName } from 'components/WhiteLabel/DefaultDestination';
import { TStrategies } from 'services/DestinationAuth/interfaces';

interface CardComponents {
  connectButton: boolean;
  alert: boolean;
  rightMessage: boolean;
  ellipsis: boolean;
  accountName: boolean;
  connectionStatus: boolean;
  cog: boolean;
  switch: boolean;
  body: boolean;
}

interface Props {
  destination: Destination;
}

const ConnectionCard: React.FC<Props> = ({ destination }) => {
  const [hasComponent, setHasComponent] = useState<CardComponents>({
    connectButton: true,
    alert: false,
    rightMessage: false,
    ellipsis: false,
    accountName: false,
    connectionStatus: false,
    cog: false,
    switch: false,
    body: false,
  });
  const { colors } = useTheme();
  const [isToggleLoading, setIsToggleLoading] = useState(false);
  const [statusColor, setStatusColor] = useState<string>(colors.accentSecondary);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const isLive = useSelector(getSessionIsLive);
  const { connect } = useLoginDestination(destination.provider, destination.id);
  const { destinationService, sessionService, destinationAuthService } = useServices();
  const sessionId = useSelector(getSessionId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (destination.status !== 'connection_successful') return;
    const timeout = setTimeout(() => {
      dispatch(setDestinationStatus(destination.provider, destination.account, StatusType.ready));
      setIsFormOpen(true);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [destination, dispatch]);

  useEffect(() => {
    const options = chooseCorrectComponents(destination, colors);
    setHasComponent(options.components);
    setStatusColor(options.color);
  }, [destination, colors]);

  const handleToggleConnection = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked;
    if (checked === destination.connected) return; // to avoid calling on first render

    setIsToggleLoading(true);

    const newDestination: Destination = {
      ...destination,
      connected: checked,
    };

    try {
      if (checked && isLive) {
        // If toggling a destination can result in it going live
        // we check if this destination is already live in another session.
        const { ids } = await sessionService.liveDestinations(sessionId, [destination]);

        if (ids.includes(destination.id)) {
          setIsToggleLoading(false);
          newDestination.status = StatusType.unable_to_stream;
          checked = false;
        } else {
          const { rtmpUrl, streamKey } = await destinationAuthService.buildRtmp(
            destination.provider as TStrategies,
            {
              provider: destination.provider,
              accessToken: destination.accessToken,
              metadata: destination.metadata,
            }
          );
          await sessionService.startRtmpOutput(sessionId, [{ rtmpKey: streamKey, rtmpUrl }]);
          newDestination.rtmp = {
            rtmpUrl,
            streamKey,
          };
          newDestination.status = StatusType.live;
        }
      }

      if (!checked && isLive) {
        await sessionService.stopRtmpOutput(sessionId, [
          { rtmpKey: destination.rtmp.streamKey, rtmpUrl: destination.rtmp.rtmpUrl },
        ]);
        newDestination.status = StatusType.ready;
      }

      const { error } = await destinationService.toggleConnectDestination(destination, checked);

      if (error) throw error;
    } catch (error) {
      console.error(error);
      return;
    } finally {
      setIsToggleLoading(false);
      dispatch(updateDestination(newDestination));
    }
  };

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const renderBody = () => {
    // the order of these blocks is important, priority must be followed.
    if (destination.status === StatusType.lost_connection) {
      return (
        <DestinationErrorMessage
          provider={destination.provider}
          errorType='LOST_CONNECTION'
          data={undefined}
          handleClose={connect}
        />
      );
    }
    if (isFormOpen) {
      return (
        <FormChoice
          destination={destination}
          body={hasComponent.body}
          isFormOpen={isFormOpen}
          handleFormToggle={closeForm}
        />
      );
    }
    if (destination.status === StatusType.unable_to_stream) {
      const errorType = destination?.data?.errorType ?? 'UNABLE_TO_STREAM';
      return (
        <DestinationErrorMessage
          provider={destination.provider}
          errorType={errorType}
          data={undefined}
          handleClose={connect}
        />
      );
    }

    return null;
  };

  return (
    <Container>
      <Header>
        <InfoColumn>
          <DestinationIcon
            provider={destination.provider}
            isSummary={false}
            status={destination.status}
          >
            {destination.imgUrl && <ProfileImage src={destination.imgUrl} />}
          </DestinationIcon>
          <ProfileInfo>
            {hasComponent.accountName &&
              (destination.provider === PROVIDERS.maestro ? (
                <DefaultDestinationName size={14} weight={500} color={colors.text200} />
              ) : (
                <Text size={14} weight={500} color={colors.text200}>
                  {destination.account || fmtDestName(destination.provider)}
                </Text>
              ))}
            {hasComponent.connectionStatus && (
              <Text size={14} weight={500} color={statusColor}>
                {presentDestinationStatus(destination.status)}
              </Text>
            )}
          </ProfileInfo>
        </InfoColumn>
        <ConfigColumn>
          {hasComponent.rightMessage && (
            <React.Fragment>
              {hasComponent.alert && (
                <AlertTriangle width={18} height={18} fill={colors.alertError} />
              )}

              <Text size={14} weight={400} color={colors.text200}>
                {presentDestinationStatus(destination.status)}
              </Text>
            </React.Fragment>
          )}
          {hasComponent.ellipsis && (
            <LoadingEllipsis dotSize={5.8} loading color={colors.alertWarning} />
          )}
          {hasComponent.cog && destination.provider !== PROVIDERS.maestro && !isFormOpen && (
            <SettingWrapper onClick={openForm} data-testid='destSettings'>
              <Settings width={24} height={24} fill={colors.text100} />
            </SettingWrapper>
          )}
          {hasComponent.switch && destination.provider !== PROVIDERS.maestro && (
            <React.Fragment>
              {isToggleLoading ? (
                <div>
                  <LoadingEllipsis dotSize={5.8} color={colors.accentPrimary} loading />
                </div>
              ) : (
                <Switch
                  data-testid='destLiveToggle'
                  width={35}
                  height={20}
                  checked={destination.connected}
                  onChange={handleToggleConnection}
                />
              )}
            </React.Fragment>
          )}
        </ConfigColumn>
      </Header>
      {hasComponent.body && <React.Fragment>{renderBody()}</React.Fragment>}
    </Container>
  );
};

const chooseCorrectComponents = (destination: Destination, colors: Theme.ThemeObject['colors']) => {
  const components = getComponentsMap(destination.status);

  let color = colors.accentSecondary;
  if (destination.status === StatusType.initializing) color = colors.alertWarning;
  else if (destination.status === StatusType.live) color = colors.alertError;
  else if (destination.status === StatusType.disconnected) color = colors.text300;
  else if (
    destination.status === StatusType.lost_connection ||
    destination.status === StatusType.unable_to_stream
  )
    color = colors.alertWarning;

  return { components, color };
};

const Container = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.surface2};
  min-height: 62px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

const InfoColumn = styled.div`
  display: flex;
  gap: 15px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ConfigColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const SettingWrapper = styled.span`
  cursor: pointer;
  margin-top: 5px;
`;

const ProfileImage = styled.img`
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default ConnectionCard;
