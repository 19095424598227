import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import Collapsible from 'react-collapsible';

import { Text } from 'components/shared';
import DestinationIcon from 'views/Host/DestinationCollapsible/DestinationIcon';
import { ChevronDown } from 'components/shared/Icons';
import { Destination, TProvider, GroupedDestinations } from 'interfaces/destinations';
import { flattenDestinations } from 'utils/destination';
import DestinationMenuHover from './DestinationMenuHover';
import ConnectionCard from './ConnectionCard';
import DestinationAddContainer from './DestinationAddContainer';
import { useDestinationsStore } from 'store/slices/destinations';
import PreConnectionCard from './PreConnectionCard';
import { DataTest } from 'interfaces/dataTestId';
import { FEATURE_FLAGS, FeatureFlag } from 'components/FeatureFlag';

interface Props extends DataTest {
  initiallyExpanded?: boolean;
  disabled?: boolean;
}

const DestinationCollapsible: React.FC<Props> = ({
  initiallyExpanded = false,
  disabled = false,
  'data-testid': dataTestId,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(initiallyExpanded);
  const [showHover, setShowHover] = useState<boolean>(false);
  const { destinations } = useDestinationsStore();

  const { colors } = useTheme();

  //NOTE: below state variables can be replaced by just calling functions in the jsx
  const [preConnectedList, setPreConnectedList] = useState<TProvider[]>([]);
  const [connectedList, setConnectedList] = useState<Destination[]>([]);

  useEffect(() => {
    const listState = renderDestinationList(destinations);
    setPreConnectedList(listState.preConnectedList);
    setConnectedList(listState.connectedList);
  }, [destinations]);

  const handleToggleExpanded = () => setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  const onMouseEnter = () => setShowHover(true);
  const onMouseLeave = () => setShowHover(false);

  const renderDestinationIconsSummary = (grouped: GroupedDestinations) => {
    const statusPriority = ['live', 'initializing', 'ready'];
    const flattenedDestinations = flattenDestinations(grouped);

    const options: Destination[] = [];
    for (let status of statusPriority) {
      const isStatusFound = flattenedDestinations.find((e) => e.status === status);
      if (!isStatusFound) continue;

      for (let key of Object.keys(grouped)) {
        const found = grouped[key as TProvider].find((dest) => dest.status === status);
        found && options.push(found);
      }
      break;
    }

    return options;
  };

  const renderDestinationList = (grouped: GroupedDestinations) => {
    const statusPriority = ['live', 'initializing', 'ready'];
    const head: Destination[] = [];
    const tail: Destination[] = [];
    const preConnected: TProvider[] = [];

    for (let key of Object.keys(grouped) as TProvider[]) {
      if (grouped[key].length === 0) preConnected.push(key);
      for (let destination of grouped[key as TProvider]) {
        if (statusPriority.includes(destination.status)) head.push(destination);
        else tail.push(destination);
      }
    }
    return {
      connectedList: [...head, ...tail],
      preConnectedList: preConnected,
    };
  };

  if (disabled) {
    return null;
  }

  return (
    <Container isExpanded={isExpanded} data-testid={dataTestId}>
      <Collapsible
        open={isExpanded}
        transitionTime={220}
        trigger={
          <HeaderContainer isExpanded={isExpanded} onClick={handleToggleExpanded}>
            <HeaderRow>
              <Text size={16} color={colors.text200} weight={700}>
                Destinations
              </Text>
              <IconContainer isExpanded={isExpanded}>
                <ChevronDown />
              </IconContainer>
            </HeaderRow>
            <HeaderRow>
              <DestinationsContainer
                isExpanded={isExpanded}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                {renderDestinationIconsSummary(destinations).map((option) => (
                  <DestinationIcon
                    data-testid='destConnectedItem'
                    isSummary
                    provider={option.provider}
                    status={option.status}
                    key={option.provider}
                  />
                ))}
              </DestinationsContainer>
            </HeaderRow>
          </HeaderContainer>
        }
      >
        <ContentContainer>
          <FeatureFlag flag={FEATURE_FLAGS.DESTINATIONS}>
            {preConnectedList.map((name) => {
              return <PreConnectionCard key={name} provider={name} />;
            })}
            {preConnectedList.length > 0 && <Divider />}
          </FeatureFlag>
          {connectedList.map((item) => {
            return <ConnectionCard key={item.id} destination={item} />;
          })}
          <FeatureFlag flag={FEATURE_FLAGS.DESTINATIONS}>
            <Divider />
            <DestinationAddContainer />
          </FeatureFlag>
        </ContentContainer>
      </Collapsible>
      {!isExpanded && showHover && <DestinationMenuHover destinations={destinations} />}
    </Container>
  );
};

const Container = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: ${(props) => (props.isExpanded ? 'auto' : '103px')};
  background-color: ${({ theme }) => theme.colors.surface4};
  border-radius: 6px;
  user-select: none;
  transition: 0.22s max-height ease-in-out;
  position: relative;
`;

const HeaderContainer = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0 10px;
  height: 56px;
  gap: 10px;
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const IconContainer = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;

  & svg {
    transform: ${(props) => (props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

const DestinationsContainer = styled.div<{ isExpanded: boolean }>`
  display: flex;
  gap: 5px;
  opacity: ${(props) => (props.isExpanded ? 0 : 1)};
  transition: 0.12s opacity ease-in-out;
  cursor: default;
  transition-delay: ${(props) => (props.isExpanded ? 0 : '0.3s')};
`;

const Divider = styled.div`
  width: 100%;
  height: 0.87px;
  background-color: ${({ theme }) => theme.colors.surface6};
  margin: 10px 0;
`;

const ContentContainer = styled.div`
  padding: 0px 10px 15px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export default DestinationCollapsible;
