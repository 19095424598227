import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface Props {
  color?: string;
  width?: number;
  height?: number;
}

const LoadingBouncer: React.FC<Props> = ({ color, width, height }) => {
  return (
    <Bouncer width={width} height={height}>
      <FirstBounce color={color} />
      <SecondBounce color={color} />
    </Bouncer>
  );
};

const Bouncer = styled.div<{ width?: number; height?: number }>`
  width: 40px;
  height: 40px;
  width: ${(props) => (props.width ? `${props.width}px` : '40px')};
  width: ${(props) => (props.height ? `${props.height}px` : '40px')};
  position: relative;
`;

const BounceAnimation = keyframes`
   0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
`;

const BounceStyle = css<{ color?: string }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${(props) => props.color || props.theme.colors.accentPrimary};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${BounceAnimation} 2s infinite ease-in-out;
`;

const FirstBounce = styled.div`
  ${BounceStyle}
`;

const SecondBounce = styled.div`
  ${BounceStyle}
  animation-delay: -1s;
`;

export default LoadingBouncer;
