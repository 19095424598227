import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/slices';
import { AppDispatch } from 'store';
import { ISetParticipantIsOnStage, ParticipantsStore, TParticipant } from 'interfaces/participants';
import { MediaState } from 'interfaces/session';

// Initial state
const initialState: ParticipantsStore = {
  participants: [],
};

// Initializing slice
const participantsSlice = createSlice({
  name: 'participants',
  initialState,
  reducers: {
    addParticipant(state, action: PayloadAction<TParticipant>) {
      state.participants = [...state.participants, action.payload];
    },
    removeParticipant(state, action: PayloadAction<string>) {
      state.participants = state.participants.filter(
        (participant) => participant.id !== action.payload
      );
    },
    setAllParticipants(state, action: PayloadAction<TParticipant[]>) {
      state.participants = action.payload;
    },
    setParticipantIsOnStage(state, action: PayloadAction<ISetParticipantIsOnStage>) {
      const { id, isOnStage, order } = action.payload;
      state.participants = state.participants.map((p) => {
        if (p.id === id) return { ...p, isOnStage, order };
        else return p;
      });
    },
  },
});

// Selectors
const selectState = (state: RootState) => state.participants;

export const getParticipants = createSelector(
  selectState,
  (state: ParticipantsStore) => state.participants
);

export const getSelf = createSelector(getParticipants, (participants) =>
  participants.find((p) => p.isSelf)
);

//TODO: we don't know yet if this derived selector stops re-renders for real
export const getParticipantById = createSelector(
  (state: RootState) => state.participants.participants,
  (_: unknown, id: string) => id,
  (participants: TParticipant[], id: string) =>
    participants.find((participant) => participant.id === id)
);

// Actions
const { actions } = participantsSlice;

export const addParticipant = (participant: TParticipant) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.addParticipant(participant));
  };
};

export const removeParticipant = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.removeParticipant(id));
  };
};

export const setAllParticipants = (participants: TParticipant[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setAllParticipants(participants));
  };
};

export const setParticipantIsOnStage = (id: string, isOnStage: boolean) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      participants: { participants },
    } = getState();

    if (id.includes('screen')) {
      dispatch(actions.setParticipantIsOnStage({ id, isOnStage, order: -1 }));
      return;
    }

    const self = participants.find((p) => p.isSelf);
    if (!self) return;

    const orders: number[] = [];

    if ((isOnStage && self.isOnStage) || (!isOnStage && !self.isOnStage)) orders.push(self.order);

    // Checking orders if Stage or Waiting Room
    if (isOnStage) participants.forEach((peer) => peer.isOnStage && orders.push(peer.order));
    else participants.forEach((peer) => !peer.isOnStage && orders.push(peer.order));

    // Getting the max order value and adding +1
    const order = orders.reduce((maxValue, b) => Math.max(maxValue, b), 0) + 1;
    dispatch(actions.setParticipantIsOnStage({ id, isOnStage, order }));

    // TODO: verify if we need to disable backstage participant audios (even though backstage videos always have the muted tag)
  };
};

export const setParticipantMediaState = (
  uid: string,
  mediaState: Partial<MediaState>,
  type: 'audio' | 'video'
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      participants: { participants },
    } = getState();

    const updatedParticipants = participants.map((participant) => {
      if (participant.id !== uid) return participant;
      const state = { ...participant[type], ...mediaState };
      return { ...participant, [type]: state };
    });

    dispatch(actions.setAllParticipants(updatedParticipants));
  };
};

export default participantsSlice.reducer;
