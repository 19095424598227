import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/slices';

interface IUserStore {
  id?: string;
}

// Initial state
const initialState: IUserStore = {
  id: undefined,
};

// Initializing slice
const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserId(state, action: PayloadAction<string>) {
      state.id = action.payload;
    },
  },
});

const selectState = (state: RootState) => state.user;

export const getUserId = createSelector(selectState, (state: IUserStore) => state.id);

export const {
  actions: { setUserId },
} = userSlice;

export default userSlice.reducer;
