import React from 'react';
import styled, { useTheme } from 'styled-components';

import { AlertTriangle } from 'components/shared/Icons';
import { Text } from 'components/shared';

interface Props {
  label: string;
  name: string;
  onChange: any;
  onBlur: any;
  value: any;
  hasError: boolean;
  error?: string;
  placeholder?: string;
  type?: string;
  maxLength?: number;
}

const FormInput: React.FC<Props> = ({ label, error, ...props }) => {
  const { colors } = useTheme();
  return (
    <InputRow>
      <Text size={14} weight={500}>
        {label}
      </Text>
      <InputContainer>
        <CustomInput {...props} />
        {props.hasError && <AlertTriangle width={18} height={18} fill={colors.alertError} />}
      </InputContainer>
      {props.hasError && error && (
        <ErrorMessage>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AlertTriangle width={14} height={14} fill={colors.alertError} />
          </div>
          <Text size={11} weight={400} color={colors.alertError}>
            {error}
          </Text>
        </ErrorMessage>
      )}
    </InputRow>
  );
};

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  position: relative;
  margin-top: 7px;
  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const CustomInput = styled.input<{ hasError: boolean }>`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.surface6};
  width: 100%;
  height: 40px;
  padding: 10px 27px 10px 10px;
  color: white;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Noto sans';
  border-radius: 5px;
  outline: none;
  border: ${(props) => (props.hasError ? '0.5px solid red' : 'none')};
  position: relative;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  height: 15px;
`;

export default FormInput;
