import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import styled from 'styled-components';

import { Destination, YouTubeFormValues } from 'interfaces/destinations';
import ButtonRow from './ButtonRow';
import FormInput from './FormInput';
import { Select, Text } from 'components/shared';
import { getSelectOption } from 'utils/destination';
import { updateDestination } from 'store/slices/destinations';
import { useServices } from 'hooks/useServices';

const youtubeCategoryOptions = [
  { label: 'Use my current Youtube category', value: 'Use my current Youtube category' },
  { label: 'Film & Animation', value: 'Film & Animation' },
  { label: 'Autos & Vehicles', value: 'Autos & Vehicles' },
  { label: 'Music', value: 'Music' },
  { label: 'Pets & Animals', value: 'Pets & Animals' },
  { label: 'Sports', value: 'Sports' },
  { label: 'Travel & Events', value: 'Travel & Events' },
  { label: 'Gaming', value: 'Gaming' },
  { label: 'People & Blogs', value: 'People & Blogs' },
  { label: 'Comedy', value: 'Comedy' },
  { label: 'Entertainment', value: 'Entertainment' },
  { label: 'News & Politics', value: 'News & Politics' },
  { label: 'Howto & Style', value: 'Howto & Style' },
  { label: 'Education', value: 'Education' },
  { label: 'Science & Technology', value: 'Science & Technology' },
  { label: 'Nonprofits & Activism', value: 'Nonprofits & Activism' },
];

const youtubeVisibility = [
  { label: 'Public', value: 'public' },
  { label: 'Private', value: 'private' },
  { label: 'Unlisted', value: 'unlisted' },
];

interface Props {
  destination: Destination;
  onClose: Function;
}

//NOTE: we can use Yup to make this validation instead.
const validate = (values: YouTubeFormValues) => {
  let errors: Partial<YouTubeFormValues> = {};
  if (!values.title) {
    errors.title = 'Missing required field';
  }
  return errors;
};

const YouTubeForm: React.FC<Props> = ({ destination, onClose }) => {
  const metadata = destination.metadata as YouTubeFormValues;
  const [category, setCategory] = useState(getSelectOption(metadata.category));
  const [visibility, setVisibility] = useState(getSelectOption(metadata.visibility));
  const { destinationService } = useServices();
  const dispatch = useDispatch();

  const onSubmit = async (
    values: YouTubeFormValues,
    { setSubmitting }: FormikHelpers<YouTubeFormValues>
  ) => {
    const formValues = {
      ...values,
      category: category.value,
      visibility: visibility.value?.toLowerCase(),
    };
    const editedDestination = { ...destination, metadata: formValues };
    const res = await destinationService.updateDestination(editedDestination);
    if (!res.error) {
      dispatch(updateDestination(editedDestination));
    } else {
      console.debug(res.error); //TODO: handle error?
    }
    setSubmitting(false);
    onClose();
  };

  const getInitialValues = (): any => {
    return {
      title: metadata.title,
      description: metadata.description,
      category: getSelectOption(metadata.category),
      visibility: getSelectOption(metadata.visibility),
    };
  };

  return (
    <Formik initialValues={getInitialValues()} validate={validate} onSubmit={onSubmit}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          {/* TODO: YouTube title should be between 1 and 128 characters https://developers.google.com/youtube/v3/live/docs/liveStreams#resource */}
          <FormInput
            data-testid='destYTTitle'
            label='Title (required)'
            name='title'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            hasError={errors.title && touched.title ? true : false}
            error={errors.title}
            maxLength={128}
          />

          <FormInput
            data-testid='destYTDescription'
            label='Description'
            name='description'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            hasError={errors.description && touched.description ? true : false}
            error={errors.description}
          />

          <SelectContainer>
            <Text size={14} weight={500}>
              Category
            </Text>

            <Select
              data-testid='destYTCategoryDropdown'
              name='category'
              height={40}
              fontSize={12}
              options={youtubeCategoryOptions}
              value={category}
              isLastChildStyle
              onChange={(e) => {
                if (e) setCategory(e);
              }}
            />
          </SelectContainer>

          <SelectContainer>
            <Text size={14} weight={500}>
              Visibility
            </Text>

            <Select
              data-testid='destYTVisibilityDropdown'
              name='visibility'
              height={40}
              fontSize={12}
              options={youtubeVisibility}
              value={visibility}
              onChange={(e) => {
                if (e) setVisibility(e);
              }}
            />
          </SelectContainer>

          <ButtonRow
            destination={destination}
            isSubmitting={isSubmitting}
            onClose={onClose}
          ></ButtonRow>
        </Form>
      )}
    </Formik>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export default YouTubeForm;
