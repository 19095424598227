export const MODALS = {
  AUDIO_CAM_SETTINGS: 'AUDIO_CAM_SETTINGS',
  KICK_GUEST: 'KICK_GUEST',
  FINISHED_TRANSMISSION: 'FINISHED_TRANSMISSION',
  LEAVE_STREAM: 'LEAVE_STREAM',
  MEDIA_NOT_ALLOWED: 'MEDIA_NOT_ALLOWED',
  SCREENSHARE_DENIED: 'SCREENSHARE_DENIED',
  ABOUT_STUDIO: 'ABOUT_STUDIO',
  SCREEN_SHARE_ALLOWED: 'SCREEN_SHARE_ALLOWED',
  SCREEN_SHARE_DENIED: 'SCREEN_SHARE_DENIED',
  SCREEN_SHARE_NOTIFICATION: 'SCREEN_SHARE_NOTIFICATION',
  UNMUTE_REQUEST: 'UNMUTE_REQUEST',
  CONNECTION_LOST: 'CONNECTION_LOST',
} as const;

// @TODO: migrate everything to ModalV2
export type ModalV2 =
  | 'MEDIA_NOT_ALLOWED'
  | 'ABOUT_STUDIO'
  | 'SCREEN_SHARE_ALLOWED'
  | 'SCREEN_SHARE_DENIED'
  | 'SCREEN_SHARE_NOTIFICATION'
  | 'KICK_GUEST'
  | 'AUDIO_CAM_SETTINGS'
  | 'UNMUTE_REQUEST'
  | 'LEAVE_STREAM'
  | 'CONNECTION_LOST';

export type Modal = typeof MODALS[keyof typeof MODALS];

export type NotAllowedType = 'host_stops' | 'cant_start';
