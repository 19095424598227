import React, { Suspense } from 'react';
import { getOptionalQueryParams } from 'utils/methods';

export const FEATURE_FLAGS = {
  DESTINATIONS: 'DESTINATIONS',
  SCREEN_SHARE: 'SCREEN_SHARE',
} as const;

type Props = {
  flag: keyof typeof FEATURE_FLAGS;
  isLazy?: boolean;
};

export const FeatureFlag: React.FC<Props> = ({ isLazy = false, flag, children }) => {
  const { featureFlags = [] } = getOptionalQueryParams();

  if (!featureFlags.includes(flag.toLocaleLowerCase())) return null;

  if (!isLazy) return <>{children}</>;

  return (
    <Suspense fallback={null}>
      <>{children}</>
    </Suspense>
  );
};
