export enum MaestroCommand {
  start = 'start',
  stop = 'stop',
  requestInviteCode = 'requestInviteCode',
}

export enum StudioMessageTypes {
  init = 'init:studio:session:action',
  end = 'end:studio:session:action',
  ready = 'ready:studio:session:action',
  notReady = 'notready:studio:session:action',
  inviteCode = 'studio:invitecode:available',
  stalled = 'stalled:studio:session',
}

export const STUDIO_MESSAGE_SOURCE = 'maestro:studio:v1';
export const MAESTRO_MESSAGE_SOURCE = 'maestro:web:v1';

export interface MaestroMessagePayload {
  command: MaestroCommand;
  source: string;
}
