import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Text } from 'components/shared';
import { closeModal } from 'store/slices/ui';
import { RootState } from 'store/slices';
import { getParticipantById } from 'store/slices/participants';
import { useServices } from 'hooks/useServices';
import { getSessionId } from 'store/slices/session';
import { useReactiveRoom } from 'contexts/LiveKitContext';

interface Props {
  id: string;
}

const ScreenShareNotification: React.FC<Props> = ({ id }) => {
  const participant = useSelector((state: RootState) => getParticipantById(state, id));
  const { sessionService } = useServices();
  const { metadata } = useReactiveRoom();
  const sessionId = useSelector(getSessionId);

  const dispatch = useDispatch();

  const updateRequest = (allowed: boolean) => {
    const newMetadata = { ...metadata };
    newMetadata.screenShareRequests = newMetadata.screenShareRequests ?? {};

    for (const screenShareRequestId in newMetadata.screenShareRequests) {
      const screenShareRequest = newMetadata.screenShareRequests[screenShareRequestId];

      if (screenShareRequest.participantId === id) {
        screenShareRequest.allowed = allowed;
        break;
      }
    }

    sessionService.updateRoomMetadata(sessionId, newMetadata);
  };

  const handleDeny = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    updateRequest(false);
    dispatch(closeModal());
  };

  const handleAllow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    updateRequest(true);
    dispatch(closeModal());
  };

  if (!participant) return null;

  return (
    <Container>
      <Text size={16} weight={400}>
        {participant.displayName} wants to share their screen
      </Text>
      <Button fullWidth onClick={handleAllow}>
        <Text size={14} weight={700}>
          Allow
        </Text>
      </Button>

      <Button fullWidth color='black' onClick={handleDeny}>
        <Text size={14} weight={700}>
          Deny
        </Text>
      </Button>
    </Container>
  );
};

const Container = styled.div`
  /* width: 480px; */
  width: 340px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.surface2};
  box-shadow: 5px 5px 26px 5px rgba(0, 0, 0, 0.47);
  border-radius: 10px;
`;

export default ScreenShareNotification;
