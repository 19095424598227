import React, { MouseEvent } from 'react';
import styled, { CSSProperties, css } from 'styled-components/macro';
import { DataTest } from 'interfaces/dataTestId';

type ButtonType = 'normal' | 'outlined';
interface Props extends DataTest {
  children: React.ReactNode;
  color?: string;
  shape?: ButtonType;
  fullWidth?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<Props> = ({
  children,
  color,
  shape = 'normal',
  fullWidth = false,
  disabled,
  style,
  onClick,
  'data-testid': dataTestId,
}) => {
  return (
    <CustomButton
      type='submit'
      style={style}
      fullWidth={fullWidth}
      color={color}
      shape={shape}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {children}
    </CustomButton>
  );
};

const CustomButton = styled.button<Props>`
  ${({ theme, disabled, fullWidth, color, shape }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: ${disabled ? 'none' : 'auto'};
    height: 40px;
    width: ${fullWidth ? '100%' : 'fit-content'};
    background-color: ${disabled ? theme.colors.surface6 : color || theme.colors.accentPrimary};
    border-radius: 5px;
    border: ${shape === 'outlined' ? `1px solid ${theme.colors.accentPrimary}` : 'none'};
    cursor: pointer;
    padding: 0px 30px;
    :active {
      box-shadow: 0 0 8px ${color || theme.colors.accentPrimary};
      transform: translateY(2px);
    }
  `}
`;

export default Button;
