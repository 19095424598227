import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getParticipants } from 'store/slices/participants';
import { TParticipant } from 'interfaces/participants';

interface FilteredParticipants {
  backstage: TParticipant[];
  stage: TParticipant[];
}

//NOTE: is this hook really that useful?
const useFilteredParticipants = (): FilteredParticipants => {
  const [filteredParticipants, setFilteredParticipants] = useState<FilteredParticipants>({
    backstage: [],
    stage: [],
  });
  const participants = useSelector(getParticipants);

  useEffect(() => {
    const filtered: FilteredParticipants = { backstage: [], stage: [] };

    for (let i = 0; i < participants.length; i++) {
      const participant = participants[i];

      if (participant.isScreenShare) {
        filtered.stage = [participant, ...filtered.stage];
        continue;
      }

      if (participant.isOnStage) {
        filtered.stage = [...filtered.stage, participant];
      } else {
        filtered.backstage = [...filtered.backstage, participant];
      }
    }

    const sorted: FilteredParticipants = {
      backstage: filtered.backstage.sort((a, b) => a.order - b.order),
      stage: filtered.stage.sort((a, b) => a.order - b.order),
    };

    setFilteredParticipants(sorted);
  }, [participants]);

  return filteredParticipants;
};

export default useFilteredParticipants;
