import { Context, FC, createContext, useContext, useEffect, useRef, useState } from 'react';
import { ConnectionState, Participant, RemoteParticipant, Room, Track } from 'livekit-client';
import { RoomContext } from '@livekit/components-react';
import { setAllParticipants } from 'store/slices/participants';
import { TParticipant } from 'interfaces/participants';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from 'store/slices/user';
import {
  getSessionId,
  getSessionMediaConstraints,
  getSessionOutputDeviceId,
  setSessionIsAskingScreenShare,
  setSessionLayout,
} from 'store/slices/session';
import { COMPOSITE_MODE, LIVEKIT_CLOUD_URL } from 'config';
import { ParticipantMetadata, RoomMetadata } from 'services/SessionService';
import { openModal, setPeerSoloViewId } from 'store/slices/ui';
import { MODALS } from 'constants/modals';
import { LAYOUTS } from 'constants/layouts';
import {
  postMessageBroadcastStopped,
  postMessageNotReadyStatus,
  postMessageReadyStatus,
} from 'store/core/maestro-messages';
import { useServices } from 'hooks/useServices';

export const LiveKitContextProvider: FC = ({ children }) => {
  const mediaConstraints = useSelector(getSessionMediaConstraints);
  const audioOutput = useSelector(getSessionOutputDeviceId);

  const roomRef = useRef(new Room());

  const [reactiveRoom, setReactiveRoom] = useState<ContextType<typeof ReactiveRoomContext>>({
    localParticipantId: roomRef.current.localParticipant.identity,
    localParticipantName:
      roomRef.current.localParticipant.name || roomRef.current.localParticipant.identity,
    metadata: JSON.parse(roomRef.current.metadata || '{}'),
    participants: roomRef.current.participants,
    state: ConnectionState.Disconnected,
    setOwnCameraEnabled: (enabled) => {
      const { localParticipant } = roomRef.current;

      localParticipant.setCameraEnabled(enabled);

      localParticipant.tracks.forEach((track) => {
        if (!track.videoTrack || track.videoTrack.source !== Track.Source.Camera) return;

        if (enabled) {
          localParticipant.publishTrack(track.videoTrack);
        } else {
          localParticipant.unpublishTrack(track.videoTrack);
        }
      });
    },
    setOwnMicEnabled: (enabled) => {
      const { localParticipant } = roomRef.current;

      localParticipant.setMicrophoneEnabled(enabled);
      localParticipant.tracks.forEach((track) => {
        if (!track.audioTrack || track.audioTrack.source !== Track.Source.Microphone) return;

        if (enabled) {
          localParticipant.publishTrack(track.audioTrack);
        } else {
          localParticipant.unpublishTrack(track.audioTrack);
        }
      });
    },
  });

  useEffect(() => {
    roomRef.current.prepareConnection(LIVEKIT_CLOUD_URL);
  }, []);

  useEffect(() => {
    if (!mediaConstraints || roomRef.current.state !== ConnectionState.Disconnected) return;

    roomRef.current.options.audioCaptureDefaults = {
      deviceId: mediaConstraints.audio.deviceId,
      echoCancellation: mediaConstraints.audio.echoCancellation,
      channelCount: mediaConstraints.audio.channelCount,
    };

    roomRef.current.options.videoCaptureDefaults = {
      deviceId: mediaConstraints.video.deviceId,
    };

    roomRef.current.options.audioOutput = {
      deviceId: audioOutput,
    };
  }, [audioOutput, mediaConstraints]);

  const userId = useSelector(getUserId);
  const sessionId = useSelector(getSessionId);
  const { sessionService } = useServices();

  const dispatch = useDispatch();

  useEffect(() => {
    const room = roomRef.current;

    const getRoomMetadata = () => {
      try {
        return JSON.parse(roomRef.current.metadata || '{}') as RoomMetadata;
      } catch (error) {
        return {};
      }
    };

    const isHost = () => Boolean(getParticipantMetadata(roomRef.current.localParticipant).isHost);

    const getParticipantById = (identity: string): TParticipant | undefined => {
      if (room.localParticipant.identity === identity)
        return mapLkParticipantToStudioParticipant(room.localParticipant, userId)[0];

      for (const participant of room.participants.values()) {
        if (participant.identity === identity)
          return mapLkParticipantToStudioParticipant(participant, userId)[0];
      }
    };

    const syncParticipants = () => {
      const newParticipants: TParticipant[] = COMPOSITE_MODE
        ? []
        : mapLkParticipantToStudioParticipant(room.localParticipant, userId);

      const mySelf = newParticipants.find((p) => p.isScreenShare === false);

      if (
        mySelf !== undefined &&
        (!mySelf.audio.isAllowed || !mySelf.isOnStage) &&
        room.localParticipant.isMicrophoneEnabled
      ) {
        room.localParticipant.setMicrophoneEnabled(false);
      }

      room.participants.forEach((p) =>
        newParticipants.push(...mapLkParticipantToStudioParticipant(p, userId))
      );

      const { peerSoloViewId } = getRoomMetadata();

      if (isHost() && peerSoloViewId && !newParticipants.some((p) => p.id === peerSoloViewId)) {
        sessionService.updateRoomMetadata(sessionId, {
          ...getRoomMetadata(),
          peerSoloViewId: undefined,
        });
      }

      dispatch(setAllParticipants(newParticipants));

      setReactiveRoom((prev) => ({
        ...prev,
        participants: new Map(room.participants),
        localParticipantId: room.localParticipant.identity,
        localParticipantName: room.localParticipant.name || room.localParticipant.identity,
        state: room.state,
      }));
    };

    const syncScreenShare = () => {
      try {
        const metadata = getRoomMetadata();

        setReactiveRoom((prev) => ({
          ...prev,
          metadata,
        }));

        try {
          dispatch(setSessionLayout(metadata.layout || LAYOUTS.LANDSCAPE));
          dispatch(setPeerSoloViewId(metadata.peerSoloViewId || null));
        } catch (error) {
          console.error('Failure when processing layout update', error);
        }

        try {
          const screenShareRequests = metadata.screenShareRequests ?? {};
          let mostRecentScreenShareRequestId = 0;
          let localParticipantIsAskingScreenShare = false;

          for (const screenShareRequestId in screenShareRequests) {
            const screenShareRequest = screenShareRequests[screenShareRequestId];
            const requestingParticipantId = screenShareRequest.participantId;

            if (!requestingParticipantId) continue;

            let participantIsInTheRoom = false;

            if (!getParticipantById(requestingParticipantId)?.isOnStage) {
              continue;
            }

            if (requestingParticipantId === roomRef.current.localParticipant.identity) {
              if (screenShareRequest.allowed) {
                dispatch(
                  openModal({
                    component: MODALS.SCREEN_SHARE_ALLOWED,
                    props: {},
                    hideBackdrop: true,
                    locked: true,
                  })
                );
              } else if (screenShareRequest.allowed === false) {
                dispatch(
                  openModal({
                    component: MODALS.SCREEN_SHARE_DENIED,
                    props: {},
                    hideBackdrop: true,
                    locked: true,
                  })
                );
              } else {
                localParticipantIsAskingScreenShare = true;
              }

              participantIsInTheRoom = true;
            } else {
              roomRef.current.participants.forEach(({ identity }) => {
                if (identity === requestingParticipantId) {
                  participantIsInTheRoom = true;
                }
              });
            }

            if (!participantIsInTheRoom) continue;

            const screenShareRequestTime = Number(screenShareRequestId);

            if (screenShareRequestTime > mostRecentScreenShareRequestId) {
              mostRecentScreenShareRequestId = screenShareRequestTime;
            }
          }

          dispatch(setSessionIsAskingScreenShare(localParticipantIsAskingScreenShare));

          const mostRecentScreenShareRequest =
            screenShareRequests[mostRecentScreenShareRequestId.toString()];

          if (
            mostRecentScreenShareRequest &&
            isHost() &&
            mostRecentScreenShareRequest.allowed === undefined
          ) {
            dispatch(
              openModal({
                id: `ask-ss-${mostRecentScreenShareRequestId}`,
                component: MODALS.SCREEN_SHARE_NOTIFICATION,
                props: {
                  id: mostRecentScreenShareRequest.participantId,
                },
                hideBackdrop: true,
                locked: true,
              })
            );
          }
        } catch (error) {
          console.error('Failure when processing screenshare request', error);
        }
      } catch (error) {
        console.error(`Failure when parsing room metadata`, error);
        return;
      }
    };

    const syncUnmuteRequests = (prevMetadata: string | undefined) => {
      const prevState = getParticipantMetadata({ metadata: prevMetadata });
      const newState = getParticipantMetadata(room.localParticipant);

      if (!prevState.audioUnmuteRequested && newState.audioUnmuteRequested) {
        dispatch(
          openModal({
            component: 'UNMUTE_REQUEST',
            props: {
              media: 'audio',
            },
          })
        );
      }

      if (!prevState.videoUnmuteRequested && newState.videoUnmuteRequested) {
        dispatch(
          openModal({
            component: 'UNMUTE_REQUEST',
            props: {
              media: 'video',
            },
          })
        );
      }
    };

    room.localParticipant
      .addListener('trackPublished', syncParticipants)
      .addListener('trackUnpublished', syncParticipants)
      .addListener('trackMuted', syncParticipants)
      .addListener('trackUnmuted', syncParticipants)
      .addListener('localTrackPublished', syncParticipants)
      .addListener('localTrackUnpublished', syncParticipants)
      .addListener('participantMetadataChanged', syncParticipants)
      .addListener('participantMetadataChanged', syncScreenShare)
      .addListener('participantMetadataChanged', syncUnmuteRequests)
      .addListener('participantNameChanged', syncParticipants);

    const onDisconnected = () => {
      if (getParticipantMetadata(roomRef.current.localParticipant).kicked) {
        postMessageBroadcastStopped();
        return;
      }

      postMessageNotReadyStatus();
      dispatch(
        openModal({
          component: MODALS.CONNECTION_LOST,
          props: {},
          hideBackdrop: false,
          id: MODALS.CONNECTION_LOST,
          locked: true,
        })
      );
    };

    const onReconnected = () => {
      postMessageReadyStatus(sessionId);
    };

    room
      .on('disconnected', onDisconnected)
      .on('reconnecting', postMessageNotReadyStatus)
      .on('reconnected', onReconnected)
      .on('reconnected', syncParticipants)
      .on('reconnected', syncScreenShare)
      .on('connected', syncParticipants)
      .on('connected', syncScreenShare)
      .on('participantConnected', syncParticipants)
      .on('participantDisconnected', syncParticipants)
      .on('participantMetadataChanged', syncParticipants)
      .on('participantNameChanged', syncParticipants)
      .on('trackMuted', syncParticipants)
      .on('trackUnmuted', syncParticipants)
      .on('trackPublished', syncParticipants)
      .on('trackUnpublished', syncParticipants)
      .on('roomMetadataChanged', syncScreenShare);

    return () => {
      room
        .off('disconnected', onDisconnected)
        .off('reconnecting', postMessageNotReadyStatus)
        .off('reconnected', onReconnected)
        .off('reconnected', syncParticipants)
        .off('reconnected', syncScreenShare)
        .off('connected', syncParticipants)
        .off('connected', syncScreenShare)
        .off('participantConnected', syncParticipants)
        .off('participantDisconnected', syncParticipants)
        .off('participantMetadataChanged', syncParticipants)
        .off('participantNameChanged', syncParticipants)
        .off('trackMuted', syncParticipants)
        .off('trackUnmuted', syncParticipants)
        .off('trackPublished', syncParticipants)
        .off('trackUnpublished', syncParticipants)
        .off('roomMetadataChanged', syncScreenShare);

      room.localParticipant
        .removeListener('trackPublished', syncParticipants)
        .removeListener('trackUnpublished', syncParticipants)
        .removeListener('trackMuted', syncParticipants)
        .removeListener('trackUnmuted', syncParticipants)
        .removeListener('localTrackPublished', syncParticipants)
        .removeListener('localTrackUnpublished', syncParticipants)
        .removeListener('participantMetadataChanged', syncParticipants)
        .removeListener('participantMetadataChanged', syncScreenShare)
        .removeListener('participantMetadataChanged', syncUnmuteRequests)
        .removeListener('participantNameChanged', syncParticipants);
    };
  }, [userId, dispatch, sessionId, sessionService]);

  return (
    <RoomContext.Provider value={roomRef.current}>
      <ReactiveRoomContext.Provider value={reactiveRoom}>{children}</ReactiveRoomContext.Provider>
    </RoomContext.Provider>
  );
};

type ContextType<T> = T extends Context<infer R> ? R : never;

const ReactiveRoomContext = createContext({
  participants: new Map<string, RemoteParticipant>(),
  localParticipantId: '',
  localParticipantName: '',
  metadata: {} as RoomMetadata,
  state: ConnectionState.Disconnected,
  setOwnCameraEnabled: (() => {}) as (enabled: boolean) => void,
  setOwnMicEnabled: (() => {}) as (enabled: boolean) => void,
});

export const useReactiveRoom = () => useContext(ReactiveRoomContext);

const getParticipantMetadata = (
  participant: Pick<Participant, 'metadata'>
): Partial<ParticipantMetadata> => {
  try {
    return JSON.parse(participant.metadata || '{}');
  } catch (error) {
    return {};
  }
};

const mapLkParticipantToStudioParticipant = (
  lkParticipant: Participant,
  userId: string | undefined
): TParticipant[] => {
  const metadata = getParticipantMetadata(lkParticipant);
  const baseParticipant: TParticipant = {
    audio: {
      isAllowed: !Boolean(metadata.audioBlocked),
      isEnabled: lkParticipant.isMicrophoneEnabled && !metadata.audioBlocked,
    },
    id: lkParticipant.identity,
    isHost: Boolean(metadata.isHost),
    isOnStage: Boolean(metadata.isOnStage),
    isScreenShare: false,
    order: metadata.order ?? 0,
    video: {
      isAllowed: !Boolean(metadata.videoBlocked),
      isEnabled: lkParticipant.isCameraEnabled && !metadata.videoBlocked,
    },
    displayName: lkParticipant.name || lkParticipant.identity,
    isSelf: lkParticipant.identity === userId,
    isSpeaking: false,
    connectionQuality: '',
    joinedAt: (lkParticipant.joinedAt ?? new Date()).getTime(),
    trackIds: [],
    kicked: metadata.kicked,
    isIngress: lkParticipant.identity.indexOf('ingress_') === 0,
  };

  const result: TParticipant[] = [baseParticipant];

  if (lkParticipant.isScreenShareEnabled) {
    result.push({
      ...baseParticipant,
      id: `${lkParticipant.identity}-sc`,
      isScreenShare: true,
      order: -Infinity,
      video: {
        isAllowed: true,
        isEnabled: true,
      },
    });
  }

  return result;
};
