import React, { useEffect } from 'react';
import { DestinationService } from 'services/DestinationService';
import { IntegrationService } from 'services/IntegrationService';
import { getSessionId, getSessionSecretToken } from 'store/slices/session';
import DestinationAuthService from 'services/DestinationAuth/DestinationAuthService';
import { TMaestroJwt, TSecretToken } from 'interfaces/newType';
import SessionService from 'services/SessionService';
import { useSelector } from 'react-redux';
import { safeDecodeJwt } from 'utils/parse-jwt';
import { MediaSourcesService } from 'services/MediaSourcesService';
import { SiteConfigDto } from 'interfaces/maestro-integration';
import { getProvidersConfig } from 'store/slices/siteConfig';
import { SessionMonitor } from 'services/SessionMonitor';
import store from 'store';

export const createDefaultServices = (
  sessionId: string = '',
  secretToken: TSecretToken = '' as TSecretToken,
  providersConfig?: SiteConfigDto['data']['destinations']['providers']
) => {
  const query = Object.fromEntries(new URLSearchParams(window.location.search).entries()) as {
    token?: TMaestroJwt;
    clientUrl?: string;
    siteId?: string;
  };

  const isValidJwt = safeDecodeJwt(query.token ?? '');

  const integrationService = isValidJwt ? new IntegrationService(query.token!) : null;
  const extraHeaders = { 'x-site-id': query.siteId, 'x-client-url': query.clientUrl };
  const destinationService = new DestinationService(sessionId, secretToken, extraHeaders);

  if (integrationService) destinationService.withMaestroIntegration(integrationService);

  const sessionService = new SessionService();

  return {
    sessionService,
    destinationAuthService: new DestinationAuthService(providersConfig),
    destinationService,
    integrationService,
    mediaSourcesService: MediaSourcesService,
    sessionMonitorService: new SessionMonitor(sessionService, store),
  };
};

const ServicesContext = React.createContext(createDefaultServices());

export const ServicesContextProvider: React.FC = ({ children }) => {
  const sessionId = useSelector(getSessionId);
  const secretToken = useSelector(getSessionSecretToken);
  const providersConfig = useSelector(getProvidersConfig);

  const [services, setServices] = React.useState(
    createDefaultServices(sessionId, secretToken, providersConfig)
  );

  useEffect(() => {
    const newServices = createDefaultServices(sessionId, secretToken, providersConfig);

    setServices((oldServices) => {
      oldServices.sessionMonitorService.destructor();

      return newServices;
    });
  }, [secretToken, sessionId, providersConfig]);

  return <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider>;
};

export const useServices = () => React.useContext(ServicesContext);
