import React, { useContext, useState } from 'react';
import styled, { ThemeContext, css } from 'styled-components/macro';

import { Text } from 'components/shared';

export type Positions = 'top' | 'right' | 'bottom' | 'left';

interface Props {
  position: Positions;
  text: string;
}

const styles = {
  top: css`
    left: 50%;
    transform: translateX(-50%);
    bottom: 130%;
    ::after {
      top: 100%;
      left: 50%;
      margin-left: -8px;
      border-color: ${(props) => props.theme.colors.surface4} transparent transparent transparent;
    }
  `,
  left: css`
    top: 50%;
    transform: translateY(-50%);
    right: 145%;
    ::after {
      top: 50%;
      transform: translateY(-50%);
      left: 98%;
      border-color: transparent transparent transparent ${(props) => props.theme.colors.surface4};
    }
  `,
  right: css``,
  bottom: css``,
};

const Tooltip: React.FC<Props> = ({ position, text, children }) => {
  const [active, setActive] = useState(false);
  const theme = useContext(ThemeContext);
  // let timeout: ReturnType<typeof setTimeout>;

  const showTooltip = () => {
    setActive(true);
  };

  const hideTooltip = () => {
    setActive(false);
  };

  return (
    <TooltipContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip} onClick={hideTooltip}>
      {children}
      <TooltipText active={active} positionStyles={styles[position]}>
        <Text size={11} weight={400} color={theme.colors.text200}>
          {text}
        </Text>
      </TooltipText>
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled.div<{ active: boolean; positionStyles: any }>`
  width: fit-content;
  box-sizing: border-box;
  height: 20px;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  align-items: center;
  text-align: center;

  background-color: ${(props) => props.theme.colors.surface4};
  border-radius: 4px;
  padding: 0 8px 0 8px;
  position: absolute;
  z-index: auto;
  transition: opacity 0.3s;
  white-space: nowrap;

  ${(props) => props.positionStyles}

  ::after {
    content: '';
    position: absolute;
    border-width: 8px;
    border-style: solid;
  }
  > span {
    font-family: 'Roboto';
  }
`;

export default Tooltip;
