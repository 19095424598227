import axios from 'axios';

import { ENDPOINTS } from 'constants/endpoints';
import { Destination, DestinationDto, TProvider } from 'interfaces/destinations';
import { MaestroUserDto, RoleDto, SiteDto } from 'interfaces/maestro-integration';
import { TMaestroJwt, TSecretToken } from 'interfaces/newType';
import { SessionDto } from 'interfaces/session';
import { handleError, handleResponse } from 'utils/http-response-handler';
import { mapClientDestinationToMaestroIntegrationDestinationDto } from 'utils/maestro-integration';
import { decodeJwt } from 'utils/parse-jwt';

interface IIntegrationService {
  getSession(sessionId: string, encodedJwt: TMaestroJwt): Promise<SessionDto>;
  updateChannelInviteCode(channelId: string, inviteCode: string): Promise<{ inviteCode: string }>;
  updateDestinations(destinations: DestinationDto[]): Promise<DestinationDto[]>;
  createDestination(destination: Destination): Promise<DestinationDto>;
}

export class IntegrationService implements IIntegrationService {
  private http;
  public readonly decodedJwt: { _id: string; siteId: string; roles: RoleDto[] };

  constructor(public readonly encodedJwt: TMaestroJwt) {
    this.http = axios.create({
      baseURL: `${ENDPOINTS.MAESTRO_INTEGRATION_BASE_URL}/`,
      headers: { Authorization: `Bearer ${this.encodedJwt}` },
    });
    this.http.interceptors.request.use((req) => {
      // auto trim trailing slashes
      req.url = req.url?.replace(/\/$/, '');
      return req;
    });

    this.decodedJwt = decodeJwt(encodedJwt);
  }

  async createSession(
    sessionId: string,
    secretToken: TSecretToken,
    channelId: string,
    inviteCode: string
  ): Promise<{ sessionId: string; secretToken: TSecretToken }> {
    return this.http
      .post(`/sites/${this.decodedJwt.siteId}/${channelId}/sessions`, {
        sessionId,
        secretToken,
        inviteCode,
      })
      .then(handleResponse)
      .catch(handleError);
  }

  async updateChannelInviteCode(channelId: string, inviteCode: string) {
    return this.http
      .put(`/sites/${this.decodedJwt.siteId}/${channelId}/inviteCode`, { inviteCode })
      .then(handleResponse)
      .catch(handleError);
  }

  async getSession(sessionId: string, encodedJwt: TMaestroJwt) {
    const { siteId } = decodeJwt<{ siteId: string }>(encodedJwt);
    return this.http
      .get(`/sites/${siteId}/sessions/${sessionId}`)
      .then(handleResponse)
      .catch(handleError);
  }

  async createDestination(destination: Destination): Promise<DestinationDto> {
    return this.http
      .post(`/sites/${this.decodedJwt.siteId}/maestroUsers/${this.decodedJwt._id}/destinations`, {
        ...mapClientDestinationToMaestroIntegrationDestinationDto(destination),
      })
      .then(handleResponse)
      .catch(handleError);
  }

  //FIXME: change to use the destinations endpoint
  async upsertDestination(acct: Destination) {
    return this.http
      .patch(`/sites/${this.decodedJwt.siteId}/maestroUsers/${this.decodedJwt._id}`, {
        destinations: [mapClientDestinationToMaestroIntegrationDestinationDto(acct)],
      })
      .then(handleResponse)
      .catch(handleError);
  }

  async removeDestination(provider: TProvider, acctName: string) {
    return this.http
      .delete(
        `/sites/${this.decodedJwt.siteId}/maestroUsers/${
          this.decodedJwt._id
        }/destinations/${provider.toLocaleLowerCase()}/${acctName}`
      )
      .then(handleResponse)
      .catch(handleError);
  }

  async getOrCreateSite(
    siteId: string,
    clientUrl: string
  ): Promise<{ site: SiteDto; created: boolean }> {
    const response = await this.http.get(`/sites/getOrCreate/${siteId}`, {
      headers: { 'x-client-url': clientUrl },
    });

    return {
      site: response.data,
      created: response.status === 201,
    };
  }

  async getOrCreateUser(): Promise<{ user: MaestroUserDto; created: boolean }> {
    try {
      const response = await this.http.get(
        `/sites/${this.decodedJwt.siteId}/maestroUsers/${this.decodedJwt._id}`
      );
      return { user: response.data, created: false };
    } catch (err) {
      if (!axios.isAxiosError(err) || err.response?.status !== 404) throw err;
      const response = await this.http.post(`/sites/${this.decodedJwt.siteId}/maestroUsers`, {
        _id: this.decodedJwt._id,
        // Going to just leave the destinations empty (or else other
        // users' destination accounts might be added undesirably)
        destinations: [],
        roles: this.decodedJwt.roles.map(
          // backend-services has a very strict deserialization, so we need
          // to make sure only the specified props are passed
          ({ scope, write, pageId }) => ({ scope, write, pageId })
        ),
      });
      return { user: response.data, created: true };
    }
  }

  async createUser(user: MaestroUserDto): Promise<MaestroUserDto> {
    return this.http
      .post(`/sites/${this.decodedJwt.siteId}/maestroUsers`, user)
      .then(handleResponse)
      .catch(handleError);
  }

  async getUser(): Promise<MaestroUserDto> {
    return this.http
      .get(`/sites/${this.decodedJwt.siteId}/maestroUsers/${this.decodedJwt._id}`)
      .then(handleResponse)
      .catch(handleError);
  }

  async setBroadcastId(sessionId: string, secretToken: TSecretToken, broadcastId: string) {
    return this.http
      .put(
        `/sessions/${sessionId}/broadcastId`,
        { broadcastId },
        { headers: { Authorization: `Bearer ${secretToken}` } }
      )
      .then(handleResponse)
      .catch(handleError);
  }

  async updateDestinations(destinations: DestinationDto[]) {
    return this.http
      .patch(
        `/sites/${this.decodedJwt.siteId}/maestroUsers/${this.decodedJwt._id}/destinations`,
        destinations
      )
      .then(handleResponse)
      .catch(handleError);
  }
}

export default IntegrationService;
