import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';

import { Text, Button } from 'components/shared';
import ModalHeader from 'components/shared/ModalHeader';
import { closeModal } from 'store/slices/ui';
import { removeParticipant } from 'store/slices/participants';
import { useServices } from 'hooks/useServices';
import { getSessionId } from 'store/slices/session';

interface Props {
  userId: string;
  displayName: string;
  onDismissModal: () => void;
}

const KickGuest: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const { userId, displayName } = props;
  const { sessionService } = useServices();
  const sessionId = useSelector(getSessionId);

  const handleProcessKickUser = () => {
    sessionService.kickParticipant(sessionId, userId);
    dispatch(removeParticipant(userId));
    dispatch(closeModal());
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(closeModal());
  };

  return (
    <Container>
      <ModalHeader showCloseButton onDismissModal={handleClose} />
      <Text size={16} weight={700} textAlign='center'>
        Are you sure you want to kick {displayName}?
      </Text>
      <Text size={14} weight={500} color={theme.colors.text300} textAlign='center'>
        The guest will not be able to rejoin your stream with their invite link.
      </Text>
      <ButtonsGrid>
        <Button color={'black'} onClick={handleClose}>
          <Text size={14} weight={700} style={{ textTransform: 'uppercase' }}>
            Cancel
          </Text>
        </Button>
        <Button onClick={handleProcessKickUser}>
          <Text size={14} weight={700} style={{ textTransform: 'uppercase' }}>
            Kick
          </Text>
        </Button>
      </ButtonsGrid>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 353px;
  height: 268px;
  gap: 25px;
  text-align: center;
  padding: 25px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 5px 5px 26px 5px rgba(0, 0, 0, 0.47);
  background-color: ${({ theme }) => theme.colors.surface5};
`;

const ButtonsGrid = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 9px;
`;

export default KickGuest;
