import React from 'react';
import styled, { useTheme } from 'styled-components';
import Frame from 'components/LayoutIcons/Frame';
import { Person } from 'components/shared/Icons';

const Screenshare_3: React.FC = () => {
  const { colors } = useTheme();
  return (
    <Container>
      <PersonContainer>
        <Person fill={colors.surface6} />
      </PersonContainer>
      <PersonContainer>
        <Person fill={colors.surface6} />
      </PersonContainer>
      <Frame color={colors.surface6} />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 7px 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: -1;
  gap: 2px;
  grid-template-areas:
    '. main'
    '. main';
  & > :last-child {
    grid-area: main;
  }
`;

const PersonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.surface5};
  border-radius: 2.559px;
  > svg {
    width: 10px;
    height: 10px;
  }
`;

export default Screenshare_3;
