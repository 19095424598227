import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  children: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

const Checkbox: React.FC<Props> = ({ children, onChange, checked }) => {
  return (
    <Container>
      {children}
      <CheckboxWrapper onChange={onChange} checked={checked} />
      <CheckMark></CheckMark>
    </Container>
  );
};

const Container = styled.label`
  display: inline-block;
  width: fit-content;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  :hover input ~ span {
    background-color: ${(props) => props.theme.colors.text200};
  }
`;

const CheckboxWrapper = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  :checked ~ span {
    background-color: ${(props) => props.theme.colors.accentPrimary + '!important'};
  }
  :checked ~ span:after {
    content: '';
    left: 4.5px;
    top: 0;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const CheckMark = styled.span`
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.text300};
  :after {
    content: '';
    position: absolute;
  }
`;

export default Checkbox;
