import {
  Destination,
  PROVIDERS,
  GroupedDestinations,
  IComponents,
  StatusType,
  TProvider,
} from 'interfaces/destinations';
import { TStrategies } from 'services/DestinationAuth/interfaces';

export const groupDestinations = (destinations: Destination[]) => {
  return destinations.reduce(
    (acc, curr) => {
      acc[curr.provider] = [...(acc[curr.provider] || []), curr];
      return acc;
    },
    {
      [PROVIDERS.maestro]: [],
      [PROVIDERS.facebook]: [],
      [PROVIDERS.youtube]: [],
      [PROVIDERS.twitch]: [],
    } as GroupedDestinations
  );
};

export const flattenDestinations = (destinations: GroupedDestinations): Destination[] => {
  return Object.values(destinations).reduce((acc, curr) => {
    return [...acc, ...curr];
  }, []);
};

export const removeDuplicate = (destinations: Destination[]) => {
  return destinations.filter((dest, idx, self) => {
    return idx === self.findIndex((e) => e.provider === dest.provider);
  });
};

export const capitalize = (str: string) => {
  return str.toLowerCase().replace(/\w/, (first) => first.toUpperCase());
};

export const presentDestinationStatus = (str: string) => {
  return capitalize(str).replace(/_/g, ' ');
};

export const initialValues = (formFieldValues: any, defaultValues: any) => {
  let omitNullAndEmpty = (obj: any) => {
    const newObj = { ...obj };
    Object.keys(newObj)
      .filter((k) => !newObj[k])
      .forEach((k) => delete newObj[k]);
    return newObj;
  };

  return { ...omitNullAndEmpty(defaultValues), ...omitNullAndEmpty(formFieldValues) };
};

export const getSelectOption = (option: string | undefined) => {
  return { value: option, label: capitalize(option || '') };
};

export const findByAccountName = (
  provider: TProvider,
  account: string,
  destinations: GroupedDestinations
) => {
  return destinations[provider].find((dest) => dest.account === account) ? true : false;
};

export const fmtDestName = (name: string) => {
  if (name === 'youtube') return 'YouTube';
  return capitalize(name) as TProvider;
};

export const buildDefaultMetadata = (title: string, description: string) => {
  return {
    [PROVIDERS.maestro]: {},
    [PROVIDERS.facebook]: {
      streamTo: '',
      title,
      description,
    },
    [PROVIDERS.youtube]: {
      title,
      description,
      category: 'Use my current Youtube category',
      visibility: 'public',
    },
    [PROVIDERS.twitch]: {
      title,
      category: 'Use my current Twitch category',
    },
  };
};

export const getTimeDiffInHours = (startDate: number, endDate: number): number => {
  return Math.abs(startDate - endDate) / (60 * 60 * 1000); // 60*60*1000, 1 hour in milliseconds
};

//NOTE: we should change this later to something more reliable like XState
const keysBuilder: Record<StatusType, IComponents[]> = {
  setup: ['connectButton', 'accountName'],
  connecting: ['rightMessage', 'ellipsis'],
  authorizing: ['rightMessage', 'ellipsis'],
  connection_successful: ['rightMessage'],
  ready: ['accountName', 'connectionStatus', 'cog', 'switch', 'body'],
  initializing: ['accountName', 'connectionStatus', 'ellipsis', 'body'],
  live: ['accountName', 'connectionStatus', 'cog', 'switch', 'body'],
  disconnected: ['accountName', 'connectionStatus', 'cog', 'switch', 'body'],
  unable_to_stream: ['accountName', 'connectionStatus', 'cog', 'switch', 'body'],
  lost_connection: ['accountName', 'connectionStatus', 'body'],
  authorization_unsuccessful: ['rightMessage', 'body'],
};

export const getComponentsMap = (status: StatusType) => {
  const map = {
    connectButton: false,
    alert: false,
    rightMessage: false,
    ellipsis: false,
    accountName: false,
    connectionStatus: false,
    cog: false,
    switch: false,
    body: false,
  };
  keysBuilder[status].forEach((key) => (map[key] = true));
  return map;
};

export const isValidStrategy = (strategy: any): strategy is TStrategies => {
  if (strategy === PROVIDERS.maestro) return false;
  return Object.keys(PROVIDERS).some((e) => e === strategy);
};

export const isValidProvider = (provider: any): provider is TProvider => {
  return Object.keys(PROVIDERS).some((e) => e === provider);
};

export const changeDestinationsStatus = (
  destinations: GroupedDestinations,
  from: StatusType[],
  to: StatusType
) => {
  //TODO: change to not need the flatten -> group
  return groupDestinations(
    flattenDestinations(destinations).map((destination) => {
      if (from.includes(destination.status)) {
        return { ...destination, status: to } as Destination;
        //NOTE: set connected false on BE from here or is the BE handling that?
      }
      return destination;
    })
  );
};
