// Allows you to throw an error in expressions. Example:

import axios from 'axios';

// const foo = query.bar || throwExpr('bar is undefined');
export function throwErr(msg: string): never {
  throw new Error(msg);
}

// Promise resolves after the passed amount of milliseconds.
export async function delay(durationMs: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), durationMs);
  });
}

export function logMaybeAxiosError(context: string, maybeAxiosErr: any) {
  if (axios.isAxiosError(maybeAxiosErr)) {
    console.error(`${context}: ${maybeAxiosErr.message}`);
  } else {
    console.error(`${context}: ${maybeAxiosErr}`);
  }
}

/**
 * This helps you manage cancelled/aborted promises. The main use case is avoiding
 * trying to perform a `setState` inside a `useEffect` after the component has
 * been unmounted or that effect's cleanup callback has been called. Here's an example:
 *
 * ```
 * const MyComponent = () => {
 *   const [data, setData] = useState(null);
 *
 *   // bad. if this promise resolves after the component is unmounted, we'll get an error
 *   // in the console saying that we're trying to update the state of an unmounted component
 *   useEffect(
 *     () => {
 *       fetchData()
 *         .then(setData)
 *     },
 *     [],
 *   );
 *
 *  // better. when the data fetching call resolves, we check if we should still do something
 *  // before calling `setData()`
 *  useEffect(
 *     () => {
 *       const signal = makeSignal();
 *
 *       Promise.race([
 *         fetchData(),
 *         signal.abortionPromise,
 *       ])
 *         .then((result) => {
 *           // since result comes from a `Promise.race`, it can have two different types
 *           // `singal.checkId` will narrow that down.
 *           if (signal.checkId(result)) return;
 *
 *           setData(result);
 *         });
 *
 *       return signal.abort;
 *     },
 *     [],
 *   );
 *
 *   return (
 *     <div />
 *   );
 * };
 * ```
 * @returns
 */
export const makeSignal = () => {
  let abort = () => {};
  let done = () => {};
  let isDone = false;
  let isAborted = false;
  const id = Object.freeze({ signal: true });
  type SignalId = typeof id;

  const completionPromise = new Promise<SignalId>((resolve) => {
    done = () => {
      resolve(id);
      isDone = true;
    };
  });

  const abortionPromise = new Promise<SignalId>((resolve) => {
    abort = () => {
      resolve(id);
      isAborted = true;
    };
  });

  return Object.freeze({
    abort,
    abortionPromise,
    isAborted: () => isAborted,
    done,
    completionPromise,
    isDone: () => isDone,
    checkId: (signalId: any): signalId is SignalId => signalId === id,
  });
};
