import { Switch, Text } from 'components/shared';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSessionIsHost } from 'store/slices/session';
import { SectionContainer, SwitchOptionsRow } from './styles';
import { useFormikContext } from 'formik';
import { useTheme } from 'styled-components';

export type SessionOptionsSettingsFormValues = {
  enableEchoCancellation: boolean;
  enableStereoAudio: boolean;
  enableDisplayNames: boolean;
};

export const SessionOptionsSettings: React.FC = () => {
  const isHost = useSelector(getSessionIsHost);
  const { values, setFieldValue } = useFormikContext<SessionOptionsSettingsFormValues>();
  const { enableDisplayNames, enableEchoCancellation, enableStereoAudio } = values;
  const { colors } = useTheme();

  return (
    <SectionContainer gridArea='options' style={{ justifyContent: 'space-evenly' }}>
      <SwitchOptionsRow>
        <Text size={12} weight={500} color={colors.text200}>
          Echo Cancellation
        </Text>
        <Switch
          width={30}
          height={15}
          onChange={() => {
            setFieldValue('enableEchoCancellation', !enableEchoCancellation);
            if (values.enableStereoAudio) setFieldValue('enableStereoAudio', !enableStereoAudio);
          }}
          checked={enableEchoCancellation}
        />
      </SwitchOptionsRow>
      <SwitchOptionsRow style={{ marginTop: '0px' }}>
        <Text size={12} weight={500} color={colors.text200}>
          Stereo Audio
        </Text>
        <Switch
          width={30}
          height={15}
          onChange={() => {
            setFieldValue('enableStereoAudio', !enableStereoAudio);
            if (values.enableEchoCancellation)
              setFieldValue('enableEchoCancellation', !enableEchoCancellation);
          }}
          checked={enableStereoAudio}
        />
      </SwitchOptionsRow>
      {isHost && (
        <SwitchOptionsRow style={{ marginTop: '0px' }}>
          <Text size={12} weight={500} color={colors.text200}>
            Display Names
          </Text>
          <Switch
            width={30}
            height={15}
            onChange={() => setFieldValue('enableDisplayNames', !enableDisplayNames)}
            checked={enableDisplayNames}
          />
        </SwitchOptionsRow>
      )}
    </SectionContainer>
  );
};
