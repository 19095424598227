import { Track } from 'livekit-client';
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';

const THRESHOLD = { min: 10, max: 100 };
const SIXTY_TIMES_PER_SECOND = 17;

const useAudioLevel = (track?: Track, externalStream?: MediaStream | null) => {
  const trackStream = useMemo(
    () => new MediaStream(track?.mediaStreamTrack ? [track.mediaStreamTrack] : []),
    [track?.mediaStreamTrack]
  );

  const stream = externalStream ?? trackStream;

  const audioContext = useRef<AudioContext>();
  const analyser = useRef<AnalyserNode>();
  const dataArray = useRef<Uint8Array>(new Uint8Array(0));
  const source = useRef<MediaStreamAudioSourceNode>();
  const audioData = useRef<Uint8Array>(new Uint8Array(0));
  const [audioLevel, setAudioLevel] = useState(0);

  const refreshAudioLevel = () => {
    analyser.current?.getByteTimeDomainData(dataArray.current);
    audioData.current = dataArray.current;
    let volume = getAudioLevel() * 1.5;
    if (volume <= THRESHOLD.min) volume = 0;
    else if (volume > THRESHOLD.max) volume = THRESHOLD.max;
    if (Number.isNaN(volume)) volume = 0;
    setAudioLevel(volume);
  };

  const getAudioLevel = () => {
    analyser.current?.getByteFrequencyData(audioData.current);
    const length = audioData.current.length;
    let total = 0;
    for (let i = 0; i < length; i++) {
      total += audioData.current[i];
    }
    return total / length;
  };

  useEffect(() => {
    if (stream) {
      if (stream.getAudioTracks().length === 0) return;
      let AudioContext = window.AudioContext || (window as any).webkitAudioContext;
      audioContext.current = new AudioContext();
      analyser.current = audioContext.current.createAnalyser();
      dataArray.current = new Uint8Array(analyser.current.frequencyBinCount);
      source.current = audioContext.current.createMediaStreamSource(stream);
      source.current.connect(analyser.current);
    }

    return () => {
      analyser.current?.disconnect();
      source.current?.disconnect();
    };
  }, [stream]);

  useEffect(() => {
    const interval = setInterval(refreshAudioLevel, SIXTY_TIMES_PER_SECOND);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { audioLevel };
};

const useBooleanAudioLevel = (track?: Track, externalStream?: MediaStream | null) => {
  const trackStream = useMemo(
    () => new MediaStream(track?.mediaStreamTrack ? [track.mediaStreamTrack] : []),
    [track?.mediaStreamTrack]
  );

  const stream = externalStream ?? trackStream;

  const audioContext = useRef<AudioContext>();
  const analyser = useRef<AnalyserNode>();
  const dataArray = useRef<Uint8Array>(new Uint8Array(0));
  const source = useRef<MediaStreamAudioSourceNode>();
  const audioData = useRef<Uint8Array>(new Uint8Array(0));
  const [audioLevel, setAudioLevel] = useState(false);

  const refreshAudioLevel = useCallback(() => {
    analyser.current?.getByteTimeDomainData(dataArray.current);
    audioData.current = dataArray.current;
    const volume = getAudioLevel() * 1.5;
    const isTalking = volume >= THRESHOLD.min ? true : false;
    if (audioLevel !== isTalking) setAudioLevel(isTalking);
  }, [audioLevel]);

  const getAudioLevel = () => {
    analyser.current?.getByteFrequencyData(audioData.current);
    const length = audioData.current.length;
    let total = 0;
    for (let i = 0; i < length; i++) {
      total += audioData.current[i];
    }
    return total / length;
  };

  useEffect(() => {
    if (stream) {
      if (stream.getAudioTracks().length === 0) return;
      let AudioContext = window.AudioContext || (window as any).webkitAudioContext;
      audioContext.current = new AudioContext();
      analyser.current = audioContext.current.createAnalyser();
      dataArray.current = new Uint8Array(analyser.current.frequencyBinCount);
      source.current = audioContext.current.createMediaStreamSource(stream);
      source.current.connect(analyser.current);
    }

    return () => {
      analyser.current?.disconnect();
      source.current?.disconnect();
    };
  }, [stream]);

  useEffect(() => {
    const interval = setInterval(refreshAudioLevel, 100);
    return () => clearInterval(interval);
  }, [refreshAudioLevel]);

  return audioLevel;
};

export default useAudioLevel;
export { useBooleanAudioLevel };
