import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppDispatch, AppThunk } from 'store';
import { RootState } from 'store/slices';

interface ProviderCredentials {
  clientId: string;
  clientSecret: string;
}

interface ISiteConfigStore {
  source: string;
  data: {
    privacyPolicyUrl: string;
    needHelpUrl: string;
    destinations: {
      defaultTitle: string;
      defaultDescription: string;
      providers: {
        facebook: ProviderCredentials;
        youtube: ProviderCredentials;
        twitch: ProviderCredentials;
      };
    };
  };
}

// Initial state
const initialState: ISiteConfigStore = {
  source: 'maestro',
  data: {
    privacyPolicyUrl: 'https://www.maestro.io/company/privacy-policy/',
    needHelpUrl: 'https://help.maestro.io/',
    destinations: {
      defaultTitle: 'Live with Maestro',
      defaultDescription: 'Powered by Maestro https://info.maestro.io',
      providers: {
        facebook: {
          clientId: '',
          clientSecret: '',
        },
        youtube: {
          clientId: '',
          clientSecret: '',
        },
        twitch: {
          clientId: '',
          clientSecret: '',
        },
      },
    },
  },
};

// Initializing slice
const siteConfigSlice = createSlice({
  name: 'siteConfig',
  initialState,
  reducers: {
    setPrivacyPolicyUrl(state, action: PayloadAction<string>) {
      state.data.privacyPolicyUrl = action.payload;
    },
    setNeedHelpUrl(state, action: PayloadAction<string>) {
      state.data.needHelpUrl = action.payload;
    },
    setSiteSource(state, action: PayloadAction<string>) {
      state.source = action.payload;
    },
    setAllConfig(state, action: PayloadAction<ISiteConfigStore>) {
      state.source = action.payload.source;
      state.data = action.payload.data;
    },
  },
});

// Selectors
const selectState = (state: RootState) => state.siteConfig;

export const getPrivacyPolicyUrl = createSelector(
  selectState,
  (state: ISiteConfigStore) => state.data.privacyPolicyUrl
);
export const getNeedHelpUrl = createSelector(
  selectState,
  (state: ISiteConfigStore) => state.data.needHelpUrl
);
export const getSiteSource = createSelector(selectState, (state: ISiteConfigStore) => state.source);

export const getDestinationTitle = createSelector(
  selectState,
  (state: ISiteConfigStore) => state.data.destinations.defaultTitle
);

export const getDestinationDescription = createSelector(
  selectState,
  (state: ISiteConfigStore) => state.data.destinations.defaultDescription
);

export const getProvidersConfig = createSelector(
  selectState,
  (state: ISiteConfigStore) => state.data.destinations.providers
);

// Actions
const { actions } = siteConfigSlice;

export const setAllConfig = (config: ISiteConfigStore): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setAllConfig(config));
  };
};

export default siteConfigSlice.reducer;
