import type { Modifier } from '@dnd-kit/core';
import {
  getEventCoordinates,
  isTouchEvent,
  hasViewportRelativeCoordinates,
} from '@dnd-kit/utilities';

// isMouseEvent was removed in this commit: https://github.com/clauderic/dnd-kit/commit/0e628bce53fb1a7223cdedd203cb07b6e62e5ec1
function isMouseEvent(event: Event): boolean {
  return hasViewportRelativeCoordinates(event);
}

const snapCenterToCursorCustom: Modifier = ({
  activatorEvent,
  activeNodeRect,
  transform,
  draggingNodeRect,
}) => {
  if (
    activeNodeRect &&
    activatorEvent &&
    draggingNodeRect &&
    (isTouchEvent(activatorEvent) || isMouseEvent(activatorEvent))
  ) {
    const activatorCoordinates = getEventCoordinates(activatorEvent) ?? {
      // Before `getEventCoordinates` was changed to return a nullable, it
      // returned 0, 0: https://github.com/clauderic/dnd-kit/blob/13be602229c6d5723b3ae98bca7b8f45f0773366/packages/utilities/src/coordinates/getEventCoordinates.ts
      x: 0,
      y: 0,
    };
    const offsetX = activatorCoordinates.x - activeNodeRect.left;
    const offsetY = activatorCoordinates.y - activeNodeRect.top;
    return {
      ...transform,
      x: transform.x + offsetX - draggingNodeRect.width / 2,
      y: transform.y + offsetY - draggingNodeRect.height / 2,
    };
  }

  return transform;
};

export { snapCenterToCursorCustom };
