import React from 'react';
import { useParticipantById } from 'hooks/useParticipantById';
import { useSelector } from 'react-redux';
import Tooltip from 'components/Tooltip';
import { Cam, CamOff, Mic, MicOff } from 'components/shared/Icons';
import { MediaState } from 'interfaces/session';
import { MediaButton } from 'components/VideoCard/styles';
import { getSessionId, getSessionMediaConstraints } from 'store/slices/session';
import { getIsDeviceAuthorized } from 'components/DeviceNotAuthorizedAlert/DeviceNotAuthorizedAlert';
import { useTheme } from 'styled-components';
import { Track } from 'livekit-client';
import { useReactiveRoom } from 'contexts/LiveKitContext';
import { useServices } from 'hooks/useServices';

interface Props {
  id: string;
  isSelf: boolean;
  audio: MediaState;
  video: MediaState;
  isScreenShare: boolean;
  fontSize: number;
  iconSize: number;
}

export const MediaControls: React.FC<Props> = ({
  id,
  isSelf,
  audio,
  video,
  isScreenShare,
  iconSize,
}) => {
  const studioParticipant = useParticipantById(id);
  const mediaConstraints = useSelector(getSessionMediaConstraints);
  const isDeviceAuthorized = getIsDeviceAuthorized(mediaConstraints);

  const sessionId = useSelector(getSessionId);

  const { participants: lkParticipants, setOwnCameraEnabled, setOwnMicEnabled } = useReactiveRoom();

  const { sessionService } = useServices();

  const { colors } = useTheme();

  const handleToggleAudio = () => {
    if (!studioParticipant) return;

    if (isSelf) {
      const { isEnabled } = audio;
      if (!isDeviceAuthorized.audio) return;

      setOwnMicEnabled(!isEnabled);
    } else {
      if (!id) return;

      lkParticipants.forEach((lkParticipant) => {
        if (lkParticipant.identity !== id) return;

        const isEnabled = studioParticipant?.audio.isEnabled;

        for (const audioTrack of lkParticipant.audioTracks.values()) {
          if (isScreenShare && audioTrack.source !== Track.Source.ScreenShareAudio) {
            return;
          }

          if (!isScreenShare && audioTrack.source === Track.Source.ScreenShareAudio) {
            return;
          }

          sessionService.updateParticipantMetadata(sessionId, id, {
            audioBlocked: isEnabled,
          });

          break;
        }

        if (!isEnabled) {
          sessionService.updateParticipantMetadata(sessionId, id, {
            audioUnmuteRequested: true,
          });
        }
      });
    }
  };

  const handleToggleVideo = () => {
    if (!studioParticipant) return;

    if (isSelf) {
      const { isEnabled } = video;
      if (!isDeviceAuthorized.video) return;

      setOwnCameraEnabled(!isEnabled);
    } else {
      if (!id) return;

      let participantIsntPublishingVideo = true;

      lkParticipants.forEach((participant) => {
        if (participant.identity !== id) return;

        participant.videoTracks.forEach((videoTrack) => {
          if (isScreenShare && videoTrack.source !== Track.Source.ScreenShare) {
            return;
          }

          if (!isScreenShare && videoTrack.source === Track.Source.ScreenShare) {
            return;
          }

          participantIsntPublishingVideo = false;

          const isEnabled = studioParticipant?.video.isEnabled;
          videoTrack.setEnabled(!isEnabled);
          sessionService.updateParticipantMetadata(sessionId, id, {
            videoBlocked: isEnabled,
          });
        });
      });

      if (participantIsntPublishingVideo) {
        sessionService.updateParticipantMetadata(sessionId, id, {
          videoUnmuteRequested: true,
        });
      }
    }
  };

  if (isScreenShare) return null;

  return (
    <>
      <Tooltip
        position='left'
        text={audio.isEnabled ? 'Mute' : isSelf ? 'Unmute' : 'Ask to Unmute'}
      >
        <MediaButton alwaysShow={!audio.isEnabled} iconSize={iconSize} onClick={handleToggleAudio}>
          {audio.isEnabled ? (
            <Mic width={iconSize} height={iconSize} fill={colors.text100} />
          ) : (
            <MicOff width={iconSize} height={iconSize} fill={colors.text100} />
          )}
        </MediaButton>
      </Tooltip>
      <Tooltip
        position='left'
        text={video.isEnabled ? 'Stop Video' : isSelf ? 'Start Video' : 'Ask to Start Video'}
      >
        <MediaButton data-testid='videoButton' iconSize={iconSize} onClick={handleToggleVideo}>
          {video.isEnabled ? (
            <Cam width={iconSize} height={iconSize} fill={colors.text100} stroke={colors.text100} />
          ) : (
            <CamOff width={iconSize} height={iconSize} fill={colors.text100} />
          )}
        </MediaButton>
      </Tooltip>
    </>
  );
};
