import React, { MouseEvent, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Collapsible from 'react-collapsible';
import { useDispatch, useSelector } from 'react-redux';

import { Text, Button } from 'components/shared';
import { CheckMark, PersonAdd, Plus, Clear } from 'components/shared/Icons';
import {
  getMaestroChannelId,
  getSessionId,
  setSessionInviteCode,
  getMaestroGuestInviteLink,
} from 'store/slices/session';
import { useServices } from 'hooks/useServices';

const InviteGuestPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { sessionService, integrationService } = useServices();
  const channelId = useSelector(getMaestroChannelId);
  const sessionId = useSelector(getSessionId);
  const maestroGuestURL = useSelector(getMaestroGuestInviteLink);
  const [showCopiedButton, setShowCopiedButton] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { colors } = useTheme();

  const handleCopyLink = async (e: MouseEvent<HTMLButtonElement>) => {
    try {
      e.stopPropagation();
      // TODO: what if the token is not valid anymore, how are we handling that
      await navigator.clipboard.writeText(maestroGuestURL);
      setShowCopiedButton(true);
      setTimeout(() => setShowCopiedButton(false), 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateNewInviteCode = async () => {
    try {
      const { inviteCode: newCode } = await sessionService.regenerateInviteCode(sessionId);
      await integrationService?.updateChannelInviteCode(channelId, newCode);
      dispatch(setSessionInviteCode(newCode));
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleExpanded = () => setIsExpanded((prevIsExpanded) => !prevIsExpanded);

  return (
    <Container>
      <Collapsible
        open={isExpanded}
        transitionTime={220}
        trigger={
          <HeaderContainer data-testid='inviteGuestDetailToggle' onClick={handleToggleExpanded}>
            {showCopiedButton ? (
              <HeaderContentContainer>
                <CheckMark width={14} height={14} fill={colors.alertConfirmation} />
                <Text size={14} weight={500} color={colors.text100}>
                  Guest invite link copied to clipboard
                </Text>
              </HeaderContentContainer>
            ) : (
              <HeaderContentContainer>
                <LeftContainer>
                  <PersonAdd fill={colors.text100} width={17} height={15} />
                  <Text color={colors.text200} size={16} weight={600}>
                    Invite Guest
                  </Text>
                </LeftContainer>

                <RightContainer>
                  <Button
                    data-testid='copyInviteLinkButton'
                    style={{ height: 24, width: 103, padding: 0 }}
                    onClick={handleCopyLink}
                  >
                    <Text size={12} weight={700} style={{ textTransform: 'uppercase' }}>
                      Copy Link
                    </Text>
                  </Button>
                  {isExpanded ? (
                    <Clear width={24} height={24} fill={colors.text100} />
                  ) : (
                    <Plus width={24} height={24} fill={colors.text100} />
                  )}
                </RightContainer>
              </HeaderContentContainer>
            )}
          </HeaderContainer>
        }
      >
        <ContentContainer>
          <Text size={14} weight={400} color={colors.text100}>
            Generating a new invite link will disable the previous link for guests.
          </Text>
          <InviteLinkInput data-testid='inviteLinkText' value={maestroGuestURL} readOnly />
          <Button
            data-testid='genNewInviteLinkButton'
            color='transparent'
            shape='outlined'
            fullWidth
            style={{ marginBottom: 5 }}
            onClick={handleGenerateNewInviteCode}
          >
            <Text size={13} weight={700} style={{ textTransform: 'uppercase' }}>
              Generate New Invite Link
            </Text>
          </Button>
        </ContentContainer>
      </Collapsible>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.surface2};
  padding: 0px 15px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.surface6};
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
`;

const LeftContainer = styled.div`
  display: flex;
  gap: 11px;
  align-items: center;
  flex: 1;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  width: 130px;
  justify-content: flex-end;
  gap: 10px;
`;

const HeaderContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 10px 15px 10px;
  box-sizing: border-box;
`;

const InviteLinkInput = styled.input`
  border: 0.5px solid ${({ theme }) => theme.colors.surface6};
  outline: none;
  font-family: 'Noto Sans';
  font-size: 13px;
  height: 39px;
  font-weight: 400;
  padding: 5px 5px;
  box-sizing: border-box;
  background: transparent;
  color: white;
  border-radius: 5px;
  width: 100%;
`;

export default InviteGuestPanel;
