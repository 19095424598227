import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppDispatch, AppThunk } from 'store';
import { RootState } from 'store/slices';
import { ErrorDrawerType, UIStore } from 'interfaces/ui';
import { ModalConfig, OpenModalArgs } from 'interfaces/modals';
import { v4 as uuid } from 'uuid';
import { TParticipant } from 'interfaces/participants';
import { getParticipantById, getParticipants } from './participants';

// Initial state
const initialState: UIStore = {
  modal: {
    visible: false,
    type: null,
    params: {},
  },
  modalStack: [],
  isCompatibilityAlertVisible: true,
  isBackstageVisible: true,
  isPanelsVisible: true,
  peerBeingDragged: null,
  isDrawerPanelVisible: false,
  peerSoloViewId: null,
  peerScreenShareId: null,
  errorDrawer: {
    visible: false,
    type: 'NONE',
    params: {},
  },
  isLoadingSkeletonActive: false,
  isMaxPeopleReached: false,
};

// Initializing slice
const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsBackstageVisible(state, action: PayloadAction<boolean>) {
      state.isBackstageVisible = action.payload;
    },
    setPeerBeingDragged(state, action: PayloadAction<TParticipant | null>) {
      state.peerBeingDragged = action.payload;
    },
    setIsDrawerPanelVisible(state, action: PayloadAction<boolean>) {
      state.isDrawerPanelVisible = action.payload;
    },
    setPeerSoloViewId(state, action: PayloadAction<string | null>) {
      state.peerSoloViewId = action.payload;
    },
    setPeerScreenShareId(state, action: PayloadAction<string | null>) {
      state.peerScreenShareId = action.payload;
    },
    setErrorDrawerVisible(state, action: PayloadAction<boolean>) {
      state.errorDrawer.visible = action.payload;
    },
    setErrorDrawerType(state, action: PayloadAction<{ type: ErrorDrawerType; params?: any }>) {
      state.errorDrawer.type = action.payload.type;
      state.errorDrawer.params = action.payload.params || {};
    },
    setIsLoadingSkeletonActive(state, action: PayloadAction<boolean>) {
      state.isLoadingSkeletonActive = action.payload;
    },
    setIsCompatibilityAlertVisible(state, action: PayloadAction<boolean>) {
      state.isCompatibilityAlertVisible = action.payload;
    },
    setModalStack(state, action: PayloadAction<ModalConfig[]>) {
      state.modalStack = action.payload;
    },
  },
});

// Selectors
const selectState = (state: RootState) => state.ui;

export const getIsBackstageVisible = createSelector(
  selectState,
  (state: UIStore) => state.isBackstageVisible
);
export const getIsPanelsVisible = createSelector(
  selectState,
  (state: UIStore) => state.isPanelsVisible
);
export const getPeerBeingDragged = createSelector(
  selectState,
  (state: UIStore) => state.peerBeingDragged
);
export const getIsDrawerPanelVisible = createSelector(
  selectState,
  (state: UIStore) => state.isDrawerPanelVisible
);
export const getPeerSoloViewId = createSelector(
  selectState,
  (state: UIStore) => state.peerSoloViewId
);
export const getPeerScreenShareId = createSelector(
  (state: RootState) => state.participants.participants,
  (participants) => participants.find((t) => t.isScreenShare)?.id ?? null
);

export const getIsErrorDrawerVisible = createSelector(
  selectState,
  (state: UIStore) => state.errorDrawer.visible
);

export const getIsLoadingSkeletonActive = createSelector(
  selectState,
  (state: UIStore) => state.isLoadingSkeletonActive
);

export const getIsMaxPeopleReached = createSelector(
  selectState,
  (state: UIStore) => state.isMaxPeopleReached
);

export const getIsCompatibilityAlertVisible = createSelector(
  selectState,
  (state: UIStore) => state.isCompatibilityAlertVisible
);

export const getModalStack = createSelector(selectState, (state: UIStore) => state.modalStack);

export const getErrorDrawer = createSelector(selectState, (state: UIStore) => state.errorDrawer);

// Actions
const { actions } = uiSlice;

export const setIsBackstageVisible = (isVisible: boolean): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setIsBackstageVisible(isVisible));
  };
};

export const setPeerBeingDragged = (peer: TParticipant | null): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setPeerBeingDragged(peer));
  };
};

export const setIsDrawerPanelVisible = (isVisible: boolean): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setIsDrawerPanelVisible(isVisible));
  };
};

export const setPeerSoloViewId = (id: string | null): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    const state = getState();

    if (!id) {
      dispatch(actions.setPeerSoloViewId(null));
      return;
    }

    const newSoloPeer = getParticipantById(state, id);

    if (newSoloPeer?.isOnStage) {
      dispatch(actions.setPeerSoloViewId(id));
      return;
    }

    const fallbackSoloPeer = getParticipants(state).find((p) => p.isOnStage);

    if (fallbackSoloPeer) {
      dispatch(actions.setPeerSoloViewId(fallbackSoloPeer.id));
    }
  };
};

export const setPeerScreenShareId = (id: string | null) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setPeerScreenShareId(id));
  };
};

export const setErrorDrawerVisible = (isVisible: boolean): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setErrorDrawerVisible(isVisible));
  };
};

export const setErrorDrawerType = (type: ErrorDrawerType, params?: any): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setErrorDrawerType({ type, params }));
  };
};

export const setIsLoadingSkeletonActive = (loading: boolean): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setIsLoadingSkeletonActive(loading));
  };
};

export const setIsCompatibilityAlertVisible = (isVisible: boolean): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setIsCompatibilityAlertVisible(isVisible));
  };
};

export const openModal = (args: OpenModalArgs) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      ui: { modalStack },
    } = getState();

    const stack = modalStack.filter(({ id }) => id !== args.id);

    const modalConfig = {
      ...args,
      id: args.id ?? uuid(),
    };

    dispatch(actions.setModalStack([...stack, modalConfig]));
  };
};

export const closeModal = (id?: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      ui: { modalStack },
    } = getState();

    if (id) return dispatch(actions.setModalStack(modalStack.filter((config) => id !== config.id)));
    dispatch(actions.setModalStack(modalStack.slice(0, modalStack.length - 1)));
  };
};

export const closeAllModals = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      ui: { modalStack },
    } = getState();

    dispatch(actions.setModalStack(modalStack.filter(({ locked }) => locked)));
  };
};

export default uiSlice.reducer;
