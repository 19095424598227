import React from 'react';
import styled from 'styled-components';
import Frame from 'components/LayoutIcons/Frame';

const Landscape: React.FC = () => {
  return (
    <Container>
      <Frame />
      <Frame />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 27px;
  gap: 3px;
  padding: 23px 8px;
  width: 100%;
  height: 100%;
  place-content: center;
`;

export default Landscape;
