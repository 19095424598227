import { ENDPOINTS } from 'constants/endpoints';
import { SiteConfigDto } from 'interfaces/maestro-integration';
import { TMaestroJwt } from 'interfaces/newType';
import { BaseService } from './BaseService';

interface ISiteConfigService {
  getSiteConfigBySiteId(siteId: string, clientUrl: string): Promise<SiteConfigDto>;
  updateSiteConfig(
    siteId: string,
    maestroJwt: TMaestroJwt,
    payload: UpdateSiteConfigPayload
  ): Promise<SiteConfigDto>;
}

export default class SiteConfigService extends BaseService implements ISiteConfigService {
  constructor() {
    super(new URL(ENDPOINTS.MAESTRO_INTEGRATION_BASE_URL), {
      'Content-Type': 'application/json',
    });
  }

  async getSiteConfigBySiteId(siteId: string, clientUrl: string): Promise<SiteConfigDto> {
    return this.withHeaders({ 'x-client-url': clientUrl }).get<SiteConfigDto>(
      `/sites/getWhiteLabelConfig/${siteId}`
    );
  }

  /**
   * Updates the siteConfig with data coming from maestro web. Only for hosts with a valid maestroJwt
   */
  async updateSiteConfig(
    siteId: string,
    maestroJwt: TMaestroJwt,
    payload: UpdateSiteConfigPayload
  ): Promise<SiteConfigDto> {
    return this.withHeaders({ Authorization: `Bearer ${maestroJwt}` }).put<SiteConfigDto>(
      `/sites/updateSiteConfig/${siteId}`,
      payload
    );
  }
}

type UpdateSiteConfigPayload = Pick<SiteConfigDto['data'], 'needHelpUrl' | 'privacyPolicyUrl'>;
