import React from 'react';
import styled from 'styled-components';

import { Button, Text } from 'components/shared';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'store/slices/ui';
import { getSessionId } from 'store/slices/session';
import { useServices } from 'hooks/useServices';
import { useReactiveRoom } from 'contexts/LiveKitContext';

interface Props {
  onDismissModal: () => void;
}

const ScreenShareDenied: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { sessionService } = useServices();
  const { localParticipantId, metadata } = useReactiveRoom();
  const sessionId = useSelector(getSessionId);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(closeModal());

    const newMetadata = { ...metadata };
    newMetadata.screenShareRequests = newMetadata.screenShareRequests ?? {};

    for (const screenShareRequestId in newMetadata.screenShareRequests) {
      if (
        newMetadata.screenShareRequests[screenShareRequestId]?.participantId === localParticipantId
      )
        delete newMetadata.screenShareRequests[screenShareRequestId];
    }

    sessionService.updateRoomMetadata(sessionId, newMetadata);
  };

  return (
    <Container>
      <Text size={18} weight={600} textAlign='center'>
        Host has denied your screen share request.
      </Text>
      <Button onClick={handleClick} fullWidth>
        <Text size={14} weight={700}>
          Close
        </Text>
      </Button>
    </Container>
  );
};

const Container = styled.div`
  width: 316px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.surface5};
  box-shadow: 5px 5px 26px 5px rgba(0, 0, 0, 0.47);
  border-radius: 10px;
`;

export default ScreenShareDenied;
