import React from 'react';
import styled from 'styled-components';

import { Button, Text } from 'components/shared';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'store/slices/ui';
import { useRoomContext } from '@livekit/components-react';
import { useServices } from 'hooks/useServices';
import { getSessionId } from 'store/slices/session';
import { useReactiveRoom } from 'contexts/LiveKitContext';

const ScreenShareAllowed: React.FC = () => {
  const dispatch = useDispatch();
  const { localParticipant } = useRoomContext();
  const { sessionService } = useServices();
  const sessionId = useSelector(getSessionId);
  const { metadata } = useReactiveRoom();

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(closeModal());
    sessionService.updateRoomMetadata(sessionId, { ...metadata, screenShareRequests: undefined });
    localParticipant.setScreenShareEnabled(true, { audio: true });
  };

  return (
    <Container>
      <Text size={18} weight={600} textAlign='center'>
        Host has allowed your screen share request.
      </Text>
      <Button onClick={handleClick} fullWidth>
        <Text size={14} weight={700}>
          Share Screen
        </Text>
      </Button>
    </Container>
  );
};

const Container = styled.div`
  width: 316px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 5px 5px 26px 5px rgba(0, 0, 0, 0.47);
  background-color: ${({ theme }) => theme.colors.surface5};
  border-radius: 10px;
`;

export default ScreenShareAllowed;
