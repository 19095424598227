import { v4 as uuid } from 'uuid';
import { PROVIDERS, StatusType } from 'interfaces/destinations';
import {
  MaestroCommand,
  MaestroMessagePayload,
  STUDIO_MESSAGE_SOURCE,
  MAESTRO_MESSAGE_SOURCE,
  StudioMessageTypes,
} from 'interfaces/maestro-messages';
import { TMaestroJwt, TSecretToken } from 'interfaces/newType';
import { IntegrationService } from 'services/IntegrationService';
import SessionService from 'services/SessionService';
import { AppDispatch, AppThunk } from 'store';
import { RootState } from 'store/slices';
import { setSessionIsLive } from 'store/slices/session';
import { delay, logMaybeAxiosError } from 'utils';
import {
  setAllDestinations,
  setDestinationStatus,
  updateDestination,
} from '../slices/destinations';
import { changeDestinationsStatus, flattenDestinations } from 'utils/destination';
import DestinationService from 'services/DestinationService';
import { getOptionalQueryParams } from 'utils/methods';
import { getTheme } from 'contexts/ThemeContextProvider';

let integrationService = (() => {
  let service: IntegrationService | undefined;
  return (jwt?: TMaestroJwt) => {
    if (!service && jwt) {
      service = new IntegrationService(jwt);
    }
    return service;
  };
})();

export const onMaestroMessageReceived = (message: { data: any }) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { sessionId, maestroJwt, secretToken, inviteCode } = state.session;
    const { command } = message.data as MaestroMessagePayload;
    const broadcastId = uuid();

    if (!isMaestroPostMessage(message.data)) return;

    if (!sessionId || !maestroJwt || !secretToken) {
      console.warn('sessionId, maestroJwt, or secretToken is not set, discarding post message');
      return;
    }

    integrationService(maestroJwt);

    if (command === MaestroCommand.start) {
      try {
        await setBroadcastIdInMaestroIntegration(broadcastId, sessionId, secretToken);
      } catch (e) {
        console.error(`Setting broadcast ID: ${e}`);
      }

      dispatch(onStartStudioBroadcast());
    } else if (command === MaestroCommand.stop) {
      dispatch(onStopStudioBroadcast());
    } else if (command === MaestroCommand.requestInviteCode) {
      postMessageMaestroInviteCode(inviteCode);
    }
  };
};

async function setBroadcastIdInMaestroIntegration(
  broadcastId: string,
  sessionId: string,
  secretToken: TSecretToken
) {
  if (!integrationService()) {
    throw new Error('Integration service is not initialized');
  }

  // TODO: we should have a generic library function to do this
  // We use a retry loop because it is important that this gets set.
  for (let retries = 0; retries < 10; retries++) {
    try {
      return await integrationService()!.setBroadcastId(sessionId, secretToken, broadcastId);
    } catch (err) {
      logMaybeAxiosError('Error setting broadcastId', err);
      await delay(1000);
    }
  }

  throw new Error('Failed to set broadcastId in maestro integration');
}

export const onStartStudioBroadcast = (): AppThunk => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      session: { sessionId, secretToken, isLive, maestroJwt },
      destinations: { destinations },
    } = getState();

    try {
      if (!isLive) {
        dispatch(
          setAllDestinations(
            changeDestinationsStatus(destinations, [StatusType.ready], StatusType.initializing)
          )
        );

        const { streamKey: maestroStreamKey, ingestUrl: maestroIngestUrl } =
          getOptionalQueryParams();

        const rtmps: { rtmpUrl: string; rtmpKey: string }[] = [];

        if (maestroStreamKey && maestroIngestUrl) {
          rtmps.push({
            rtmpKey: maestroStreamKey,
            rtmpUrl: maestroIngestUrl,
          });
        }

        const sessionService = new SessionService();

        const connectedDestinations = flattenDestinations(destinations).filter((d) => d.connected);

        const { ids: destinationsLiveInOtherSessions } = await sessionService.liveDestinations(
          sessionId,
          connectedDestinations
        );

        const integration = integrationService(maestroJwt);
        const destinationService = new DestinationService(sessionId, secretToken);
        integration && destinationService.withMaestroIntegration(integration);

        await Promise.all(
          connectedDestinations.map(async (d) => {
            if (d.provider === 'maestro') return;

            const destination = { ...d };
            const alreadyStreaming = destinationsLiveInOtherSessions.includes(destination.id);

            if (alreadyStreaming) {
              destination.connected = false;
              destination.status = StatusType.unable_to_stream;
              destination.data = {
                ...destination.data,
                errorType: 'ALREADY_STREAMING_DESTINATION',
              };
            } else if (destination.rtmp.rtmpUrl && destination.rtmp.streamKey) {
              destination.status = StatusType.live;
              rtmps.push({
                rtmpKey: destination.rtmp.streamKey,
                rtmpUrl: destination.rtmp.rtmpUrl,
              });
            }

            dispatch(updateDestination(destination)); // updates our store and UI
            await destinationService.updateDestination(destination); // updates our db
          })
        );

        await sessionService.startRtmpOutput(sessionId, rtmps, {
          theme: JSON.stringify(getTheme()),
        });
        await sessionService.setSessionLive(sessionId, true);

        dispatch(setSessionIsLive(true));
        dispatch(setDestinationStatus(PROVIDERS.maestro, '', StatusType.live));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const onStopStudioBroadcast = (): AppThunk => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const sessionService = new SessionService();
    const {
      session: { sessionId, isLive, maestroJwt, secretToken },
      destinations: { destinations },
    } = getState();

    try {
      if (!isLive) {
        console.warn('This session is not live!');
      }

      const integration = integrationService(maestroJwt);
      const destinationService = new DestinationService(sessionId, secretToken);
      integration && destinationService.withMaestroIntegration(integration);

      const rtmps: { rtmpKey: string; rtmpUrl: string }[] = [];

      const { streamKey: maestroStreamKey, ingestUrl: maestroIngestUrl } = getOptionalQueryParams();

      if (maestroStreamKey && maestroIngestUrl) {
        rtmps.push({
          rtmpKey: maestroStreamKey,
          rtmpUrl: maestroIngestUrl,
        });
      }

      const updateDestinationTasks: Promise<unknown>[] = [];

      flattenDestinations(destinations).forEach((destination) => {
        if (destination.provider === 'maestro') return;

        if (
          destination.status === StatusType.live &&
          destination.rtmp.rtmpUrl &&
          destination.rtmp.streamKey
        ) {
          rtmps.push({
            rtmpKey: destination.rtmp.streamKey,
            rtmpUrl: destination.rtmp.rtmpUrl,
          });
          const newDestination = { ...destination, status: StatusType.ready };
          dispatch(updateDestination(newDestination));
          updateDestinationTasks.push(destinationService.updateDestination(newDestination));
        }
      });

      await Promise.all([
        sessionService.stopRtmpOutput(sessionId, rtmps),
        sessionService.setSessionLive(sessionId, false),
        Promise.all(updateDestinationTasks),
      ]);

      dispatch(setSessionIsLive(false));
    } catch (error) {
      //TODO: handle error how? Using this endpoint we don't know which destinations fail or not
      console.error(error);
    }
  };
};

export const postMessageBroadcastStopped = () => {
  window.parent.postMessage({ action: StudioMessageTypes.end, source: STUDIO_MESSAGE_SOURCE }, '*');
};

export const postMessageBroadcastStalled = () => {
  window.parent.postMessage(
    { action: StudioMessageTypes.stalled, source: STUDIO_MESSAGE_SOURCE },
    '*'
  );
};

export const postMessageReadyStatus = (sessionId: string) => {
  window.parent.postMessage(
    { action: StudioMessageTypes.ready, sessionId, source: STUDIO_MESSAGE_SOURCE },
    '*'
  );
};

export const postMessageNotReadyStatus = () => {
  window.parent.postMessage(
    { action: StudioMessageTypes.notReady, source: STUDIO_MESSAGE_SOURCE },
    '*'
  );
};

export const postMessageMaestroInviteCode = (inviteCode: string) => {
  window.parent.postMessage(
    {
      action: StudioMessageTypes.inviteCode,
      inviteCode,
      source: STUDIO_MESSAGE_SOURCE,
    },
    '*'
  );
};

const isMaestroPostMessage = (message: any): message is MaestroMessagePayload => {
  if (!message) return false;

  const { command, source } = message as MaestroMessagePayload;

  if (!Object.values(MaestroCommand).includes(command)) return false;

  if (source !== MAESTRO_MESSAGE_SOURCE) return false;

  return true;
};
