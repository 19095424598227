import {
  createContext,
  FC,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { getSessionMediaConstraints } from 'store/slices/session';
import { MediaConstraints } from 'interfaces/session';
import { DevicesByType, MediaSourcesService } from 'services/MediaSourcesService';
import { makeSignal } from 'utils';

interface IMediaState {
  audio: boolean;
  video: boolean;
}

export const MediaSourcesContext = createContext(
  {} as {
    devicesByType: DevicesByType;
    isLoading: boolean;
    isStreamChanging: boolean;
    isUserMediaAllowed: boolean;
    mediaConstraints: MediaConstraints;
    setMediaConstraints: Dispatch<SetStateAction<MediaConstraints>>;
    mediaStream: MediaStream | undefined;
    initMediaSources: () => void;
    mediaEnabled: IMediaState;
    setMediaEnabled: Dispatch<SetStateAction<IMediaState>>;
    supportsOutputAudioDeviceSelection: boolean;
  }
);

export const MediaSourcesContextProvider: FC = ({ children }) => {
  const [mediaEnabled, setMediaEnabled] = useState<{ audio: boolean; video: boolean }>({
    audio: true,
    video: true,
  });
  const sessionMediaConstraints = useSelector(getSessionMediaConstraints);

  const [isLoading, setIsLoading] = useState(true);
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [isStreamChanging, setIsStreamChanging] = useState(false);
  const [isUserMediaAllowed, setIsUserMediaAllowed] = useState(false);
  const [devicesByType, setDevicesByType] = useState(MediaSourcesService.defaultDevicesByType);
  const [initialized, setInitialized] = useState(false);
  const [mediaConstraints, setMediaConstraints] =
    useState<MediaConstraints>(sessionMediaConstraints);
  const [mediaFromPermissions, setMediaFromPermissions] = useState<MediaStream | null>(null);

  const requestPermissions = useCallback(() => {
    if (mediaEnabled.video) return MediaSourcesService.requestPermissions();

    return Promise.resolve();
  }, [mediaEnabled.video]);

  useEffect(() => {
    return () => {
      if (mediaFromPermissions) MediaSourcesService.stopTracks(mediaFromPermissions);
    };
  }, [mediaFromPermissions]);

  useEffect(() => {
    if (!initialized) return;

    const signal = makeSignal();

    Promise.race([requestPermissions(), signal.abortionPromise])
      .then((result) => {
        if (MediaSourcesService.isMediaStream(result)) {
          // Setting the track here to solve a problem where the webcam would blink if we stopped the tracks right away
          // (because we request the video again)
          setMediaFromPermissions(result);
        }
        if (signal.checkId(result)) return;

        setIsUserMediaAllowed(true);
        signal.done();
      })
      .catch((err) => {
        console.log(`Couldn't access media devices`, err);
        setIsUserMediaAllowed(false);
        setIsLoading(false);
      });

    return signal.abort;
  }, [initialized, requestPermissions]);

  useEffect(() => {
    if (!initialized || !isUserMediaAllowed) return;

    const signal = makeSignal();

    (async () => {
      await MediaSourcesService.prepareDevicesOnFirefox();

      const devices = await MediaSourcesService.listDevices();
      const devicesByType = MediaSourcesService.groupDevicesByType(devices);
      const constraints = MediaSourcesService.getMediaConstraints(
        devicesByType,
        sessionMediaConstraints
      );

      if (signal.isAborted()) return;

      setMediaConstraints(constraints);
      setDevicesByType(devicesByType);
      setIsLoading(false);
    })();

    return signal.abort;
  }, [isUserMediaAllowed, sessionMediaConstraints, initialized]);

  useEffect(() => {
    if (!initialized || !isUserMediaAllowed || (!mediaEnabled.video && !mediaEnabled.audio)) return;

    let userStream: MediaStream | undefined = undefined;
    const signal = makeSignal();

    (async () => {
      try {
        userStream = await MediaSourcesService.getCameraMicStream({
          audio: mediaEnabled.audio ? mediaConstraints.audio : false,
          video: mediaEnabled.video ? mediaConstraints.video : false,
        });

        if (signal.isAborted()) return MediaSourcesService.stopTracks(userStream);

        if (mediaFromPermissions) MediaSourcesService.stopTracks(mediaFromPermissions);

        setMediaStream((oldStream) => {
          if (oldStream) MediaSourcesService.stopTracks(oldStream);

          return userStream;
        });
      } catch (err) {
        console.warn(err);
      } finally {
        setIsStreamChanging(false);
      }
    })();

    return () => {
      signal.abort();
      MediaSourcesService.stopTracks(userStream);
    };
  }, [initialized, mediaConstraints, isUserMediaAllowed, mediaEnabled, mediaFromPermissions]);

  const initMediaSources = useCallback(() => {
    if (!initialized) setInitialized(true);
  }, [initialized]);

  return (
    <MediaSourcesContext.Provider
      value={{
        devicesByType,
        isLoading,
        isStreamChanging,
        isUserMediaAllowed,
        mediaConstraints,
        setMediaConstraints,
        mediaStream,
        initMediaSources,
        mediaEnabled,
        setMediaEnabled,
        supportsOutputAudioDeviceSelection: devicesByType.audiooutput.length > 0,
      }}
    >
      {children}
    </MediaSourcesContext.Provider>
  );
};
