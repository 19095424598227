import styled, { useTheme } from 'styled-components';
import { Z_INDEXES } from 'constants/zIndexes';
import { useSelector } from 'react-redux';
import { getSessionMediaConstraints } from 'store/slices/session';
import { AlertTriangle, Close } from 'components/shared/Icons';
import { useState } from 'react';
import { MediaConstraints } from 'interfaces/session';
import { MediaSourcesService } from 'services/MediaSourcesService';
import { getNeedHelpUrl } from 'store/slices/siteConfig';

const NOT_DETECTED_MESSAGES = {
  MICROPHONE: 'Microphone could not be detected. ',
  CAMERA: 'Camera could not be detected. ',
  BOTH: 'Microphone and camera could not be detected. ',
};

const getNotDetectedMessage = (audioDeviceAuthorized: boolean, videoDeviceAuthorized: boolean) => {
  if (!videoDeviceAuthorized && !audioDeviceAuthorized) return NOT_DETECTED_MESSAGES.BOTH;
  if (!videoDeviceAuthorized) return NOT_DETECTED_MESSAGES.CAMERA;
  return NOT_DETECTED_MESSAGES.MICROPHONE;
};

export const getIsDeviceAuthorized = (mediaConstraints: MediaConstraints) => {
  const audioDevice = MediaSourcesService.extractMediaConstraintValue(
    mediaConstraints.audio.deviceId
  );

  const videoDevice = MediaSourcesService.extractMediaConstraintValue(
    mediaConstraints.video.deviceId
  );

  return { audio: Boolean(audioDevice), video: Boolean(videoDevice) };
};

export const DeviceNotAuthorizedAlert = () => {
  const [shouldShow, setShouldShow] = useState(true);
  const mediaConstraints = useSelector(getSessionMediaConstraints);
  const needHelpUrl = useSelector(getNeedHelpUrl);
  const isDeviceAuthorized = getIsDeviceAuthorized(mediaConstraints);
  const { colors } = useTheme();

  if (!shouldShow) return null;

  if (isDeviceAuthorized.audio && isDeviceAuthorized.video) return null;

  return (
    <Alert>
      <TextContainer>
        <AlertTriangle width={20} height={20} fill={colors.alertWarning} />
        <Text>
          {getNotDetectedMessage(isDeviceAuthorized.audio, isDeviceAuthorized.video)}
          <TroubleshootingLink href={needHelpUrl} target='_blank' rel='noopener noreferrer'>
            Troubleshooting
          </TroubleshootingLink>
        </Text>
      </TextContainer>
      <CloseButton onClick={() => setShouldShow(false)}>
        <Close width={18} height={18} fill={colors.alertWarning} />
      </CloseButton>
    </Alert>
  );
};

const Text = styled.span`
  margin: 0 0 0 1rem;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TroubleshootingLink = styled.a`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.alertWarning};
`;

const CloseButton = styled.button`
  all: unset;
  display: flex;
  cursor: pointer;
`;

const Alert = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: 4rem;
  font-size: 1rem;
  border-radius: 2rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.surface4};
  color: ${({ theme }) => theme.colors.alertWarning};
  width: 600px;
  padding: 0.5rem 0.5rem;
  z-index: ${Z_INDEXES.DEVICE_NOT_AUTHORIZED_ALERT};
  box-shadow: 1px 1px 3px 1px black;
`;
