import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { Destination, TwitchFormValues } from 'interfaces/destinations';
import ButtonRow from './ButtonRow';
import FormInput from './FormInput';
import { Select, Text } from 'components/shared';
import { getSelectOption } from 'utils/destination';
import { updateDestination } from 'store/slices/destinations';
import { useServices } from 'hooks/useServices';

const twitchCategories = [
  { label: 'Use my current Twitch category', value: 'Use my current Twitch category' },
  { label: 'Just Chatting', value: 'Just Chatting' },
  { label: 'Music', value: 'Music' },
  { label: 'Sports', value: 'Sports' },
  { label: 'ASMR', value: 'ASMR' },
  { label: 'Art', value: 'ART' },
  { label: 'Talk Shows & Podcasts', value: 'Talk Shows & Podcasts' },
];

interface Props {
  destination: Destination;
  loadedValues: TwitchFormValues;
  onClose: Function;
}

//NOTE: we can use Yup to make this validation instead.
const validate = (values: TwitchFormValues) => {
  let errors: Partial<TwitchFormValues> = {};
  if (!values.title) {
    errors.title = 'Missing required field';
  }
  return errors;
};

const TwitchForm: React.FC<Props> = ({ destination, onClose }) => {
  const metadata = destination.metadata as TwitchFormValues;
  const [category, setCategory] = useState(getSelectOption(metadata.category));
  const { destinationService } = useServices();
  const dispatch = useDispatch();

  const onSubmit = async (
    values: TwitchFormValues,
    { setSubmitting }: FormikHelpers<TwitchFormValues>
  ) => {
    const formValues = { ...values, category: category.value };
    const editedDestination = { ...destination, metadata: formValues };
    const res = await destinationService.updateDestination(editedDestination);
    if (!res.error) {
      dispatch(updateDestination(editedDestination));
    } else {
      console.debug(res.error); //TODO: handle error?
    }
    setSubmitting(false);
    onClose();
  };

  const getInitialValues = (): any => {
    return {
      title: metadata.title,
      category: getSelectOption(metadata.category),
    };
  };

  return (
    <Formik initialValues={getInitialValues()} validate={validate} onSubmit={onSubmit}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <FormInput
            data-testid='destTWTitle'
            label='Title (required)'
            name='title'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            hasError={errors.title && touched.title ? true : false}
            error={errors.title}
          />

          <SelectContainer>
            <Text size={14} weight={500}>
              Twitch Category
            </Text>

            <Select
              data-testid='destTWCategoryDropdown'
              name='category'
              height={40}
              fontSize={12}
              options={twitchCategories}
              value={category}
              isLastChildStyle
              onChange={(e) => {
                if (e) setCategory(e);
              }}
            />
          </SelectContainer>

          <ButtonRow
            destination={destination}
            isSubmitting={isSubmitting}
            onClose={onClose}
          ></ButtonRow>
        </Form>
      )}
    </Formik>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export default TwitchForm;
