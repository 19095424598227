import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, LoadingSpinner, Text } from 'components/shared';
import { getSessionIsLive, setSessionIsLive } from 'store/slices/session';

export const GoLiveButton: React.FC<{ disabled?: boolean }> = ({ disabled = false }) => {
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const dispatch = useDispatch();
  const isLive = useSelector(getSessionIsLive);

  const toggleLive = async () => {
    setIsAwaitingResponse(true);
    try {
      dispatch(setSessionIsLive(!isLive));
    } catch (e) {
      console.error(e);
    } finally {
      setIsAwaitingResponse(false);
    }
  };

  if (disabled) {
    return null;
  }

  return (
    <div>
      <Button onClick={toggleLive} fullWidth={true} disabled={isAwaitingResponse}>
        {isAwaitingResponse ? (
          <LoadingSpinner size={14} />
        ) : (
          <Text size={12} weight={700} textAlign='center'>
            {isLive ? 'Go Offline' : 'Go Live'}
          </Text>
        )}
      </Button>
    </div>
  );
};
