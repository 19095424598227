import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';

import { Button, Text } from 'components/shared';
import { AlertTriangle, Facebook, Maestro, Twitch, YouTube } from 'components/shared/Icons';
import { TProvider, PROVIDERS, StatusType } from 'interfaces/destinations';
import DestinationIcon from './DestinationIcon';
import LoadingEllipsis from 'components/LoadingEllipsis';
import { fmtDestName, getComponentsMap, presentDestinationStatus } from 'utils/destination';
import DestinationErrorMessage from './DestinationErrorMessage';
import { useLoginDestination } from './useLoginDestination';

interface CardComponents {
  connectButton: boolean;
  alert: boolean;
  rightMessage: boolean;
  ellipsis: boolean;
  accountName: boolean;
  connectionStatus: boolean;
  cog: boolean;
  switch: boolean;
  body: boolean;
}

interface Props {
  provider: TProvider;
}

interface IConnectButton {
  provider: TProvider;
  connect(): void;
}

const ConnectButton: React.FC<IConnectButton> = ({ provider, connect }) => {
  const size = { width: 24, height: 24 };
  const renderDestinationBadge = () => {
    if (provider === PROVIDERS.maestro) return <Maestro {...size} />;
    if (provider === PROVIDERS.facebook) return <Facebook {...size} />;
    if (provider === PROVIDERS.youtube) return <YouTube {...size} />;
    if (provider === PROVIDERS.twitch) return <Twitch {...size} />;
    return null;
  };

  return (
    <Button
      data-testid='destConnectButton'
      style={{
        width: '110px',
        height: '30px',
        padding: '3.5px 8px',
        borderRadius: '5px',
        gap: '8px',
      }}
      onClick={connect}
    >
      <Text size={12} weight={700}>
        CONNECT
      </Text>
      {renderDestinationBadge()}
    </Button>
  );
};

const PreConnectionCard: React.FC<Props> = ({ provider }) => {
  const [hasComponent, setHasComponent] = useState<CardComponents>({
    connectButton: true,
    alert: false,
    rightMessage: false,
    ellipsis: false,
    accountName: false,
    connectionStatus: false,
    cog: false,
    switch: false,
    body: false,
  });
  const { status, error, connect, reset } = useLoginDestination(provider);
  const { colors } = useTheme();

  useEffect(() => {
    const options = getComponentsMap(status);
    setHasComponent(options);
  }, [status]);

  return (
    <Container status={status}>
      <Header>
        <InfoColumn>
          <DestinationIcon provider={provider} isSummary={false} status={StatusType.setup} />
          <ProfileInfo>
            {status === 'setup' && (
              <Text size={14} weight={500} color={colors.text200}>
                {fmtDestName(provider)}
              </Text>
            )}
          </ProfileInfo>
        </InfoColumn>
        <ConfigColumn>
          {hasComponent.connectButton && <ConnectButton provider={provider} connect={connect} />}
          {hasComponent.rightMessage && (
            <React.Fragment>
              {hasComponent.alert && (
                <AlertTriangle width={18} height={18} fill={colors.alertError} />
              )}
              <Text size={14} weight={400} color={colors.text200}>
                {presentDestinationStatus(status)}
              </Text>
            </React.Fragment>
          )}
          {hasComponent.ellipsis && (
            <LoadingEllipsis dotSize={5.8} loading color={colors.alertWarning} />
          )}
        </ConfigColumn>
      </Header>
      {hasComponent.body && (
        <DestinationErrorMessage
          provider={provider}
          errorType={error.type}
          data={error.data}
          handleClose={reset}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{ status: StatusType }>`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.surface2};
  min-height: 62px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: ${(props) =>
    props.status === 'authorization_unsuccessful'
      ? `1px solid ${props.theme.colors.alertError}`
      : 'none'};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

const InfoColumn = styled.div`
  display: flex;
  gap: 15px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ConfigColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export default PreConnectionCard;
