import { getPeerScreenShareId, getPeerSoloViewId, setPeerSoloViewId } from 'store/slices/ui';
import { useDispatch, useSelector } from 'react-redux';
import { getParticipants } from 'store/slices/participants';
import { CONFIG } from 'constants/config';
import { getSessionId, getSessionIsHost, getSessionLayout } from 'store/slices/session';
import { LAYOUTS } from 'constants/layouts';
import { useServices } from 'hooks/useServices';
import { useReactiveRoom } from 'contexts/LiveKitContext';

export const useLayoutHandlers = () => {
  const dispatch = useDispatch();
  const participants = useSelector(getParticipants);
  const peerScreenShareId = useSelector(getPeerScreenShareId);
  const peerSoloViewId = useSelector(getPeerSoloViewId);
  const isHost = useSelector(getSessionIsHost);
  const { sessionService } = useServices();
  const sessionId = useSelector(getSessionId);

  const layout = useSelector(getSessionLayout);

  const { metadata } = useReactiveRoom();

  const electNewSoloViewPeer = async (peerId: string) => {
    const participantsOnStage = participants.filter(
      (participant) => participant.isOnStage && participant.id !== peerId
    );

    const newSoloId = participantsOnStage[0]?.id ?? null;

    sessionService.updateRoomMetadata(sessionId, {
      ...metadata,
      peerSoloViewId: newSoloId || undefined,
    });

    dispatch(setPeerSoloViewId(newSoloId));
  };

  const handleChangeStageState = async (
    peerId: string,
    isOnStage: boolean,
    stageLength: number
  ) => {
    const isStageFull =
      isOnStage && CONFIG.MAX_PEERS_ON_STAGE === stageLength - (peerScreenShareId ? 1 : 0);
    if (isStageFull) return;

    const isSoloViewPeerDraggedOffStage = peerId === peerSoloViewId && !isOnStage;
    if (isSoloViewPeerDraggedOffStage) await electNewSoloViewPeer(peerId);

    const isSpotlightAndHasParticipantsOnStage =
      layout === LAYOUTS.SPOTLIGHT && stageLength - 1 > 0;

    if (!isSpotlightAndHasParticipantsOnStage) {
      sessionService.updateRoomMetadata(sessionId, {
        ...metadata,
        layout,
      });
    }

    if (!isHost) return;

    const participant = participants.find((p) => p.id === peerId);

    if (!participant) return;

    const newMetadata = {
      isOnStage,
      order: isOnStage
        ? (() => {
            let max = 0;

            for (const { order } of participants) {
              if (order > max) {
                max = order;
              }
            }

            return max + 1;
          })()
        : 0,
    };

    sessionService.updateParticipantMetadata(sessionId, peerId, newMetadata);
  };

  return {
    handleChangeStageState,
  };
};
