import React from 'react';
import styled, { css } from 'styled-components/macro';

import Full from 'components/LayoutIcons/Full';
import Landscape from 'components/LayoutIcons/Landscape';
import Spotlight from 'components/LayoutIcons/Spotlight';
import SoloView from 'components/LayoutIcons/SoloView';
import Screenshare_1 from 'components/LayoutIcons/Screenshare_1';
import Screenshare_2 from 'components/LayoutIcons/Screenshare_2';
import Screenshare_3 from 'components/LayoutIcons/Screenshare_3';
import { Layout, LAYOUTS } from 'constants/layouts';
import { Text } from 'components/shared';
import { capitalize } from 'utils/methods';
import { DataTest } from 'interfaces/dataTestId';

interface SelectedLayout {
  [layout: string]: any;
}

interface Props extends DataTest {
  active: boolean;
  layout: Layout;
  disabled?: boolean;
  onClick: (layout: Layout) => void;
}

const LayoutIcon: React.FC<Props> = ({
  active,
  layout,
  disabled = false,
  onClick,
  'data-testid': dataTestId,
}) => {
  const Layouts: SelectedLayout = {
    [LAYOUTS.FULL]: Full,
    [LAYOUTS.LANDSCAPE]: Landscape,
    [LAYOUTS.SPOTLIGHT]: Spotlight,
    [LAYOUTS.SOLO_VIEW]: SoloView,
    [LAYOUTS.SCREEN_SHARE_1]: Screenshare_1,
    [LAYOUTS.SCREEN_SHARE_2]: Screenshare_2,
    [LAYOUTS.SCREEN_SHARE_3]: Screenshare_3,
  };

  const RenderLayout = Layouts[layout];

  const handleClick = () => {
    if (disabled) return true;
    onClick(layout);
  };

  return (
    <Container
      active={active && !disabled}
      disabled={disabled}
      onClick={handleClick}
      data-testid={dataTestId}
    >
      <PillContainer active={active && !disabled}>
        <Pill>
          <Text size={12} weight={600}>
            {capitalize(layout).replace('_', ' ')}
          </Text>
        </Pill>
      </PillContainer>
      {RenderLayout && <RenderLayout />}
    </Container>
  );
};

const PillContainer = styled.div<{ active: boolean }>`
  display: ${(props) => (props.active ? 'block' : 'none')};
  text-transform: capitalize;
  position: absolute;
`;

const Pill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: fit-content;
  background-color: ${(props) => props.theme.colors.accentPrimary};
  border-radius: 25.59px;
  padding: 0px 12px;
`;

const Container = styled.div<{ active: boolean; disabled: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  height: 73px;
  width: 116.6px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  background: ${(props) => props.theme.colors.surface2};
  box-sizing: border-box;
  border: ${(props) =>
    props.active
      ? `1px solid ${props.theme.colors.accentPrimary}`
      : `0.875943px solid ${props.theme.colors.surface5}`};
  border-radius: 3.23077px;
  &:hover ${PillContainer} {
    display: ${(props) => (props.disabled ? 'none' : 'block')};
  }
  &:hover {
    border: ${(props) =>
      props.disabled
        ? `0.875943px solid ${props.theme.colors.surface5}`
        : `1px solid ${props.theme.colors.text200}`};
  }
  ${(props) =>
    props.active &&
    css`
      &:hover {
        border: ${(props: any) =>
          props.active
            ? `1px solid ${props.theme.colors.accentPrimary}`
            : `1px solid ${props.theme.colors.text200}`};
      }
    `}
  transition: all 300ms linear;
`;

export default LayoutIcon;
