export const LAYOUTS = {
  SOLO_VIEW: 'SOLO_VIEW',
  FULL: 'FULL',
  LANDSCAPE: 'LANDSCAPE',
  SPOTLIGHT: 'SPOTLIGHT',
  SCREEN_SHARE_1: 'SCREEN_SHARE_1',
  SCREEN_SHARE_2: 'SCREEN_SHARE_2',
  SCREEN_SHARE_3: 'SCREEN_SHARE_3',
} as const;

export const FULL_LAYOUT_VALID_COUNT = [2, 5, 6];

export type Layout = typeof LAYOUTS[keyof typeof LAYOUTS];
