import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getHideDisplayNames, getSessionId } from 'store/slices/session';
import { getPeerBeingDragged, setPeerSoloViewId } from 'store/slices/ui';
import { useParticipantById } from 'hooks/useParticipantById';
import BaseVideoCard from 'components/VideoCard/BaseVideoCard';
import { SoloView, SoloViewExit } from 'components/shared/Icons';
import { MediaControls } from 'components/VideoCard/MediaControls';
import Tooltip from 'components/Tooltip';
import { HoverContainer, MediaButton } from 'components/VideoCard/styles';
import { useTheme } from 'styled-components';
import { useReactiveRoom } from 'contexts/LiveKitContext';
import { useServices } from 'hooks/useServices';

interface Props {
  idx: number;
  id: string;
  isBeingDragged: boolean;
  isSinglePeer: boolean;
  isSoloView: boolean;
  isSpotlight: boolean;
  shouldDisplayTalkingStatus: boolean;
}

const StageVideoCard: React.FC<Props> = (props: Props) => {
  const {
    idx,
    id,
    isSinglePeer = false,
    isSoloView,
    isSpotlight,
    shouldDisplayTalkingStatus = true,
  } = props;
  const dispatch = useDispatch();

  const { colors } = useTheme();

  const peerBeingDragged = useSelector(getPeerBeingDragged);
  const hideDisplayNames = useSelector(getHideDisplayNames);

  const { metadata } = useReactiveRoom();
  const { sessionService } = useServices();
  const sessionId = useSelector(getSessionId);

  const participant = useParticipantById(id);

  const handleSoloView = async (id: string | null) => {
    dispatch(setPeerSoloViewId(id));
    sessionService.updateRoomMetadata(sessionId, {
      ...metadata,
      peerSoloViewId: id || undefined,
    });
  };

  if (!participant) return <></>;

  const isSpotlightAndRightColumn = isSpotlight && idx > 0;

  return (
    <HoverContainer>
      <BaseVideoCard
        id={`stage:${participant.id}`}
        displayName={participant.displayName}
        video={participant.video}
        audio={participant.audio}
        isDraggable={true}
        isBeingDragged={peerBeingDragged?.id === participant.id}
        isSpotlightAndRightColumn={isSpotlightAndRightColumn}
        hideDisplayNames={hideDisplayNames}
        isScreenShare={participant.isScreenShare}
        isSelf={participant.isSelf}
        isMirrored={!participant.isScreenShare && !participant.isIngress}
        shouldDisplayTalkingStatus={shouldDisplayTalkingStatus}
        isStage={true}
        participantId={id}
      >
        {({ iconSize, fontSize }) => (
          <>
            <MediaControls
              isSelf={participant.isSelf}
              id={participant.id}
              audio={participant.audio}
              video={participant.video}
              isScreenShare={participant.isScreenShare}
              fontSize={fontSize}
              iconSize={iconSize}
            />
            {isSoloView ? (
              <Tooltip position='left' text='Exit Solo View'>
                <MediaButton iconSize={iconSize} onClick={() => handleSoloView(null)} enabled>
                  <SoloViewExit width={iconSize} height={iconSize} fill={colors.text100} />
                </MediaButton>
              </Tooltip>
            ) : (
              !isSinglePeer && (
                <Tooltip position='left' text='Enter Solo View'>
                  <MediaButton iconSize={iconSize} onClick={() => handleSoloView(id || '')}>
                    <SoloView width={iconSize} height={iconSize} fill={colors.text100} />
                  </MediaButton>
                </Tooltip>
              )
            )}
          </>
        )}
      </BaseVideoCard>
    </HoverContainer>
  );
};

export default StageVideoCard;
