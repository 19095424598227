import React from 'react';
import styled from 'styled-components';
import Frame from 'components/LayoutIcons/Frame';

const Spotlight: React.FC = () => {
  return (
    <Container>
      <Frame />
      <Frame />
      <Frame />
      <Frame />
      <Frame />
      <Frame />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 9.47px);
  gap: 2.54px;
  padding: 8px 7px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  & > :first-child {
    height: 96%;
    grid-area: span 6 / span 6;
  }
`;

export default Spotlight;
