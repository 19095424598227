import { useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import {
  Destination,
  DestinationErrorType,
  TProvider,
  StatusType,
  FormValues,
} from 'interfaces/destinations';

import {
  addDestination,
  getDestinations,
  removeDestination,
  setDestinationStatus,
} from 'store/slices/destinations';
import { useServices } from 'hooks/useServices';
import { IPopupState, TStrategies } from 'services/DestinationAuth/interfaces';
import {
  getSessionIsLive,
  getSessionSecretToken,
  getSessionTransmissionId,
} from 'store/slices/session';
import { getDestinationTitle, getDestinationDescription } from 'store/slices/siteConfig';
import { buildDefaultMetadata } from 'utils/destination';

interface IAuthError {
  type: DestinationErrorType | undefined;
  data: any;
}

interface IUseLoginDestination {
  status: StatusType;
  error: IAuthError;
  connect: () => void;
  reset: () => void;
}

/**
 * @param provider the name of the destination to connect with
 * @returns [status, error, connect, reset]
 */
export const useLoginDestination = (
  provider: TProvider,
  destinationId?: string
): IUseLoginDestination => {
  const [status, setStatus] = useState<StatusType>(StatusType.setup);
  const [error, setError] = useState<IAuthError>({ type: undefined, data: {} });

  const sessionId = useSelector(getSessionTransmissionId);
  const secretToken = useSelector(getSessionSecretToken);
  const destinations = useSelector(getDestinations);
  const isSessionLive = useSelector(getSessionIsLive);
  const destinationTitle = useSelector(getDestinationTitle);
  const destinationDescription = useSelector(getDestinationDescription);
  const { destinationService, destinationAuthService, integrationService, sessionService } =
    useServices();
  const idRef = useRef(uuid());
  const dispatch = useDispatch();

  //TODO: what happens when an existing destination errors out with the reconnect?
  const handleError = useCallback(
    (error: any) => {
      console.debug(error);
      if (destinationId) return dispatch(removeDestination(provider, destinationId));

      const obj = { type: error?.type || 'UNHANDLED_ERROR', data: error?.data || {} };
      setError(obj);
      setStatus(StatusType.authorization_unsuccessful);
    },
    [destinationId, provider, dispatch]
  );

  const handleSaveDestination = useCallback(
    async (destination: Destination) => {
      try {
        const { rtmpUrl, streamKey } = await destinationAuthService.buildRtmp(
          destination.provider as TStrategies,
          destination
        );
        //NOTE: little change here. Usually we would call destinationService.createDestination() and that would create a new destination in the session service
        // and only then try to create a new destination in the integration service. But now, if we are considering integration as source of truth
        // and we want to preserve the ids, we need to create first in the integration service and then in the session service
        const created = await integrationService!.createDestination(destination);
        await sessionService.upsertDestinations(sessionId, [created]);

        if (destinationId) dispatch(removeDestination(provider, destinationId));

        const updated: Destination = {
          ...destination,
          id: created.id,
          rtmp: {
            rtmpUrl,
            streamKey,
          },
          status: isSessionLive ? StatusType.live : StatusType.ready,
        };

        dispatch(addDestination(updated));
        setStatus(StatusType.setup);
      } catch (error) {
        handleError(error);
      }
    },
    [
      integrationService,
      sessionService,
      sessionId,
      destinationAuthService,
      isSessionLive,
      destinationId,
      dispatch,
      provider,
      handleError,
    ]
  );

  const handleSetStatus = useCallback(
    (newStatus: StatusType) => {
      if (!destinationId) return setStatus(newStatus);

      // if we have an id, this means it is from the Connection card and we want to update it
      const found = destinations[provider].find((e) => e.id === destinationId);
      if (!found) return;
      dispatch(setDestinationStatus(found.provider, found.account, newStatus));
    },
    [destinationId, provider, dispatch, destinations]
  );

  const handleDestinationFromPopup = useCallback(async () => {
    // search if we added the data in the window object
    const index = window.destinationAuthTokens.findIndex((e) => {
      if (!e.state) return false;
      try {
        const state = JSON.parse(e.state) as IPopupState;
        return state.refId === idRef.current;
      } catch (err) {
        console.debug(err);
        return false;
      }
    });
    // This means that the window was closed before we were able to send back an error/success message
    if (index === -1) return handleError('Window closed');

    // remove the token from the window object
    const found = window.destinationAuthTokens.splice(index, 1)[0];
    //NOTE: maybe check for authenticity (using our secretToken and sessionId) and JSON.parse(found.state)

    if (found.status === 'error') {
      const { error_description, error, error_reason } = found;
      return handleError({ error, error_description, error_reason });
    }

    //NOTE: maybe we can move all this part for the popup_redirect component and get the full destination object instead of just the auth code
    const { data, error: exchangeCodeError } = await destinationService.exchangeCodeForTokens(
      provider as TStrategies,
      found.code
    );

    if (!data || !data.access_token) return handleError(exchangeCodeError);

    const { data: destinationObj, error: createDestinationError } =
      await destinationAuthService.createDestinationObject(provider as TStrategies, {
        id: idRef.current,
        accessToken: data.access_token,
        refreshToken: data.refresh_token || '', // Facebook destinations does not have refreshTokens
        expiresAt: Date.now() + data.expires_in * 1000, // expires_in is in seconds, we need to convert to milliseconds
        metadata: buildDefaultMetadata(destinationTitle, destinationDescription)[
          provider
        ] as FormValues,
      });

    if (!destinationObj) return handleError(createDestinationError);

    await handleSaveDestination(destinationObj);
    idRef.current = uuid();
  }, [
    handleError,
    destinationService,
    provider,
    destinationAuthService,
    destinationTitle,
    destinationDescription,
    handleSaveDestination,
  ]);

  const connect = async () => {
    handleSetStatus(StatusType.connecting);
    // if we have an id, this means it is from the Connection card and we want to reconnect it
    if (destinationId) {
      const destination = Object.values(destinations)
        .flat()
        .find((d) => d.id === destinationId);
      await destinationService.removeDestination(destination);
    }
    const state: IPopupState = { refId: idRef.current, sessionId, secretToken };

    const openedPopup = destinationAuthService.openLoginPopup(provider as TStrategies, {
      state: JSON.stringify(state),
    });

    if (!openedPopup) return;

    const timer = setInterval(() => {
      try {
        if (openedPopup && openedPopup.closed) {
          handleDestinationFromPopup();
          clearInterval(timer);
        }
      } catch (e) {
        clearInterval(timer);
      }
    }, 1000);
  };

  //TODO:
  const reset = () => {
    handleSetStatus(StatusType.setup);
    setError({ type: undefined, data: {} });
  };

  return { status, error, connect, reset };
};
