import { Button, Text } from 'components/shared';
import { Maestro, Facebook, YouTube, Twitch } from 'components/shared/Icons';
import { TProvider, PROVIDERS } from 'interfaces/destinations';

interface IConnectButton {
  provider: TProvider;
  handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  isReconnect?: boolean;
}

interface IBadgeSize {
  width: number;
  height: number;
}

const renderDestinationBadge = (provider: TProvider, size: IBadgeSize) => {
  if (provider === PROVIDERS.maestro) return <Maestro {...size} />;
  if (provider === PROVIDERS.facebook) return <Facebook {...size} />;
  if (provider === PROVIDERS.youtube) return <YouTube {...size} />;
  if (provider === PROVIDERS.twitch) return <Twitch {...size} />;
  return null;
};

const getButtonStyle = (isReconnect: boolean) => {
  if (isReconnect)
    return { width: '125px', height: '40px', padding: 0, margin: '15px 0 5px 0', gap: '8px' };
  return {
    width: '110px',
    height: '30px',
    padding: '3.5px 8px',
    borderRadius: '5px',
    gap: '8px',
  };
};

const ConnectButton: React.FC<IConnectButton> = ({
  provider,
  handleClick,
  isReconnect = false,
}) => {
  const size = { width: 24, height: 24 };

  const buttonStyle = getButtonStyle(isReconnect);

  return (
    <Button
      data-testid='destConnectButton' // TODO: check if data test id should be the same
      style={{ ...buttonStyle }}
      onClick={handleClick}
    >
      <Text size={12} weight={700}>
        {isReconnect ? 'RECONNECT' : 'CONNECT'}
      </Text>
      {renderDestinationBadge(provider, size)}
    </Button>
  );
};

export default ConnectButton;
