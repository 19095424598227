import styled from 'styled-components/macro';
import { css } from 'styled-components';

export const HoverContainer = styled.div`
  width: 100%;
  height: 100%;
  :hover {
    button {
      display: flex;
    }
  }
`;

export const MediaButton = styled.button<{
  alwaysShow?: boolean;
  active?: boolean;
  enabled?: boolean;
  iconSize: number;
}>`
  font-size: ${(props) => props.iconSize}px;
  position: relative;
  display: ${(props) => (props.alwaysShow ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: ${(props) =>
    props.active || props.enabled ? props.theme.colors.accentPrimary : props.theme.colors.surface4};
  width: 1.7em;
  height: 1.7em;
  border: none;
  border-radius: 50%;

  ${(props) => {
    if (props.enabled) {
      return css`
        cursor: pointer;
        :hover {
          background-color: ${(props) => props.theme.colors.accentPrimary};
        }

        :active {
          transform: translate(1px, 1px);
          box-shadow: ${(props) => `0 0 12px ${props.theme.colors.accentPrimary}`};
        }
      `;
    }
  }}

  ::before,
  ::after {
    display: ${(props) => (props.active ? 'block' : 'none')};
  }
`;
