import React, { FC, memo, useMemo } from 'react';
import styled from 'styled-components/macro';

import { getVideoObjectFit } from 'views/shared/Stage/layouts';
import { useSelector } from 'react-redux';
import { getSessionLayout } from 'store/slices/session';
import { AudioTrack, VideoTrack, VideoTrackProps, useRoomContext } from '@livekit/components-react';
import { Participant, Track } from 'livekit-client';
import { RootState } from 'store/slices';
import { getParticipantById } from 'store/slices/participants';
import { useReactiveRoom } from 'contexts/LiveKitContext';

interface Props {
  muted?: boolean;
  playing?: boolean;
  borderRadius?: number;
  isStage?: boolean;
  objectFit?: 'contain' | 'cover';
  isScreenShare?: boolean;
  mirrored: boolean;
  participantId?: string;
  mediaStream?: MediaStream;
}

const VideoPlayerV2: React.FC<Props> = memo(
  ({
    muted = false,
    isStage,
    borderRadius,
    isScreenShare,
    mirrored,
    participantId,
    mediaStream,
  }) => {
    const layout = useSelector(getSessionLayout);

    const studioParticipant = useSelector((state: RootState) =>
      participantId ? getParticipantById(state, participantId) : null
    );

    const lkParticipantId = participantId?.replace(/-sc$/, '');

    const { localParticipant } = useRoomContext();
    const { localParticipantId, participants } = useReactiveRoom();

    const lkParticipant = useMemo(() => {
      if (mediaStream) return;

      if (localParticipantId === lkParticipantId) return localParticipant;

      let found: Participant | undefined;

      participants.forEach((p) => {
        if (p.identity !== lkParticipantId) return;
        found = p;
      });

      return found;
    }, [mediaStream, localParticipantId, lkParticipantId, localParticipant, participants]);

    if (!lkParticipant && !mediaStream) return null;

    return (
      <>
        {lkParticipant && !muted && (
          <Audio
            participant={lkParticipant}
            source={
              studioParticipant?.isScreenShare
                ? Track.Source.ScreenShareAudio
                : Track.Source.Microphone
            }
          />
        )}
        <Video
          participant={lkParticipant}
          objectFit={getVideoObjectFit(
            layout,
            { height: 128, width: 128 },
            Boolean(isStage),
            isScreenShare
          )}
          borderRadius={borderRadius || 0}
          mirrored={mirrored}
          source={studioParticipant?.isScreenShare ? Track.Source.ScreenShare : Track.Source.Camera}
          mediaStream={mediaStream}
        />
      </>
    );
  }
);

const VideoProxy: FC<
  { mediaStream?: MediaStream; className?: string } & Pick<
    VideoTrackProps,
    'participant' | 'source'
  >
> = ({ mediaStream, participant, source, className }) => {
  if (mediaStream) {
    return (
      <video
        autoPlay={true}
        ref={(v) => {
          if (v) v.srcObject = mediaStream;
        }}
        className={className}
      />
    );
  } else {
    return <VideoTrack participant={participant} source={source} className={className} />;
  }
};

const Video = styled(VideoProxy)<{ objectFit: string; mirrored: boolean; borderRadius: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: ${(props) => props.objectFit};
  background-color: black;
  transform: ${(props) => (props.mirrored ? 'scale(-1,1)' : 'none')};
  border-radius: ${(props) => `${props.borderRadius}px`};
`;

const Audio = styled(AudioTrack)`
  display: none;
`;

export default VideoPlayerV2;
