import React from 'react';
import styled from 'styled-components';

import Frame from 'components/LayoutIcons/Frame';

const SoloView: React.FC = () => {
  return (
    <Container>
      <Frame></Frame>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  padding: 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export default SoloView;
