/**
 * @param color the original color str (hex, rgb or rgba)
 * @param alphaValue a number from 0 to 1.0
 * @returns the transformed color
 */
export const addAlpha = (color: string, alphaValue: number) => {
  if (/^rgb/.test(color)) {
    const [r, g, b] = color
      .split('(')[1]
      .split(')')[0]
      .split(',')
      .map((t) => t.trim());

    return `rgba(${r}, ${g}, ${b}, ${alphaValue})`;
  } else if (color[0] === '#') {
    return color.substring(0, 7) + Math.round(alphaValue * 255).toString(16);
  } else {
    return color;
  }
};
