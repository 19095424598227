import { isMobile } from 'react-device-detect';
import styled, { css, useTheme } from 'styled-components/macro';

import { AlertTriangle } from 'components/shared/Icons';
import { Button, Text } from 'components/shared';
import { useState } from 'react';
import { useEffect } from 'react';

const MaxPeopleReached = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const { colors } = useTheme();

  const reEnableButton = () =>
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 10000);

  const handleClick = () => {
    setIsButtonDisabled(true);
    reEnableButton();
  };

  useEffect(() => {
    reEnableButton();
  }, []);

  return (
    <Container>
      <MessageContainer>
        <AlertTriangle
          width={isMobile ? 33 : 20}
          height={isMobile ? 38 : 22}
          fill={colors.highlightPrimary}
        />
        {isMobile ? (
          <Text style={{ textAlign: 'center' }} size={14} weight={400} color={colors.text100}>
            The waiting room is full. We have informed the host that someone is trying to enter. You
            will be able to join after someone leaves.
          </Text>
        ) : (
          <Text size={14} weight={400} color={colors.text100}>
            The Studio is full. The host has been notified that you are here. You will be able to
            enter once someone leaves.
          </Text>
        )}

        <Button fullWidth={isMobile} disabled={isButtonDisabled} onClick={handleClick}>
          <Text
            style={{ textTransform: 'uppercase' }}
            weight={700}
            color={colors.text100}
            size={14}
          >
            Try again
          </Text>
        </Button>
        {isMobile && (
          <Text weight={400} size={11} color={colors.text200}>
            Button will be enabled when someone leaves the studio
          </Text>
        )}
      </MessageContainer>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  place-content: ${isMobile ? 'flex-end' : 'center'};
  height: 100%;
  background: black;
  border-radius: 10px;
`;

const MessageContainer = styled.div`
  ${({ theme }) => css`
    box-sizing: border-box;
    width: 100%;
    min-width: 270px;
    height: ${isMobile ? '55vh' : '70px'};
    padding: ${isMobile ? '10px 32px' : '10px 15px'};
    background-color: ${theme.colors.surface2};
    display: flex;
    flex-direction: ${isMobile ? 'column' : 'row'};
    align-items: center;
    border-top: ${isMobile ? `1px solid ${theme.colors.surface6}` : 'none'};
    border-bottom: ${isMobile ? `1px solid ${theme.colors.surface6}` : 'none'};
    border-radius: ${isMobile ? '0' : '10px'};
    order: ${isMobile ? '1' : '2'};
    gap: ${isMobile ? '25px' : '12px'};
    ${isMobile &&
    css`
      justify-content: center;
    `}
  `}
`;

export default MaxPeopleReached;
