import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { getIsDrawerPanelVisible, setIsDrawerPanelVisible } from 'store/slices/ui';
import ShareLinkPanel from 'views/Guest/ShareLinkPanel';
import LayoutsPanel from 'views/Host/LayoutsPanel';
import ControlButton from 'views/shared/ControlButton';
import { DataTest } from 'interfaces/dataTestId';

const PANELS = {
  LAYOUT: 'LAYOUT',
  SHARE: 'SHARE',
} as const;

type PanelType = typeof PANELS[keyof typeof PANELS];

interface Props extends DataTest {
  type: PanelType;
  text: string;
  style?: any;
}

const DrawerButton: React.FC<Props> = ({
  children,
  text,
  type,
  style,
  'data-testid': dataTestId,
}) => {
  const isDrawerPanelVisible = useSelector(getIsDrawerPanelVisible);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setIsDrawerPanelVisible(!isDrawerPanelVisible));
  };

  const Panels = {
    [PANELS.LAYOUT]: LayoutsPanel,
    [PANELS.SHARE]: ShareLinkPanel,
  };
  const RenderElement = Panels[type];

  return (
    <Container>
      {isDrawerPanelVisible && (
        <PanelContainer type={type}>
          <RenderElement />
        </PanelContainer>
      )}
      <ControlButton
        tooltipPosition='top'
        text={text}
        onClick={handleClick}
        primary={isDrawerPanelVisible}
        style={style}
        data-testid={dataTestId}
      >
        {children}
      </ControlButton>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  z-index: 4;
`;

const PanelContainer = styled.div<{ type: PanelType }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${(props) => (props.type === 'LAYOUT' ? 'calc(100% + 20px)' : 'calc(100% + 35px)')};
`;

export default DrawerButton;
