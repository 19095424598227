import React from 'react';
import styled, { CSSProperties, useTheme } from 'styled-components/macro';
import { useDispatch } from 'react-redux';

import { Text } from 'components/shared';
import { closeModal } from 'store/slices/ui';
import { Close } from 'components/shared/Icons';

interface Props {
  title?: string;
  showCloseButton?: boolean;
  titleStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  buttonSize?: number;
  onDismissModal?: (e: React.MouseEvent) => void;
  style?: CSSProperties;
}

const ModalHeader: React.FC<Props> = (props: Props) => {
  const {
    title,
    onDismissModal,
    showCloseButton = false,
    titleStyle,
    buttonStyle,
    buttonSize = 8,
    style,
  } = props;
  const dispatch = useDispatch();
  const dismissModal = onDismissModal ?? (() => dispatch(closeModal()));
  const { colors } = useTheme();
  return (
    <Container style={style}>
      <Text weight={500} size={14} color={colors.text200} style={titleStyle}>
        {title}
      </Text>
      {showCloseButton && (
        <CloseButton onClick={dismissModal} style={buttonStyle}>
          <Close width={buttonSize} height={buttonSize} fill={colors.text100} />
        </CloseButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseButton = styled.div`
  margin: 0;
  padding: 5px;
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.surface1};
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  &:active {
    box-shadow: ${({ theme }) => `0 0 8px ${theme.colors.surface1}`};
    transform: translate(1px, 1px);
  }
`;

export default ModalHeader;
