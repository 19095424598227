import { MediaConstraints } from 'interfaces/session';
import { isFirefox } from 'react-device-detect';

export type Device = {
  value: string;
  label: string;
  groupId: string;
};

export type DeviceId =
  | undefined
  | number
  | string[]
  | string
  | ConstrainStringParameters
  | ConstrainNumberRange;

export type DevicesByType = {
  audioinput: Device[];
  videoinput: Device[];
  audiooutput: Device[];
};

export class MediaSourcesService {
  static stopTracks = (mediaStream: MediaStream | undefined) => {
    mediaStream?.getTracks().forEach((track) => {
      track.stop();
    });
  };

  static emptyDevice = { value: '', label: 'None', groupId: '' };

  static get defaultDevicesByType(): DevicesByType {
    return {
      audioinput: [],
      videoinput: [],
      audiooutput: [],
    };
  }

  static extractMediaConstraintValue = (
    value: DeviceId
  ): string | number | string[] | undefined => {
    if (!value) return value;
    if (typeof value !== 'object' || Array.isArray(value)) return value;
    return MediaSourcesService.extractMediaConstraintValue(value.exact ?? value.ideal);
  };

  static groupDevicesByType = (devices: MediaDeviceInfo[]): DevicesByType =>
    devices.reduce(
      (acc: Record<MediaDeviceInfo['kind'], Array<Device>>, { kind, deviceId, label, groupId }) => {
        // devices with the same groupId are physically the same
        const deviceAlreadyListed = acc[kind].find((device) => device.groupId === groupId);
        return deviceAlreadyListed
          ? acc
          : {
              ...acc,
              [kind]: [...acc[kind], { value: deviceId, label, groupId }],
            };
      },
      MediaSourcesService.defaultDevicesByType
    );

  static getDefaultDeviceId = (devices: Device[], selectedDevice: Device['value']) => {
    const foundDevice = devices.find(
      ({ value }) => value === MediaSourcesService.extractMediaConstraintValue(selectedDevice)
    );

    if (foundDevice) return foundDevice.value;

    if (isFirefox && selectedDevice === 'default') return devices[0].value;

    return selectedDevice;
  };

  static getMediaConstraints = (
    devicesByType: DevicesByType,
    mediaConstraints: MediaConstraints
  ): MediaConstraints => {
    if (!mediaConstraints) return mediaConstraints;

    const defaultAudioDeviceId = MediaSourcesService.getDefaultDeviceId(
      devicesByType.audioinput,
      mediaConstraints.audio.deviceId as string
    );

    const defaultVideoDeviceId = MediaSourcesService.getDefaultDeviceId(
      devicesByType.videoinput,
      mediaConstraints.video.deviceId as string
    );

    return {
      audio: {
        ...mediaConstraints.audio,
        deviceId: defaultAudioDeviceId,
      },
      video: {
        ...mediaConstraints.video,
        deviceId: defaultVideoDeviceId,
      },
    };
  };

  static prepareDevicesOnFirefox = async () => {
    if (isFirefox) {
      const ms = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      ms.getTracks().forEach((track) => track.stop()); // we always need to stop unused tracks
    }
  };

  static listDevices = () => navigator.mediaDevices.enumerateDevices();

  static getCameraMicStream = (mediaConstraints: MediaStreamConstraints) => {
    return navigator.mediaDevices.getUserMedia(mediaConstraints);
  };

  static requestPermissions = async () => {
    return navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
  };

  static isMediaStream(mediaStream: any): mediaStream is MediaStream {
    if ((mediaStream as MediaStream)?.getTracks) {
      // NOTE: for our purposes, this test is enough.
      return true;
    }
    return false;
  }
}

export type IMediaSourcesService = {
  [K in keyof typeof MediaSourcesService]: typeof MediaSourcesService[K];
};
