import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import NotFound from 'views/NotFound';
import LoginGate from 'views/LoginGate';
import { MAIN_ROUTES } from 'constants/routes';
import PopupRedirect from 'views/PopupRedirect';
import { Composite } from 'views/Composite';

const MainRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={MAIN_ROUTES.POPUP_REDIRECT} element={<PopupRedirect />} />
        <Route path={MAIN_ROUTES.STUDIO_GUEST} element={<LoginGate />} />
        <Route path={MAIN_ROUTES.COMPOSITE_MODE} element={<Composite />} />
        <Route path={MAIN_ROUTES.ACCESS_CODE_GATE} element={<LoginGate />} />
        <Route path={MAIN_ROUTES.NOT_FOUND} element={<NotFound />} />
        <Route path='*' element={<Navigate to={MAIN_ROUTES.NOT_FOUND} />} />
      </Routes>
    </>
  );
};

export default MainRoutes;
