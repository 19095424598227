// Query parameters which aren't critical to Studio functioning
export interface OptionalQueryParams {
  featureFlags?: string[];
  logLevel?: string;
  streamKey?: string;
  ingestUrl?: string;
  maestroData?: string;
}

export const capitalize = (text: string) => {
  return text?.charAt(0).toUpperCase() + text?.slice(1).toLowerCase() || '';
};

export const hasAllProperties = (obj: any, properties: string[]) => {
  try {
    return properties.every((property) => obj.hasOwnProperty(property));
  } catch (error) {
    console.error(error);
    return false;
  }
};

// NOTE: this is being called multiple times
export function getOptionalQueryParams(): OptionalQueryParams {
  const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
  return {
    featureFlags: (params.featureFlags ?? '').toLowerCase().split(','),
    logLevel: (params.logLevel ?? '').toLowerCase(),
    streamKey: params.streamKey ?? '',
    ingestUrl: params.ingestUrl ?? '',
    maestroData: decodeURIComponent(params.maestroData ?? ''),
  };
}

export function lightenDarkenColor(color: string, amount: number) {
  const num = parseInt(color.replace('#', ''), 16);

  let r = (num >> 16) + amount;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amount;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amount;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (color[0] === '#' ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}
