import React from 'react';
import { ReactComponent as MicIcon } from 'assets/icons/mic.svg';
import { ReactComponent as MicOffIcon } from 'assets/icons/micOff.svg';
import { ReactComponent as CamIcon } from 'assets/icons/cam.svg';
import { ReactComponent as CamOffIcon } from 'assets/icons/camOff.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
import { ReactComponent as LayoutIcon } from 'assets/icons/layout.svg';
import { ReactComponent as SpeakerIcon } from 'assets/icons/speaker.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as HidePanelIcon } from 'assets/icons/hidePanel.svg';
import { ReactComponent as BackStageIcon } from 'assets/icons/backstage.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkMark.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as PanelIcon } from 'assets/icons/panel.svg';
import { ReactComponent as PersonAddIcon } from 'assets/icons/personAdd.svg';
import { ReactComponent as PersonArrowLeftIcon } from 'assets/icons/personArrowLeft.svg';
import { ReactComponent as PersonArrowDownIcon } from 'assets/icons/personArrowDown.svg';
import { ReactComponent as PersonCheckIcon } from 'assets/icons/personCheck.svg';
import { ReactComponent as ToolsIcon } from 'assets/icons/tools.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as PeopleIcon } from 'assets/icons/people.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as GoogleLogoIcon } from 'assets/icons/googleLogo.svg';
import { ReactComponent as SoloViewIcon } from 'assets/icons/soloView.svg';
import { ReactComponent as SoloViewExitIcon } from 'assets/icons/soloViewExit.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import { ReactComponent as ConfigIcon } from 'assets/icons/config.svg';
import { ReactComponent as PolygonDownIcon } from 'assets/icons/polygonDown.svg';
import { ReactComponent as LeaveIcon } from 'assets/icons/leave.svg';
import { ReactComponent as AlertCircleIcon } from 'assets/icons/alertCircle.svg';
import { ReactComponent as AlertTriangleIcon } from 'assets/icons/alertTriangle.svg';
import { ReactComponent as ShareLinkIcon } from 'assets/icons/shareLink.svg';
import { ReactComponent as ScreenShareIcon } from 'assets/icons/screenShare.svg';
import { ReactComponent as StopScreenShareIcon } from 'assets/icons/stopScreenShare.svg';
import { ReactComponent as ShareArrowIcon } from 'assets/icons/shareArrow.svg';
import { ReactComponent as HideBackstageIcon } from 'assets/icons/hideBackstage.svg';
import { ReactComponent as ShowBackstageIcon } from 'assets/icons/showBackstage.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevronDown.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ClearIcon } from 'assets/icons/clear.svg';
import { ReactComponent as MaestroIcon } from 'assets/icons/maestro.svg';
import { ReactComponent as GettrIcon } from 'assets/icons/gettrIcon.svg';
import { ReactComponent as GettrLogoIcon } from 'assets/icons/gettrLogo.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as YouTubeIcon } from 'assets/icons/youtube.svg';
import { ReactComponent as TwitchIcon } from 'assets/icons/twitch.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as ChromeLogoIcon } from 'assets/icons/chromeLogo.svg';
import { ReactComponent as FirefoxLogoIcon } from 'assets/icons/firefoxLogo.svg';
import { ReactComponent as MaestroStudioIcon } from 'assets/icons/maestroStudioBrand.svg';
import { ReactComponent as BetaIcon } from 'assets/icons/betaIcon.svg';
import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation.svg';

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  stroke?: string;
}

export const Exclamation = ExclamationIcon as React.FC<IconProps>;
export const Trash = TrashIcon as React.FC<IconProps>;
export const Settings = SettingsIcon as React.FC<IconProps>;
export const ShowBackstage = ShowBackstageIcon as React.FC<IconProps>;
export const ShareArrow = ShareArrowIcon as React.FC<IconProps>;
export const ShareLink = ShareLinkIcon as React.FC<IconProps>;
export const AlertCircle = AlertCircleIcon as React.FC<IconProps>;
export const AlertTriangle = AlertTriangleIcon as React.FC<IconProps>;
export const Leave = LeaveIcon as React.FC<IconProps>;
export const SoloViewExit = SoloViewExitIcon as React.FC<IconProps>;
export const Config = ConfigIcon as React.FC<IconProps>;
export const Person = PersonIcon as React.FC<IconProps>;
export const SoloView = SoloViewIcon as React.FC<IconProps>;
export const Arrow = ArrowIcon as React.FC<IconProps>;
export const People = PeopleIcon as React.FC<IconProps>;
export const Copy = CopyIcon as React.FC<IconProps>;
export const Tools = ToolsIcon as React.FC<IconProps>;
export const PersonAdd = PersonAddIcon as React.FC<IconProps>;
export const PersonArrowLeft = PersonArrowLeftIcon as React.FC<IconProps>;
export const PersonArrowDown = PersonArrowDownIcon as React.FC<IconProps>;
export const PersonCheck = PersonCheckIcon as React.FC<IconProps>;
export const Panel = PanelIcon as React.FC<IconProps>;
export const Mic = MicIcon as React.FC<IconProps>;
export const MicOff = MicOffIcon as React.FC<IconProps>;
export const Cam = CamIcon as React.FC<IconProps>;
export const CamOff = CamOffIcon as React.FC<IconProps>;
export const Eye = EyeIcon as React.FC<IconProps>;
export const Help = HelpIcon as React.FC<IconProps>;
export const Speaker = SpeakerIcon as React.FC<IconProps>;
export const Layout = LayoutIcon as React.FC<IconProps>;
export const HidePanel = HidePanelIcon as React.FC<IconProps>;
export const BackStage = BackStageIcon as React.FC<IconProps>;
export const CheckMark = CheckMarkIcon as React.FC<IconProps>;
export const Close = CloseIcon as React.FC<IconProps>;
export const GoogleLogo = GoogleLogoIcon as React.FC<IconProps>;
export const PolygonDown = PolygonDownIcon as React.FC<IconProps>;
export const ScreenShare = ScreenShareIcon as React.FC<IconProps>;
export const StopScreenShare = StopScreenShareIcon as React.FC<IconProps>;
export const HideBackstage = HideBackstageIcon as React.FC<IconProps>;
export const ChevronDown = ChevronDownIcon as React.FC<IconProps>;
export const Plus = PlusIcon as React.FC<IconProps>;
export const Clear = ClearIcon as React.FC<IconProps>;
export const Maestro = MaestroIcon as React.FC<IconProps>;
export const Gettr = GettrIcon as React.FC<IconProps>;
export const GettrLogo = GettrLogoIcon as React.FC<IconProps>;
export const Facebook = FacebookIcon as React.FC<IconProps>;
export const YouTube = YouTubeIcon as React.FC<IconProps>;
export const Twitch = TwitchIcon as React.FC<IconProps>;
export const ChromeLogo = ChromeLogoIcon as React.FC<IconProps>;
export const FirefoxLogo = FirefoxLogoIcon as React.FC<IconProps>;
export const MaestroStudio = MaestroStudioIcon as React.FC<IconProps>;
export const Beta = BetaIcon as React.FC<IconProps>;
