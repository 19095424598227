import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes, useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { AlertTriangle } from 'components/shared/Icons';
import { Text } from 'components/shared';
import { ErrorDrawerType } from 'interfaces/ui';
import { getErrorDrawer, setErrorDrawerVisible } from 'store/slices/ui';
import { CONFIG } from 'constants/config';

const createErrorInfo = (type: ErrorDrawerType, guestName?: string) => {
  if (type === 'FULL_STAGE') {
    return {
      bold: 'Stage is Full. ',
      normal: `You have reached your ${CONFIG.MAX_PEERS_ON_STAGE} person limit. Please remove a guest from the stage and try again.`,
    };
  } else if (type === 'FULL_STUDIO_HOST') {
    return {
      bold: `${guestName} is trying to enter but the studio is full. `,
      normal: `You have reached your ${CONFIG.MAX_PEERS_ON_STUDIO} person limit. Please ask someone to leave if you want ${guestName} to join.`,
    };
  } else if (type === 'FULL_STUDIO_GUEST') {
    return {
      bold: `The Studio is full. `,
      normal: `The host has been notified that you are here. You will be able to enter once someone leaves.`,
    };
  } else {
    return {
      bold: '',
      normal: '',
    };
  }
};

const ErrorDrawer: React.FC = () => {
  const [errorInfo, setErrorInfo] = useState({ bold: '', normal: '' });
  const errorDrawer = useSelector(getErrorDrawer);
  const containerRef = useRef<HTMLDivElement>(null);
  const [animateOut, setAnimateOut] = useState(false);

  const { colors } = useTheme();

  const dispatch = useDispatch();

  useEffect(() => {
    if (errorDrawer.type === 'NONE') {
      dispatch(setErrorDrawerVisible(false));
      return;
    }
    const newErrorInfo = createErrorInfo(errorDrawer.type, errorDrawer.params.guestName);
    setErrorInfo(newErrorInfo);
  }, [dispatch, errorDrawer.params.guestName, errorDrawer.type]);

  useEffect(() => {
    const timeoutAnimation = setTimeout(() => {
      if (containerRef.current) {
        setAnimateOut(true);
      }
    }, 10000);
    const timeoutItem = setTimeout(() => {
      dispatch(setErrorDrawerVisible(false));
    }, 10300);
    return () => {
      clearTimeout(timeoutItem);
      clearTimeout(timeoutAnimation);
    };
  }, [dispatch, errorInfo]);

  const onButtonClick = () => {};

  return (
    <React.Fragment>
      <Container ref={containerRef} animateOut={animateOut}>
        <div>
          <AlertTriangle width={20} height={20} fill={colors.accentPrimary}></AlertTriangle>
        </div>
        <div>
          <Text size={14} weight={700} color={colors.text100}>
            {errorInfo.bold}
          </Text>
          <Text size={14} weight={400} color={colors.text100}>
            {errorInfo.normal}
          </Text>
        </div>

        {errorDrawer.params.hasButton && (
          <StyledButton enabled={errorDrawer.params.buttonEnabled || false} onClick={onButtonClick}>
            <Text size={14} weight={700} style={{ textTransform: 'uppercase' }}>
              Try again
            </Text>
          </StyledButton>
        )}
      </Container>
    </React.Fragment>
  );
};

const slideUp = keyframes`
   0% {
     transform: translateY(100vh);
     opacity: 0;
   }
   100% {
     transform: translateY(0);
     opacity: 1;
   }
`;

const slideDown = keyframes`
   0% {
     transform: translateY(0);
     opacity: 1;
   }
   100% {
     transform: translateY(100vh);
     opacity: 0;
   }
`;

const Container = styled.div<{ animateOut: boolean }>`
  z-index: 5;
  max-width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.surface2};
  display: flex;
  align-items: center;
  padding: 15px 20px 15px 20px;
  margin: 0px 0px 15px 0px;
  border: 10px;
  gap: 12px;
  ${(props) =>
    props.animateOut
      ? css`
          animation: ${slideDown} 0.375s cubic-bezier(0.01, 0.93, 0.45, 1.13) both;
        `
      : css`
          animation: ${slideUp} 0.275s cubic-bezier(0.01, 0.93, 0.45, 1.13) both;
        `}
`;

const StyledButton = styled.button<{ enabled: boolean }>`
  background-color: ${(props) =>
    props.enabled ? props.theme.colors.accentPrimary : props.theme.colors.surface6};
  height: 40px;
  min-width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    color: ${(props) => (props.enabled ? props.theme.colors.text100 : props.theme.colors.text400)};
  }
  :active {
    transform: translate(1px, 1px);
    box-shadow: 0 0 8px ${({ theme }) => theme.colors.accentPrimary};
  }
`;

export default ErrorDrawer;
