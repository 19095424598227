import { ThunkAction } from 'redux-thunk';
import { configureStore, Action } from '@reduxjs/toolkit';

import rootReducer, { RootState } from './slices';

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, undefined, Action<string>>;

export function createStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['ui/setModalStack', 'participants/setAllParticipants'],
          ignoredPaths: [
            'ui.modalStack.0.props.handleNext',
            'ui.peerBeingDragged.joinedAt',
            ...new Array(10)
              .fill('participants.participants.POSITION.joinedAt')
              .map((value, index) => value.replace('POSITION', index)),
          ],
        },
      }),
    preloadedState,
  });
}

const store = createStore();

export const waitForStateChange = async (detectChange: (state: RootState) => boolean) => {
  let unsubscribe = () => {};

  const state = await new Promise<RootState>((resolve) => {
    unsubscribe = store.subscribe(() => {
      const state = store.getState();

      if (detectChange(state)) resolve(state);
    });
  });

  unsubscribe();

  return state;
};

export default store;
