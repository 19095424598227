import axios, { AxiosError } from 'axios';

import { ENDPOINTS } from 'constants/endpoints';
import {
  CommanderVerbsType,
  Destination,
  DestinationDto,
  TProvider,
  IServiceDestination,
  StatusType,
} from 'interfaces/destinations';
import { CustomError, CustomErrorType } from 'utils/http-response-handler';
import { IntegrationService } from './IntegrationService';
import { TSecretToken } from 'interfaces/newType';

type ServiceResponse<T> = { data?: T; error?: Error | AxiosError<T> | CustomErrorType | unknown };

interface IExchangeCodeForTokensResponse {
  data?: {
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token?: string; // only twitch and youtube
    id_token?: string; // only twitch
  };
  error?: unknown;
}

interface IBatchVerifyAndRefreshResponse {
  updated: IServiceDestination[];
  failed: IServiceDestination[];
  unchanged: IServiceDestination[];
}

// TODO: use the correct type instead of unknown
interface IDestinationService {
  createDestination: (destination: Destination) => Promise<ServiceResponse<unknown>>;
  getDestination: (provider: TProvider, account: string) => Promise<ServiceResponse<unknown>>;
  updateDestination: (destinationObj: Destination) => Promise<ServiceResponse<unknown>>;
  removeDestination: (destination: Destination) => Promise<ServiceResponse<unknown>>;
  getAllDestinations: (isLive?: boolean) => Promise<ServiceResponse<IServiceDestination[]>>;
  toggleConnectDestination: (
    destination: Destination,
    shouldConnect: boolean
  ) => Promise<ServiceResponse<unknown>>;
  exchangeCodeForTokens(provider: TProvider, code: string): Promise<IExchangeCodeForTokensResponse>;
  batchVerifyAndRefresh(
    destinations: IServiceDestination[]
  ): Promise<ServiceResponse<IBatchVerifyAndRefreshResponse>>;
}

export class DestinationService implements IDestinationService {
  private http;

  private sessionId: string;
  private secretToken: TSecretToken;

  protected integration: IntegrationService | null = null;

  constructor(
    sessionId: string,
    secretToken: TSecretToken,
    private readonly extraHeaders: Record<string, string | undefined> = {}
  ) {
    this.sessionId = sessionId;
    this.secretToken = secretToken;
    this.http = axios.create({
      baseURL: `${ENDPOINTS.SESSION_SERVICE_BASE_URL}/sessions`,
      headers: { Authorization: `Bearer ${this.secretToken}`, ...extraHeaders },
    });
  }

  // If you provide a maestro-integration client, DestinationsService will
  // attempt to propagate creates/updates/deletes to maestro-integration too.
  withMaestroIntegration(svc: IntegrationService): this {
    this.integration = svc;
    return this;
  }

  withoutMaestroIntegration(): this {
    this.integration = null;
    return this;
  }

  async createDestination(dto: Destination) {
    try {
      const destination: Omit<IServiceDestination, 'id'> = {
        provider: dto.provider.toLocaleLowerCase(),
        account: dto.account,
        accessToken: dto.accessToken,
        expiresAt: dto.expiresAt,
        refreshToken: dto.refreshToken,
        metadata: dto.metadata,
        connected: dto.connected,
      };
      const { data } = await this.http.post(`${this.sessionId}/destinations`, destination);

      // Also try to create it in maestro-integration
      await this.integration?.upsertDestination(dto).catch((err) => console.error(err));

      return { data };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.message.includes('Max accounts per destination exceeded')) {
          return { error: CustomError('MAX_3_CONNECTIONS') };
        }
      }
      return { error };
    }
  }

  /**
   * @param  {TProvider} provider The provider name
   * @param  {string} account The account/username
   */
  async getDestination(provider: TProvider, account: string) {
    try {
      const url = `${provider.toLowerCase()}/${account}`;
      const { data } = await this.http.get(url);
      return { data };
    } catch (error) {
      return { error };
    }
  }

  async updateDestination(dto: Destination) {
    try {
      const destination = this.toServiceDestination(dto);
      const url = `${this.sessionId}/destinations/${destination.provider}/${destination.provider}`;
      const { data } = await this.http.put(url, destination);

      // Also update it in maestro-integration
      await this.integration
        ?.updateDestinations([this.toServiceDestination(dto)])
        .catch((err) => console.error(err));

      return { data };
    } catch (error) {
      return { error };
    }
  }

  async removeDestination(destination: Destination) {
    try {
      const { provider, account } = destination;

      const deleteUrl = `${this.sessionId}/destinations/${provider.toLowerCase()}/${account}`;
      const { data } = await this.http.delete(deleteUrl);

      // Also try to remove it in maestro-integration
      this.integration?.removeDestination(provider, account).catch((err) => console.warn(err));

      return { data };
    } catch (error) {
      return { error };
    }
  }

  async getAllDestinations() {
    try {
      const { data } = await this.http.get<IServiceDestination[]>(`${this.sessionId}/destinations`);
      return { data };
    } catch (error) {
      console.error({ error });
      return { error };
    }
  }

  async commandDestination(destination: Destination, verb: CommanderVerbsType) {
    try {
      const { provider, account } = destination;
      const url = `${this.sessionId}/destinations/${provider.toLowerCase()}/${account}/${verb}`;
      const { data } = await this.http.post(url);
      return { data };
    } catch (error) {
      return { error };
    }
  }

  async toggleConnectDestination(destination: Destination, shouldConnect: boolean) {
    try {
      const { error } = await this.updateDestination({ ...destination, connected: shouldConnect });
      return { error }; //FIXME: what?
    } catch (error) {
      return { error };
    }
  }

  async exchangeCodeForTokens(
    provider: TProvider,
    code: string
  ): Promise<IExchangeCodeForTokensResponse> {
    try {
      const url = `${this.sessionId}/destinations/exchangeCodeForTokens`;
      const { data } = await this.http.post(url, {
        provider: provider.toLocaleLowerCase(),
        code,
      });
      return { data };
    } catch (error) {
      return { error };
    }
  }

  async batchVerifyAndRefresh(
    destinations: IServiceDestination[]
  ): Promise<ServiceResponse<IBatchVerifyAndRefreshResponse>> {
    try {
      const url = `${this.sessionId}/destinations/batchVerifyAndRefresh`;
      const { data } = await this.http.post(url, destinations);
      return { data };
    } catch (error) {
      return { error };
    }
  }

  async updateDestinations(destinations: IServiceDestination[]) {
    try {
      const { data } = await this.http.patch(`${this.sessionId}/destinations`, destinations);

      // Also update it in maestro-integration
      await this.integration
        ?.updateDestinations(destinations as unknown as DestinationDto[])
        .catch((err) => console.error(err));
      return { data };
    } catch (error) {
      return { error };
    }
  }

  setCredentials(sessionId: string, secretToken: TSecretToken) {
    this.sessionId = sessionId;
    this.secretToken = secretToken;
    this.http = axios.create({
      baseURL: `${ENDPOINTS.SESSION_SERVICE_BASE_URL}/sessions`,
      headers: { Authorization: `Bearer ${this.secretToken}`, ...this.extraHeaders },
    });
  }

  // TODO: we need to refactor this to a more scalable way
  mapStatus(connected: boolean, isLive: boolean) {
    return connected ? (isLive ? StatusType.live : StatusType.ready) : StatusType.disconnected;
  }

  fromServiceDestination(dto: IServiceDestination, isLive?: boolean) {
    const destination = {
      id: dto.id,
      provider: dto.provider as TProvider,
      account: dto.account,
      imgUrl: '',
      status: this.mapStatus(dto.connected, isLive ?? false), //TODO: This data is not saved to our BE. What if the status is something else?
      metadata: dto.metadata,
      accessToken: dto.accessToken,
      expiresAt: dto.expiresAt,
      refreshToken: dto.refreshToken,
      data: {}, //NOTE: This data is not saved to our BE
      connected: dto.connected,
      rtmp: {
        rtmpUrl: '',
        streamKey: '',
      },
    } as Destination;
    return destination;
  }

  toServiceDestination(dto: Destination) {
    const destination: IServiceDestination = {
      id: dto.id,
      provider: dto.provider,
      account: dto.account,
      connected: dto.connected,
      accessToken: dto.accessToken,
      expiresAt: dto.expiresAt,
      refreshToken: dto.refreshToken,
      metadata: dto.metadata,
    };
    return destination;
  }
}

export default DestinationService;
