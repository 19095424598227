import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import { useSelector } from 'react-redux';

import DraggableItem from 'views/Host/DraggableItem';
import StageVideoCard from 'views/Host/StageVideoCard';
import { DRAG_AREAS } from 'constants/areas';
import { calculatePeerPosition } from 'views/shared/Stage/layouts';
import { getSessionIsHost, getSessionLayout } from 'store/slices/session';
import { getPeerBeingDragged, getPeerScreenShareId, getPeerSoloViewId } from 'store/slices/ui';
import { CONFIG } from 'constants/config';
import { PersonArrowLeft } from 'components/shared/Icons';
import { LAYOUTS } from 'constants/layouts';
import useFilteredParticipants from 'hooks/useFilteredParticipants';
import { addAlpha } from 'utils/color';

const StageDroppableArea: React.FC = () => {
  const { colors } = useTheme();

  const layout = useSelector(getSessionLayout);
  const isHost = useSelector(getSessionIsHost);
  const filteredParticipants = useFilteredParticipants();

  const peerBeingDragged = useSelector(getPeerBeingDragged);
  const peerSoloViewId = useSelector(getPeerSoloViewId);
  const peerScreenShareId = useSelector(getPeerScreenShareId);

  const { setNodeRef, over, active } = useDroppable({
    id: DRAG_AREAS.STAGE,
  });

  const displayOverlay = useMemo(() => {
    let canDisplayOverlay = false;
    if (active && over && active.data.current) {
      if (peerBeingDragged && !peerBeingDragged.isOnStage) {
        canDisplayOverlay = true;
      }
      if (active.data.current.area === DRAG_AREAS.WAITING) {
        if (over.data.current && over.data.current.area === DRAG_AREAS.STAGE) {
          canDisplayOverlay = true;
        } else if (over.id === DRAG_AREAS.STAGE) {
          canDisplayOverlay = true;
        }
      }
    }
    if (
      CONFIG.MAX_PEERS_ON_STAGE ===
      filteredParticipants.stage.length - (peerScreenShareId ? 1 : 0)
    ) {
      canDisplayOverlay = false;
    }
    if (peerSoloViewId) {
      canDisplayOverlay = false;
    }
    return canDisplayOverlay;
  }, [
    active,
    over,
    filteredParticipants.stage.length,
    peerSoloViewId,
    peerBeingDragged,
    peerScreenShareId,
  ]);

  const { videoConfigs, metadata } = useMemo(
    () =>
      calculatePeerPosition({
        layout,
        count: filteredParticipants.stage.length + (displayOverlay ? 1 : 0),
        peersIds: filteredParticipants.stage.map((peer) => peer.id),
        peerInSoloView: peerSoloViewId,
        isBeingDragged: displayOverlay,
        peerScreenShareId,
      }),
    [layout, filteredParticipants.stage, displayOverlay, peerSoloViewId, peerScreenShareId]
  );

  return (
    <SortableContext
      id={DRAG_AREAS.STAGE}
      items={filteredParticipants.backstage}
      strategy={rectSortingStrategy}
    >
      <Container>
        <StageContainer data-testid='stageView' ref={setNodeRef}>
          {filteredParticipants.stage.map((participant, idx: number) => (
            <DraggableItem
              data-testid='draggableItem'
              key={`stage-participant-${participant.id}`}
              id={participant.id}
              area={DRAG_AREAS.STAGE}
              dragEnabled={isHost}
              style={videoConfigs[participant.id]}
            >
              <StageVideoCard
                idx={idx}
                id={participant.id}
                isBeingDragged={peerBeingDragged?.id === participant.id}
                isSoloView={peerSoloViewId === participant.id}
                isSinglePeer={filteredParticipants.stage.length === 1}
                isSpotlight={metadata.overriddenLayout === LAYOUTS.SPOTLIGHT}
                shouldDisplayTalkingStatus={filteredParticipants.stage.length > 2}
              />
            </DraggableItem>
          ))}
          {displayOverlay && (
            <PreviewVideoWrapper style={videoConfigs.preview}>
              <PreviewVideoContainer>
                <PersonArrowLeft width={26} height={22} fill={colors.text100} />
                <PreviewContainerText>Guest will be added here</PreviewContainerText>
              </PreviewVideoContainer>
            </PreviewVideoWrapper>
          )}
        </StageContainer>
      </Container>
    </SortableContext>
  );
};

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  box-sizing: border-box;
  aspect-ratio: 16 / 9;
  height: min(100%, calc(9 * 100cqw / 16));
  transition: width, height 0.1ms;
  border: 1px solid ${(props) => props.theme.colors.accentPrimary};
  border-radius: 10px;
`;

const StageContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  transition: all 0.5s cubic-bezier(0.01, 0.93, 0.45, 1.13);
`;

const PreviewVideoWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px;
`;

const PreviewVideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({ theme }) => addAlpha(theme.colors.accentPrimary, 0.1)};
  box-sizing: border-box;
  border: 2px dashed ${({ theme }) => theme.colors.accentPrimary};
  border-radius: 4.94898px;
`;

const PreviewContainerText = styled.p`
  color: #ffffff;
  font-size: 12px;
  text-align: center;
`;

export default StageDroppableArea;
