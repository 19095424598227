import React from 'react';
import styled, { css } from 'styled-components/macro';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import StageControls from 'views/shared/StageControls';
import WaitingRoom from 'views/Guest/WaitingRoom';
import ErrorDrawer from '../ErrorDrawer';
import { getIsErrorDrawerVisible } from 'store/slices/ui';
import BrandBar from 'components/BrandBar';
import LiveIndicator from 'views/Guest/LiveIndicator';
import StageStreamArea, { useStageStreamResize } from './StageStreamArea';
import { resizeGuestBackStage } from 'views/Guest/BackStageSection';
import { getParticipantById } from 'store/slices/participants';
import { useReactiveRoom } from 'contexts/LiveKitContext';

const BottomRow = () => {
  const { localParticipantId: participantId } = useReactiveRoom();

  const participant = useSelector((state: any) => getParticipantById(state, participantId));

  const isErrorDrawerVisible = useSelector(getIsErrorDrawerVisible);

  if (!participant) return null;

  if (participant.isHost)
    return (
      <BottomRowContainer isOnStage={participant.isOnStage}>
        {isErrorDrawerVisible && <ErrorDrawer />}
      </BottomRowContainer>
    );

  return (
    <BottomRowContainer isOnStage={participant.isOnStage}>
      {participant.isOnStage ? <LiveIndicator /> : <WaitingRoom />}
    </BottomRowContainer>
  );
};

const GuestMobileStage = () => {
  return (
    <Container id='stage-container' data-testid='stage-container'>
      <StageStreamArea />
      <LandscapeHidden id='landscape-container'>
        {isMobile && <BrandBar />}
        <BottomRow />
      </LandscapeHidden>
      <StageControls />
    </Container>
  );
};

const Stage: React.FC = () => {
  const { localParticipantId } = useReactiveRoom();

  const participant = useSelector((state: any) => getParticipantById(state, localParticipantId));

  useStageStreamResize({ resizeCallbacks: [resizeGuestBackStage] });

  const isGuestMobile = isMobile && !participant?.isHost;

  if (isGuestMobile) return <GuestMobileStage />;

  return (
    <Container id='stage-container' data-testid='stage-container'>
      <StageStreamArea />
      <BottomRow />
      <StageControls />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: ${isMobile ? 'flex-start' : 'center'};
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #000000;
  border: ${({ theme }) => (isMobile ? 'none' : `1px solid ${theme.colors.surface6}`)};
  border-radius: ${isMobile ? '0' : '10px'};
`;

const BottomRowContainer = styled.div<{ isOnStage: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${!isMobile ? 'auto' : '100%'};
  width: 100%;
  background-color: black;
  padding-top: ${isMobile ? '0' : '10px'};
  margin-bottom: ${isMobile ? 'auto' : '0'};
`;

const LandscapeHidden = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 0 220px;
  @media (orientation: landscape) {
    // NOTE: this ensure that this query only runs in mobile devices;
    ${isMobile &&
    css`
      display: none;
    `};
  }
`;

export default Stage;
