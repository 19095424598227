import React from 'react';
import { isMobile } from 'react-device-detect';
import styled, { useTheme } from 'styled-components/macro';

import { Text } from 'components/shared';
import GuestStageVideoCard from 'views/Guest/GuestStageVideoCard';
import BackStageSection, {
  DesktopBackStageMessageContainer,
  MobileBackStageMessageContainer,
} from 'views/Guest/BackStageSection';
import { useSelector } from 'react-redux';
import { getSelf } from 'store/slices/participants';

const MobileWaitingRoom: React.FC = () => {
  const { colors } = useTheme();
  const participant = useSelector(getSelf);

  return (
    <BackStageSection>
      <MobileBackStageMessageContainer>
        <Text size={20} weight={700} color={colors.text100} textAlign='center'>
          You’re in the waiting room
        </Text>
        <Text size={12} weight={400} color={colors.text100} textAlign='center'>
          The host can see you, but cannot hear you. The audience will see you once you’re brought
          on stage.
        </Text>
      </MobileBackStageMessageContainer>
      {participant?.id && (
        <MobileVideoContainer>
          <RelativeWrapper>
            <AbsoluteWrapper>
              <GuestStageVideoCard
                idx={0}
                id={participant.id}
                isSpotlight={true}
                shouldDisplayTalkingStatus={false}
              />
            </AbsoluteWrapper>
          </RelativeWrapper>
        </MobileVideoContainer>
      )}
    </BackStageSection>
  );
};

const DesktopWaitingRoom: React.FC = () => {
  const { colors } = useTheme();
  const participant = useSelector(getSelf);

  return (
    <BackStageSection>
      {participant?.id && (
        <DesktopVideoContainer>
          <GuestStageVideoCard
            idx={0}
            id={participant.id}
            isSpotlight={true}
            shouldDisplayTalkingStatus={false}
          />
        </DesktopVideoContainer>
      )}
      <DesktopBackStageMessageContainer>
        <Text size={30} weight={700} color={colors.text100} textAlign='center'>
          You’re in the waiting room
        </Text>
        <Text
          size={14}
          weight={400}
          color={colors.text100}
          textAlign='center'
          style={{ maxWidth: 400 }}
        >
          The host can see you, but cannot hear you. The audience will see you once you’re brought
          on stage.
        </Text>
      </DesktopBackStageMessageContainer>
    </BackStageSection>
  );
};

const WaitingRoom = () => {
  if (isMobile) return <MobileWaitingRoom />;
  return <DesktopWaitingRoom />;
};

const BaseVideoContainer = styled.div`
  flex: 1;
  box-sizing: border-box;
`;

const MobileVideoContainer = styled(BaseVideoContainer)`
  padding: 12px 10px;
  width: 100%;
  min-width: 269px;
  max-width: 457px;
  height: auto;
`;

const RelativeWrapper = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
  inset: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const DesktopVideoContainer = styled(BaseVideoContainer)`
  width: 239px;
  min-width: 239px;
  height: 137px;
  border: 1px solid ${({ theme }) => theme.colors.surface6};
  border-radius: 5px;
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 25%);
  /* NOTE: this fixes desktop small window */
  @media (max-width: 599px) {
    width: 130px;
    min-width: 130px;
    height: 100px;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    width: 231px;
    min-width: 231px;
    height: 130px;
  }
`;

export default WaitingRoom;
