import React from 'react';
import styled from 'styled-components';

import { Button, Text } from 'components/shared';
import { DestinationErrorType, TProvider, PROVIDERS } from 'interfaces/destinations';
import { capitalize, fmtDestName } from 'utils/destination';
import ConnectButton from './ConnectButton';
import { useSelector } from 'react-redux';
import { getNeedHelpUrl, getSiteSource } from 'store/slices/siteConfig';

interface ErrorData {
  channelId?: string;
}

interface Props {
  provider: TProvider;
  errorType?: DestinationErrorType;
  data?: ErrorData;
  // data?: Record<string, any>;
  handleClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const DestinationErrorMessage: React.FC<Props> = ({ errorType, provider, data, handleClose }) => {
  const needHelpUrl = useSelector(getNeedHelpUrl);
  const siteSource = useSelector(getSiteSource);

  return (
    <Body>
      {(!errorType ||
        errorType === 'UNHANDLED_ERROR' ||
        errorType === 'YOUTUBE_NO_CHANNEL_LINKED') && (
        <>
          {/* NOTE: since we know when there's no youtube channel linked, we could have a different error */}
          <Text size={14} weight={500} textAlign='center'>
            An issue occurred during {fmtDestName(provider)} setup. Please try connecting again.
          </Text>
          <Text size={14} weight={500} textAlign='center'>
            If the issue continues, read our{' '}
            <Link href={needHelpUrl} target='_blank' rel='noopener noreferrer'>
              {fmtDestName(provider)} troubleshooting
            </Link>{' '}
            guide or contact {capitalize(siteSource)} support.
          </Text>
          <Button style={{ width: '84px', height: '40px', padding: 0 }} onClick={handleClose}>
            <Text size={14} weight={700}>
              CLOSE
            </Text>
          </Button>
        </>
      )}

      {(provider === PROVIDERS.youtube && errorType === 'YOUTUBE_24H_WAIT') ||
        (errorType === 'YOUTUBE_LIVESTREAMING_NOT_ENABLED' && (
          <>
            <Text size={13} weight={400} textAlign='center'>
              Live Streaming must be enabled on YouTube to complete this connection.
            </Text>
            <Text size={13} weight={400} style={{ lineHeight: '17px' }}>
              Here is how to do it:
              <ol style={{ marginLeft: '20px' }}>
                <li>
                  Go to your{' '}
                  <Link href={`https://www.youtube.com/features`} target='_blank'>
                    Youtube Dashboard
                  </Link>
                </li>
                <li>Verify your Youtube Live Streaming account.</li>
                <li>Wait 24 hours for Youtube to enable your account.</li>
                <li>Come back here and click connect.</li>
              </ol>
            </Text>

            <Button style={{ width: '84px', height: '40px', padding: 0 }} onClick={handleClose}>
              <Text size={14} weight={700}>
                CLOSE
              </Text>
            </Button>
          </>
        ))}

      {errorType === 'MAX_3_CONNECTIONS' && (
        <>
          <Text size={13} weight={400} textAlign='center'>
            You’ve hit the maximum (3) number of connections made to this platform. Please remove a
            destination before trying again.
          </Text>
          <Button
            style={{ width: '84px', height: '40px', padding: 0, marginTop: '10px' }}
            onClick={handleClose}
          >
            <Text size={14} weight={700}>
              CLOSE
            </Text>
          </Button>
        </>
      )}
      {errorType === 'UNABLE_TO_STREAM' && (
        <>
          <Text size={13} weight={400} textAlign='center'>
            We encountered a problem sending your stream to this destination. Click the toggle to
            try again.
          </Text>
          <Text size={13} weight={400} textAlign='center'>
            If the issue is not fixed, Click below to reconnect your destination.
          </Text>
          <ConnectButton isReconnect provider={provider} handleClick={handleClose} />
        </>
      )}
      {errorType === 'LOST_CONNECTION' && (
        <>
          <Text size={13} weight={400} textAlign='center'>
            We lost access to your {fmtDestName(provider)} account while you were away.
          </Text>
          <Text size={13} weight={400} textAlign='center'>
            Click above to reconnect to your destination.
          </Text>
          <ConnectButton isReconnect provider={provider} handleClick={handleClose} />
        </>
      )}
      {errorType === 'ALREADY_STREAMING_DESTINATION' && (
        <>
          <Text size={13} weight={400} textAlign='center'>
            You are already streaming to this destination from another {capitalize(siteSource)}{' '}
            channel. Destinations can only accept 1 stream at a time.
          </Text>
          <Button style={{ width: '84px', height: '40px', padding: 0 }} onClick={handleClose}>
            <Text size={14} weight={700}>
              CLOSE
            </Text>
          </Button>
        </>
      )}
    </Body>
  );
};

const Body = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.accentPrimary};
`;

export default DestinationErrorMessage;
