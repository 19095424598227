import { Option } from 'components/shared/Select';
import { NODE_ENV } from 'config';

//NOTE: either we use this created Interface or we use ts-ignore
export interface SinkableElement extends HTMLVideoElement {
  sinkId: string | undefined;
  setSinkId: (deviceId: string) => Promise<void>;
}

export interface ICreateCustomMediaStream {
  audioTrack: MediaStreamTrack | null;
  videoTrack: MediaStreamTrack | null;
}

const LOW_RES_OPTION = {
  width: { ideal: 214 },
  height: { ideal: 120 },
  aspectRatio: { exact: 16 / 9 },
  deviceId: 'default',
};

const BASE_RES_OPTIONS: Option<{
  deviceId: string;
  aspectRatio: W3C.ConstrainDouble;
  width: W3C.ConstrainLong;
  height: W3C.ConstrainLong;
}>[] = [
  {
    label: 'Low Definition (360p)',
    value: {
      width: { ideal: 640 },
      height: { ideal: 360 },
      aspectRatio: { exact: 16 / 9 },
      deviceId: 'default',
    },
  },
  {
    label: 'Standard Definition (480p)',
    value: {
      width: { ideal: 854 },
      height: { ideal: 480 },
      aspectRatio: { exact: 16 / 9 },
      deviceId: 'default',
    },
  },
  {
    label: 'High Definition (720p)',
    value: {
      width: { ideal: 1280 },
      height: { ideal: 720 },
      aspectRatio: { exact: 16 / 9 },
      deviceId: 'default',
    },
  },
];

export const RESOLUTION_OPTIONS: Option<{
  deviceId: string;
  aspectRatio: W3C.ConstrainDouble;
  width: W3C.ConstrainLong;
  height: W3C.ConstrainLong;
}>[] =
  NODE_ENV === 'development'
    ? [
        // Adding an option with small resolution for local testing
        {
          label: 'Ultra-Low Definition (120p)',
          value: { ...LOW_RES_OPTION, deviceId: '' },
        },
        ...BASE_RES_OPTIONS,
      ]
    : BASE_RES_OPTIONS;

const AudioContext = window.AudioContext || (window as any).webkitAudioContext;

export const attachSinkId = async (element: SinkableElement, sinkId: string) => {
  try {
    if (typeof element?.sinkId !== 'undefined') {
      await element.setSinkId(sinkId);
    }
  } catch (error) {
    console.warn('Browser does not support output device selection. Error: ', error);
  }
};

export const createSilentAudioTrack = () => {
  const ctx = new AudioContext();
  const oscillator = ctx.createOscillator();
  const destination = oscillator.connect(
    ctx.createMediaStreamDestination()
  ) as MediaStreamAudioDestinationNode;
  oscillator.start();

  const stream = destination.stream;
  return Object.assign(stream.getAudioTracks()[0], { enabled: false });
};

export const createEmptyVideoTrack = () => {
  const width = 320,
    height = 180;

  const canvas =
    (document.getElementById('empty-canvas') as HTMLCanvasElement) ||
    document.createElement('canvas');
  Object.assign(canvas, { width, height, id: 'empty-canvas' });
  const ctx = canvas.getContext('2d');
  ctx?.fillRect(0, 0, width, height);

  // leaving it blank makes it capture new frames only when there are changes
  //@ts-ignore
  const stream = canvas.captureStream() as MediaStream;
  return Object.assign(stream.getVideoTracks()[0], { enabled: false });
};

export const createCustomMediaStream = (payload: ICreateCustomMediaStream) => {
  const audioTrack = payload.audioTrack || createSilentAudioTrack();
  const videoTrack = payload.videoTrack || createEmptyVideoTrack();
  return new MediaStream([audioTrack, videoTrack]);
};

export const getBlockedMediaKind = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();

  const obj = devices.reduce(
    (acc, curr) => {
      // NOTE: I'm assuming that when you have media not allowed you don't get its label
      if (curr.label === '' && curr.kind !== 'audiooutput') {
        return { ...acc, [curr.kind]: true };
      }
      return acc;
    },
    { audioinput: false, videoinput: false }
  );

  if (obj.audioinput && obj.videoinput) return 'both';
  if (obj.audioinput) return 'audio';
  if (obj.videoinput) return 'video';
  return 'none';
};

export const getScreenShareStream = async () => {
  // @ts-ignore
  const stream = await navigator.mediaDevices.getDisplayMedia({
    video: true, // NOTE: Previously it had { mediaSource: 'screen' }, but we couldn't find this in the cods
    audio: true,
  });
  //NOTE: this is a workaround. In order to identify whether a stream is normal stream or a screenshare stream, 2 fake empty tracks are added
  const firstFakeTrack = createEmptyVideoTrack();
  const secondFakeTrack = createEmptyVideoTrack();
  const screenShareStream = new MediaStream([
    ...stream.getTracks(),
    firstFakeTrack,
    secondFakeTrack,
  ]);
  return screenShareStream;
};
