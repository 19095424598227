import React, { useMemo } from 'react';
import styled from 'styled-components';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';

import { DRAG_AREAS } from 'constants/areas';
import { getPeerBeingDragged } from 'store/slices/ui';
import InviteGuestCard from 'views/Host/InviteGuestCard';
import BackstageVideoCard from './BackstageVideoCard';
import { useSelector } from 'react-redux';
import useFilteredParticipants from 'hooks/useFilteredParticipants';

const BackstageDroppableArea: React.FC = () => {
  const filteredParticipants = useFilteredParticipants();
  const peerBeingDragged = useSelector(getPeerBeingDragged);
  const { setNodeRef, active, over } = useDroppable({
    id: DRAG_AREAS.WAITING,
  });

  const displayOverlay = useMemo(() => {
    let canDisplayOverlay = false;
    if (active && over && active.data.current) {
      if (peerBeingDragged && peerBeingDragged.isOnStage) {
        canDisplayOverlay = true;
      }
      if (peerBeingDragged && peerBeingDragged.isScreenShare) {
        canDisplayOverlay = false;
      }
    }
    return canDisplayOverlay;
  }, [active, over, peerBeingDragged]);

  return (
    <SortableContext
      id={DRAG_AREAS.WAITING}
      items={filteredParticipants.backstage}
      strategy={rectSortingStrategy}
    >
      <Container data-testid='backstageView' ref={setNodeRef}>
        {filteredParticipants.backstage.map((participant) => (
          <BackstageVideoCard id={participant.id} key={`waiting-area-peer-${participant.id}`} />
        ))}
        {displayOverlay && <InviteGuestCard isFull={false} isReturn />}
      </Container>
    </SortableContext>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 147px);
  grid-template-rows: 140px;
  width: 300px;
  gap: 10px;
`;

export default BackstageDroppableArea;
