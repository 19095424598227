import { useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import Collapsible from 'react-collapsible';

import { Clear, Plus } from 'components/shared/Icons';
import { Text } from 'components/shared';
import PreConnectionCard from './PreConnectionCard';
import { PROVIDERS } from 'interfaces/destinations';

const DestinationAddContainer = () => {
  const { colors } = useTheme();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleToggleExpanded = () => setIsExpanded((prevIsExpanded) => !prevIsExpanded);

  return (
    <Container isExpanded={isExpanded} data-testid='destAddNewDestinationDropdown'>
      <Collapsible
        open={isExpanded}
        transitionTime={220}
        trigger={
          <HeaderContainer isExpanded={isExpanded} onClick={handleToggleExpanded}>
            {!isExpanded && <Plus width={24} height={24} fill={colors.text100} />}
            <Text size={14} weight={700} color={colors.text100}>
              Add New Destination
            </Text>
            {isExpanded && <Clear width={24} height={24} fill={colors.text100} />}
          </HeaderContainer>
        }
      >
        <ContentContainer>
          <PreConnectionCard provider={PROVIDERS.facebook} />
          <PreConnectionCard provider={PROVIDERS.youtube} />
          <PreConnectionCard provider={PROVIDERS.twitch} />
        </ContentContainer>
      </Collapsible>
    </Container>
  );
};

const Container = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: ${(props) => (props.isExpanded ? 'auto' : '72px')};
  background-color: ${({ theme }) => theme.colors.surface4};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.surface6};
  user-select: none;
  transition: 0.22s max-height ease-in-out;
`;

const HeaderContainer = styled.div<{ isExpanded: boolean }>`
  box-sizing: border-box;
  padding: 8.5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  height: ${(props) => (props.isExpanded ? '50px' : '72px')};
  justify-content: ${(props) => (props.isExpanded ? 'space-between' : 'center')};
`;

const ContentContainer = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-sizing: border-box;
`;

export default DestinationAddContainer;
