import React from 'react';
import { useSelector } from 'react-redux';
import { getSessionIsHost } from 'store/slices/session';
import { BottomRowLinks } from 'components/Modals/Settings/styles';
import { Text } from 'components/shared';
import { SettingsType } from 'components/Modals/Settings/CameraSettings';
import PrivacyPolicyLink from 'components/WhiteLabel/PrivacyPolicyLink';
import NeedHelpLink from 'components/WhiteLabel/NeedHelpLink';
import { useTheme } from 'styled-components';

type Props = {
  type: SettingsType;
};

export const Footer: React.FC<Props> = ({ type }) => {
  const isHost = useSelector(getSessionIsHost);
  const { colors } = useTheme();

  return (
    <BottomRowLinks>
      <PrivacyPolicyLink />
      {(type === 'tour' || !isHost) && type === 'tour' && (
        <Text
          weight={500}
          size={14}
          color={colors.highlightPrimary}
          style={{ textDecoration: 'underline' }}
        >
          Skip the Tour
        </Text>
      )}
      <NeedHelpLink />
    </BottomRowLinks>
  );
};
