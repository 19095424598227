import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import {
  defaultDropAnimation,
  DndContext,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  PointerSensor,
  rectIntersection,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import Stage from 'views/shared/Stage';
import Backstage from 'views/Host/Backstage';
import { getSessionId } from 'store/slices/session';
import { DRAG_AREAS } from 'constants/areas';
import { snapCenterToCursorCustom } from 'utils/modifiers';
import BackstageSkeleton from 'components/skeletons/BackstageSkeleton';
import {
  getIsBackstageVisible,
  getIsLoadingSkeletonActive,
  getPeerBeingDragged,
  getPeerScreenShareId,
  setIsDrawerPanelVisible,
  setPeerBeingDragged,
} from 'store/slices/ui';
import DraggingVideoCard from './DraggingVideoCard';
import { CONFIG } from 'constants/config';
import { getParticipants } from 'store/slices/participants';
import useFilteredParticipants from 'hooks/useFilteredParticipants';
import { useLayoutHandlers } from 'views/Host/useLayoutHandlers';
import { useServices } from 'hooks/useServices';

const dropAnimation: DropAnimation = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5,
};

const Host: React.FC = () => {
  const dispatch = useDispatch();
  const { stage } = useFilteredParticipants();
  const peerScreenShareId = useSelector(getPeerScreenShareId);
  const isBackstageVisible = useSelector(getIsBackstageVisible);
  const peerBeingDragged = useSelector(getPeerBeingDragged);
  const participants = useSelector(getParticipants);
  const isLoadingSkeletonActive = useSelector(getIsLoadingSkeletonActive);
  const { sessionService } = useServices();
  const sessionId = useSelector(getSessionId);

  const { handleChangeStageState } = useLayoutHandlers();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const participant = participants.find((participant) => participant.id === active.id);
    participant && dispatch(setPeerBeingDragged(participant));
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (!active || !over) return dispatch(setPeerBeingDragged(null));

    const { id: originId } = active;
    const { id: overId } = over;

    const originPeer = participants.find((participant) => participant.id === originId);
    const overPeer = participants.find((participant) => participant.id === overId);

    if (!originPeer || originPeer.isScreenShare || overPeer?.isScreenShare)
      return dispatch(setPeerBeingDragged(null));

    if (overId === DRAG_AREAS.STAGE && originPeer.isOnStage)
      return dispatch(setPeerBeingDragged(null));

    if (overId === DRAG_AREAS.STAGE || overId === DRAG_AREAS.WAITING) {
      // Changing area
      if (!originPeer.isScreenShare) {
        await handleChangeStageState(originId, overId === DRAG_AREAS.STAGE, stage.length);
      }
      if (overId === DRAG_AREAS.WAITING) {
        dispatch(setIsDrawerPanelVisible(false));
      }
    } else {
      const overPeer = participants.find((participant) => participant.id === overId);
      if (!overPeer) return;
      // Swapping peers
      if (overPeer.isOnStage === originPeer.isOnStage) {
        const peersToBeSwapped = [
          { id: originPeer.id, order: overPeer.order },
          { id: overPeer.id, order: originPeer.order },
        ];

        peersToBeSwapped.forEach(({ order: newOrder }, i) => {
          const peer = i === 0 ? originPeer : overPeer;
          const newMetadata = {
            isOnStage: peer.isOnStage,
            order: newOrder,
          };

          sessionService.updateParticipantMetadata(sessionId, peer.id, newMetadata);
        });
      } else {
        // Changing area
        await handleChangeStageState(originId, overPeer.isOnStage, stage.length);
        if (!overPeer.isOnStage) {
          dispatch(setIsDrawerPanelVisible(false));
        }
      }
    }
    dispatch(setPeerBeingDragged(null));
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      modifiers={[snapCenterToCursorCustom]}
    >
      <Container isBackstageVisible={isBackstageVisible}>
        {isLoadingSkeletonActive ? <BackstageSkeleton /> : <Backstage />}
        <Stage />
        {createPortal(
          <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
            {peerBeingDragged && (
              <DraggingVideoCard
                id={peerBeingDragged.id}
                displayName={peerBeingDragged.displayName}
                denied={
                  !peerBeingDragged.isOnStage &&
                  stage.length - (peerScreenShareId ? 1 : 0) === CONFIG.MAX_PEERS_ON_STAGE
                }
                isScreenShare={peerScreenShareId === peerBeingDragged.id}
              />
            )}
          </DragOverlay>,
          document.body
        )}
      </Container>
    </DndContext>
  );
};

const Container = styled.div<{ isBackstageVisible: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.isBackstageVisible ? '345px 1fr' : '1fr')};
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 5px;
  gap: 6px;
`;

export default Host;
