import React from 'react';
import styled, { useTheme } from 'styled-components/macro';

import Studio from 'views/Studio';
import EnteringStudio from 'components/WhiteLabel/EnteringStudio';
import { LoadingBouncer, Text } from 'components/shared';
import { useLoginGateMachine } from './useLoginGateMachine';

const LoginGate: React.FC = () => {
  const { isLoading, isAccessAllowed, error } = useLoginGateMachine();
  const { colors } = useTheme();

  if (isLoading) return <EnteringStudio />; //TODO: maybe not show this while the settings modal is open
  if (isAccessAllowed) return <Studio />;
  if (error) {
    return (
      <Container>
        <CardContainer>
          {error && (
            <Text color={colors.alertError} size={12} weight={300}>
              {error}
            </Text>
          )}
        </CardContainer>
      </Container>
    );
  }

  // NOTE: Adding some loading animation for the state where the siteConfig is not available yet.
  return (
    <Container>
      <LoadingBouncer />
    </Container>
  );
};

const Container = styled.div`
  animation: fadein 2s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.surface4};
`;

const CardContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-direction: column;
  width: 300px;
  background-color: ${(props) => props.theme.colors.surface5};
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
`;

export default LoginGate;
