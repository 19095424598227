import { ENDPOINTS } from 'constants/endpoints';
import { SessionDto } from 'interfaces/session';
import { BaseService } from './BaseService';
import store from 'store';
import { DestinationDto } from 'interfaces/destinations';
import { TParticipant } from 'interfaces/participants';
import { Layout } from 'constants/layouts';

export type ParticipantMetadata = Pick<TParticipant, 'isOnStage' | 'order' | 'isHost'> & {
  audioBlocked?: boolean;
  videoBlocked?: boolean;
  videoUnmuteRequested?: boolean;
  audioUnmuteRequested?: boolean;
  kicked?: boolean;
  isIngressSource?: boolean;
};

export type RoomMetadata = {
  screenShareRequests?: Record<string, { participantId: string; allowed?: boolean }>;
  layout?: Layout;
  peerSoloViewId?: string;
};

export interface ISessionService {
  createSession(
    inviteCode?: string
  ): Promise<Required<Pick<SessionDto, 'secretToken' | 'inviteCode' | 'sessionId'>>>;
  getOrCreateSession(sessionId: string, inviteCode?: string): Promise<Required<SessionDto>>;
  getSession(sessionId: string): Promise<SessionDto>;
  getStreamToken(sessionId: string, userId: string): Promise<{ token: string }>;
  setSessionLive(sessionId: string, isLive: boolean): Promise<Pick<SessionDto, 'live'>>;
  regenerateInviteCode(sessionId: string): Promise<Pick<SessionDto, 'inviteCode'>>;
  getSessionByInviteCode(inviteCode: string): Promise<SessionDto>;
  liveDestinations(sessionId: string, destinations: DestinationDto[]): Promise<{ ids: string[] }>;
  updateParticipantMetadata: (
    sessionId: string,
    identity: string,
    metadata: Partial<ParticipantMetadata>
  ) => Promise<void>;
  updateParticipantName: (sessionId: string, token: string, name: string) => Promise<void>;
  updateRoomMetadata: (sessionId: string, metadata: RoomMetadata) => Promise<void>;
  startRtmpOutput: (
    sessionId: string,
    rtmps: {
      rtmpUrl: string;
      rtmpKey: string;
    }[],
    compositorParams?: Record<string, string>
  ) => Promise<void>;
  stopRtmpOutput: (
    sessionId: string,
    rtmps: { rtmpUrl: string; rtmpKey: string }[]
  ) => Promise<void>;
  kickParticipant: (sessionId: string, identity: string) => Promise<void>;
  pollStatus: (sessionId: string) => Promise<{ isLive: boolean }>;
}

class SessionService extends BaseService implements ISessionService {
  constructor() {
    super(new URL(ENDPOINTS.SESSION_SERVICE_BASE_URL), {
      'Content-Type': 'application/json',
    });
  }

  async updateParticipantMetadata(
    sessionId: string,
    identity: string,
    metadata: Partial<ParticipantMetadata>
  ) {
    await this.withHeaders(this.getHeaders()).patch(
      `/sessions/${sessionId}/participantMetadata/${identity}`,
      { metadata }
    );
  }

  async updateParticipantName(sessionId: string, token: string, name: string) {
    await this.put(`/sessions/${sessionId}/participantName?token=${token}`, { name });
  }

  async updateRoomMetadata(sessionId: string, metadata: RoomMetadata) {
    await this.put(`/sessions/${sessionId}/roomMetadata`, { metadata: JSON.stringify(metadata) });
  }

  async startRtmpOutput(
    sessionId: string,
    rtmps: { rtmpUrl: string; rtmpKey: string }[],
    compositorParams?: Record<string, string>
  ) {
    await this.post(`/sessions/${sessionId}/rtmpStart`, { rtmps, compositorParams });
  }

  async stopRtmpOutput(sessionId: string, rtmps: { rtmpUrl: string; rtmpKey: string }[]) {
    await this.post(`/sessions/${sessionId}/rtmpStop`, { rtmps });
  }

  getStreamToken(sessionId: string, userId: string): Promise<{ token: string }> {
    return this.withHeaders(this.getHeaders()).get(`/sessions/streamToken/${sessionId}`, {
      userId,
    });
  }

  async kickParticipant(sessionId: string, identity: string) {
    await this.withHeaders(this.getHeaders()).delete(
      `/sessions/${sessionId}/removeParticipant/${identity}`
    );
  }

  async createSession(
    inviteCode?: string
  ): Promise<Required<Pick<SessionDto, 'secretToken' | 'inviteCode' | 'sessionId'>>> {
    return this.withHeaders(this.getHeaders()).post(`/sessions`, { inviteCode });
  }

  async getOrCreateSession(sessionId: string, inviteCode?: string): Promise<Required<SessionDto>> {
    return this.withHeaders(this.getHeaders()).get(`sessions/getOrCreate`, {
      inviteCode,
      sessionId: sessionId ?? '',
    });
  }

  async getSession(sessionId: string): Promise<SessionDto> {
    return this.withHeaders(this.getHeaders()).get(`/sessions/${sessionId}`);
  }

  async setSessionLive(sessionId: string, isLive: boolean): Promise<Pick<SessionDto, 'live'>> {
    return this.withHeaders(this.getHeaders()).patch(`/sessions/${sessionId}/live`, {
      live: isLive,
    });
  }

  async regenerateInviteCode(sessionId: string): Promise<Pick<SessionDto, 'inviteCode'>> {
    return this.withHeaders(this.getHeaders()).patch(`/sessions/inviteCode`, { sessionId });
  }

  async getSessionByInviteCode(inviteCode: string): Promise<SessionDto> {
    return this.withHeaders(this.getHeaders()).get(`/sessions/inviteCode/${inviteCode}`);
  }

  async upsertDestinations(sessionId: string, destinations: any[]) {
    const url = `sessions/${sessionId}/destinations`;
    const headers = this.getHeaders();
    return this.withHeaders(headers).put(url, destinations);
  }

  async liveDestinations(sessionId: string, destinations: DestinationDto[]) {
    return this.withHeaders(this.getHeaders()).post<{ ids: string[] }>(
      `sessions/${sessionId}/liveDestinations`,
      destinations
    );
  }

  async pollStatus(sessionId: string): Promise<{ isLive: boolean }> {
    return this.withHeaders(this.getHeaders()).get(`sessions/${sessionId}/status`);
  }

  private getHeaders(): Record<string, string> {
    const {
      session: { secretToken },
    } = store.getState();
    return { Authorization: `Bearer ${secretToken}` };
  }
}

export default SessionService;
