import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';

import { getHideDisplayNames } from 'store/slices/session';
import { setErrorDrawerType, setErrorDrawerVisible } from 'store/slices/ui';
import { useParticipantById } from 'hooks/useParticipantById';
import BaseVideoCard from 'components/VideoCard/BaseVideoCard';

interface Props {
  id: string;
  displayName?: string;
  denied: boolean;
  isScreenShare?: boolean;
}

const DraggingVideoCard: React.FC<Props> = ({ id, displayName, denied, isScreenShare }) => {
  const hideDisplayNames = useSelector(getHideDisplayNames);
  const participant = useParticipantById(id);

  const dispatch = useDispatch();

  useEffect(() => {
    if (denied) {
      dispatch(setErrorDrawerVisible(true));
      dispatch(setErrorDrawerType('FULL_STAGE'));
    }
  }, [denied, dispatch]);

  if (!participant) return <></>;

  return (
    <Container denied={false}>
      <BaseVideoCard
        id={`dragging:${participant.id}`}
        displayName={participant.displayName}
        video={participant.video}
        audio={participant.audio}
        isMuted={true}
        isScreenShare={isScreenShare}
        isSelf={participant.isSelf}
        isMirrored={participant.isSelf && !participant.isScreenShare && !participant.isIngress}
        hideDisplayNames={hideDisplayNames}
        isStage={false}
        shouldDisplayTalkingStatus={false}
      />
    </Container>
  );
};

const Container = styled.div<{ denied: boolean }>`
  width: 147px;
  height: 140px;
  position: relative;
  background: #000;
  background-color: ${({ theme }) => theme.colors.accentPrimary};
  transition: all 220ms ease-in-out;
  box-sizing: border-box;
  user-select: none;
  border-radius: 10px;
  overflow: hidden;
  border: 3px solid transparent;
  border: ${(props) =>
    props.denied
      ? `1.5px solid ${props.theme.colors.alertError}`
      : `1.5px solid ${props.theme.colors.accentPrimary}`};
`;

export default DraggingVideoCard;
