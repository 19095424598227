import jwtDecode from 'jwt-decode';
import { actions, assign, createMachine, DoneInvokeEvent as Done } from 'xstate';

import { MaestroJwt } from 'interfaces/maestro-integration';
import SessionService from 'services/SessionService';
import {
  setMaestroJwt,
  setSessionId,
  setSessionInviteCode,
  setSessionIsHost,
  setSessionIsLive,
  setSessionSecretToken,
  setSessionPlaybackUrl,
  setSessionClientUrl,
} from 'store/slices/session';
import store from 'store';

import {
  IInitMaestroSessionContext as Context,
  TGetOrCreateOrchestrationSession,
} from './interfaces';
import { TMaestroJwt, TSecretToken } from 'interfaces/newType';
import SiteConfigService from 'services/SiteConfigService';
import { setUserId } from 'store/slices/user';

const { escalate } = actions;
const sessionService = new SessionService();
const siteConfigService = new SiteConfigService();

const getOrCreateSite = async (context: Context) => {
  const { siteConfig } = store.getState();
  const { maestroJwt, integrationService, channelId, clientUrl } = context;
  if (!integrationService) throw new Error('Integration service not defined');

  const decodedJwt: MaestroJwt = jwtDecode(maestroJwt);
  const siteId = decodedJwt?.siteId;

  const { site, created } = await integrationService!.getOrCreateSite(siteId, clientUrl);

  const { needHelpUrl, privacyPolicyUrl } = siteConfig.data;
  siteConfigService.updateSiteConfig(siteId, maestroJwt, { needHelpUrl, privacyPolicyUrl });

  console.debug(`${created ? 'Created' : 'Found'} site ${site.siteId}`);

  const channel = site.channels.find((c) => c.channelId === channelId);
  store.dispatch(setSessionSecretToken(channel?.session?.secretToken!));

  return {
    secretToken: channel?.session?.secretToken,
    sessionId: channel?.session?.sessionId,
    inviteCode: channel?.inviteCode,
  };
};

const getOrCreateMaestroUser = async (context: Context) => {
  const { integrationService } = context;
  const { user, created } = await integrationService!.getOrCreateUser();

  console.debug(`${created ? 'Created' : 'Found'} user ${user._id}`);

  store.dispatch(setUserId(user._id));

  return user;
};

const getOrCreateOrchestrationSession = async (context: Context) => {
  const { inviteCode } = context;
  const result = await sessionService.getOrCreateSession(context.sessionId, inviteCode);
  const { inviteCode: newInviteCode, secretToken, sessionId, streamPlaybackUrl, live } = result;
  store.dispatch(setSessionSecretToken(secretToken!));

  return {
    secretToken,
    inviteCode: newInviteCode || inviteCode,
    sessionId,
    streamPlaybackUrl,
    isLive: live,
  };
};

const getOrCreateMaestroSession = async (context: Context) => {
  const { sessionId, integrationService, maestroJwt, channelId, inviteCode, secretToken } = context;

  try {
    await integrationService!.getSession(sessionId, maestroJwt);
    console.debug(`Got session ${sessionId} from maestro-integration`);

    return { isNew: false };
  } catch (e) {
    console.debug('Creating session in maestro-integration');
    // If the sessions has already been removed, we create a new one
    await integrationService!.createSession(sessionId, secretToken, channelId, inviteCode);
    // Since session service session and maestro-integration session only exists at the same time this means that the session service session is also new
    return { isNew: true };
  }
};

const persistData = async (context: Context) => {
  const { secretToken, maestroJwt, sessionId, inviteCode, isLive, clientUrl, streamPlaybackUrl } =
    context;

  store.dispatch(setSessionSecretToken(secretToken!));
  store.dispatch(setSessionId(sessionId));
  store.dispatch(setSessionIsHost(true));
  store.dispatch(setSessionInviteCode(inviteCode));
  store.dispatch(setSessionClientUrl(clientUrl));
  store.dispatch(setMaestroJwt(maestroJwt));
  store.dispatch(setSessionIsLive(isLive));
  store.dispatch(setSessionPlaybackUrl(streamPlaybackUrl));
};

// https://stately.ai/viz/606764bb-4d55-4220-902a-c4cabf1280b9
export const initMaestroSessionMachine = createMachine<Context>(
  {
    id: 'initMaestroSessionMachine',
    initial: 'GET_CREATE_SITE',
    context: {
      sessionId: '',
      maestroJwt: '' as TMaestroJwt,
      clientUrl: '',
      secretToken: '' as TSecretToken,
      inviteCode: '',
      streamPlaybackUrl: '',
      isLive: false,
      integrationService: null,
      maestroUser: null,
      channelId: '',
    },
    states: {
      GET_CREATE_SITE: {
        invoke: {
          id: 'getOrCreateSite',
          src: 'getOrCreateSite',
          onDone: {
            target: 'GET_CREATE_MAESTRO_USER',
            actions: assign({
              inviteCode: (_, event) => event.data.inviteCode,
              secretToken: (_, event) => event.data.secretToken,
              sessionId: (_, event) => event.data.sessionId,
            }),
          },
          onError: {
            target: 'ERROR',
          },
        },
      },
      GET_CREATE_MAESTRO_USER: {
        invoke: {
          id: 'getOrCreateMaestroUser',
          src: 'getOrCreateMaestroUser',
          onDone: {
            target: 'GET_CREATE_ORCHESTRATION_SESSION',
            actions: assign({
              maestroUser: (_, event) => event.data,
            }),
          },
          onError: {
            target: 'ERROR',
          },
        },
      },
      GET_CREATE_ORCHESTRATION_SESSION: {
        invoke: {
          id: 'getOrCreateOrchestrationSession',
          src: 'getOrCreateOrchestrationSession',
          onDone: {
            target: 'GET_CREATE_MAESTRO_SESSION',
            actions: assign<Context, Done<TGetOrCreateOrchestrationSession>>({
              secretToken: (ctx, evt) => evt.data.secretToken ?? ctx.secretToken,
              inviteCode: (_, evt) => evt.data.inviteCode,
              streamPlaybackUrl: (ctx, evt) => evt.data.streamPlaybackUrl ?? ctx.streamPlaybackUrl,
              sessionId: (_, evt) => evt.data.sessionId,
              isLive: (_, evt) => evt.data.isLive,
            }),
          },
          onError: {
            target: 'ERROR',
          },
        },
      },
      GET_CREATE_MAESTRO_SESSION: {
        invoke: {
          id: 'getOrCreateMaestroSession',
          src: 'getOrCreateMaestroSession',
          onDone: [
            {
              target: 'PERSIST_DATA',
            },
          ],
          onError: {
            target: 'ERROR',
          },
        },
      },
      PERSIST_DATA: {
        invoke: {
          id: 'persistData',
          src: 'persistData',
          onDone: {
            target: 'SUCCESS',
          },
          onError: {
            target: 'ERROR',
          },
        },
      },
      ERROR: {
        type: 'final',
        entry: escalate((_, event) => event.data),
      },
      SUCCESS: {
        type: 'final',
        data: {
          sessionId: (ctx: Context) => ctx.sessionId,
          secretToken: (ctx: Context) => ctx.secretToken,
          maestroUser: (ctx: Context) => ctx.maestroUser,
        },
      },
    },
  },
  {
    services: {
      getOrCreateSite,
      getOrCreateMaestroUser,
      getOrCreateOrchestrationSession,
      getOrCreateMaestroSession,
      persistData,
    },
  }
);
