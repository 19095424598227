import React from 'react';
import styled, { keyframes, useTheme } from 'styled-components';

const DOT_SIZE = 6;

interface Props {
  dotSize: number;
  loading?: boolean;
  color?: string;
}

const LoadingEllipsis: React.FC<Props> = ({ loading, dotSize, color }) => {
  const { colors } = useTheme();
  const dotColor = color || colors.accentPrimary;

  return loading ? (
    <Ellipsis dotSize={dotSize}>
      <Dot color={dotColor} />
      <Dot color={dotColor} />
      <Dot color={dotColor} />
    </Ellipsis>
  ) : null;
};

const fade = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const Ellipsis = styled.div<{ dotSize?: number }>`
  display: flex;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  ${({ dotSize = DOT_SIZE }) => `
    & > div {
      width: ${dotSize}px;
      height: ${dotSize}px;
    }
  `}
`;

const Dot = styled.div<{ dotSize?: number; color?: string }>`
  background: ${(props) => props.color || props.theme.colors.accentPrimary};
  width: ${DOT_SIZE}px;
  min-width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  min-height: ${DOT_SIZE}px;
  border-radius: 50%;
  animation: ${fade} 0.75s infinite alternate;
  margin-left: 5px;
  &:nth-child(2) {
    animation-delay: 0.25s;
  }
  &:nth-child(3) {
    animation-delay: 0.5s;
  }
`;

export default LoadingEllipsis;
