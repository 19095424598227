import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import styled, { css, useTheme } from 'styled-components';

import { getIsAskingScreenShare, getSessionId, getSessionIsHost } from 'store/slices/session';
import { getPeerScreenShareId } from 'store/slices/ui';
import ControlButton from 'views/shared/ControlButton';
import { ScreenShare, StopScreenShare } from 'components/shared/Icons';
import { useRoomContext } from '@livekit/components-react';
import { getParticipantById } from 'store/slices/participants';
import { RootState } from 'store/slices';
import { useServices } from 'hooks/useServices';
import { useReactiveRoom } from 'contexts/LiveKitContext';

const ShareScreenButtonStyle = css`
  background-color: ${({ theme }) => theme.colors.alertConfirmation};
  border: none;

  :hover {
    background-color: ${({ theme }) => theme.colors.alertError};
    border: 2px solid ${({ theme }) => theme.colors.alertError};
  }
`;

const ssWaitingPermission = css`
  background-color: ${({ theme }) => theme.colors.alertWarning};
  border: none;
`;

const StageControlsShareButton = () => {
  const { colors } = useTheme();

  const isHost = useSelector(getSessionIsHost);
  const { localParticipant } = useRoomContext();
  const { metadata, localParticipantId } = useReactiveRoom();
  const sessionId = useSelector(getSessionId);
  const participant = useSelector((state: RootState) =>
    getParticipantById(state, localParticipantId)
  );
  const isAskingScreenShare = useSelector(getIsAskingScreenShare);
  const isScreenSharing = Boolean(
    useSelector((state: RootState) => getParticipantById(state, `${localParticipantId}-sc`))
  );
  const peerScreenShareId = useSelector(getPeerScreenShareId);

  const isOnStage = participant?.isOnStage;

  const isDisabled = !isOnStage || (!isScreenSharing && !!peerScreenShareId);

  const { sessionService } = useServices();

  const handleScreenShare = async () => {
    if (peerScreenShareId && !isScreenSharing) {
      return;
    }

    const newMetadata = { ...metadata };

    if (isScreenSharing) {
      if (metadata.peerSoloViewId === `${localParticipantId}-sc`) {
        newMetadata.peerSoloViewId = undefined;
        sessionService.updateRoomMetadata(sessionId, newMetadata);
      }
      await localParticipant.setScreenShareEnabled(false);
      return;
    }

    if (isHost) {
      await localParticipant.setScreenShareEnabled(true, { audio: true });
      return;
    }

    newMetadata.screenShareRequests = newMetadata.screenShareRequests ?? {};

    //ask for permission
    if (isAskingScreenShare) {
      for (const screenShareRequestId in newMetadata.screenShareRequests) {
        if (
          newMetadata.screenShareRequests[screenShareRequestId]?.participantId ===
          localParticipantId
        )
          delete newMetadata.screenShareRequests[screenShareRequestId];
      }
    } else {
      newMetadata.screenShareRequests[Date.now()] = {
        participantId: localParticipantId,
      };
    }

    sessionService.updateRoomMetadata(sessionId, newMetadata);
  };

  const getTooltipLabel = () => {
    if (isScreenSharing) return 'Stop Sharing';
    if (peerScreenShareId) return 'Someone is already sharing';

    if (isHost) return 'Share Screen';
    else {
      if (!isOnStage) return 'Screen share available on Stage';
      if (isAskingScreenShare) return 'Stop asking for ScreenShare';
      else return 'Ask for ScreenShare';
    }
  };

  if (isMobile) return null;

  return (
    <ControlButton
      text={getTooltipLabel()}
      onClick={handleScreenShare}
      style={isAskingScreenShare ? ssWaitingPermission : isScreenSharing && ShareScreenButtonStyle}
      disabled={isDisabled}
      data-testid='shareScreenButton'
    >
      <ScreenShareIconContainer active={isScreenSharing}>
        <ScreenShare width={22} height={22} fill={isDisabled ? colors.text400 : colors.text100} />
        <StopScreenShare width={22} height={22} />
      </ScreenShareIconContainer>
    </ControlButton>
  );
};

const ScreenShareIconContainer = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  > svg:first-child {
    display: block;
  }

  > svg:last-child {
    display: none;
  }

  ${(props) =>
    props.active &&
    css`
      :hover {
        > svg:first-child {
          display: none;
        }

        > svg:last-child {
          display: block;
        }
      }
    `}
`;

export default StageControlsShareButton;
