import { FC, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

export const getTheme = (): Theme.ThemeObject => {
  const searchParams = new URLSearchParams(window.location.search);

  if (!searchParams.has('theme')) {
    return defaultTheme;
  }

  try {
    return JSON.parse(searchParams.get('theme')!);
  } catch (error) {
    console.warn(`Couldn't parse theme from params. Falling back to default`, error);
    return defaultTheme;
  }
};

export const ThemeContextProvider: FC = ({ children }) => {
  const theme = useMemo(getTheme, []);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const defaultTheme: Theme.ThemeObject = {
  button: {
    borderRadius: 'rounded',
    textCapitalization: 'uppercase',
  },
  name: 'Studio Default Theme',
  colors: {
    accentPrimary: '#FC223B',
    accentSecondary: '#0A84FF',
    accentTertiary: '#F3F7FF',
    alertConfirmation: '#43D48F',
    alertError: '#FF0000',
    alertWarning: '#F4D73D',
    highlightPrimary: '#F3F7FF',
    highlightSecondary: '#9DA0A6',
    highlightTertiary: '#0C0D0D',
    surface1: '#080A0C',
    surface2: '#12141A',
    surface3: '#1B1E26',
    surface4: '#242833',
    surface5: '#363C4D',
    surface6: '#3E4659',
    text100: '#F3F7FF',
    text200: '#C2C5CC',
    text300: '#919499',
    text400: '#616266',
    text500: '#0C0D0D',
  },
};
