import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/slices/ui';
import { CloseButton, TitleContainer, TopRow } from 'components/Modals/Settings/styles';
import { Text } from 'components/shared';
import { Close } from 'components/shared/Icons';
import { useTheme } from 'styled-components';

type Props = {
  hideClose: boolean;
};

export const Header: React.FC<Props> = ({ hideClose }) => {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeModal());
  const { colors } = useTheme();

  return (
    <TopRow>
      <TitleContainer>
        <Text weight={500} size={14} color={colors.text200}>
          Audio/Video Settings
        </Text>
      </TitleContainer>
      {!hideClose && (
        <CloseButton onClick={handleClose}>
          <Close width={7.5} height={7.5} fill={colors.text100} />
        </CloseButton>
      )}
    </TopRow>
  );
};
