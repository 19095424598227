export interface DestinationsStore {
  destinations: GroupedDestinations;
}

export type Destination =
  | MaestroDestination
  | FacebookDestination
  | YouTubeDestination
  | TwitchDestination;

export enum StatusType {
  setup = 'setup',
  connecting = 'connecting',
  authorizing = 'authorizing',
  connection_successful = 'connection_successful',
  ready = 'ready',
  initializing = 'initializing',
  live = 'live',
  disconnected = 'disconnected',
  unable_to_stream = 'unable_to_stream',
  lost_connection = 'lost_connection',
  authorization_unsuccessful = 'authorization_unsuccessful',
}

// NOTE: since every platform has different form fields
interface MaestroDestination extends DestinationBase {}

interface FacebookDestination extends DestinationBase {}

interface YouTubeDestination extends DestinationBase {}

interface TwitchDestination extends DestinationBase {}

interface DestinationBase {
  id: string;
  provider: TProvider;
  account: string;
  imgUrl: string;
  status: StatusType;
  accessToken: string;
  refreshToken: string; // The refresh is encrypted
  expiresAt: number;
  metadata: FormValues;
  data?: any; //NOTE: putting any data that is specific for a single platform here
  connected: boolean;
  rtmp: {
    rtmpUrl: string;
    streamKey: string;
  };
}

export interface IServiceDestination {
  id: string;
  provider: string;
  account: string;
  accessToken: string;
  expiresAt: number;
  refreshToken: string;
  connected: boolean;
  metadata: YouTubeFormValues | TwitchFormValues | FacebookFormValues; // or Record<string, unknown>
}

// TODO: fix these type overlaps
export interface DestinationDto {
  id: string;
  provider: string;
  account: string;
  accessToken: string;
  expiresAt: number;
  refreshToken: string;
  connected: boolean;
  metadata: YouTubeFormValues | TwitchFormValues | FacebookFormValues; // or Record<string, unknown>
}

export interface YouTubeFormValues {
  title: string;
  description?: string;
  category?: string;
  visibility?: string;
}

export interface TwitchFormValues {
  title: string;
  description?: string;
  category?: string;
}

export interface FacebookFormValues {
  title: string;
  description?: string;
  streamTo?: string;
}

export type FormValues = YouTubeFormValues | TwitchFormValues | FacebookFormValues;
export interface SetDestinationStatusPayload {
  destination: Destination;
  status: StatusType;
}

export enum PROVIDERS {
  maestro = 'maestro',
  facebook = 'facebook',
  youtube = 'youtube',
  twitch = 'twitch',
}
export type TProvider = keyof typeof PROVIDERS;

export interface GroupedDestinations {
  [PROVIDERS.maestro]: Destination[];
  [PROVIDERS.facebook]: Destination[];
  [PROVIDERS.youtube]: Destination[];
  [PROVIDERS.twitch]: Destination[];
}

enum ComponentsToRender {
  connectButton,
  rightMessage,
  alert,
  ellipsis,
  accountName,
  connectionStatus,
  cog,
  switch,
  body,
}

export type IComponents = keyof typeof ComponentsToRender;

export enum DESTINATION_ERROR {
  UNHANDLED_ERROR,
  YOUTUBE_24H_WAIT,
  YOUTUBE_NO_CHANNEL_LINKED,
  YOUTUBE_LIVESTREAMING_NOT_ENABLED,
  MAX_3_CONNECTIONS,
  UNABLE_TO_STREAM,
  LOST_CONNECTION,
  ALREADY_STREAMING_DESTINATION,
}

export type DestinationErrorType = keyof typeof DESTINATION_ERROR;

export interface FacebookStreamToData {
  facebookProfile: { id: string; name: string };
  facebookPages: { id: string; name: string }[];
}

enum CommanderVerbs {
  start,
  stop,
}

export type CommanderVerbsType = keyof typeof CommanderVerbs;
