export const openPopup = (url: string, title: string, features?: string) => {
  if (features) return window.open(url, title, features);
  // NOTE: was going to use my own solution but found this one with much more checks on stack overflow
  // https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen

  const w = 600;
  const h = window.innerHeight * 0.8;

  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const left = (width - w) / 2 + dualScreenLeft;
  const top = (height - h) / 2 + dualScreenTop;
  const defaultFeatures = `resizable,scrollbars,status,width=${w},height=${h},top=${top},left=${left}`;

  const openedWindow = window.open(url, title, defaultFeatures);
  if (openedWindow) openedWindow?.focus();

  return openedWindow;
};
