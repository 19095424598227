import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { Exclamation, HideBackstage, ShowBackstage } from 'components/shared/Icons';
import { getIsBackstageVisible, setIsBackstageVisible } from 'store/slices/ui';
import ControlButton from '../ControlButton';
import { getParticipants } from 'store/slices/participants';
import { TParticipant } from 'interfaces/participants';

type State = {
  seenMap: { [key: string]: boolean };
  participantsSize: number;
};

type Action =
  | {
      type: 'UPDATE_PARTICIPANTS';
      payload: TParticipant[];
    }
  | { type: 'UPDATE_MAP'; payload: State['seenMap'] };

function reducer(state: State, action: Action) {
  const { participantsSize, seenMap } = state;
  switch (action.type) {
    case 'UPDATE_PARTICIPANTS':
      const participants = action.payload;
      // size didn't changed
      if (participantsSize === participants.length) return state;

      // add new participants to the map
      const map: State['seenMap'] = { ...seenMap };

      // add new participants to the map
      for (let i = 0; i < participants.length; i++) {
        if (participants[i].isSelf) continue;
        const id = participants[i].id;
        map[id] = seenMap[id] ?? false;
      }

      Object.keys(map).forEach((key) => {
        const found = participants.find((e) => e.id === key);
        if (!found) delete map[key];
      });

      return { ...state, participantsSize: participants.length, seenMap: map };
    case 'UPDATE_MAP':
      return { ...state, seenMap: action.payload };
    default:
      return state;
  }
}

const BackstageButton = () => {
  const dispatch = useDispatch();
  const participants = useSelector(getParticipants);
  const isBackstageVisible = useSelector(getIsBackstageVisible);
  const [{ seenMap }, localDispatch] = useReducer(reducer, {
    participantsSize: participants.length,
    seenMap: {},
  });

  useEffect(() => {
    localDispatch({ type: 'UPDATE_PARTICIPANTS', payload: participants });
  }, [participants]);

  const toggleBackstage = () => {
    if (isBackstageVisible) {
      // mark all videos (including new ones) as seen
      const map = { ...seenMap };
      Object.keys(map).forEach((key) => (map[key] = true));
      localDispatch({ type: 'UPDATE_MAP', payload: map });
    }

    dispatch(setIsBackstageVisible(!isBackstageVisible));
  };

  const hasUnseenGuests = Object.values(seenMap).some((e) => !e);

  return (
    <ControlButton
      text={isBackstageVisible ? 'Hide Backstage' : 'Show Backstage'}
      onClick={toggleBackstage}
      data-testid='hideBackstageButton'
    >
      {isBackstageVisible ? (
        <HideBackstage width={22} height={22} fill='transparent' />
      ) : (
        <>
          {hasUnseenGuests && (
            <NewGuestAlertWrapper>
              <Exclamation width={18} height={18} />
            </NewGuestAlertWrapper>
          )}
          <ShowBackstage width={22} height={22} fill='transparent' />
        </>
      )}
    </ControlButton>
  );
};

const NewGuestAlertWrapper = styled.div`
  position: absolute;
  top: -4.5px;
  right: -4.5px;
`;

export default BackstageButton;
