import React from 'react';
import { useTheme } from 'styled-components';
import { Text } from 'components/shared';
import { isMobile } from 'react-device-detect';
import BackStageSection, {
  DesktopBackStageMessageContainer,
  MobileBackStageMessageContainer,
} from 'views/Guest/BackStageSection';

const MobileLiveIndicator: React.FC = () => {
  const { colors } = useTheme();

  return (
    <BackStageSection>
      <MobileBackStageMessageContainer>
        <Text size={20} weight={700} color={colors.text500} textAlign='center'>
          You’re live!
        </Text>
        <Text size={12} weight={400} color={colors.text500} textAlign='center'>
          The audience can see and hear you now.
        </Text>
      </MobileBackStageMessageContainer>
    </BackStageSection>
  );
};

const DesktopLiveIndicator: React.FC = () => {
  const { colors } = useTheme();

  return (
    <BackStageSection>
      <DesktopBackStageMessageContainer>
        <Text size={30} weight={700} color={colors.text500} textAlign='center'>
          You’re live!
        </Text>
        <Text
          size={14}
          weight={400}
          color={colors.text500}
          textAlign='center'
          style={{ maxWidth: 400 }}
        >
          The audience can see and hear you now.
        </Text>
      </DesktopBackStageMessageContainer>
    </BackStageSection>
  );
};

const LiveIndicator = () => {
  if (isMobile) return <MobileLiveIndicator />;
  return <DesktopLiveIndicator />;
};
export default LiveIndicator;
