import React, { FC, ReactChild } from 'react';
import styled, { CSSProperties } from 'styled-components/macro';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { DataTest } from 'interfaces/dataTestId';

interface Props extends DataTest {
  children: ReactChild;
  id: string;
  area: string;
  dragEnabled: boolean;
  style?: CSSProperties;
}

const DraggableItem: FC<Props> = ({
  children,
  id,
  area,
  dragEnabled,
  style,
  'data-testid': dataTestId,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    data: {
      area,
    },
    transition: {
      duration: 220, // expressed in ms
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  const finalStyle = {
    ...style,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Container
      dragEnabled={dragEnabled}
      id={id}
      ref={setNodeRef}
      style={finalStyle}
      data-testid={dataTestId}
      {...attributes}
      {...listeners}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<{ dragEnabled: boolean }>`
  user-select: none;
  cursor: grab;
  pointer-events: ${(props) => (props.dragEnabled ? 'auto' : 'none')};
`;

export default DraggableItem;
