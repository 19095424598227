import React, { useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { Text, Button } from 'components/shared';
import { CheckMark, ShareLink } from 'components/shared/Icons';
import { getSessionClientUrl } from 'store/slices/session';
import { useSelector } from 'react-redux';

const ShareLinkPanel: React.FC = () => {
  const [showCopiedButton, setShowCopiedButton] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const clientUrl = useSelector(getSessionClientUrl);
  const { colors } = useTheme();

  const handleCopyLink = async () => {
    try {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      await navigator.clipboard.writeText(clientUrl || window.location.href);
      setShowCopiedButton(true);
      timeoutRef.current = setTimeout(() => setShowCopiedButton(false), 3000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Text size={20} weight={600}>
        Share
      </Text>
      <Text size={16} weight={400} textAlign='center' style={{ paddingTop: '20px' }}>
        Share the stream url below with your fans.
      </Text>

      <InviteLinkContainer>
        <ShareLink width={15} height={8} fill={colors.text400} />
        <Text size={14} weight={500}>
          {clientUrl || window.location.href}
        </Text>
      </InviteLinkContainer>
      <Button
        color={showCopiedButton ? colors.alertConfirmation : colors.highlightPrimary}
        fullWidth
        style={{ display: 'flex', gap: 13, padding: 0 }}
        onClick={handleCopyLink}
      >
        {showCopiedButton && <CheckMark fill={colors.text100} width={16} height={16} />}
        <Text size={14} weight={700} style={{ textTransform: 'uppercase' }} textAlign='initial'>
          {showCopiedButton ? 'Link Copied To Clipboard' : 'Copy Stream URL'}
        </Text>
      </Button>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 440px;
  background-color: ${({ theme }) => theme.colors.surface2};
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ::after {
    content: '';
    position: absolute;
    border-width: 16px;
    border-style: solid;
    top: 100%;
    left: 50%;
    margin-left: -16px;
    border-color: ${({ theme }) => theme.colors.surface2} transparent transparent transparent;
  }
`;

const InviteLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 13px 0;
  padding: 0 15px;
  height: 39px;
  border-radius: 5px;
  gap: 30px;
  border: 0.5px solid ${({ theme }) => theme.colors.surface5};
  margin-bottom: 10px;
`;

export default ShareLinkPanel;
