import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { RootState } from 'store/slices';
import { AppDispatch, AppThunk } from 'store';
import {
  DestinationsStore,
  Destination,
  TProvider,
  StatusType,
  SetDestinationStatusPayload,
  GroupedDestinations,
  PROVIDERS,
} from 'interfaces/destinations';

// Initial state
const initialState: DestinationsStore = {
  destinations: {
    [PROVIDERS.maestro]: [
      //TODO: just for testing
      {
        provider: PROVIDERS.maestro,
        id: uuid(),
        status: StatusType.ready,
        accessToken: '',
        expiresAt: 0,
        refreshToken: '',
        account: '',
        imgUrl: '',
        metadata: {
          title: '',
        },
        connected: true,
        rtmp: {
          rtmpUrl: '',
          streamKey: '',
        },
      },
    ],
    [PROVIDERS.facebook]: [],
    [PROVIDERS.youtube]: [],
    [PROVIDERS.twitch]: [],
  },
};

// Initializing slice
const destinationsSlice = createSlice({
  name: 'destinations',
  initialState,
  reducers: {
    addDestination(state, action: PayloadAction<Destination>) {
      const { provider } = action.payload;
      state.destinations[provider] = [...state.destinations[provider], action.payload];
    },
    removeDestination(state, action: PayloadAction<Destination>) {
      const { provider, id } = action.payload;
      state.destinations[provider] = state.destinations[provider].filter(
        (destination: Destination) => destination.id !== id
      );
    },
    setDestinationStatus(state, action: PayloadAction<SetDestinationStatusPayload>) {
      const { provider, id } = action.payload.destination;
      const { status } = action.payload;
      state.destinations[provider] = state.destinations[provider].map((destination) => {
        return destination.id === id ? { ...destination, status } : destination;
      });
    },
    updateDestination(state, action: PayloadAction<Destination>) {
      const { provider, id } = action.payload;
      state.destinations[provider] = state.destinations[provider].map((destination) => {
        return destination.id === id ? action.payload : destination;
      });
    },
    setAllDestinations(state, action: PayloadAction<GroupedDestinations>) {
      state.destinations = action.payload;
    },
  },
});

// Selectors
const selectState = (state: RootState) => state.destinations;

export const useDestinationsStore = (): DestinationsStore => {
  return {
    destinations: useSelector(getDestinations),
  };
};

export const getDestinations = createSelector(
  selectState,
  (state: DestinationsStore) => state.destinations
);

// Actions
const { actions } = destinationsSlice;

export const addDestination = (destination: Destination): AppThunk => {
  return async (dispatch: AppDispatch) => {
    if (destination.provider === PROVIDERS.maestro)
      console.log("Can't add another maestro destination"); // NOTE: handle error?
    dispatch(actions.addDestination(destination));
  };
};

export const removeDestination = (provider: TProvider, id: string): AppThunk => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      destinations: { destinations },
    } = getState();
    const destination = destinations[provider].find((e) => e.id === id);
    if (destination) {
      dispatch(actions.removeDestination(destination));
    } else {
      console.error('Destination not found'); // NOTE: handle error?
    }
  };
};

export const setDestinationStatus = (
  provider: TProvider,
  account: string,
  status: StatusType
): AppThunk => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      destinations: { destinations },
    } = getState();
    const destination = destinations[provider].find((e) => e.account === account);
    if (destination) {
      dispatch(actions.setDestinationStatus({ destination, status }));
    } else {
      console.error('Destination not found'); // NOTE: handle error?
    }
  };
};

export const updateDestination = (destination: Destination, upsert = false): AppThunk => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      destinations: { destinations },
    } = getState();
    const { provider, account } = destination;
    const found = destinations[provider].find((e) => e.account === account);
    if (found) {
      dispatch(actions.updateDestination(destination));
    } else if (upsert) {
      dispatch(actions.addDestination(destination));
    } else {
      console.error('Destination not found'); // NOTE: handle error?
    }
  };
};

export const setAllDestinations = (groupedDest: GroupedDestinations): AppThunk => {
  //NOTE: should be Destination[], but some props are different
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      destinations: { destinations },
    } = getState();

    if (!groupedDest?.maestro.length) {
      groupedDest.maestro = [{ ...destinations.maestro[0] }];
    }
    dispatch(actions.setAllDestinations(groupedDest));
  };
};

export default destinationsSlice.reducer;
