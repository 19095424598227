import styled, { useTheme } from 'styled-components/macro';

import SkeletonDiv from 'components/skeletons/SkeletonDiv';
import { Text } from 'components/shared';

const BackstageSkeleton = () => {
  const { colors } = useTheme();

  return (
    <Container>
      <Header>
        <Text size={16} color={colors.text300} weight={700}>
          Backstage
        </Text>
      </Header>
      <PanelContainer>
        <PanelHeader>
          <Text size={16} weight={400} color={colors.text200}>
            Waiting Room
          </Text>
        </PanelHeader>
        <VideosContainer>
          <SkeletonDiv height={'46px'} />
          <SkeletonDiv height={'46px'} />
          <SkeletonDiv height={'140px'} />
        </VideosContainer>
      </PanelContainer>
      <PanelContainer>
        <PanelHeader>
          <Text size={16} weight={400} color={colors.text200}>
            Destinations
          </Text>
        </PanelHeader>
        <DestinationsContainer>
          <SkeletonDiv shape='circular' height={'42px'} width={'42px'} />
          <SkeletonDiv shape='circular' height={'42px'} width={'42px'} />
          <SkeletonDiv shape='circular' height={'42px'} width={'42px'} />
        </DestinationsContainer>
      </PanelContainer>
    </Container>
  );
};

const Container = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.surface6}`};
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.surface2};
  width: 345px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  box-sizing: border-box;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  height: 22px;
`;

const PanelHeader = styled.div`
  display: flex;
  align-items: center;
  height: 51px;
`;

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: ${({ theme }) => theme.colors.surface4};
  padding: 0px 10px;
  border-radius: 6px;
`;

const VideosContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(321px - 51px);
  gap: 10px;
`;

const DestinationsContainer = styled.div`
  display: flex;
  gap: 10px;
  height: calc(103px - 51px);
`;

export default BackstageSkeleton;
