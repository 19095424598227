// import './wdyr'; // <--- first import
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import store from 'store';
import App from './App';
import './config';
import './index.css';
import { ServicesContextProvider } from 'hooks/useServices';
import Modal from 'components/Modals/ModalV2';
import { VideoCardResizeProvider } from 'components/VideoCard/VideoCardResizeProvider';
import { ThemeContextProvider } from 'contexts/ThemeContextProvider';
import { setupErrorTracking } from 'services/ErrorTracking';
import { LiveKitContextProvider } from 'contexts/LiveKitContext';
import { MediaSourcesContextProvider } from 'contexts/MediaSourcesContext';

setupErrorTracking();

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <VideoCardResizeProvider>
        <ServicesContextProvider>
          <ThemeContextProvider>
            <MediaSourcesContextProvider>
              <LiveKitContextProvider>
                <Modal />
                <App />
              </LiveKitContextProvider>
            </MediaSourcesContextProvider>
          </ThemeContextProvider>
        </ServicesContextProvider>
      </VideoCardResizeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

console.log('Studio app initialized 🚀');
