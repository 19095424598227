import { assign, createMachine } from 'xstate';

import SessionService from 'services/SessionService';
import {
  getSessionMediaConstraints,
  setMaestroChannelId,
  setSessionDisplayName,
  setSessionId,
  setSessionIsHost,
  setSessionMediaConstraints,
  setSessionOutputDeviceId,
} from 'store/slices/session';
import { postMessageBroadcastStopped } from 'store/core/maestro-messages';
import store from 'store';
import { initMaestroSessionMachine } from 'stateMachines/initMaestroSessionMachine';
import { transcoderInitMachine } from 'stateMachines/transcoderInitMachine';
import { initDestinationsMachine } from 'stateMachines/initDestinationsMachine';
import { ILoginGateMachineContext as Context } from './interfaces';
import { TMaestroJwt, TSecretToken } from 'interfaces/newType';
import { openModal } from 'store/slices/ui';
import { IMediaSourcesService } from 'services/MediaSourcesService';
import { setAllConfig } from 'store/slices/siteConfig';
import SiteConfigService from 'services/SiteConfigService';
import { getOptionalQueryParams } from 'utils/methods';
import { IMaestroData } from 'interfaces/maestro-integration';
import { LOCAL_STORAGE_KEYS } from 'constants/storage';
import { MediaConstraints } from 'interfaces/session';
import { getLocalStorage, removeFromLocalStorage, saveLocalStorage } from 'utils/storage';
import { v4 as uuidV4 } from 'uuid';
import { setUserId } from 'store/slices/user';

const LOGIN_GATE_MACHINE_ID = 'loginGateMachine';

const sessionService = new SessionService();
const siteConfigService = new SiteConfigService();

export const ALLOWED_ACCESS_STATES = ['INIT_TRANSCODER', 'FINISHED'];

export const LOADING_STATES = [
  'OPEN_SETTINGS_MODAL',
  'INIT_SESSION_MAESTRO',
  'INIT_SESSION_GUEST',
  'INIT_DESTINATIONS',
];

export const loginGateMachineInitialState: Context = {
  isStandAlone: false,
  sessionId: '',
  maestroJwt: '' as TMaestroJwt,
  clientUrl: '',
  siteId: '',
  accessCode: '',
  error: '',
  secretToken: '' as TSecretToken,
  integrationService: null,
  channelId: '',
  inviteCode: '',
  maestroUser: null,
  mediaSourcesService: undefined as any as IMediaSourcesService,
};

const setInitialState = async (ctx: Context) => {
  const { clientUrl, maestroJwt, channelId, inviteCode, siteId, userName: nameFromParams } = ctx;
  const { siteConfig } = store.getState();

  const isGuest = Boolean(ctx.inviteCode);
  const isHost = !isGuest || ctx.isStandAlone;
  const { streamKey, ingestUrl, maestroData } = getOptionalQueryParams();

  const requiredProps = isHost
    ? { maestroJwt, clientUrl, siteId, channelId, streamKey, ingestUrl }
    : { inviteCode, clientUrl, siteId };

  const missingProps = findMissingProps(requiredProps);

  if (missingProps.length) throw new Error(`Missing required query params: ${missingProps}`);

  const { source, data } = await siteConfigService.getSiteConfigBySiteId(siteId, clientUrl);
  const parsedData = maestroData ? (JSON.parse(maestroData) as IMaestroData) : null;

  if ((source && data) || parsedData) {
    const payload = {
      source: source ?? siteConfig.source,
      data: {
        ...siteConfig.data,
        ...data,
        privacyPolicyUrl:
          parsedData?.privacyUrl || data?.privacyPolicyUrl || siteConfig.data.privacyPolicyUrl,
        needHelpUrl: parsedData?.supportUrl || data?.needHelpUrl || siteConfig.data.needHelpUrl,
      },
    };
    store.dispatch(setAllConfig(payload));
  }

  const storedName = getLocalStorage<string>(LOCAL_STORAGE_KEYS.DISPLAY_NAME);
  const outputDeviceId = getLocalStorage<string>(LOCAL_STORAGE_KEYS.AUDIO_OUTPUT);
  const constraints = getLocalStorage<MediaConstraints>(LOCAL_STORAGE_KEYS.MEDIA_CONSTRAINTS);

  const userName = storedName ?? nameFromParams;

  store.dispatch(setSessionIsHost(isHost));
  store.dispatch(setMaestroChannelId(channelId));
  userName && store.dispatch(setSessionDisplayName(userName));
  outputDeviceId && store.dispatch(setSessionOutputDeviceId(outputDeviceId));
  constraints && store.dispatch(setSessionMediaConstraints(constraints));
  return { isHost };
};

const openSettingsModal = async (context: Context) => {
  const { mediaSourcesService } = context;

  let shouldOpenSettings = false;
  const constraints = getSessionMediaConstraints(store.getState());

  try {
    await mediaSourcesService.getCameraMicStream(constraints);
  } catch (error) {
    shouldOpenSettings = true;
    removeFromLocalStorage(LOCAL_STORAGE_KEYS.MEDIA_CONSTRAINTS);
  }

  if (shouldOpenSettings) {
    /* An invoked service transitions the state when its promise resolves, so we wrap the open modal in a promise and use resolve to finish the invoked service */
    return new Promise((resolve) => {
      store.dispatch(
        openModal({
          id: 'AUDIO_CAM_SETTINGS',
          component: 'AUDIO_CAM_SETTINGS',
          locked: true,
          props: { type: 'init', hideClose: true, handleNext: resolve },
        })
      );
    });
  }
};

const initSessionFromInviteCode = async (context: Context) => {
  const { inviteCode } = context;
  try {
    const { sessionId, inviteToken } = await sessionService.getSessionByInviteCode(inviteCode);
    store.dispatch(setSessionId(sessionId));

    // anonymous auth
    const userId =
      getLocalStorage<string>(LOCAL_STORAGE_KEYS.ANONYMOUS_AUTH_STORAGE_KEY) || uuidV4();
    saveLocalStorage(LOCAL_STORAGE_KEYS.ANONYMOUS_AUTH_STORAGE_KEY, userId);

    store.dispatch(setUserId(userId));

    return { sessionId, inviteToken };
  } catch (error: any) {
    if (error.statusCode === 404) {
      // redirect from broken guest view
      postMessageBroadcastStopped();
    }
    throw new Error(error.message);
  }
};

//TODO: handle error correctly
const handleMachineError = async (ctx: Context) => {
  console.error(ctx.error);
};

const findMissingProps = (data: Record<string, any>) => {
  const missing: string[] = [];
  for (const [key, value] of Object.entries(data)) {
    if (!value) {
      missing.push(key);
    }
  }
  return missing;
};

export const loginGateMachine = createMachine<Context>(
  {
    id: LOGIN_GATE_MACHINE_ID,
    initial: 'SET_INITIAL_STATE',
    context: loginGateMachineInitialState,
    states: {
      SET_INITIAL_STATE: {
        invoke: {
          id: 'setInitialState',
          src: 'setInitialState',
          onDone: {
            target: 'OPEN_SETTINGS_MODAL',
            actions: assign({
              isHost: (_, event) => {
                return event.data.isHost;
              },
            }),
          },
          onError: {
            target: 'ERROR',
            actions: ['assignError'],
          },
        },
      },
      OPEN_SETTINGS_MODAL: {
        invoke: {
          src: 'openSettingsModal',
          onDone: [
            { target: 'INIT_SESSION_GUEST', cond: (ctx) => Boolean(ctx.inviteCode) },
            { target: 'INIT_SESSION_MAESTRO' },
          ],
        },
      },
      INIT_SESSION_MAESTRO: {
        invoke: {
          id: 'initMaestroSessionMachine',
          src: 'initMaestroSessionMachine',
          data: {
            sessionId: (ctx: Context) => ctx.sessionId,
            maestroJwt: (ctx: Context) => ctx.maestroJwt,
            channelId: (ctx: Context) => ctx.channelId,
            clientUrl: (ctx: Context) => ctx.clientUrl,
            integrationService: (ctx: Context) => ctx.integrationService,
          },
          onDone: {
            target: 'INIT_VENDOR',
            actions: assign({
              sessionId: (_, event) => event.data.sessionId,
              secretToken: (_, event) => event.data.secretToken,
              maestroUser: (_, event) => event.data.maestroUser,
              isHost: (_, event) => true,
            }),
          },
          onError: {
            target: 'ERROR',
            actions: ['assignError'],
          },
        },
        on: {
          ERROR: {
            target: 'ERROR',
            actions: ['assignError'],
          },
        },
      },
      INIT_SESSION_GUEST: {
        invoke: {
          src: 'initSessionFromInviteCode',
          onDone: {
            target: 'INIT_VENDOR',
            actions: assign({
              sessionId: (_, event) => event.data.sessionId,
              inviteToken: (_, event) => event.data.inviteToken,
              isHost: (_) => false,
            }),
          },
          onError: {
            target: 'ERROR',
            actions: ['assignError'],
          },
        },
      },
      INIT_VENDOR: {
        invoke: {
          src: async () => {},
          onDone: [
            {
              cond: (ctx) => Boolean(ctx.isHost),
              target: 'INIT_DESTINATIONS',
            },
            {
              cond: (ctx) => !ctx.isHost,
              target: 'FINISHED',
            },
          ],
          onError: {
            target: 'ERROR',
            actions: ['assignError'],
          },
        },
        on: {
          ERROR: {
            target: 'ERROR',
            actions: ['assignError'],
          },
        },
      },
      INIT_DESTINATIONS: {
        invoke: {
          src: 'initDestinationsMachine',
          data: {
            sessionId: (ctx: Context) => ctx.sessionId,
            secretToken: (ctx: Context) => ctx.secretToken,
            maestroUser: (ctx: Context) => ctx.maestroUser,
          },
          onDone: {
            target: 'INIT_TRANSCODER',
          },
          onError: {
            target: 'ERROR',
            actions: ['assignError'],
          },
        },
      },
      INIT_TRANSCODER: {
        invoke: {
          src: 'transcoderInitMachine',
          data: {
            secretToken: (ctx: Context) => ctx.secretToken,
            sessionId: (ctx: Context) => ctx.sessionId,
          },
          onDone: {
            target: 'FINISHED',
          },
        },
        on: {
          ERROR: {
            target: 'ERROR',
            actions: ['assignError'],
          },
        },
      },
      FINISHED: {
        type: 'final',
      },
      ERROR: {
        type: 'final',
        invoke: {
          src: 'handleMachineError',
        },
      },
    },
  },
  {
    services: {
      setInitialState,
      initMaestroSessionMachine,
      openSettingsModal,
      transcoderInitMachine,
      initSessionFromInviteCode,
      initDestinationsMachine,
      handleMachineError,
    },
    actions: {
      assignError: assign({
        error: (_, event) => event.data,
      }),
    },
  }
);
