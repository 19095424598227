import React from 'react';
import styled from 'styled-components';

import { ModalHeader, Text } from 'components/shared';
import { PRIVACY_POLICY_URL } from 'constants/links';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/slices/ui';

const AboutStudio: React.FC = () => {
  const dispatch = useDispatch();

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(closeModal());
  };

  return (
    <Container>
      <ModalHeader
        showCloseButton
        onDismissModal={handleClose}
        title='About Maestro Studio'
        titleStyle={{
          fontSize: '20px',
        }}
      ></ModalHeader>
      <Text weight={500} size={15}>
        Maestro Studio allows you to interact with your guests in real time while streaming to
        several destinations at once.
      </Text>
      <Text weight={700} size={18}>
        How we use your data
      </Text>
      <Text weight={500} size={15}>
        Before enabling streaming, you will need to grant Studio permission for each service you
        would like to stream to. When you click the Go Live button, Studio will create a broadcast
        on your behalf on each platform you sign in with. When you click Go Offline, Studio will end
        the broadcast on each platform.
      </Text>
      <Text weight={500} size={15}>
        <PrivacyPolicyLink target='_blank' rel='noreferrer' href={PRIVACY_POLICY_URL}>
          Click here to read Maestro's Privacy policy
        </PrivacyPolicyLink>
      </Text>
    </Container>
  );
};

const PrivacyPolicyLink = styled.a`
  color: ${({ theme }) => theme.colors.text100};
  :visited {
    color: ${({ theme }) => theme.colors.accentPrimary};
  }
`;

const Container = styled.div`
  width: 516px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 5px 5px 26px 5px rgba(0, 0, 0, 0.47);
  background-color: ${({ theme }) => theme.colors.surface5};
  border-radius: 10px;
`;

export default AboutStudio;
