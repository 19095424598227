import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { handleError, handleResponse } from 'utils/http-response-handler';

export let axiosFactory = (params: any) => axios.create(params);

type AxiosInterceptor = (
  value: AxiosRequestConfig<any>
) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>;

// TODO: this kind of wants to just be a generic HTTP client, we should design
// one or copy paste one.
export abstract class BaseService {
  protected axiosInstance: AxiosInstance;
  protected nextHeaders: Record<string, string> | null = null;
  constructor(
    protected baseURL: URL,
    protected defaultHeaders: AxiosRequestHeaders = {},
    protected reqInterceptor?: AxiosInterceptor //TODO: type it
  ) {
    this.axiosInstance = axiosFactory({ baseURL: baseURL.toString() });
    this.axiosInstance.interceptors.request.use(this.autoTrimInterceptor);
    reqInterceptor && this.axiosInstance.interceptors.request.use(reqInterceptor);
  }

  protected autoTrimInterceptor = (req: AxiosRequestConfig) => {
    req.url = req.url?.replace(/\/$/, ''); // auto trim trailing slashes
    return req;
  };

  // Adds one-time headers to the next request
  protected withHeaders(headers: Record<string, string>): this {
    this.nextHeaders = headers;
    return this;
  }

  protected async get<T>(url: string, params?: Record<string, any>): Promise<T> {
    return this.request({ method: 'GET', url, params });
  }

  protected async post<T>(url: string, data?: any): Promise<T> {
    return this.request({ method: 'POST', url, data });
  }

  protected async put<T>(url: string, data?: any): Promise<T> {
    return this.request({ method: 'PUT', url, data });
  }

  protected async patch<T>(url: string, data?: any): Promise<T> {
    return this.request({ method: 'PATCH', url, data });
  }

  protected async delete<T>(url: string): Promise<T> {
    return this.request({ method: 'DELETE', url });
  }

  private async request<T>(config: AxiosRequestConfig): Promise<T> {
    const headers = {
      ...this.defaultHeaders,
      ...(this.nextHeaders ?? {}),
    };

    const response = (await this.axiosInstance
      .request({ ...config, headers })
      .then(handleResponse)
      .catch(handleError)) as T;

    this.nextHeaders = null;

    return response;
  }
}
