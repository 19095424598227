import styled from 'styled-components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeAllModals, getModalStack } from 'store/slices/ui';
import { ModalConfig } from 'interfaces/modals';
import AboutStudio from 'components/Modals/AboutStudio/AboutStudio';
import ScreenShareAllowed from 'components/Modals/ScreeShareAllowed/ScreenShareAllowed';
import ScreenShareDenied from 'components/Modals/ScreenShareDenied';
import KickGuest from 'components/Modals/KickGuest';
import ScreenShareNotification from 'components/Modals/ScreenShareNotification';
import MediaNotAllowed from 'components/Modals/MediaNotAllowed';
import { ModalV2 } from 'constants/modals';
import LeaveStream from './LeaveStream';
import { Settings } from 'components/Modals/Settings';
import UnmuteRequest from './UnmuteRequest/UnmuteRequest';
import { ConnectionLostNotification } from './ConnectionLostNotification';

type ContentProps = {
  config: ModalConfig;
  index: number;
};

const MODAL_TYPES: Record<ModalV2, React.ElementType> = {
  ABOUT_STUDIO: AboutStudio,
  SCREEN_SHARE_ALLOWED: ScreenShareAllowed,
  SCREEN_SHARE_DENIED: ScreenShareDenied,
  KICK_GUEST: KickGuest,
  SCREEN_SHARE_NOTIFICATION: ScreenShareNotification,
  MEDIA_NOT_ALLOWED: MediaNotAllowed,
  AUDIO_CAM_SETTINGS: Settings,
  LEAVE_STREAM: LeaveStream,
  UNMUTE_REQUEST: UnmuteRequest,
  CONNECTION_LOST: ConnectionLostNotification,
};

const ModalContent: React.FC<ContentProps> = ({ config, index }) => {
  const { props, component } = config;
  const Component = MODAL_TYPES[component];

  return (
    <Container index={index}>
      <Component {...props} />
    </Container>
  );
};

const Modal: React.FC = () => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const modalStack = useSelector(getModalStack);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const handleClickOutside = (e: Event) => {
      if (!contentRef.current?.contains(e.target as HTMLDivElement)) dispatch(closeAllModals());
    };

    const handleEsc = (e: KeyboardEvent) => {
      const EscCode = 'Escape';
      e.stopPropagation();
      if (e.code === EscCode) {
        dispatch(closeAllModals());
      }
    };

    if (modalStack.length) {
      document.addEventListener<'click'>('click', handleClickOutside, false);
      document.addEventListener<'keyup'>('keyup', handleEsc, false);
    } else {
      document.removeEventListener<'click'>('click', handleClickOutside, false);
      document.removeEventListener<'keyup'>('keyup', handleEsc, false);
    }

    contentRef.current?.focus();

    return () => {
      document.removeEventListener<'click'>('click', handleClickOutside, false);
      document.removeEventListener<'keyup'>('keyup', handleEsc, false);
    };
  }, [dispatch, modalStack]);

  if (!modalStack.length) return null;

  const { hideBackdrop } = modalStack[modalStack.length - 1];

  return (
    <>
      <Backdrop hide={Boolean(hideBackdrop)}>
        <ContentWrapper ref={contentRef}>
          {modalStack.map((config, index) => (
            <ModalContent key={config.id} config={config} index={index} />
          ))}
        </ContentWrapper>
      </Backdrop>
    </>
  );
};

const BASE_Z_INDEX = 100;

const Backdrop = styled.div<{ hide: boolean }>`
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => (props.hide ? 'transparent' : 'rgba(0, 0, 0, 0.75)')};
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  overflow: hidden;
  align-items: center;
  transition: background-color 0.2s;
  z-index: ${BASE_Z_INDEX};
`;

const Container = styled.div<{ index: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${(props) => BASE_Z_INDEX + 1 + props.index};
`;

const ContentWrapper = styled.div`
  position: absolute;
  padding: 1rem;
  z-index: ${BASE_Z_INDEX + 1};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default Modal;
