import { Speaker } from 'components/shared/Icons';
import { Select, Text } from 'components/shared';
import { FC } from 'react';
import { useFormikContext } from 'formik';
import { SectionIconContainer, SectionContainer } from './styles';
import { Option } from 'components/shared/Select';
import { useMediaSources } from 'hooks/useMediaSources';
import { useServices } from 'hooks/useServices';
import { useTheme } from 'styled-components';

export type SpeakerSettingsFormValue = {
  audioOutput: Option<string>;
};

export const SpeakerSettings: FC = () => {
  const {
    isStreamChanging,
    devicesByType: { audiooutput: audioOutputs },
  } = useMediaSources();
  const { values, setFieldValue } = useFormikContext<SpeakerSettingsFormValue>();
  const { mediaSourcesService } = useServices();
  const { colors } = useTheme();

  const options = audioOutputs.length ? audioOutputs : [mediaSourcesService.emptyDevice];

  return (
    <SectionContainer gridArea='speaker' style={{ justifyContent: 'space-evenly' }}>
      <SectionIconContainer>
        <Speaker width={14} height={14} fill={colors.text300} />
        <Text weight={600} size={14}>
          Speaker
        </Text>
      </SectionIconContainer>

      <Select
        data-testid='speakerDropdown'
        isSearchable={false}
        isDisabled={isStreamChanging}
        options={options}
        name='audioOutput'
        onChange={(value) => setFieldValue('audioOutput', value)}
        value={values.audioOutput}
      />
    </SectionContainer>
  );
};
