import React from 'react';
import styled, { css, useTheme } from 'styled-components/macro';
import { useSelector } from 'react-redux';

import BackstageDroppableArea from './BackstageDroppableArea';
import { People } from 'components/shared/Icons';
import { Text } from 'components/shared';
import { getIsBackstageVisible } from 'store/slices/ui';
import InviteGuestPanel from '../InviteGuestPanel';
import ExpandablePanel from 'components/ExpandablePanel';

import { GoLiveButton } from 'components/GoLiveButton/GoLiveButton';
import { getMaestroJwt } from 'store/slices/session';
import { REACT_APP_DISABLE_DESTINATIONS } from 'config';
import { getParticipants } from 'store/slices/participants';
import { CONFIG } from 'constants/config';

import DestinationCollapsible from 'views/Host/DestinationCollapsible';

const Backstage: React.FC = () => {
  const participants = useSelector(getParticipants);
  const isBackstageVisible = useSelector(getIsBackstageVisible);
  const maestroJwt = useSelector(getMaestroJwt);
  const { colors } = useTheme();

  const getSpotsLeft = () => {
    const usedSpots = participants.reduce((acc, curr) => (!curr.isScreenShare ? acc + 1 : acc), 0);
    return usedSpots === CONFIG.MAX_PEERS_ON_STUDIO
      ? 'Full'
      : `${CONFIG.MAX_PEERS_ON_STUDIO - usedSpots} spots left`;
  };

  return (
    <Container visible={isBackstageVisible}>
      <Header>
        <Text size={16} color={colors.text300} weight={700}>
          Backstage
        </Text>
      </Header>
      <InnerContainer>
        <ExpandablePanel
          initiallyExpanded
          header={
            <HeaderContainer>
              <Text size={16} color={colors.text200} weight={700}>
                Waiting Room
              </Text>
              <SectionIconContainer>
                <People width={16} height={16} fill={colors.text300} />
                <Text weight={500} size={14}>
                  {getSpotsLeft()}
                </Text>
              </SectionIconContainer>
            </HeaderContainer>
          }
        >
          <WaitingRoomContent>
            <InviteGuestPanel />
            <Text size={14} color={colors.text200} weight={300}>
              Drag and drop guests to the stage to add them to your live broadcast.
            </Text>
            <BackstageDroppableArea />
          </WaitingRoomContent>
        </ExpandablePanel>
        <DestinationCollapsible
          data-testid='destDropdown'
          disabled={REACT_APP_DISABLE_DESTINATIONS}
        />

        {maestroJwt ? null : <GoLiveButton disabled={REACT_APP_DISABLE_DESTINATIONS} />}
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div<{ visible: boolean }>`
  position: relative;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  /* display: flex; */
  height: 100%;
  flex-direction: column;
  //TODO: check if this is the best way to do this
  width: ${(props) => (props.visible ? '345px' : '0px')};
  border: ${(props) => (props.visible ? `1px solid ${props.theme.colors.surface6}` : 'none')};
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.surface2};
  box-sizing: border-box;
  transition: width 0.2s ease;
  padding: 15px 10px;
  gap: 10px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 15px;
  box-sizing: border-box;
`;

const InnerContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: calc(100vh - 22px - 50px);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    /* firefox */
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.accentPrimary} black;
    /* firefox */
    ::-webkit-scrollbar {
      width: 2.6px;
      border-radius: 20px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      width: 4px;
      background: black;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: linear-gradient(
        0deg,
        ${theme.colors.accentPrimary} 57.14%,
        ${theme.colors.accentTertiary} 100%
      );
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.accentPrimary};
    }
  `}
`;

const Header = styled.div`
  display: flex;
  height: 22px;
`;

const WaitingRoomContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SectionIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 25px;
  background-color: ${(props) => props.theme.colors.surface4};
  border-radius: 10px;
  gap: 13px;
`;

export default Backstage;
