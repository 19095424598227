import { FC, useEffect } from 'react';
import { Header } from 'components/Modals/Settings/Header';
import { Footer } from 'components/Modals/Settings/Footer';
import { Container } from 'components/Modals/Settings/styles';
import { SettingsForm } from 'components/Modals/Settings/SettingsForm';
import { useMediaSources } from 'hooks/useMediaSources';
import { SettingsType } from 'components/Modals/Settings/CameraSettings';

interface Props {
  type: SettingsType;
  hideClose: boolean;
  handleNext: () => void;
}

export const Settings: FC<Props> = (props) => {
  return <InnerSettings {...props} />;
};

const InnerSettings: FC<Props> = ({ hideClose, type, handleNext }) => {
  const { isLoading, isStreamChanging } = useMediaSources();

  useEffect(() => {
    if (!isStreamChanging) document.getElementById('settings-modal')?.click();
  }, [isStreamChanging]);

  if (isLoading) return null;
  return (
    <Container id='settings-modal'>
      <Header hideClose={hideClose} />
      <SettingsForm type={type} handleNext={handleNext} />
      <Footer type={type} />
    </Container>
  );
};
