import useAudioLevel from 'hooks/useAudioLevel';
import { FC } from 'react';
import styled, { useTheme } from 'styled-components/macro';

interface Props {
  stream: MediaStream | undefined;
}

const AudioIndicator: FC<Props> = ({ stream }) => {
  const { audioLevel } = useAudioLevel(undefined, stream);
  // const audioLevel = 0;
  const { colors } = useTheme();

  const barForegrounds = [
    colors.alertConfirmation,
    colors.alertConfirmation,
    colors.alertConfirmation,
    colors.alertWarning,
    colors.alertError,
  ];
  const barBd = colors.surface2;

  const getLinearGradient = (left: string, right: string, audioLevel: number) => {
    const divisor = (audioLevel % 1).toFixed(2).substring(2) + '%';
    return `linear-gradient(to right, ${left} 0%, ${left} ${divisor}, ${right} ${divisor}, ${right} 100%)`;
  };

  return (
    <Container>
      {barForegrounds.map((e, idx) => {
        const fullBars = Math.floor(audioLevel / 20);
        let color = e;
        if (fullBars === idx) {
          color = getLinearGradient(e, barBd, audioLevel / 100);
        } else if (idx > fullBars) {
          color = barBd;
        }
        return <Bar key={idx} color={color} />;
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 3px;
  margin: 0px 13px 0px 15px;
  position: relative;
  width: 100%;
`;

const Bar = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  width: 20%;
  min-width: 10px;
  height: 4px;
  border-radius: 10px;
  z-index: 1;
`;

export default AudioIndicator;
