import React, { ReactNode, useState } from 'react';
import styled from 'styled-components/macro';
import Collapsible from 'react-collapsible';

import { ChevronDown } from 'components/shared/Icons';

interface Props {
  initiallyExpanded?: boolean;
  header: ReactNode;
}

const ExpandablePanel: React.FC<Props> = ({ initiallyExpanded = false, header, children }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(initiallyExpanded);

  const handleToggleExpanded = () => setIsExpanded((prevIsExpanded) => !prevIsExpanded);

  return (
    <Container isExpanded={isExpanded}>
      <Collapsible
        open={isExpanded}
        transitionTime={220}
        trigger={
          <HeaderContainer onClick={handleToggleExpanded}>
            {header}
            <IconContainer isExpanded={isExpanded}>
              <ChevronDown />
            </IconContainer>
          </HeaderContainer>
        }
      >
        <ContentContainer>{children}</ContentContainer>
      </Collapsible>
    </Container>
  );
};

const Container = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: ${(props) => (props.isExpanded ? 'auto' : '56px')};
  background-color: ${({ theme }) => theme.colors.surface4};
  border-radius: 6px;
  user-select: none;
  transition: 0.22s max-height ease-in-out;
`;

const HeaderContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 56px;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0px 10px;
`;

const ContentContainer = styled.div`
  padding: 0px 10px 15px 10px;
  box-sizing: border-box;
`;

const IconContainer = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  & svg {
    transform: ${(props) => (props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

export default ExpandablePanel;
