import React from 'react';
import { useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';

import { Text } from 'components/shared';
import Animation from 'components/Animation';
import { getSiteSource } from 'store/slices/siteConfig';
import { lightenDarkenColor } from 'utils/methods';
import { SITE_SOURCES } from 'constants/config';
import { Beta } from 'components/shared/Icons';

const EnteringStudio: React.FC = () => {
  const siteSource = useSelector(getSiteSource);
  const { colors } = useTheme();

  const replaceColors = [
    {
      source: '#FC223B',
      target: colors.accentPrimary,
    },
    {
      source: '#C91B2F', // This color is present in the neck of the colored profile icon
      target: lightenDarkenColor(colors.accentPrimary, -30),
    },
  ];

  return (
    <Container>
      <EnteringStudioAnimation replaceColors={replaceColors} />
      <Text size={20} weight={500} color={colors.text100} style={{ opacity: 0.6 }}>
        You're entering
      </Text>
      <NameContainer>
        <Text size={24} weight={600} color={colors.text100}>
          {`${siteSource === SITE_SOURCES.MAESTRO ? 'Maestro Studio' : 'Studio'}`}
        </Text>
        <Beta fill={colors.accentPrimary} width={53} height={30} />
      </NameContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: black;
  justify-content: center;
  align-items: center;
  gap: 18px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
`;

//TODO: fix this this block somehow. It breaks vscode's coloring rules
const EnteringStudioAnimation = styled(Animation).attrs((props) => ({
  animationName: 'entering_studio',
  loop: true,
  autoplay: true,
  height: 191,
  width: 232,
}))``;

export default React.memo(EnteringStudio);
