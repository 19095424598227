import React from 'react';
import { useSelector } from 'react-redux';
import { getSiteSource } from 'store/slices/siteConfig';
import { Maestro, Gettr, GettrLogo } from 'components/shared/Icons';
import { SITE_SOURCES } from 'constants/config';
import { Text } from 'components/shared';
import { capitalize } from 'utils/destination';

interface DefaultThumbProps {
  width: number;
  height: number;
}

const DefaultDestinationThumbnail: React.FC<DefaultThumbProps> = ({ width, height }) => {
  const source = useSelector(getSiteSource);
  const size = { width, height };

  if (source === SITE_SOURCES.GETTR) {
    return <GettrLogo width={width - 7} height={height - 7} />; // it looks too big as is, hence the -7
  }

  return <Maestro {...size} />;
};

interface DefaultIconProps extends DefaultThumbProps {}

const DefaultDestinationBadge: React.FC<DefaultIconProps> = ({ width, height }) => {
  const source = useSelector(getSiteSource);
  const size = { width, height };

  if (source === SITE_SOURCES.GETTR) {
    return <Gettr width={width - 2} height={height - 2} />; //  it looks a bit big as is, hence the -2
  }

  return <Maestro {...size} />;
};

interface DefaultNameProps {
  size: number;
  weight: number;
  color?: string;
}

const DefaultDestinationName: React.FC<DefaultNameProps> = (props) => {
  const source = useSelector(getSiteSource);
  return <Text {...props}>{capitalize(source)}</Text>;
};

export { DefaultDestinationThumbnail, DefaultDestinationBadge, DefaultDestinationName };
