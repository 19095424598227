import React from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  shape?: 'flat' | 'circular';
  width?: string;
  height?: string;
}

const SkeletonDiv: React.FC<Props> = ({ shape = 'flat', width, height, children }) => {
  return (
    <CustomDiv shape={shape} width={width} height={height}>
      {children}
    </CustomDiv>
  );
};

const placeHolderShimmer = keyframes`
  0%{
      background-position: -500px 0
  }
  100%{
      background-position: 500px 0
  }
`;

const CustomDiv = styled.div<Props>`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  border-radius: ${(props) => (props.shape === 'flat' ? '5px' : '50%')};
  background: ${(props) =>
    `linear-gradient(to right, ${props.theme.colors.surface2} 8%, #2d2e39 18%, ${props.theme.colors.surface2} 33%)`};
  background-size: 1000px 100px;
  animation: ${placeHolderShimmer} 2s linear infinite;
`;

export default SkeletonDiv;
