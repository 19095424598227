import { actions, createMachine } from 'xstate';
import { postMessageReadyStatus } from 'store/core/maestro-messages';
import store from 'store';
import {
  ITranscoderInitMachineContext as Context,
  TTranscoderInitMachineEvents as MachineEvents,
} from './interfaces';
import { TSecretToken } from 'interfaces/newType';
import { getOptionalQueryParams } from 'utils/methods';

const { escalate } = actions;

export const setupMachine = async (ctx: Context) => {
  const { session } = store.getState();
  const { streamKey, ingestUrl } = getOptionalQueryParams();

  postMessageReadyStatus(ctx.sessionId);

  if (session.isLive) return { skip: true };

  if (streamKey && ingestUrl) return { default: true };
};

export const handleMaestroDefaultDestination = async (context: Context) => {
  const { ingestUrl, streamKey } = getOptionalQueryParams();

  if (!ingestUrl || !streamKey) throw new Error('IngestUrl and/or StreamKey not provided');
};

export const transcoderInitMachine = createMachine<Context, MachineEvents>(
  {
    id: 'transcoderInitMachine',
    initial: 'SETUP_MACHINE',
    context: {
      sessionId: '',
      secretToken: '' as TSecretToken,
    },
    states: {
      SETUP_MACHINE: {
        invoke: {
          id: 'setupMachine',
          src: 'setupMachine',
          onDone: [
            { target: 'SUCCESS', cond: (_, event) => Boolean(event.data.skip) },
            {
              target: 'HANDLE_MAESTRO_DEFAULT_DESTINATION',
              cond: (_, event) => Boolean(event.data.default),
            },
          ],
        },
      },
      HANDLE_MAESTRO_DEFAULT_DESTINATION: {
        invoke: {
          id: 'handleMaestroDefaultDestination',
          src: 'handleMaestroDefaultDestination',
          onDone: {
            target: 'SUCCESS',
          },
          onError: {
            target: 'ERROR',
          },
        },
      },
      ERROR: {
        type: 'final',
        entry: escalate((_, event) => event.data),
      },
      SUCCESS: {
        type: 'final',
      },
    },
  },
  {
    services: {
      handleMaestroDefaultDestination,
      setupMachine,
    },
  }
);
