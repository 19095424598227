import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components/macro';
import { css, useTheme } from 'styled-components';

import Tooltip from 'components/Tooltip';
import { Positions } from 'components/Tooltip/Tooltip';
import { Text } from 'components/shared';
import { DataTest } from 'interfaces/dataTestId';

interface Props extends DataTest {
  text: string;
  onClick: (e: any) => void;
  tooltipPosition?: Positions;
  primary?: boolean;
  disabled?: boolean;
  active?: boolean;
  style?: any;
}

type StyledButtonProps = {
  primary?: boolean;
  disabled?: boolean;
  active?: boolean;
  customStyles?: string;
};

const ControlButton: React.FC<Props> = ({
  tooltipPosition,
  text,
  onClick,
  children,
  primary,
  disabled,
  active,
  style,
  'data-testid': dataTestId,
}) => {
  const { colors } = useTheme();

  return (
    <React.Fragment>
      {isMobile ? (
        <MobileButtonContainer>
          <StyledButton
            disabled={disabled}
            onClick={onClick}
            primary={primary}
            customStyles={style}
            active={active}
            data-testid={dataTestId}
          >
            {children}
          </StyledButton>
          <Text weight={400} size={11} textAlign='center' color={colors.text100}>
            {text}
          </Text>
        </MobileButtonContainer>
      ) : (
        <Tooltip position={tooltipPosition || 'top'} text={text}>
          <StyledButton
            onClick={onClick}
            disabled={disabled}
            primary={primary}
            customStyles={style}
            active={active}
            data-testid={dataTestId}
          >
            {children}
          </StyledButton>
        </Tooltip>
      )}
    </React.Fragment>
  );
};

const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  outline: none;
  background-color: ${(props) =>
    props.active ? props.theme.colors.accentPrimary : props.theme.colors.surface4};
  opacity: ${(props) => (props.disabled ? '0.7' : '1')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 44px;
  height: 44px;
  border: ${(props) => (props.primary ? `2px solid ${props.theme.colors.accentPrimary}` : 'none')};
  border-radius: 50%;

  ${(props) =>
    !props.disabled &&
    css<StyledButtonProps>`
      :hover {
        background-color: ${(props) =>
          !props.active && props.primary
            ? props.theme.colors.surface4
            : props.theme.colors.accentPrimary};
        border: ${(props) =>
          props.primary ? `2px solid ${props.theme.colors.accentPrimary}` : 'none'};
      }
      :active {
        transform: translate(1px, 1px);
      }
    `};

  ${(props) => props.customStyles}
`;

const MobileButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;

export default ControlButton;
