import React, { useEffect } from 'react';

import useMaestroMessages from 'hooks/useMaestroMessages';
import MainRoutes from 'routes/main';
import { TPopupAuthPayload } from 'services/DestinationAuth/interfaces';
import { BUILD_INFO } from 'config';
import { isChrome, isIOS } from 'react-device-detect';
import { useMediaSources } from 'hooks/useMediaSources';
import { useServices } from 'hooks/useServices';

declare global {
  interface Window {
    //TODO: name and type it better
    destinationAuthTokens: TPopupAuthPayload[];
  }
}

const App: React.FC = () => {
  // Log build information
  useEffect(() => console.log(BUILD_INFO), []);

  // Listening to maestro-web messages
  useMaestroMessages();

  useEffect(() => {
    window.destinationAuthTokens = [];
  }, []);

  useEffect(() => {
    const { clientUrl } = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    ) as {
      clientUrl?: string;
    };
    const isIncompatibleDevice = isIOS && isChrome;
    if (isIncompatibleDevice) {
      window.location.href = clientUrl || 'https://info.maestro.io/open-in-safari';
    }
  }, []);

  const { initMediaSources } = useMediaSources();

  useEffect(() => {
    initMediaSources();
  }, [initMediaSources]);

  const { sessionMonitorService } = useServices();

  useEffect(() => {
    sessionMonitorService.startPolling();

    return () => sessionMonitorService.stopPolling();
  }, [sessionMonitorService]);

  return <MainRoutes />;
};

export default App;
