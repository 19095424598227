import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { TPopupAuthPayload, TStrategies } from 'services/DestinationAuth/interfaces';
import { useServices } from 'hooks/useServices';
import { isValidStrategy } from 'utils/destination';
import { CONFIG } from 'constants/config';

const PopupRedirect: React.FC = () => {
  const { destination } = useParams();
  const { destinationAuthService } = useServices();

  useEffect(() => {
    if (!window.opener || !window.opener.destinationAuthTokens || !destination)
      return window.close();
    if (!isValidStrategy(destination)) return window.close();

    const res = destinationAuthService.getResponseFromLocationUrl(destination as TStrategies);
    if (!res) return window.close();

    // console.debug(res);

    window.opener.destinationAuthTokens = [
      res as TPopupAuthPayload,
      ...window.opener.destinationAuthTokens.slice(0, CONFIG.MAX_PEERS_ON_STAGE - 1), // Just to make sure we don't keep more peer than we want at the same time
    ];
    //NOTE: maybe display a message of success and then after some seconds close it?
    window.close();
  }, [destination, destinationAuthService]);

  //TODO: we need some type of user feedback
  return <div></div>;
};

export default PopupRedirect;
