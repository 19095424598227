import React, { createRef } from 'react';

export const VideoCardResizeContext = React.createContext<{
  observerRef: React.RefObject<ResizeObserver>;
  sizeMap: { [id: string]: number };
}>({
  observerRef: createRef(),
  sizeMap: {} as { [id: string]: number },
});

const entriesToMap = (entries: ResizeObserverEntry[]) =>
  entries.reduce((map, entry) => {
    const { target, contentRect } = entry;
    return { ...map, [target.id]: contentRect.width };
  }, {});

export const VideoCardResizeProvider: React.FC = ({ children }) => {
  const [sizeMap, setSizeMap] = React.useState<{ [id: string]: number }>({});

  const observerRef = React.useRef(
    new ResizeObserver((entries) => setSizeMap((map) => ({ ...map, ...entriesToMap(entries) })))
  );

  return (
    <VideoCardResizeContext.Provider value={{ observerRef, sizeMap }}>
      {children}
    </VideoCardResizeContext.Provider>
  );
};
