import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { getIsMaxPeopleReached } from 'store/slices/ui';
import Stage from 'views/shared/Stage';
import MaxPeopleReached from './MaxPeopleReached';

const Guest: React.FC = () => {
  const isMaxPeopleReached = useSelector(getIsMaxPeopleReached);
  return <Container>{isMaxPeopleReached ? <MaxPeopleReached /> : <Stage />}</Container>;
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: ${isMobile ? 0 : '15px'};
  box-sizing: border-box;
`;

export default Guest;
