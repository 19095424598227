import React from 'react';
import styled, { useTheme } from 'styled-components';
import Frame from 'components/LayoutIcons/Frame';

const Screenshare_2: React.FC = () => {
  const { colors } = useTheme();
  return (
    <Container>
      <FloatingContainer>
        <Frame />
        <Frame />
        <Frame />
        <Frame />
      </FloatingContainer>
      <MainContainer>
        <Frame color={colors.surface6} />
      </MainContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
  padding: 7px 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: -1;
`;

const FloatingContainer = styled.div`
  position: absolute;
  left: 2px;
  top: 2px;
  display: grid;
  grid-template-rows: repeat(4, 9.46px);
  grid-template-columns: repeat(2, 16.06px);
  width: 94%;
  height: 84%;
  box-sizing: border-box;
  place-content: space-between;
  row-gap: 45px;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export default Screenshare_2;
