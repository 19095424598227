import React from 'react';
import styled, { keyframes, useTheme } from 'styled-components';

interface Props {
  size: number;
  color?: string;
  background?: string;
}

const LoadingSpinner: React.FC<Props> = ({ color, size, background }) => {
  const { colors } = useTheme();
  return (
    <Spinner color={color || colors.text100} size={size} bd={background || colors.accentPrimary} />
  );
};

const Loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<{ size: number; color: string; bd: string }>`
  width: ${(props) => props.size + 'px'};
  height: ${(props) => props.size + 'px'};
  border-radius: 50%;
  background: ${(props) =>
    `linear-gradient(to right, ${props.color} 10%, rgba(255, 255, 255, 0) 42%)`};
  position: relative;
  animation: ${Loading} 0.9s infinite linear;
  transform: translateZ(0);
  ::before {
    width: 50%;
    /* height: 50%; */
    background: ${(props) => props.color};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  ::after {
    background: ${(props) => props.bd};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

export default LoadingSpinner;
