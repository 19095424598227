import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import { replaceColor } from 'lottie-colorify';
import styled from 'styled-components';

const Empty = styled.div<{ height?: number; width?: number }>`
  height: ${({ height }) => height || '100%'};
  width: ${({ width }) => width || '100%'};
`;

interface IReplaceColor {
  source: string | number[];
  target: string | number[];
}

interface IAnimationProps {
  animationName: string;
  autoplay?: boolean;
  height?: number;
  isPaused?: boolean;
  isStopped?: boolean;
  loop?: boolean;
  replaceColors?: IReplaceColor[];
  width?: number;
}

const Animation: React.FC<IAnimationProps> = ({
  animationName,
  replaceColors = [],
  loop,
  autoplay,
  height,
  width,
  ...props
}) => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    import(`assets/lotties/${animationName}.json`).then((result) => {
      const data = replaceColors.reduce(
        (prev, curr) => replaceColor(curr.source, curr.target, prev),
        result.default
      );
      setAnimationData(data);
    });
  }, [animationName, replaceColors]);

  if (!animationData) {
    return <Empty height={height} width={width} />;
  }

  return (
    <Lottie
      animationData={animationData}
      play={autoplay}
      loop={loop}
      rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
      style={{ height, width }}
      {...props}
    />
  );
};

export default Animation;
