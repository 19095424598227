import { FC, useEffect, useRef, useState } from 'react';
import StageArea from './StageArea';
import StageDroppableArea from './StageDroppableArea';
import styled, { css } from 'styled-components/macro';
import { isMobile } from 'react-device-detect';
import { getSessionDisplayName, getSessionIsHost } from 'store/slices/session';
import { useSelector } from 'react-redux';
import { LiveKitRoom, useRoomContext } from '@livekit/components-react';
import { LIVEKIT_CLOUD_URL } from 'config';
import EnteringStudio from 'components/WhiteLabel/EnteringStudio';
import { useServices } from 'hooks/useServices';
import { getSessionId } from 'store/slices/session';
import { getUserId } from 'store/slices/user';
import { ConnectionState } from 'livekit-client';
import { useReactiveRoom } from 'contexts/LiveKitContext';
import { useMediaSources } from 'hooks/useMediaSources';

type ResizeCallback = (widthPercent: `${number}px`, heightPercent?: `${number}px`) => void;

type UseStateStreamResizeArgs = {
  resizeCallbacks: ResizeCallback[];
};

const calculateAspectRatioSize = (
  fatherNode: HTMLElement,
  offset: { widthOffset: number; heightOffset: number }
) => {
  const { widthOffset = 0, heightOffset = 0 } = offset;
  const availableWidth = fatherNode.clientWidth - widthOffset;
  const availableHeight = fatherNode.clientHeight - heightOffset;

  const height = Math.min(availableWidth / 1.778, availableHeight);
  const width = height * 1.778;

  const widthPercent = Math.round((100 * width) / availableWidth);
  const heightPercent = Math.round((100 * height) / availableHeight);

  return {
    width,
    height,
    widthPercent,
    heightPercent,
  };
};

export const useStageStreamResize = ({ resizeCallbacks = [] }: UseStateStreamResizeArgs) => {
  useEffect(() => {
    // NOTE: currently this works targeting the first child. Targeting by ID can also be done
    const resizeObserver = new ResizeObserver((entries) => {
      const streamBounds = entries[0].target as HTMLElement;
      const target = streamBounds.firstChild as HTMLElement;

      if (!target) return;

      if (isMobile) {
        const stageContainer = document.getElementById('stage-container');

        if (!stageContainer) return;
        // we could calculate this data from window window.getComputedStyle(container) instead. 20 is the padding
        const { width } = calculateAspectRatioSize(stageContainer, {
          widthOffset: 15 * 2,
          heightOffset: 15 * 2,
        });

        target.style.width = `${width}px`;
        return;
      }

      const { width, height } = calculateAspectRatioSize(streamBounds, {
        widthOffset: 15 * 2,
        heightOffset: 15 * 2,
      });

      target.style.width = `${width}px`;

      resizeCallbacks.forEach((callback) => callback(`${width}px`, `${height}px`));
    });
    const element = document.getElementById('stream-bounds-container');
    element && resizeObserver.observe(element);
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export type StageStreamAreaProps =
  | {
      compositeMode?: false;
      streamToken?: undefined;
    }
  | {
      streamToken: string;
      compositeMode: true;
    };

const StageStreamArea: FC<StageStreamAreaProps> = ({
  streamToken: compositorToken,
  compositeMode,
}) => {
  const isHost = useSelector(getSessionIsHost);

  const { sessionService } = useServices();
  const sessionId = useSelector(getSessionId);
  const userId = useSelector(getUserId);

  const { mediaEnabled, isUserMediaAllowed } = useMediaSources();

  const [streamToken, setStreamToken] = useState<string | undefined>(compositorToken);

  useEffect(() => {
    (async () => {
      if (!userId || streamToken) return;

      try {
        const { token } = await sessionService.getStreamToken(sessionId, userId);
        setStreamToken(token);
      } catch (error) {
        console.error(`Couldn't fetch stream token`, error);
      }
    })();
  }, [sessionId, sessionService, streamToken, userId]);

  const room = useRoomContext();
  const {
    state: roomState,
    localParticipantName,
    participants,
    setOwnCameraEnabled,
    setOwnMicEnabled,
  } = useReactiveRoom();

  const guestCount = participants.size;
  let hostAdded = useRef(false);

  const name = useSelector(getSessionDisplayName);

  useEffect(() => {
    if (!name || !streamToken || localParticipantName === name) return;

    (async () => {
      await sessionService.updateParticipantName(sessionId, streamToken, name);
    })();
  }, [localParticipantName, name, sessionId, sessionService, streamToken]);

  useEffect(() => {
    if (!isHost || roomState !== ConnectionState.Connected || !userId || hostAdded.current) {
      return;
    }

    if (guestCount === 0) {
      hostAdded.current = true;
      sessionService.updateParticipantMetadata(sessionId, userId, {
        isOnStage: true,
        order: 0,
      });
    }
  }, [guestCount, isHost, roomState, sessionId, sessionService, userId]);

  const devicesPublished = useRef(false);

  useEffect(() => {
    if (roomState !== ConnectionState.Connected || devicesPublished.current || !isUserMediaAllowed)
      return;

    if (mediaEnabled.video) {
      setOwnCameraEnabled(true);
    }

    if (mediaEnabled.audio) {
      setOwnMicEnabled(true);
    }

    devicesPublished.current = true;
  }, [isUserMediaAllowed, mediaEnabled, roomState, setOwnCameraEnabled, setOwnMicEnabled]);

  if (!userId || !streamToken) {
    return <EnteringStudio />;
  }

  return (
    <>
      <StreamBounds id='stream-bounds-container' isCompositing={compositeMode}>
        <LiveKitRoom
          token={streamToken}
          serverUrl={LIVEKIT_CLOUD_URL}
          room={room}
          style={{ display: 'contents' }}
        >
          {isHost ? <StageDroppableArea /> : <StageArea borderless={compositeMode} />}
        </LiveKitRoom>
      </StreamBounds>
    </>
  );
};

const StreamBounds = styled.div<{ isCompositing?: boolean }>`
  ${({ isCompositing }) =>
    isCompositing
      ? css`
          width: 100%;
          margin: 0;
        `
      : css`
          width: calc(100% - 10px);
          margin: ${isMobile ? '0px' : '5px'};
        `}
  position: relative;
  flex: 1;
  container-type: size;
`;

export default StageStreamArea;
