const requireEnv = (key: string) => {
  const envVar = process.env[key];
  if (!envVar) {
    throw new Error(`Please set ${key} in environment`);
  }
  return envVar;
};

// https://create-react-app.dev/docs/adding-custom-environment-variables/
type INODE_ENV = 'development' | 'test' | 'production';

export const NODE_ENV = requireEnv('NODE_ENV') as INODE_ENV;

export const REACT_APP_MAESTRO_INTEGRATION_URL = requireEnv('REACT_APP_MAESTRO_INTEGRATION_URL');

export const REACT_APP_FACEBOOK_APP_ID = requireEnv('REACT_APP_FACEBOOK_APP_ID');
export const REACT_APP_TWITCH_CLIENT_ID = requireEnv('REACT_APP_TWITCH_CLIENT_ID');
export const REACT_APP_YOUTUBE_CLIENT_ID = requireEnv('REACT_APP_YOUTUBE_CLIENT_ID');
export const REACT_APP_DESTINATION_REDIRECT_URL = requireEnv('REACT_APP_DESTINATION_REDIRECT_URL');
// 'http://localhost:3001'; //NOTE: change to HTTPS if you are running locally with it

export const BUILD_INFO =
  process.env.REACT_APP_BUILD_INFO ?? 'no build information detected for this build';

export const REACT_APP_DISABLE_DESTINATIONS =
  process.env.REACT_APP_DISABLE_DESTINATIONS === 'true' ?? false;

export const REACT_APP_SESSION_SERVICE_URL = requireEnv('REACT_APP_SESSION_SERVICE_URL');

export const COMPOSITE_MODE = window.location.pathname.indexOf('/composite') === 0;

export const LIVEKIT_CLOUD_URL = COMPOSITE_MODE
  ? new URLSearchParams(window.location.search).get('url')!
  : `wss://${requireEnv('REACT_APP_LIVEKIT_CLOUD_HOST')}`;
