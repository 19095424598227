import React from 'react';
import styled, { CSSProperties } from 'styled-components/macro';

type TextAlign = 'center' | 'end' | 'justify' | 'left' | 'right' | 'inherit' | 'initial';

interface Props {
  size: number;
  weight: number;
  color?: string;
  textAlign?: TextAlign;
  style?: CSSProperties;
}

const Text: React.FC<Props> = ({ size, weight, color, textAlign, style, children }) => {
  return (
    <CustomText size={size} weight={weight} color={color} textAlign={textAlign} style={style}>
      {children}
    </CustomText>
  );
};

const CustomText = styled.span<Props>`
  font-size: ${(props) => `${props.size}px`};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color || '#ffffff'};
  text-align: ${(props) => props.textAlign || 'left'};
  text-overflow: ellipsis;
  overflow: hidden;
  ::selection {
    background-color: ${(props) => props.theme.colors.accentPrimary};
    color: white;
  }
`;

export default Text;
