import { useParams, useSearchParams } from 'react-router-dom';
import { useMachine } from '@xstate/react';

import { IntegrationService } from 'services/IntegrationService';
import {
  ALLOWED_ACCESS_STATES,
  LOADING_STATES,
  loginGateMachine,
  loginGateMachineInitialState,
} from 'stateMachines/loginGateMachine';
import { TMaestroJwt } from 'interfaces/newType';
import { ILoginGateMachineContext } from 'stateMachines/interfaces';
import { useServices } from 'hooks/useServices';

const getInitialContext = () => {
  const query = Object.fromEntries(new URLSearchParams(window.location.search).entries()) as {
    sessionId?: string;
    token?: TMaestroJwt; //TODO: since we removed standalone access, we only have MaestroJwt for now.
    clientUrl?: string;
    channelId?: string;
    siteId?: string;
  };

  if (query.token) {
    try {
      const integrationService = new IntegrationService(query.token);
      return {
        integrationService,
        ...query,
      };
    } catch (e) {
      console.error(e);
    }
  }

  return {
    integrationService: null,
    ...query,
  };
};

const initialContext = getInitialContext();

const getError = (state: ReturnType<typeof useMachine>[0], context: ILoginGateMachineContext) => {
  if (state.matches('ERROR')) {
    if (context.inviteCode)
      return 'Uh oh! Looks like you have an old invite code. Ask the host for the latest invite code.';
    if (!context.integrationService) return 'Standalone access is disabled for now';
    return 'An unexpected error occurred, please try again later.';
  }

  return state.context.error;
};

export const useLoginGateMachine = () => {
  const { inviteCode = '' } = useParams();
  const [searchParams] = useSearchParams();

  const userName = searchParams.get('userName') ?? undefined;

  const { mediaSourcesService } = useServices();

  const context: ILoginGateMachineContext = {
    ...loginGateMachineInitialState,
    ...initialContext,
    inviteCode,
    isStandAlone: false, // if integration service was not started (null), we assume that studio is running outside Maestro
    maestroJwt: initialContext.token as TMaestroJwt,
    mediaSourcesService,
    userName,
  };

  const [state, send] = useMachine(loginGateMachine, {
    context,
  });

  return {
    isLoading: LOADING_STATES.some(state.matches),
    isAccessAllowed: ALLOWED_ACCESS_STATES.some(state.matches),
    error: getError(state, context),
    send,
  };
};
