import React from 'react';
import styled from 'styled-components';

import { Button, Text } from 'components/shared';

interface Props {
  id: string;
}

export const ConnectionLostNotification: React.FC<Props> = ({ id }) => {
  const handleReload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    window.location.reload();
  };

  return (
    <Container>
      <Text size={16} weight={400}>
        Connection lost. Please reload Studio to reconnect.
      </Text>
      <Button fullWidth onClick={handleReload}>
        <Text size={14} weight={700}>
          Reload and Reconnect
        </Text>
      </Button>
    </Container>
  );
};

const Container = styled.div`
  width: 340px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.surface2};
  box-shadow: 5px 5px 26px 5px rgba(0, 0, 0, 0.47);
  border-radius: 10px;
`;
