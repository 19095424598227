import React from 'react';
import styled, { useTheme } from 'styled-components';

import { Button, ModalHeader, Text } from 'components/shared';
import { closeModal } from 'store/slices/ui';
import { postMessageBroadcastStopped } from 'store/core/maestro-messages';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionClientUrl } from 'store/slices/session';
import { getNeedHelpUrl } from 'store/slices/siteConfig';

const LeaveStream: React.FC = () => {
  const dispatch = useDispatch();
  const clientUrl = useSelector(getSessionClientUrl);
  const needHelpUrl = useSelector(getNeedHelpUrl);
  const { colors } = useTheme();

  const handleLeaveStream = () => {
    if (window === window.parent) {
      // if running outside an iframe
      window.location.href = clientUrl ?? needHelpUrl;
    }
    postMessageBroadcastStopped();
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(closeModal());
  };

  return (
    <Container>
      <ModalHeader showCloseButton />
      <Text size={16} weight={700} textAlign='center'>
        Are you sure you want to leave the stream?
      </Text>
      <ButtonsGrid>
        <Button color='black' onClick={handleClose}>
          <Text size={14} weight={700} style={{ textTransform: 'uppercase' }}>
            Go Back
          </Text>
        </Button>
        <Button color={colors.accentPrimary} onClick={handleLeaveStream}>
          <Text size={14} weight={700} style={{ textTransform: 'uppercase' }}>
            Leave
          </Text>
        </Button>
      </ButtonsGrid>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 353px;
  height: 226px;
  gap: 25px;
  text-align: center;
  padding: 25px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.surface5};
  border-radius: 10px;
`;

const ButtonsGrid = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 9px;
`;

export default LeaveStream;
