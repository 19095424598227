import React from 'react';
import styled, { useTheme } from 'styled-components';

interface Props {
  color?: string;
}

const Frame: React.FC<Props> = (props: Props) => {
  const { colors } = useTheme();
  const { color = colors.surface5 } = props;
  return <StyledFrame color={color} />;
};

const StyledFrame = styled.div<{ color: string }>`
  height: 100%;
  width: 100%;
  background: ${(props) => props.color};
  border-radius: 2.559px;
`;

export default Frame;
