import React from 'react';
import styled from 'styled-components';

import { DataTest } from 'interfaces/dataTestId';

interface Props extends DataTest {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  width: number;
  height: number;
}

const Switch: React.FC<Props> = ({
  onChange,
  checked,
  width,
  height,
  children,
  'data-testid': dataTestId,
}) => {
  return (
    <SwitchWrapper width={width} height={height}>
      <HiddenCheckbox
        onChange={onChange}
        checked={checked}
        width={width}
        data-testid={dataTestId}
      />
      <Slider width={width} height={height} />
    </SwitchWrapper>
  );
};

const SwitchWrapper = styled.label<{ width: number; height: number }>`
  position: relative;
  display: inline-block;
  width: ${(props) => props.width + 'px'};
  height: ${(props) => props.height + 'px'};
  border-radius: 30px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })<{ width: number }>`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + span::before {
    background-color: white;
    /* transform: translateX(15px); */
    right: 1px;
    top: 1px;
    left: ${(props) => `${props.width / 2 + 1}px`};
  }
  &:checked + span {
    background-color: ${({ theme }) => theme.colors.accentPrimary};
  }
`;

const Slider = styled.span<{ width: number; height: number }>`
  position: absolute;
  cursor: pointer;
  inset: 0;
  transition: 0.4s;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.text200};

  &::before {
    position: absolute;
    content: '';
    height: ${(props) => `calc(${props.height}px - 2px)`};
    width: ${(props) => `calc(${props.height}px - 2px)`};
    box-sizing: border-box;
    left: 1px;
    bottom: 1px;

    transition: 0.4s;
    background-color: white;
    border-radius: 50%;
  }
`;

export default Switch;
