import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Mic, MicOff } from 'components/shared/Icons';
import { Select, Text } from 'components/shared';
import AudioIndicator from 'components/AudioIndicator';

import {
  SectionContainer,
  SectionIconContainer,
  MicVerifyContainer,
  MicRoundWrapper,
} from './styles';
import { Option } from 'components/shared/Select';
import { useMediaSources } from 'hooks/useMediaSources';
import { useServices } from 'hooks/useServices';
import { useTheme } from 'styled-components';

export type MicrophoneSettingsFormValue = {
  audioSource: Option<string>;
};

export const MicrophoneSettings: FC = () => {
  const {
    isUserMediaAllowed,
    mediaStream,
    isStreamChanging,
    devicesByType,
    mediaEnabled: { audio: isMicEnabled },
  } = useMediaSources();
  const { mediaSourcesService } = useServices();

  const { values, setFieldValue } = useFormikContext<MicrophoneSettingsFormValue>();

  const { colors } = useTheme();

  const options = devicesByType.audioinput.length
    ? devicesByType.audioinput
    : [mediaSourcesService.emptyDevice];

  const [audioPreviewStream, setAudioPreviewStream] = useState<MediaStream>();

  useEffect(() => {
    if (!mediaStream || !isMicEnabled) return;

    const mediaStreamCopy = mediaStream.clone();

    // we only want to keep audio tracks in this stream
    mediaStreamCopy.getVideoTracks().forEach((videoTrack) => {
      mediaStreamCopy.removeTrack(videoTrack);
    });

    setAudioPreviewStream(mediaStreamCopy);

    return () => {
      mediaStreamCopy.getTracks().forEach((track) => track.stop());
    };
  }, [mediaStream, isMicEnabled]);

  return (
    <SectionContainer gridArea='mic' style={{ justifyContent: 'space-evenly' }}>
      <SectionIconContainer>
        <Mic width={18} height={18} fill={colors.text300} />
        <Text weight={600} size={14}>
          Mic
        </Text>
      </SectionIconContainer>
      <MicVerifyContainer>
        <MicRoundWrapper>
          {isUserMediaAllowed && isMicEnabled ? (
            <Mic width={15} height={15} fill={colors.text100} />
          ) : (
            <MicOff width={15} height={15} fill={colors.text100} />
          )}
        </MicRoundWrapper>
        <AudioIndicator stream={audioPreviewStream} />
      </MicVerifyContainer>

      <Select
        data-testid='micDropdown'
        isSearchable={false}
        isDisabled={isStreamChanging}
        options={options}
        name='audioSource'
        onChange={(value) => setFieldValue('audioSource', value)}
        value={values.audioSource}
      />
    </SectionContainer>
  );
};
