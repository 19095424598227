import { AlertTriangle, Person } from 'components/shared/Icons';
import { Text } from 'components/shared';
import { SectionContainer, SectionIconContainer, DisplayNameInput, ErrorContainer } from './styles';
import { useFormikContext } from 'formik';
import { useTheme } from 'styled-components';

export type DisplayNameSettingsFormValues = {
  displayName: string;
};

export const displayNameValidateFn = (values: DisplayNameSettingsFormValues) => {
  const MAX_SIZE = 40;
  const { displayName } = values;
  if (!displayName.trim()) return { displayName: 'Please enter a Display Name and try again.' };
  if (displayName.trim().length > MAX_SIZE)
    return {
      displayName: `${MAX_SIZE} character limit reached, please update your Display Name and try again.`,
    };

  return {};
};

export const DisplayNameSettings = () => {
  const { values, errors, handleChange, handleBlur } =
    useFormikContext<DisplayNameSettingsFormValues>();
  const { colors } = useTheme();

  const hasError = Boolean(errors.displayName);
  return (
    <SectionContainer gridArea='displayName' style={{ height: '100%' }}>
      <SectionIconContainer>
        <Person width={10} height={13} fill={colors.text300} />
        <Text weight={600} size={14}>
          Display Name
        </Text>
      </SectionIconContainer>
      <DisplayNameInput
        data-testid='displayName'
        value={values.displayName}
        name='displayName'
        placeholder='Enter a name'
        hasError={hasError}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasError && (
        <ErrorContainer>
          <AlertTriangle width={14} height={9} fill={colors.alertError} />
          <Text color={colors.alertError} size={12} weight={400}>
            {errors.displayName}
          </Text>
        </ErrorContainer>
      )}
    </SectionContainer>
  );
};
