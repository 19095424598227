import { Destination, DestinationDto } from 'interfaces/destinations';

// Maps the client's Destination type into one that maestro-integration will
// accept as a DTO.
// FIXME: this would not be necessary if we agreed on a standard format
// for destination accounts across all services
export function mapClientDestinationToMaestroIntegrationDestinationDto(
  dest: Destination
): DestinationDto {
  return {
    id: dest.id,
    provider: dest.provider.toLocaleLowerCase(),
    account: dest.account,
    accessToken: dest.accessToken,
    expiresAt: dest.expiresAt,
    refreshToken: dest.refreshToken,
    metadata: dest.metadata as any,
    connected: dest.connected,
  };
}
