import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { getSelf } from 'store/slices/participants';

const GUEST_BACKSTAGE_CONTAINER_ID = 'waiting-room-container';

export const resizeGuestBackStage = (widthPerCent: `${number}px`) => {
  const container = document.getElementById(GUEST_BACKSTAGE_CONTAINER_ID);
  if (!container) return;
  container.style.width = widthPerCent;
};

const MobileBackStageSection: React.FC = ({ children }) => {
  const participant = useSelector(getSelf);

  return (
    <MobileBackstageContainer isOnStage={Boolean(participant?.isOnStage)}>
      {children}
    </MobileBackstageContainer>
  );
};

const DesktopBackStageSection: React.FC = ({ children }) => {
  const participant = useSelector(getSelf);

  return (
    <DesktopOuterContainer>
      <DesktopBackStageContainer
        id={GUEST_BACKSTAGE_CONTAINER_ID}
        isOnStage={Boolean(participant?.isOnStage)}
      >
        {children}
      </DesktopBackStageContainer>
    </DesktopOuterContainer>
  );
};

const BackStageSection: React.FC = (props) => {
  if (isMobile) return <MobileBackStageSection {...props} />;
  return <DesktopBackStageSection {...props} />;
};

const DesktopOuterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
`;

const BaseMessageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  min-width: 270px;
  gap: 5px;
`;

export const MobileBackStageMessageContainer = styled(BaseMessageContainer)`
  padding: 16px 32px 0 32px;
`;

export const DesktopBackStageMessageContainer = styled(BaseMessageContainer)`
  padding: 24px;
  /* NOTE: this fixes desktop small window */
  @media (max-width: 599px) {
    padding: 16px;
    min-width: 100px;
    > span:first-of-type {
      font-size: 14px;
    }

    > span:last-of-type {
      font-size: 8px;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    padding: 16px;
    min-width: 100px;
    > span:first-of-type {
      font-size: 22px;
    }

    > span:last-of-type {
      font-size: 16px;
    }
  }
`;

const BaseBackstageContainer = styled.div<{ isOnStage: boolean }>`
  display: flex;
  box-sizing: border-box;
  background-color: ${({ isOnStage, theme }) =>
    isOnStage ? theme.colors.alertWarning : theme.colors.surface6};
`;

const MobileBackstageContainer = styled(BaseBackstageContainer)`
  flex-direction: column;
  width: 100%;
  padding-bottom: ${(props) => (props.isOnStage ? '16px' : 0)};
  ${(props) =>
    props.isOnStage
      ? css`
          margin-bottom: auto;
        `
      : css`
          height: 100%;
        `};
`;

const DesktopBackStageContainer = styled(BaseBackstageContainer)`
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 10px;
  align-items: center;
  /* NOTE: this fixes desktop small window */
  @media (max-width: 599px) {
    min-width: 222.25px;
    height: 100px;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    min-width: 222.25px;
    height: 130px;
  }
`;

export default BackStageSection;
