import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { Text, VideoPlayerV2 } from 'components/shared';
import NoVideoFeedCard from 'views/shared/NoVideoFeedCard';
import { css, useTheme } from 'styled-components';
import { useVideoCardSize } from 'components/VideoCard/useVideoCardSize';
import { TParticipant } from 'interfaces/participants';
import { useBooleanAudioLevel } from 'hooks/useAudioLevel';

type ControlProps = {
  iconSize: number;
  fontSize: number;
};

type Props = {
  id: `${string}:${string}`;
  displayName: TParticipant['displayName'];
  video: TParticipant['video'];
  audio: TParticipant['audio'];
  isPlaying?: boolean;
  isMuted?: boolean;
  isStage: boolean;
  isScreenShare?: boolean;
  isDraggable?: boolean;
  isMirrored?: boolean;
  isSpotlightAndRightColumn?: boolean;
  isSelf?: boolean;
  isLoading?: boolean;
  isBeingDragged?: boolean;
  hideDisplayNames?: boolean;
  shouldDisplayTalkingStatus?: boolean;
  children?: (props: ControlProps) => JSX.Element | null;
  participantId?: string;
  mediaStream?: MediaStream;
};

const TalkingBorder: React.FC<{
  shouldDisplayBorder: boolean;
  isEnabled: boolean;
}> = ({ shouldDisplayBorder, isEnabled }) => {
  const isTalking = useBooleanAudioLevel();

  return <StyledTalkingBorder isTalking={isEnabled && shouldDisplayBorder && isTalking} />;
};

const BaseVideoCard: React.FC<Props> = ({
  id,
  isPlaying,
  displayName,
  video,
  audio,
  isSelf = false,
  hideDisplayNames = false,
  isBeingDragged = false,
  isScreenShare = false,
  isMirrored = false,
  isDraggable = false,
  isStage = false,
  shouldDisplayTalkingStatus = false,
  children,
  participantId,
  mediaStream,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const showDisplayName = !hideDisplayNames && !isScreenShare;

  const { fontSize, iconSize } = useVideoCardSize(containerRef);

  const { colors } = useTheme();

  const isMuted = props.isMuted || isSelf || !audio.isEnabled;

  return (
    <Container ref={containerRef} id={id}>
      <DraggedContainer isBeingDragged={isBeingDragged}>
        <VideoPlayerV2
          isStage={isStage}
          playing={isPlaying}
          muted={isMuted}
          isScreenShare={isScreenShare}
          mirrored={isMirrored}
          participantId={participantId}
          mediaStream={mediaStream}
        />
        <NoVideoFeedCard displayName={displayName} show={!video.isEnabled} />
      </DraggedContainer>
      <ControlsContainer iconSize={iconSize}>
        {children ? children({ iconSize, fontSize }) : null}
      </ControlsContainer>

      {showDisplayName && (
        <DisplayName fontSize={fontSize}>
          <Text size={fontSize} weight={600} color={colors.text100}>
            {isSelf ? 'You' : displayName}
          </Text>
        </DisplayName>
      )}

      <VideoBorder isDraggable={isDraggable} isBeingDragged={isBeingDragged}>
        <TalkingBorder
          isEnabled={audio.isEnabled}
          shouldDisplayBorder={shouldDisplayTalkingStatus}
        />
      </VideoBorder>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  user-select: none;
  border-radius: 10px;
  overflow: hidden;
`;

const DraggedContainer = styled.div<{ isBeingDragged: boolean }>`
  width: 100%;
  height: 100%;
  filter: ${(props) => (props.isBeingDragged ? 'brightness(20%)' : 'brightness(100%)')};
`;

const DisplayName = styled.div<{ fontSize: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  font-size: ${(props) => props.fontSize}px;
  box-sizing: border-box;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 85%;
  color: white;
  > span {
    padding-left: 0.5em;
  }

  ::before {
    content: '';
    width: 4px;
    min-width: 4px;
    height: 1.9em;
    background-color: ${({ theme }) => theme.colors.alertConfirmation};
  }
`;

const VideoBorder = styled.div<{ isBeingDragged: boolean; isDraggable: boolean }>`
  position: absolute;
  top: 0;
  border-radius: 10px;
  transition: all 200ms cubic-bezier(0.4, 0, 1, 1);
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  ${(props) => {
    if (props.isDraggable)
      return css<{ isBeingDragged: boolean; isDraggable: boolean }>`
        :hover {
          border: ${(props) =>
            props.isBeingDragged
              ? `2px solid ${props.theme.colors.accentPrimary}`
              : '2px solid white'};
        }
      `;
  }}
`;

const StyledTalkingBorder = styled.div<{ isTalking: boolean }>`
  opacity: ${(props) => (props.isTalking ? 1 : 0)};
  border-radius: 10px;
  border: ${(props) => `2px solid ${props.theme.colors.accentPrimary}`};
  transition: opacity 200ms cubic-bezier(0.4, 0, 1, 1);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const pulse = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
`;

const ControlsContainer = styled.div<{ iconSize: number }>`
  position: absolute;
  font-size: ${(props) => props.iconSize}px;
  top: 0.5em;
  right: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  div:first-child > button {
    ::before,
    ::after {
      content: '';
      position: absolute;
      border-radius: 50%;
      left: -5px;
      right: -5px;
      top: -5px;
      bottom: -5px;
      border: 1px solid ${(props) => props.theme.colors.accentPrimary};
      animation: ${pulse} 1.5s linear infinite;
    }

    ::after {
      border-width: 2px;
      animation-delay: 0.5s;
    }
  }

  z-index: 3;
`;

export default BaseVideoCard;
