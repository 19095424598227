import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'components/shared';

interface Props {
  show: boolean;
  displayName: string;
  small?: boolean;
  isSpotlightAndRightColumn?: boolean;
}

const NoVideoFeedCard: React.FC<Props> = ({
  show,
  displayName,
  small,
  isSpotlightAndRightColumn,
}) => {
  const getInitials = (name: string) => {
    return name
      .split(/\s/)
      .map((e) => e[0] && e[0].toUpperCase())
      .join('')
      .substring(0, 3);
  };

  if (!show) {
    return null;
  }

  return (
    <NoVideoFeedContainer>
      <NoFeedLabel small={small} isSpotlightAndRightColumn={isSpotlightAndRightColumn}>
        <Text size={16} weight={700} color='#BBBBBB'>
          {getInitials(displayName)}
        </Text>
      </NoFeedLabel>
    </NoVideoFeedContainer>
  );
};

const NoVideoFeedContainer = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: #222222;
  //background-color: ${(props) => props.theme.colors.surface5};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-sizing: border-box;
`;

const NoFeedLabel = styled.div<{
  small: boolean | undefined;
  isSpotlightAndRightColumn: boolean | undefined;
}>`
  box-sizing: border-box;
  border-radius: 50%;
  width: ${(props) => (props.isSpotlightAndRightColumn ? '5.5vh' : '8vh')};
  height: ${(props) => (props.isSpotlightAndRightColumn ? '5.5vh' : '8vh')};
  max-width: 120px;
  max-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: ${(props) => props.theme.colors.accentPrimary};
  background-color: #555555;
`;

export default NoVideoFeedCard;
