import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import DraggableItem from 'views/Host/DraggableItem';
import { getHideDisplayNames, getSessionIsHost } from 'store/slices/session';
import { getPeerBeingDragged, openModal } from 'store/slices/ui';
import { DRAG_AREAS } from 'constants/areas';
import { MODALS } from 'constants/modals';
import { useParticipantById } from 'hooks/useParticipantById';
import BaseVideoCard from 'components/VideoCard/BaseVideoCard';
import { PersonArrowDown } from 'components/shared/Icons';
import { HoverContainer } from 'components/VideoCard/styles';

interface Props {
  id: string;
}

const BackstageVideoCard: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const hideDisplayNames = useSelector(getHideDisplayNames);
  const isHost = useSelector(getSessionIsHost);
  const peerBeingDragged = useSelector(getPeerBeingDragged);

  const participant = useParticipantById(id);

  const { colors } = useTheme();

  const handleKickUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (!participant) return;
    dispatch(
      openModal({
        component: MODALS.KICK_GUEST,
        props: {
          userId: participant.id,
          displayName: participant.displayName,
          backgroundColor: colors.surface5,
        },
        hideBackdrop: true,
      })
    );
  };

  if (!participant) return <></>;

  return (
    <DraggableItem
      id={participant.id}
      area={DRAG_AREAS.WAITING}
      dragEnabled={isHost}
      data-testid='draggableItem'
    >
      <BackstageHoverContainer>
        <BaseVideoCard
          id={`backstage:${participant.id}`}
          displayName={participant.displayName}
          video={participant.video}
          audio={participant.audio}
          isDraggable={true}
          isMuted={true}
          isBeingDragged={peerBeingDragged?.id === participant.id}
          hideDisplayNames={hideDisplayNames}
          isScreenShare={participant.isScreenShare}
          isSelf={participant.isSelf}
          isMirrored={!participant.isIngress}
          isStage={false}
          shouldDisplayTalkingStatus={false}
          participantId={id}
        >
          {({ iconSize }) =>
            participant.isSelf ? null : (
              <KickButtonContainer iconSize={iconSize} onClick={handleKickUser}>
                <PersonArrowDown
                  width={1.7 * iconSize}
                  height={1.7 * iconSize}
                  fill={colors.text100}
                />
              </KickButtonContainer>
            )
          }
        </BaseVideoCard>
      </BackstageHoverContainer>
    </DraggableItem>
  );
};

const BackstageHoverContainer = styled(HoverContainer)`
  width: 147px;
  height: 140px;
`;

const KickButtonContainer = styled.button<{ iconSize: number }>`
  background: rgba(0, 0, 0, 0.7);
  font-size: ${(props) => props.iconSize};
  width: 2em;
  height: 2em;
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  display: none;
  place-items: center;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  z-index: 2;
  justify-content: center;
  path:first-child {
    fill: red;
  }

  :active {
    transform: translate(1px, 1px);
  }
  :hover {
    background-color: red;
    path {
      fill: white;
    }
  }
`;

export default BackstageVideoCard;
