import axios from 'axios';

import {
  ICreateDestinationObjectParams,
  IDestinationAuthStrategy,
  IGetDestinationInfoParams,
  IOpenLoginPopupParams,
  IYoutubeLiveStreamingError,
  TBuildRtmpParams,
  TPopupAuthPayload,
} from './interfaces';
import { REACT_APP_DESTINATION_REDIRECT_URL, REACT_APP_YOUTUBE_CLIENT_ID } from 'config';
import { Destination, PROVIDERS, StatusType, YouTubeFormValues } from 'interfaces/destinations';
import { openPopup } from 'utils/popup';
import { CustomError, isCustomError, ServiceResponse } from 'utils/http-response-handler';
import { getTimeDiffInHours } from 'utils/destination';

const SCOPES = [
  'https://www.googleapis.com/auth/youtube',
  'https://www.googleapis.com/auth/youtube.readonly',
];

export default class YoutubeStrategy implements IDestinationAuthStrategy {
  private readonly clientId;
  private readonly redirectUri = encodeURIComponent(
    REACT_APP_DESTINATION_REDIRECT_URL + '/popup_redirect/youtube'
  );
  private readonly scopes = encodeURIComponent(SCOPES.join(' '));
  private readonly baseUrl = 'https://www.googleapis.com/youtube/v3';

  constructor(clientId?: string) {
    this.clientId = encodeURIComponent(clientId || REACT_APP_YOUTUBE_CLIENT_ID);
  }

  openLoginPopup(params: IOpenLoginPopupParams): Window | null {
    const encodedState = encodeURIComponent(params.state);
    const uri =
      `https://accounts.google.com/o/oauth2/auth` +
      `?response_type=code` +
      `&client_id=${this.clientId}` +
      `&redirect_uri=${this.redirectUri}` +
      `&scope=${this.scopes}` +
      `&state=${encodedState}` +
      `&access_type=offline` +
      `&prompt=consent`; // https://github.com/googleapis/google-api-nodejs-client/issues/750
    //TODO: force select_account?

    return openPopup(uri, 'YouTube Authentication');
  }

  getResponseFromLocationUrl(): TPopupAuthPayload {
    const query = new URLSearchParams(window.location.search);
    const { code, state, error } = Object.fromEntries(query.entries());

    if (code && state) return { status: 'success', code, state };
    return { status: 'error', state, error };
  }

  async createDestinationObject(
    params: ICreateDestinationObjectParams
  ): Promise<ServiceResponse<Destination>> {
    try {
      const { id, accessToken, refreshToken, expiresAt } = params;
      const res = await this.fetchChannelInfo(params.accessToken);
      const destination: Destination = {
        id,
        provider: PROVIDERS.youtube,
        status: StatusType.connection_successful,
        account: res.data.title,
        imgUrl: res.data.imgUrl,
        accessToken,
        expiresAt,
        refreshToken,
        metadata: params.metadata,
        data: {
          channelId: res.data.channelId,
        },
        connected: true,
        rtmp: {
          rtmpUrl: '',
          streamKey: '',
        },
      };
      return { data: destination };
    } catch (error) {
      if (isCustomError(error)) {
        return { error };
      }
      return { error: CustomError('UNHANDLED_ERROR', error) };
    }
  }

  async getDestinationInfo(
    params: IGetDestinationInfoParams
  ): Promise<ServiceResponse<Destination>> {
    try {
      const { destination } = params;
      const {
        data: { channelId, title, imgUrl },
      } = await this.fetchChannelInfo(destination.accessToken);

      const data = {
        ...destination,
        account: title,
        imgUrl,
        data: {
          ...destination?.data,
          channelId,
        },
      } as Destination;

      return { data };
    } catch (error) {
      if (isCustomError(error)) {
        return { error };
      }
      return { error: CustomError('UNHANDLED_ERROR', error) };
    }
  }

  async buildRtmp({ accessToken, ...params }: TBuildRtmpParams) {
    const metadata = params.metadata as YouTubeFormValues;
    const {
      data: { cdn, id: liveStreamId },
    } = await axios.post<CreateLiveStreamResponse>(
      `${this.baseUrl}/liveStreams`,
      {
        snippet: {
          title: metadata.title,
          description: metadata.description,
        },
        cdn: {
          // TODO: parameterize these
          resolution: '720p',
          frameRate: '30fps',
          ingestionType: 'rtmp',
        },
      },
      {
        params: { part: 'id,snippet,cdn,contentDetails,status' },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    const {
      data: { id: broadcastId },
    } = await axios.post<CreateLiveBroadcastResponse>(
      `${this.baseUrl}/liveBroadcasts`,
      {
        snippet: {
          title: metadata.title,
          scheduledStartTime: new Date().toISOString(),
          description: metadata.description,
        },
        status: { privacyStatus: metadata.visibility },
        contentDetails: { enableAutoStart: true, enableAutoStop: true },
      },
      {
        params: { part: 'id,snippet,contentDetails,status' },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    const {
      ingestionInfo: { ingestionAddress: rtmpUrl, streamName: streamKey },
    } = cdn;

    await axios.post<CreateLiveBroadcastResponse>(
      `${this.baseUrl}/liveBroadcasts/bind`,
      {},
      {
        params: { part: 'id,contentDetails', id: broadcastId, streamId: liveStreamId },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    return {
      rtmpUrl,
      streamKey,
    };
  }

  private async fetchChannelInfo(accessToken: string) {
    try {
      // You can find this route at https://developers.google.com/youtube/v3/docs/channels/list
      // This response should be of type Channel Resource https://developers.google.com/youtube/v3/docs/channels#resource
      const { data: channelData } = await axios.get(`${this.baseUrl}/channels`, {
        params: { part: 'snippet', mine: true },
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (channelData.pageInfo?.totalResults === 0) {
        throw CustomError('YOUTUBE_NO_CHANNEL_LINKED');
      }
      const timeDiff = getTimeDiffInHours(
        new Date(channelData.items[0].snippet.publishedAt).valueOf(),
        Date.now()
      );
      if (timeDiff < 24) {
        throw CustomError('YOUTUBE_24H_WAIT');
      }

      // https://developers.google.com/youtube/v3/live/docs/liveStreams/list?
      // NOTE: this is a bit hacky. The liveStreams endpoint will error out if you don't have livestream enabled.
      // So what we are doing is to check if this call errors out or not
      await axios.get(`${this.baseUrl}/liveStreams`, {
        params: { part: 'status', mine: true, maxResults: 0 },
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      // TODO: we let it throw just so the pattern is the same from the other strategies until we decide on https://maestrojira.atlassian.net/browse/NS-4951

      const title = channelData.items[0].snippet.title as string;
      const imgUrl = channelData.items[0].snippet.thumbnails.default.url as string;
      const channelId = channelData.items[0].id;
      return { data: { expiresAt: 0, title, imgUrl, channelId } };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.error) {
          const err = error.response.data.error as IYoutubeLiveStreamingError;
          // NOTE: we could also just check if error.message is "The user is not enabled for live streaming"
          if (err.errors.find((e) => e.reason === 'liveStreamingNotEnabled')) {
            throw CustomError('YOUTUBE_LIVESTREAMING_NOT_ENABLED');
          }
        }
        throw CustomError('UNHANDLED_ERROR');
      }
      throw error;
    }
  }
}

type CreateLiveStreamResponse = {
  kind: 'youtube#liveStream';
  etag: string;
  id: string;
  snippet: {
    publishedAt: Date;
    channelId: string;
    title: string;
    description: string;
    isDefaultStream: boolean;
  };
  cdn: {
    ingestionType: string;
    ingestionInfo: {
      streamName: string;
      ingestionAddress: string;
      backupIngestionAddress: string;
    };
    resolution: string;
    frameRate: string;
  };
  status: {
    streamStatus: string;
    healthStatus: {
      status: string;
      lastUpdateTimeSeconds: number;
      configurationIssues: [
        {
          type: string;
          severity: string;
          reason: string;
          description: string;
        }
      ];
    };
  };
  contentDetails: {
    closedCaptionsIngestionUrl: string;
    isReusable: boolean;
  };
};

type CreateLiveBroadcastResponse = {
  kind: 'youtube#liveBroadcast';
  etag: string;
  id: string;
  snippet: {
    publishedAt: Date;
    channelId: string;
    title: string;
    description: string;
    thumbnails: {
      [key: string]: {
        url: string;
        width: number;
        height: number;
      };
    };
    scheduledStartTime: Date;
    scheduledEndTime: Date;
    actualStartTime: Date;
    actualEndTime: Date;
    isDefaultBroadcast: boolean;
    liveChatId: string;
  };
  status: {
    lifeCycleStatus: string;
    privacyStatus: string;
    recordingStatus: string;
    madeForKids: string;
    selfDeclaredMadeForKids: string;
  };
  contentDetails: {
    boundStreamId: string;
    boundStreamLastUpdateTimeMs: Date;
    monitorStream: {
      enableMonitorStream: boolean;
      broadcastStreamDelayMs: number;
      embedHtml: string;
    };
    enableEmbed: boolean;
    enableDvr: boolean;
    enableContentEncryption: boolean;
    startWithSlate: boolean;
    recordFromStart: boolean;
    enableClosedCaptions: boolean;
    closedCaptionsType: string;
    projection: string;
    enableLowLatency: boolean;
    latencyPreference: boolean;
    enableAutoStart: boolean;
    enableAutoStop: boolean;
  };
  statistics: {
    totalChatCount: number;
  };
};
