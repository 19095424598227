import jwtDecode from 'jwt-decode';

export const decodeJwt = jwtDecode;

export function safeDecodeJwt<T = unknown>(token: string): T | null {
  try {
    return jwtDecode<T>(token);
  } catch {
    return null;
  }
}
