import type { RootState } from 'store/slices';
import type { ISessionService } from './SessionService';
import { getSessionId, getSessionIsLive } from 'store/slices/session';
import { onStopStudioBroadcast, postMessageBroadcastStalled } from 'store/core/maestro-messages';

type SomeStore = {
  getState: () => RootState;
  dispatch: <T>(action: T) => unknown;
};

const POLL_INTERVAL_MS = 5000;

export class SessionMonitor {
  private store: SomeStore;
  private sessionService: ISessionService;
  private isPolling = false;
  private pollTimeout: NodeJS.Timeout | null = null;
  private sessionKillScheduled = false;

  constructor(sessionService: ISessionService, store: SomeStore) {
    this.sessionService = sessionService;
    this.store = store;
  }

  private async pollLoop() {
    if (this.pollTimeout !== null) {
      clearTimeout(this.pollTimeout);
    }

    if (!this.isPolling) {
      return;
    }

    await (async () => {
      try {
        const state = this.store.getState();
        const sessionId = getSessionId(state);
        const published = getSessionIsLive(state);

        if (!sessionId || !published) {
          return;
        }

        const { isLive } = await this.sessionService.pollStatus(sessionId);

        if (isLive) {
          this.sessionKillScheduled = false;
          return;
        }

        if (this.sessionKillScheduled) {
          this.sessionKillScheduled = false;
          this.store.dispatch(onStopStudioBroadcast());
          postMessageBroadcastStalled();
        } else {
          console.log('Session is dead. Going offline on next tick...');
          this.sessionKillScheduled = true;
        }
      } catch (error) {
        console.error('Failure when polling session:', error);
      }
    })();

    this.pollTimeout = setTimeout(() => this.pollLoop(), POLL_INTERVAL_MS);
  }

  startPolling() {
    if (this.isPolling) {
      return;
    }

    this.isPolling = true;
    this.pollLoop();
  }

  stopPolling() {
    this.isPolling = false;
  }

  destructor() {
    this.stopPolling();
  }
}
