import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import LayoutIcon from 'components/LayoutIcons';
import { PolygonDown } from 'components/shared/Icons';
import { FULL_LAYOUT_VALID_COUNT, Layout, LAYOUTS } from 'constants/layouts';
import {
  getIsDrawerPanelVisible,
  getPeerScreenShareId,
  getPeerSoloViewId,
  setIsDrawerPanelVisible,
  setPeerSoloViewId,
} from 'store/slices/ui';
import { getSessionId, getSessionLayout, setSessionLayout } from 'store/slices/session';
import { Text } from 'components/shared';
import useFilteredParticipants from 'hooks/useFilteredParticipants';
import { useServices } from 'hooks/useServices';
import { useReactiveRoom } from 'contexts/LiveKitContext';

const LayoutsPanel: React.FC = () => {
  const dispatch = useDispatch();
  const layout = useSelector(getSessionLayout);
  const isDrawerPanelsVisible = useSelector(getIsDrawerPanelVisible);
  const peerScreenShareId = useSelector(getPeerScreenShareId);
  const peerSoloViewId = useSelector(getPeerSoloViewId);
  const { stage } = useFilteredParticipants();
  const { sessionService } = useServices();
  const sessionId = useSelector(getSessionId);
  const { metadata } = useReactiveRoom();

  const selectedLayout = peerSoloViewId ?? layout;

  // TODO: animation is missing
  if (!isDrawerPanelsVisible) {
    return null;
  }

  const isFullLayoutDisabled = !FULL_LAYOUT_VALID_COUNT.includes(stage.length);
  const isLayoutChangeDisabled = Boolean(stage.length < 2 || peerScreenShareId);

  const handleChangeLayout = (layout: Layout) => {
    const newMetadata = { ...metadata };

    if (peerSoloViewId !== null) {
      dispatch(setPeerSoloViewId(null));
      newMetadata.peerSoloViewId = undefined;
    }

    newMetadata.layout = layout;
    dispatch(setSessionLayout(layout));
    dispatch(setIsDrawerPanelVisible(false));

    sessionService.updateRoomMetadata(sessionId, newMetadata);
  };

  return (
    <Container>
      <InnerContainer
        isFullLayoutDisabled={isFullLayoutDisabled}
        isScreenshare={Boolean(peerScreenShareId)}
      >
        <PillContainer active={isLayoutChangeDisabled}>
          <Pill>
            <Text size={14} weight={500} textAlign='center'>
              {peerScreenShareId
                ? 'More screen share layouts coming soon!'
                : 'Add guests to enable layouts'}
            </Text>
          </Pill>
        </PillContainer>
        {peerScreenShareId ? (
          <>
            <LayoutIcon
              layout={LAYOUTS.SCREEN_SHARE_1}
              active={selectedLayout === LAYOUTS.SCREEN_SHARE_1}
              onClick={handleChangeLayout}
              disabled={isLayoutChangeDisabled}
            />
            <LayoutIcon
              layout={LAYOUTS.SCREEN_SHARE_2}
              active={selectedLayout === LAYOUTS.SCREEN_SHARE_2}
              onClick={handleChangeLayout}
              disabled={isLayoutChangeDisabled}
            />
            <LayoutIcon
              layout={LAYOUTS.SCREEN_SHARE_3}
              active={selectedLayout === LAYOUTS.SCREEN_SHARE_3}
              onClick={handleChangeLayout}
              disabled={isLayoutChangeDisabled}
            />
          </>
        ) : (
          <>
            {FULL_LAYOUT_VALID_COUNT.includes(stage.length) && (
              <LayoutIcon
                data-testid='fullLayoutButton'
                layout={LAYOUTS.FULL}
                active={selectedLayout === LAYOUTS.FULL}
                onClick={handleChangeLayout}
                disabled={isLayoutChangeDisabled}
              />
            )}
            <LayoutIcon
              data-testid='landscapeLayoutButton'
              layout={LAYOUTS.LANDSCAPE}
              active={selectedLayout === LAYOUTS.LANDSCAPE}
              onClick={handleChangeLayout}
              disabled={isLayoutChangeDisabled}
            />
            <LayoutIcon
              data-testid='spotlightLayoutButton'
              layout={LAYOUTS.SPOTLIGHT}
              active={selectedLayout === LAYOUTS.SPOTLIGHT}
              onClick={handleChangeLayout}
              disabled={isLayoutChangeDisabled}
            />
          </>
        )}
      </InnerContainer>
      <PolygonDown />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const InnerContainer = styled.div<{ isFullLayoutDisabled: boolean; isScreenshare?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isFullLayoutDisabled
      ? `repeat(${props.isScreenshare ? '4' : '2'}, 1fr)`
      : 'repeat(3, 1fr)'};
  height: 100%;
  background-color: ${(props) => props.theme.colors.surface4};
  border-radius: 4px;
  gap: 10px;
  padding: 13.1391px;
  box-sizing: border-box;
  width: fit-content;
`;

const PillContainer = styled.div<{ active: boolean }>`
  display: ${(props) => (props.active ? 'block' : 'none')};
  position: absolute;
  left: 48%;
  top: 47%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: fit-content;
  height: 50px;
`;

const Pill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.surface2};
  border-radius: 10px;
  padding: 0px 10px;
`;

export default LayoutsPanel;
