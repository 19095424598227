import axios, { AxiosError, AxiosResponse } from 'axios';
import { DestinationErrorType, DESTINATION_ERROR } from 'interfaces/destinations';

export type CustomErrorType = { type: DestinationErrorType; data?: unknown };

export type ServiceResponse<T> = {
  data?: T;
  error?: Error | AxiosError<T> | CustomErrorType | unknown;
};
export function handleResponse(response: AxiosResponse<any>) {
  if (response.data) {
    return response.data;
  }
  return response;
}

export function handleError(error: AxiosError<any>) {
  //TODO: identify error codes with messages;
  throw error;
}

export const CustomError = (type: DestinationErrorType, data?: unknown) => {
  if (axios.isAxiosError(data)) return { type, data: data.response?.data };
  return { type, data };
};

export const isCustomError = (error: any): error is CustomErrorType => {
  if (error.type) {
    return Object.keys(DESTINATION_ERROR).some((errorType) => errorType === error.type);
  }
  return false;
};
