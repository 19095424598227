import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { Cam, CamOff, Mic, MicOff } from 'components/shared/Icons';
import { LoadingBouncer, Select, Text } from 'components/shared';
import mediaNotAllowed from 'assets/media_not_allowed.png';
import {
  SectionIconContainer,
  SectionContainer,
  VideoWrapper1,
  VideoWrapper2,
  VideoWrapper3,
  WebcamOption,
  WebcamOptionsContainer,
  MediaOptionsContainer,
  MediaOptionsButton,
} from './styles';
import { Option } from 'components/shared/Select';
import BaseVideoCard from 'components/VideoCard/BaseVideoCard';
import { useMediaSources } from 'hooks/useMediaSources';
import { useServices } from 'hooks/useServices';
import { useTheme } from 'styled-components';

export type CameraSettingsFormValues = {
  videoSource: Option<string>;
  videoResolution: Option<{
    deviceId: string;
    aspectRatio: W3C.ConstrainDouble;
    width: W3C.ConstrainLong;
    height: W3C.ConstrainLong;
  }>;
};

export type SettingsType = 'tour' | 'init' | 'config';

interface CameraSettingsProps {
  type: SettingsType;
}

export const CameraSettings: FC<CameraSettingsProps> = ({ type }) => {
  return (
    <SectionContainer gridArea='video' style={{ height: '100%' }}>
      <CameraPreview />
      <CameraOptions />
      {type === 'init' && <MediaOptions />}
    </SectionContainer>
  );
};

const CameraPreview: FC = () => {
  const { values } = useFormikContext<any>();
  const { displayName, enableDisplayNames } = values;

  const { isUserMediaAllowed, mediaStream, mediaEnabled } = useMediaSources();
  const { colors } = useTheme();

  const [webcamPreviewStream, setWebcamPreviewStream] = useState<MediaStream>();

  useEffect(() => {
    if (!mediaStream || !mediaEnabled.video) return;

    const mediaStreamCopy = mediaStream.clone();

    // we only want to keep video tracks in this stream
    mediaStreamCopy.getAudioTracks().forEach((audioTrack) => {
      mediaStreamCopy.removeTrack(audioTrack);
    });

    setWebcamPreviewStream(mediaStreamCopy);

    return () => {
      mediaStreamCopy.getTracks().forEach((track) => track.stop());
    };
  }, [mediaStream, mediaEnabled.video]);

  return (
    <>
      <SectionIconContainer>
        <Cam width={15} height={15} fill={colors.text300} stroke={colors.text300} />
        <Text weight={600} size={14}>
          Camera
        </Text>
      </SectionIconContainer>
      <VideoWrapper1>
        <VideoWrapper2>
          <VideoWrapper3>
            {isUserMediaAllowed ? (
              !mediaStream ? (
                <LoadingBouncer />
              ) : (
                <BaseVideoCard
                  isStage={true}
                  id={'settings:local-id'}
                  video={{ isEnabled: mediaEnabled.video, isAllowed: true }}
                  audio={{ isEnabled: false, isAllowed: true }}
                  displayName={displayName}
                  hideDisplayNames={!enableDisplayNames}
                  isMirrored={true}
                  shouldDisplayTalkingStatus={false}
                  mediaStream={webcamPreviewStream}
                />
              )
            ) : (
              <img src={mediaNotAllowed} alt='Webcam not allowed' />
            )}
          </VideoWrapper3>
        </VideoWrapper2>
      </VideoWrapper1>
    </>
  );
};

const CameraOptions: FC = () => {
  const {
    isStreamChanging,
    devicesByType: { videoinput: videoSources },
  } = useMediaSources();
  const { mediaSourcesService } = useServices();
  const { values, setFieldValue } = useFormikContext<CameraSettingsFormValues>();

  const options = videoSources.length ? videoSources : [mediaSourcesService.emptyDevice];

  return (
    <WebcamOptionsContainer>
      <WebcamOption>
        <Select
          data-testid='videoDropdown'
          isSearchable={false}
          isDisabled={isStreamChanging}
          options={options}
          name='videoSource'
          onChange={(value) => setFieldValue('videoSource', value)}
          value={values.videoSource}
        />
      </WebcamOption>
    </WebcamOptionsContainer>
  );
};

const MediaOptions: FC = () => {
  const { mediaEnabled, setMediaEnabled } = useMediaSources();

  const toggleAudio = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setMediaEnabled((state) => ({ ...state, audio: !state.audio }));
  };

  const toggleVideo = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setMediaEnabled((state) => ({ ...state, video: !state.video }));
  };

  return (
    <MediaOptionsContainer>
      <MediaOptionsButton type='button' data-testid='audioButton' onClick={toggleAudio}>
        {mediaEnabled.audio ? (
          <Mic width={24} height={24} fill='white' />
        ) : (
          <MicOff width={24} height={24} fill='white' />
        )}
      </MediaOptionsButton>
      <MediaOptionsButton type='button' data-testid='videoButton' onClick={toggleVideo}>
        {mediaEnabled.video ? (
          <Cam width={24} height={24} fill='white' />
        ) : (
          <CamOff width={24} height={24} fill='white' />
        )}
      </MediaOptionsButton>
    </MediaOptionsContainer>
  );
};
