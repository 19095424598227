import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { onMaestroMessageReceived } from 'store/core/maestro-messages';

//NOTE: I don't think this code has much use being a react "hook"
const useMaestroMessages = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleReceivedMessage = (message: MessageEvent) => {
      if (typeof message.data === 'object') {
        dispatch(onMaestroMessageReceived({ data: message.data }));
      }
    };
    window.addEventListener('message', handleReceivedMessage, false);
    return () => {
      window.removeEventListener('message', handleReceivedMessage);
    };
  }, [dispatch]);
};

export default useMaestroMessages;
