import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

import GuestStageVideoCard from 'views/Guest/GuestStageVideoCard';
import { calculatePeerPosition } from 'views/shared/Stage/layouts';
import { getSessionLayout } from 'store/slices/session';
import {
  getIsLoadingSkeletonActive,
  getPeerScreenShareId,
  getPeerSoloViewId,
} from 'store/slices/ui';
import { LAYOUTS } from 'constants/layouts';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import useFilteredParticipants from 'hooks/useFilteredParticipants';

const StageArea: React.FC<{ borderless?: boolean }> = ({ borderless }) => {
  const layout = useSelector(getSessionLayout);
  const { stage } = useFilteredParticipants();
  const isLoadingSkeletonActive = useSelector(getIsLoadingSkeletonActive);
  const peerSoloViewId = useSelector(getPeerSoloViewId);
  const peerScreenShareId = useSelector(getPeerScreenShareId);

  const { videoConfigs, metadata } = useMemo(
    () =>
      calculatePeerPosition({
        layout,
        count: stage.length,
        peersIds: stage.map((participant) => participant.id),
        peerInSoloView: peerSoloViewId,
        isBeingDragged: false,
        peerScreenShareId,
      }),
    [layout, stage, peerSoloViewId, peerScreenShareId]
  );

  return (
    <Container borderless={borderless || isLoadingSkeletonActive}>
      <StageContainer>
        {stage.map((participant, idx: number) => (
          <PeerContainer key={participant.id} style={videoConfigs[participant.id]}>
            <GuestStageVideoCard
              idx={idx}
              id={participant.id}
              isSpotlight={metadata.overriddenLayout === LAYOUTS.SPOTLIGHT}
              shouldDisplayTalkingStatus={stage.length > 2}
            />
          </PeerContainer>
        ))}
      </StageContainer>
    </Container>
  );
};

const Container = styled.div<{ borderless?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  box-sizing: border-box;
  transition: width, height 0.1ms;
  aspect-ratio: 16 / 9;
  height: min(100%, calc(9 * 100cqw / 16));
  border: ${(props) =>
    props.borderless || isMobile ? 'none' : `1px solid ${props.theme.colors.accentPrimary}`};
  border-radius: 10px;
`;

const PeerContainer = styled.div``;

const StageContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  transition: all 0.5s cubic-bezier(0.01, 0.93, 0.45, 1.13);
`;

export default StageArea;
