import { Destination, PROVIDERS } from 'interfaces/destinations';
import styled from 'styled-components';
import FacebookForm from './FacebookForm';
import TwitchForm from './TwitchForm';
import YouTubeForm from './YouTubeForm';

interface Props {
  body: boolean;
  destination: Destination;
  isFormOpen: boolean;
  handleFormToggle: Function;
}

const FormChoice: React.FC<Props> = ({ body, destination, isFormOpen, handleFormToggle }) => {
  const Forms: any = {
    [PROVIDERS.facebook]: FacebookForm,
    [PROVIDERS.youtube]: YouTubeForm,
    [PROVIDERS.twitch]: TwitchForm,
  };

  if (!isFormOpen || !body) return null;

  const FormElement = Forms[destination.provider];
  if (FormElement) {
    return (
      <Body>
        <FormElement destination={destination} onClose={handleFormToggle} />
      </Body>
    );
  } else {
    return null;
  }
};

const Body = styled.div`
  color: white;
  margin-top: 15px;
`;

export default FormChoice;
