import { Destination, PROVIDERS } from 'interfaces/destinations';
import { SiteConfigDto } from 'interfaces/maestro-integration';
import { ServiceResponse } from 'utils/http-response-handler';
import FacebookStrategy from './FacebookStrategy';
import {
  ICreateDestinationObjectParams,
  IDestinationAuthContext,
  IDestinationAuthStrategy,
  IGetDestinationInfoParams,
  IOpenLoginPopupParams,
  TBuildRtmpParams,
  TPopupAuthPayload,
  TStrategies,
} from './interfaces';
import TwitchStrategy from './TwitchStrategy';
import YoutubeStrategy from './YoutubeStrategy';

export default class DestinationAuthService implements IDestinationAuthContext {
  private readonly strategies: Record<TStrategies, IDestinationAuthStrategy>;

  constructor(config?: SiteConfigDto['data']['destinations']['providers']) {
    this.strategies = {
      [PROVIDERS.facebook]: new FacebookStrategy(config?.facebook.clientId),
      [PROVIDERS.youtube]: new YoutubeStrategy(config?.youtube.clientId),
      [PROVIDERS.twitch]: new TwitchStrategy(config?.twitch.clientId),
    };
  }

  // Workaround found at https://stackoverflow.com/a/48240128
  openLoginPopup(strategy: TStrategies, params: IOpenLoginPopupParams): Window | null {
    return this.strategies[strategy].openLoginPopup(params);
  }

  getResponseFromLocationUrl(strategy: TStrategies): TPopupAuthPayload | null {
    return this.strategies[strategy].getResponseFromLocationUrl();
  }

  async createDestinationObject(
    strategy: TStrategies,
    params: ICreateDestinationObjectParams
  ): Promise<ServiceResponse<Destination>> {
    return this.strategies[strategy].createDestinationObject(params);
  }

  async getDestinationInfo(
    strategy: TStrategies,
    params: IGetDestinationInfoParams
  ): Promise<ServiceResponse<Destination>> {
    return this.strategies[strategy].getDestinationInfo(params);
  }

  async buildRtmp(strategy: TStrategies, account: TBuildRtmpParams) {
    return this.strategies[strategy].buildRtmp(account);
  }
}
