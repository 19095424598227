import React, { useEffect, useState } from 'react';
import styled, { keyframes, useTheme } from 'styled-components';

import { Destination, GroupedDestinations, PROVIDERS } from 'interfaces/destinations';
import { Text } from 'components/shared';
import DestinationIcon from './DestinationIcon';
import { flattenDestinations, fmtDestName } from 'utils/destination';
import { DefaultDestinationName } from 'components/WhiteLabel/DefaultDestination';

interface Props {
  destinations: GroupedDestinations;
}

const DestinationMenuHover: React.FC<Props> = ({ destinations }) => {
  const { colors } = useTheme();

  const [currentStatus, setCurrentStatus] = useState('ready');
  const [orderedDestinations, setOrderedDestinations] = useState<Destination[]>([]);

  useEffect(() => {
    const flattenedDestinations = flattenDestinations(destinations);
    const status = getCurrentStatus(flattenedDestinations);
    setOrderedDestinations(flattenedDestinations.filter((dest) => dest.status === status));
    setCurrentStatus(status);
  }, [destinations]);

  const getCurrentStatus = (flattenedDestinations: Destination[]) => {
    const statusPriority = ['live', 'initializing', 'ready'];
    for (let status of statusPriority) {
      for (let destination of flattenedDestinations) {
        if (destination.status === status) {
          return status;
        }
      }
    }
    return 'ready'; //NOTE: default it to ready?
  };

  //TODO: maybe fix the scrolling that happens in the 0.22s delay for the collapsible
  // to collapse

  return (
    <AbsoluteWrapper>
      <Container>
        <Header>
          <Text size={16} weight={700} color={colors.text100}>
            Total Connections
          </Text>
          <Text size={16} weight={700} color={colors.text100}>
            {orderedDestinations.filter((e) => e.status === currentStatus).length}
          </Text>
        </Header>
        <Divider />
        <Body>
          {orderedDestinations
            .filter((dest) => {
              return dest.status === currentStatus;
            })
            .map((dest) => {
              return (
                <BodyRow key={dest.id}>
                  <DestinationIcon
                    provider={dest.provider}
                    key={dest.provider}
                    status={dest.status}
                  >
                    {dest.provider !== 'maestro' && dest.imgUrl && (
                      <ProfileImage src={dest.imgUrl} />
                    )}
                  </DestinationIcon>
                  {dest.provider === PROVIDERS.maestro ? (
                    <DefaultDestinationName size={14} weight={400} />
                  ) : (
                    <Text size={14} weight={400}>
                      {fmtDestName(dest.provider)}
                    </Text>
                  )}
                </BodyRow>
              );
            })}
        </Body>
      </Container>
    </AbsoluteWrapper>
  );
};

const FadeIn = keyframes`
  from { opacity: 0};
  to { opacity: 1};
`;

const AbsoluteWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  background-color: black;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: ${FadeIn} 0.22s ease-in;
  //FIXME: this is making the component flicker once. The idea is to just show this component after a 0.22s delay
  /* animation-delay: 0.22s; */
`;

const Container = styled.div`
  position: relative;
  padding: 20px;
  ::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 15%;
    margin-left: -5px;
    border-color: transparent transparent black transparent;
    border-width: 13px;
    border-style: solid;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Divider = styled.div`
  width: 100%;
  height: 0.87px;
  background-color: ${({ theme }) => theme.colors.surface6};
  margin: 10px 0 17px 0;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
`;

const BodyRow = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ProfileImage = styled.img`
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default DestinationMenuHover;
