import React from 'react';
import styled, { css } from 'styled-components/macro';

import { Facebook, YouTube, Twitch, Person } from 'components/shared/Icons';
import { TProvider, PROVIDERS, StatusType } from 'interfaces/destinations';
import { DataTest } from 'interfaces/dataTestId';
import {
  DefaultDestinationBadge,
  DefaultDestinationThumbnail,
} from 'components/WhiteLabel/DefaultDestination';

interface Props extends DataTest {
  provider: TProvider;
  status: StatusType;
  isSummary?: boolean;
}

const DestinationIcon: React.FC<Props> = ({
  children,
  status,
  provider,
  isSummary,
  'data-testid': dataTestId,
}) => {
  const renderDestinationThumbnail = (width: number, height: number) => {
    const size = { width, height };
    if (provider === PROVIDERS.maestro) return <DefaultDestinationThumbnail {...size} />;
    if (provider === PROVIDERS.facebook) return <Facebook {...size} />;
    if (provider === PROVIDERS.youtube) return <YouTube {...size} />;
    if (provider === PROVIDERS.twitch) return <Twitch {...size} />;
    return null;
  };

  const renderDestinationBadge = (width: number, height: number) => {
    const size = { width, height };
    if (provider === PROVIDERS.maestro) return <DefaultDestinationBadge {...size} />;
    if (provider === PROVIDERS.facebook) return <Facebook {...size} />;
    if (provider === PROVIDERS.youtube) return <YouTube {...size} />;
    if (provider === PROVIDERS.twitch) return <Twitch {...size} />;
    return null;
  };

  const isBackgroundBlack = isSummary || provider === PROVIDERS.maestro ? true : Boolean(children);

  return (
    <GradientBoxWrapper status={status} data-testid={dataTestId}>
      <Container noBd={isBackgroundBlack}>
        {isSummary || provider === PROVIDERS.maestro
          ? renderDestinationThumbnail(36, 36)
          : children || <Person width={14} height={16} />}
      </Container>
      {!isSummary && <ProviderBadge>{renderDestinationBadge(24, 24)}</ProviderBadge>}
    </GradientBoxWrapper>
  );
};

const ProviderBadge = styled.div`
  position: absolute;
  left: 26px;
  top: 18px;
  width: 24px;
  height: 24px;
`;

const Default = css`
  background: ${({ theme }) => theme.colors.alertConfirmation};
`;

const Live = css`
  background: linear-gradient(180deg, #ffa9a9 0%, #fb4d4d 50.52%, #fa2f2f 100%);
`;

const Initializing = css`
  background: linear-gradient(124.63deg, #fda92c -4.3%, #fff2c2 109.31%);
`;

const Disconnected = css`
  background: ${({ theme }) => theme.colors.text200};
  opacity: 0.5;
`;

const None = css`
  background: ${({ theme }) => theme.colors.text400};
`;

const StreamUnable = css`
  background: ${({ theme }) => theme.colors.alertWarning};
`;

const Container = styled.div<{ noBd: boolean }>`
  background: ${(props) => (props.noBd ? 'black' : props.theme.colors.text400)};
  border-radius: inherit;
  display: grid;
  width: 100%;
  height: 100%;
  place-items: center;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  img {
    object-fit: cover;
  }
`;

const GradientBoxWrapper = styled.div<{ status: StatusType }>`
  position: relative;
  box-sizing: border-box;
  display: grid;
  place-items: center;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  ${(props) => (props.status === 'ready' || props.status === 'connection_successful') && Default};
  ${(props) => props.status === 'live' && Live};
  ${(props) => props.status === 'initializing' && Initializing};
  ${(props) => props.status === 'disconnected' && Disconnected};
  ${(props) => props.status === 'unable_to_stream' && StreamUnable};
  ${(props) => props.status === 'authorization_unsuccessful' && None};
  padding: 1.6px; // size of the "border"
  padding-top: 2px;
`;

export default DestinationIcon;
