import axios from 'axios';

import { REACT_APP_DESTINATION_REDIRECT_URL, REACT_APP_TWITCH_CLIENT_ID } from 'config';
import { Destination, PROVIDERS, StatusType, TwitchFormValues } from 'interfaces/destinations';
import { CustomError, ServiceResponse } from 'utils/http-response-handler';
import { openPopup } from 'utils/popup';
import {
  ICreateDestinationObjectParams,
  IDestinationAuthStrategy,
  IGetDestinationInfoParams,
  IOpenLoginPopupParams,
  TBuildRtmpParams,
  TPopupAuthPayload,
} from './interfaces';

//TODO: verify needed scopes
const SCOPES = [
  'user:read:email',
  'openid', // TODO: didn't find this in the docs but it was the only way to get the profile pic. Research about it
  'channel:manage:broadcast',
  'channel:manage:videos',
  'channel:read:stream_key',
];

const TwitchCategoriesId: Record<string, number | null> = {
  'Just Chatting': 509658,
  Music: 26936,
  Sports: 518203,
  ART: 509660,
  ASMR: 509659,
  'Talk Shows & Podcasts': 417752,
  'Use my current Twitch category': null,
};

type TwitchCategories = keyof typeof TwitchCategoriesId;

type TTwitchAuthClaims = {
  preferred_username: string;
  picture: string;
};

type ValidateResponse = {
  client_id: string;
  login: string;
  scopes: string[];
  user_id: string;
  expires_in: number;
};

type GetStreamKeyResponse = {
  data: Array<{ stream_key: string }>;
};

export default class TwitchStrategy implements IDestinationAuthStrategy {
  private readonly clientId;
  private readonly redirectUri = encodeURIComponent(
    REACT_APP_DESTINATION_REDIRECT_URL + '/popup_redirect/twitch'
  );

  private readonly scopes = encodeURIComponent(SCOPES.join(' '));
  private readonly claims = encodeURIComponent(
    JSON.stringify({ userinfo: { picture: null, preferred_username: null } })
  );

  constructor(clientId?: string) {
    this.clientId = encodeURIComponent(clientId || REACT_APP_TWITCH_CLIENT_ID);
  }

  openLoginPopup(params: IOpenLoginPopupParams): Window | null {
    const encodedState = encodeURIComponent(params.state);
    const uri =
      `https://id.twitch.tv/oauth2/authorize` +
      `?force_verify=true&response_type=code` +
      `&client_id=${this.clientId}` +
      `&redirect_uri=${this.redirectUri}` +
      `&scope=${this.scopes}` +
      `&state=${encodedState}` +
      `&claims=${this.claims}`;

    return openPopup(uri, 'Twitch Authentication');
  }

  getResponseFromLocationUrl(): TPopupAuthPayload {
    const query = new URLSearchParams(window.location.search);
    const { code, state, error, error_description } = Object.fromEntries(query.entries());

    if (code && state) return { status: 'success', code, state };
    return { status: 'error', state, error, error_description };
  }

  async createDestinationObject(
    params: ICreateDestinationObjectParams
  ): Promise<ServiceResponse<Destination>> {
    try {
      const { id, accessToken, refreshToken, expiresAt } = params;
      const res = await this.fetchUserInfo(params.accessToken);
      const destination: Destination = {
        provider: PROVIDERS.twitch,
        id,
        account: res.data.preferred_username,
        imgUrl: res.data.picture,
        accessToken,
        expiresAt,
        refreshToken,
        status: StatusType.connection_successful,
        metadata: params.metadata,
        connected: true,
        rtmp: {
          rtmpUrl: '',
          streamKey: '',
        },
      };
      return { data: destination };
    } catch (error) {
      return { error: CustomError('UNHANDLED_ERROR', error) };
    }
  }

  async getDestinationInfo(
    params: IGetDestinationInfoParams
  ): Promise<ServiceResponse<Destination>> {
    try {
      const { destination } = params;
      const { data } = await this.fetchUserInfo(destination.accessToken);

      const newDest = {
        ...destination,
        account: data.preferred_username,
        imgUrl: data.picture,
      } as Destination;

      return { data: newDest };
    } catch (error) {
      return { error: CustomError('UNHANDLED_ERROR', error) };
    }
  }

  async buildRtmp({ accessToken, metadata }: TBuildRtmpParams) {
    // Have to validate the auth token in order to get the user's actual ID
    const {
      data: { client_id: clientId, user_id: userId },
    } = await axios.get<ValidateResponse>('https://id.twitch.tv/oauth2/validate', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const gameId = TwitchCategoriesId[(metadata as TwitchFormValues).category as TwitchCategories];

    // Update channel status
    // https://dev.twitch.tv/docs/api/reference#modify-channel-information
    await axios.patch(
      `https://api.twitch.tv/helix/channels?broadcaster_id=${userId}`,
      {
        title: metadata.title,
        ...(gameId ? { game_id: gameId } : {}),
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Client-Id': clientId,
        },
      }
    );

    // Then get the stream key
    // https://dev.twitch.tv/docs/api/reference#get-stream-key
    const {
      data: { data: streams },
    } = await axios.get<GetStreamKeyResponse>(
      `https://api.twitch.tv/helix/streams/key?broadcaster_id=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Client-Id': clientId,
        },
      }
    );

    const streamKey = streams[0].stream_key;

    // TODO: choose the right one based on geographic location
    const url = 'lax.contribute.live-video.net/app';

    return {
      rtmpUrl: `rtmp://${url}`,
      streamKey,
    };
  }

  private async fetchUserInfo(accessToken: string) {
    const { data } = await axios.get<TTwitchAuthClaims>('https://id.twitch.tv/oauth2/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  }
}
