import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  ICreateDestinationObjectParams,
  IDestinationAuthStrategy,
  IFbResponse,
  IGetDestinationInfoParams,
  IOpenLoginPopupParams,
  TBuildRtmpParams,
  TPopupAuthPayload,
} from './interfaces';
import { REACT_APP_DESTINATION_REDIRECT_URL, REACT_APP_FACEBOOK_APP_ID } from 'config';
import { openPopup } from 'utils/popup';
import { Destination, PROVIDERS, StatusType } from 'interfaces/destinations';
import { CustomError, ServiceResponse } from 'utils/http-response-handler';

const SCOPES = [
  'public_profile',
  'email',
  'pages_show_list',
  'publish_video',
  'pages_manage_posts',
  'pages_read_engagement',
];

enum LiveVideoStatus {
  UNPUBLISHED = 'UNPUBLISHED',
  LIVE_NOW = 'LIVE_NOW',
  SCHEDULED_UNPUBLISHED = 'SCHEDULED_UNPUBLISHED',
  SCHEDULED_LIVE = 'SCHEDULED_LIVE',
  SCHEDULED_CANCELED = 'SCHEDULED_CANCELED',
}

interface CreateLiveVideoRequest {
  title: string;
  description: string;
  published?: boolean;
  status?: LiveVideoStatus;
  stop_on_delete_stream: boolean;
}

interface CreateLiveVideoResponse {
  id: string;
  stream_url: string;
  secure_stream_url: string;
  stream_secondary_urls: string[];
  secure_stream_secondary_urls: string[];
  dash_ingest_url: string;
  dash_ingest_secondary_urls: string[];
  event_id: string;
}

interface IGetProfilePictureResponse {
  data: {
    height: number;
    width: number;
    url: string;
    is_silhouette: boolean;
    cache_key?: string;
  };
}

export default class FacebookStrategy implements IDestinationAuthStrategy {
  private readonly clientId;
  private readonly redirectUri = encodeURIComponent(
    REACT_APP_DESTINATION_REDIRECT_URL + '/popup_redirect/facebook'
  );
  private readonly scopes = encodeURIComponent(SCOPES.join(' '));
  private readonly baseUrl = 'https://graph.facebook.com/v14.0';

  constructor(clientId?: string) {
    this.clientId = encodeURIComponent(clientId || REACT_APP_FACEBOOK_APP_ID);
  }

  getResponseFromLocationUrl(): TPopupAuthPayload {
    const query = new URLSearchParams(window.location.search);
    const {
      code,
      state,
      error, // what is this exactly? Message?
      error_description,
      error_reason,
    } = Object.fromEntries(query.entries());

    if (code && state) return { status: 'success', code, state };
    return { status: 'error', state, error, error_description, error_reason };
  }

  openLoginPopup(params: IOpenLoginPopupParams): Window | null {
    const encodedState = encodeURIComponent(params.state);
    const uri =
      `${this.baseUrl.replace('graph.', '')}/dialog/oauth` +
      `?response_type=code` +
      `&client_id=${this.clientId}` +
      `&redirect_uri=${this.redirectUri}` +
      `&scope=${this.scopes}` +
      `&state=${encodedState}`;

    return openPopup(uri, 'Facebook Authentication');
  }

  async createDestinationObject(
    params: ICreateDestinationObjectParams
  ): Promise<ServiceResponse<Destination>> {
    try {
      const { id, accessToken, refreshToken, expiresAt } = params;
      const [facebookProfile, facebookPages] = await Promise.all([
        this.getFBProfileInfo(params.accessToken),
        this.getFBPagesInfo(params.accessToken),
      ]);
      const profilePic = await this.getFBProfilePicture(facebookProfile.id, params.accessToken);
      const destination: Destination = {
        id,
        provider: PROVIDERS.facebook,
        status: StatusType.connection_successful,
        accessToken,
        expiresAt,
        refreshToken,
        data: { facebookProfile, facebookPages },
        account: facebookProfile.name,
        imgUrl: profilePic,
        metadata: { ...params.metadata, streamTo: facebookProfile.id },
        connected: true,
        rtmp: {
          rtmpUrl: '',
          streamKey: '',
        },
      };
      return { data: destination };
    } catch (error) {
      return { error };
    }
  }

  async getDestinationInfo(
    params: IGetDestinationInfoParams
  ): Promise<ServiceResponse<Destination>> {
    try {
      const { destination } = params;
      const [facebookProfile, facebookPages] = await Promise.all([
        this.getFBProfileInfo(destination.accessToken),
        this.getFBPagesInfo(destination.accessToken),
      ]);
      const profilePic = await this.getFBProfilePicture(
        facebookProfile.id,
        destination.accessToken
      );
      const data = {
        ...destination,
        imgUrl: profilePic,
        data: { facebookProfile, facebookPages },
        account: facebookProfile.name,
      } as Destination;

      return { data };
    } catch (error) {
      return { error: CustomError('UNHANDLED_ERROR', error) };
    }
  }

  async buildRtmp({ accessToken, metadata }: TBuildRtmpParams) {
    const { title, streamTo, description } = metadata as Pick<
      CreateLiveVideoRequest,
      'title' | 'description'
    > & { streamTo: string };

    // Make a LiveVideo object.
    const body = {
      title,
      description,
      status: LiveVideoStatus.LIVE_NOW,
      // published: true,
      // TODO: check what happens if we just stop streaming - does it stop the live video by itself?
      stop_on_delete_stream: true,
    };

    const { data } = await this.fbApi<CreateLiveVideoRequest, CreateLiveVideoResponse>({
      method: 'POST',
      endpoint: `/${streamTo}/live_videos`,
      body,
      accessToken: accessToken,
    });

    // According to https://developers.facebook.com/docs/live-video-api/getting-started
    // The broadcast URL should be rtmps://.../rtmp/
    // and the stream key is everything after that
    const [rtmpUrl, streamKey] = data.secure_stream_url.split('/rtmp/');

    return {
      rtmpUrl: `${rtmpUrl}/rtmp/`,
      streamKey,
    };
  }

  private async getFBPagesInfo(accessToken?: string): Promise<IFbResponse[]> {
    const {
      data: { data: pages },
    } = await this.fbApi<unknown, { data: Array<{ id: string; name: string }> }>({
      endpoint: 'me/accounts',
      accessToken,
    });

    const facebookPages = pages.map((page: any) => ({
      id: page.id,
      name: page.name,
    }));
    return facebookPages || [];
  }

  private async getFBProfileInfo(accessToken?: string): Promise<IFbResponse> {
    const { data } = await this.fbApi<unknown, { id: string; name: string }>({
      endpoint: 'me',
      accessToken,
    });
    return { id: data.id, name: data.name };
  }

  private async getFBProfilePicture(userId: string, access_token: string): Promise<string> {
    // https://developers.facebook.com/docs/graph-api/reference/user/picture/
    const {
      data: { data },
    } = await this.fbApi<unknown, IGetProfilePictureResponse>({
      endpoint: `${userId}/picture?redirect=false`,
      accessToken: access_token,
    });
    // NOTE: this https://graph.facebook.com/v13.0/${facebookProfile.id}/picture?width=50&height=50 with the profile.id that we have does not return the correct profilePic
    // it has to be a different id that I'm not aware of how to retrieve
    //TODO: the documentation says this url expires... are we going to handle this somehow?
    return data.url;
  }

  private async fbApi<TRequest, TResponse>({
    method = 'get',
    endpoint,
    accessToken,
    body,
  }: {
    endpoint: string;
    method?: AxiosRequestConfig<TRequest>['method'];
    body?: TRequest;
    accessToken?: string;
  }) {
    const url = `${this.baseUrl}/${endpoint}`;

    const { data } = (await axios({
      url,
      method,
      params: { access_token: accessToken },
      data: body,
    })) as AxiosResponse<TResponse>;

    return { data };
  }
}
