import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';

import { Button, Text } from 'components/shared';
import { ModalHeader } from 'components/shared';
import { CamOff, MicOff } from 'components/shared/Icons';
import { NotAllowedType } from 'constants/modals';
import { closeModal } from 'store/slices/ui';

interface Props {
  media: 'audio' | 'video';
  type: NotAllowedType;
}

const MediaNotAllowed: React.FC<Props> = ({ media, type }) => {
  const dispatch = useDispatch();
  const { colors } = useTheme();

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(closeModal());
  };

  return (
    <Container>
      <ModalHeader showCloseButton onDismissModal={handleClose} />

      <BodyContainer>
        <IconContainer>
          {media === 'audio' && <MicOff width={18} height={18} fill={colors.text100} />}
          {media === 'video' && <CamOff width={18} height={18} fill={colors.text100} />}
        </IconContainer>
        {type === 'cant_start' && media === 'video' && (
          <React.Fragment>
            <Text size={14} weight={700} color={colors.text100} textAlign='center'>
              Unable to start video
            </Text>
            <Text size={14} weight={400} color={colors.text100} textAlign='center'>
              You can’t start your video because the host has stopped it
            </Text>
            <Button
              onClick={handleClose}
              color={colors.accentPrimary}
              style={{ width: '80px', height: '40px' }}
            >
              OK
            </Button>
          </React.Fragment>
        )}

        {type === 'cant_start' && media === 'audio' && (
          <React.Fragment>
            <Text size={14} weight={700} color={colors.text100} textAlign='center'>
              Unable to start audio
            </Text>
            <Text size={14} weight={400} color={colors.text100} textAlign='center'>
              You can’t start your audio because the host has stopped it
            </Text>
            <Button
              onClick={handleClose}
              color={colors.accentPrimary}
              style={{ width: '80px', height: '40px' }}
            >
              OK
            </Button>
          </React.Fragment>
        )}
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  height: auto;
  gap: 5px;
  text-align: center;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.surface2};
  min-width: 200px;
  width: auto;
`;

const IconContainer = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.accentPrimary};
`;

const BodyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 0 10px 10px 10px;
`;

export default MediaNotAllowed;
