import CSS from 'csstype';
import { FULL_LAYOUT_VALID_COUNT, Layout, LAYOUTS } from 'constants/layouts';
import { CONFIG } from 'constants/config';

interface LayoutVideosConfig {
  [peerId: string]: CSS.Properties;
}

const ASPECT_RATIO = 16 / 9;
const INVERSE_ASPECT_RATIO = Math.pow(ASPECT_RATIO, -1);

/**
 * Receive an array and split it into chunks of chunkSize
 * @param array
 * @param chunkSize
 */
const chunk = (array: unknown[], chunkSize: number): unknown[][] =>
  array.reduce(
    (acc: unknown[][], cur) => {
      if (acc[acc.length - 1].length === chunkSize) return [...acc, [cur]];
      acc[acc.length - 1].push(cur);
      return acc;
    },
    [[]]
  );

/**
 * Zip two arrays [a1, a2] => [[a1[0], a2[0]], [a1[n],a2[n]]]
 * @param rows
 */
const zip = (rows: unknown[][]) => rows[0].map((_, c) => rows.map((row) => row[c]));

type CalculateLayoutArgs = {
  layout: Layout;
  count: number;
  peersIds: string[];
  peerInSoloView: string | null;
  isBeingDragged: boolean;
  peerScreenShareId: string | null;
};

type CalculateLayoutFn = (args: CalculateLayoutArgs) => LayoutVideosConfig;

const overwriteLayout = (args: CalculateLayoutArgs): CalculateLayoutArgs => {
  const { count, peerInSoloView, peerScreenShareId, layout } = args;
  if (count === 1 || peerInSoloView) {
    return { ...args, layout: LAYOUTS.SOLO_VIEW };
  }

  if (peerScreenShareId && layout !== LAYOUTS.SOLO_VIEW) {
    return { ...args, layout: LAYOUTS.SPOTLIGHT };
  }

  if (!FULL_LAYOUT_VALID_COUNT.includes(count) && layout === LAYOUTS.LANDSCAPE) {
    return { ...args, layout: LAYOUTS.FULL };
  }

  return args;
};

const calculateLandscapeLayout = ({ count, peersIds }: CalculateLayoutArgs): LayoutVideosConfig => {
  // Sizes in percentage
  const MARGIN = 0.5;

  // Maximum columns each row can have
  const maxColumnCount = count === 1 ? 1 : count < 5 ? 2 : 3;

  // Width of each element, considering all the available space minus the necessary space for margins
  const width = 100 / maxColumnCount - ((maxColumnCount + 1) / maxColumnCount) * MARGIN;

  const baseStyle = {
    position: 'absolute',
    width: `${width}%`,
    aspectRatio: '16/9',
  };

  // create an matrix from a flat array
  const items = chunk(Array.from(Array(count)), maxColumnCount);

  const calculateX = (columnIndex: number, columnsCount: number) => {
    const emptySpace = 100 - columnsCount * width;
    const offsetStart = (emptySpace - (columnsCount - 1) * MARGIN * INVERSE_ASPECT_RATIO) / 2;
    const offsetColumn = columnIndex * width;
    const offsetMargin = columnIndex * MARGIN * INVERSE_ASPECT_RATIO;
    return { left: `${offsetStart + offsetColumn + offsetMargin}%` };
  };

  const calculateY = (rowIndex: number, rowCount: number) => {
    const emptySpace = 100 - rowCount * width;
    const offsetStart = (emptySpace - (rowCount - 1) * MARGIN) / 2;
    const offsetRow = rowIndex * width;
    const offsetMargin = rowIndex * MARGIN;
    return { top: `${offsetStart + offsetRow + offsetMargin}%` };
  };

  const styles = items
    .map((row, rowIndex, rows) =>
      row.map((_, columnIndex, columns) => {
        const x = calculateX(columnIndex, columns.length); // get the x positioning styles
        const y = calculateY(rowIndex, rows.length); // get the y positioning styles
        return { ...baseStyle, ...x, ...y };
      })
    )
    .flat(1);

  // assign the ids for each style and then create a object from that
  return Object.fromEntries(zip([[...peersIds, 'preview'], styles]));
};

const calculateFullLayout = (args: CalculateLayoutArgs): LayoutVideosConfig => {
  const { count } = args;

  // Sizes in percentage
  const MARGIN = 0.5;

  if (count === 1) return calculateSoloViewLayout(args);

  const landscapePositions = calculateLandscapeLayout(args);

  // for this number of elements, the full layout is identical to landscape
  if ([1, 3, 4].includes(count)) return landscapePositions;

  const maxColumnCount = count === 1 ? 1 : count < 5 ? 2 : 3;
  const rowsCount = Math.ceil(count / maxColumnCount);

  const sizeEachBox = 100 / rowsCount;
  const sizeMargins = (MARGIN * (rowsCount + 1)) / rowsCount;

  const height = sizeEachBox - sizeMargins;

  const baseStyle = {
    height: `${height}%`,
  };

  const calculateY = (index: number) => {
    const rowIndex = Math.floor(index / maxColumnCount);
    const offsetRow = rowIndex * height;
    const offsetStart = rowsCount === 1 ? MARGIN : ((3 - INVERSE_ASPECT_RATIO) * MARGIN) / 2;
    const offsetMargin = rowIndex * INVERSE_ASPECT_RATIO;
    return { top: `${offsetStart + offsetRow + offsetMargin}%` };
  };

  // Reuse the X position from landscape view, but calculate a new Y position for each element
  const fullPositions = Object.entries(landscapePositions).map(([key, value], index) => {
    if (!value) return [key, value];
    const { aspectRatio, ...restStyles } = value;
    const posY = calculateY(index);
    return [key, { ...restStyles, ...baseStyle, ...posY }];
  });

  return Object.fromEntries(fullPositions);
};

const calculateSpotlightLayout = (args: CalculateLayoutArgs): LayoutVideosConfig => {
  const MAX_PEERS_OUTSIDE_FOCUS = CONFIG.MAX_PEERS_ON_STAGE;
  // Sizes in percentage
  const FOCUS_WIDTH = 84.5;
  const OTHER_PEERS_WIDTH = 14;
  const MARGIN = 0.5;
  const EMPTY_SPACE_FOCUS = 100 - FOCUS_WIDTH;
  const HEIGHT_MARGIN = MARGIN * ASPECT_RATIO;
  const EMPTY_SPACE_OTHER_PEERS =
    100 -
    OTHER_PEERS_WIDTH * MAX_PEERS_OUTSIDE_FOCUS -
    (MAX_PEERS_OUTSIDE_FOCUS - 1) * HEIGHT_MARGIN;

  const { count, peersIds } = args;

  const baseStyle = {
    position: 'absolute',
    aspectRatio: '16/9',
  };

  const focusStyle = {
    ...baseStyle,
    left: `${MARGIN}%`,
    top: `${EMPTY_SPACE_FOCUS / 2}%`,
    width: `${FOCUS_WIDTH}%`,
  };

  const baseOtherPeersStyle = {
    ...baseStyle,
    width: `${OTHER_PEERS_WIDTH}%`,
    left: `${100 - MARGIN - OTHER_PEERS_WIDTH}%`,
  };

  const otherPeersStyle = Array.from(Array(Math.max(count - 1, 0))).map((_, index) => {
    const offsetVideoCard = index * OTHER_PEERS_WIDTH;
    const offsetMargin = index * MARGIN * ASPECT_RATIO;
    const offsetInitialPosition = EMPTY_SPACE_OTHER_PEERS / 2;

    return {
      ...baseOtherPeersStyle,
      top: `${offsetInitialPosition + offsetVideoCard + offsetMargin}%`,
    };
  });

  return Object.fromEntries(
    zip([
      [...peersIds, 'preview'],
      [focusStyle, ...otherPeersStyle],
    ])
  );
};

const calculateSoloViewLayout = (args: CalculateLayoutArgs): LayoutVideosConfig => {
  const { peersIds, peerInSoloView } = args;

  const soloViewId = peerInSoloView ?? peersIds[0];
  // Sizes in percentage
  const SOLO_WIDTH = 100;
  const MARGIN = 0; // We need a small margin because of parent borders

  const baseStyle = {
    position: 'absolute',
    width: `${SOLO_WIDTH}%`,
    top: `${MARGIN}%`,
    left: `${MARGIN}%`,
    aspectRatio: '16/9',
  };

  return Object.fromEntries(
    peersIds.map((id) => (id === soloViewId ? [id, baseStyle] : [id, { display: 'none' }]))
  );
};

const calculateFnByLayout: Record<Layout, CalculateLayoutFn> = {
  [LAYOUTS.SOLO_VIEW]: calculateSoloViewLayout,
  [LAYOUTS.FULL]: calculateFullLayout,
  [LAYOUTS.LANDSCAPE]: calculateLandscapeLayout,
  [LAYOUTS.SPOTLIGHT]: calculateSpotlightLayout,
  [LAYOUTS.SCREEN_SHARE_1]: calculateSpotlightLayout,
  [LAYOUTS.SCREEN_SHARE_2]: calculateSpotlightLayout,
  [LAYOUTS.SCREEN_SHARE_3]: calculateSpotlightLayout,
};

export const calculatePeerPosition = (args: CalculateLayoutArgs) => {
  const { layout = LAYOUTS.LANDSCAPE } = overwriteLayout(args);
  const videoConfigs = calculateFnByLayout[layout](args);
  return {
    videoConfigs,
    metadata: {
      overriddenLayout: layout,
    },
  };
};

export const getVideoObjectFit = (
  layout: Layout,
  videoSize: { height: number; width: number },
  isOnStage: boolean,
  isScreenShare: boolean | undefined
) => {
  if (layout === LAYOUTS.FULL) return 'cover';
  if (!isOnStage) return 'cover';
  if (videoSize.height > videoSize.width) return 'contain';
  if (isScreenShare) return 'contain';
  return 'cover';
};
