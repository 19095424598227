import styled from 'styled-components/macro';
import { Beta, MaestroStudio } from '../shared/Icons';

const BrandBar = () => {
  return (
    <Container>
      <MaestroStudio width={115} />
      <Beta width={50} height={15} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.surface3};
`;

export default BrandBar;
