import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isFirefox } from 'react-device-detect';

import { Text } from 'components/shared';
import { PersonAdd, PersonArrowLeft, PersonCheck } from 'components/shared/Icons';
import { addAlpha } from 'utils/color';

interface Props {
  isReturn: boolean;
  isFull: boolean;
  onClick?: () => void;
}

const InviteGuestCard: React.FC<Props> = ({ isReturn, isFull, onClick }) => {
  const { colors } = useTheme();

  const renderComponent = () => {
    if (isReturn) {
      const background = addAlpha(colors.accentPrimary, 0.1);
      const border = `1px dashed ${colors.accentPrimary}`;
      return (
        <Container border={border} background={background} isFirefox={isFirefox}>
          <PersonArrowLeft width={26} height={22} fill={colors.text100} />
          <Text size={12} weight={400} textAlign='center'>
            Guest will land here
          </Text>
        </Container>
      );
    }

    const border = `1.5px dashed ${colors.text400};`;
    const background = isFull ? `${colors.surface2}80` : `${colors.surface2}`;

    return (
      <Container
        border={border}
        background={background}
        isFull={isFull}
        isFirefox={isFirefox}
        onClick={onClick}
      >
        {isFull ? (
          <PersonCheck width={26} height={22} fill={colors.text100} />
        ) : (
          <PersonAdd width={26} height={22} fill={colors.text100} />
        )}
        <Text size={14} weight={400} textAlign='center'>
          {isFull ? '10 Guests limit reached' : 'Invite Guest'}
        </Text>
      </Container>
    );
  };

  return renderComponent();
};

const Container = styled.div<{
  border: string;
  background: string;
  isFull?: boolean;
  isFirefox: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: ${(props) => (props.isFirefox ? '142px' : '145px')};
  height: 140px; // or 100%
  background: ${(props) => props.background};
  border: ${(props) => props.border};
  box-sizing: border-box;
  border-radius: 4.94898px;
  gap: 15px;
  > span {
    width: 80%;
  }
  ${(props) =>
    !props.isFull &&
    css`
      :hover {
        background: ${(props) => `${props.theme.colors.accentPrimary}1A`};
        border: ${(props) => `1.5px dashed ${props.theme.colors.accentPrimary}`};
        transition: background 0.15s linear, border 0.15s linear;
      }
    `}
`;

export default InviteGuestCard;
