import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingSpinner, Text } from 'components/shared';
import { Destination, PROVIDERS } from 'interfaces/destinations';
import { Facebook, Maestro, Trash, Twitch, YouTube } from 'components/shared/Icons';
import { removeDestination } from 'store/slices/destinations';
import { useServices } from 'hooks/useServices';
import { getSessionIsLive } from 'store/slices/session';

interface Props {
  destination: Destination;
  onClose: Function;
  isSubmitting: boolean;
}

const ButtonRow: React.FC<Props> = ({ destination, onClose, isSubmitting }) => {
  const { destinationService } = useServices();
  const isLive = useSelector(getSessionIsLive);

  // const [isSaveLoading, setIsSaveLoading] = useState(false); // TODO: right now,
  // this functionality comes from the parent "isSubmitting". Either move it here or move the remove loading up
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const dispatch = useDispatch();

  const { colors } = useTheme();

  const renderDestinationBadge = (width: number, height: number) => {
    const size = { width, height };
    const provider = destination.provider;
    if (provider === PROVIDERS.maestro) return <Maestro {...size} />;
    if (provider === PROVIDERS.facebook) {
      // for some reason facebook icon is behaving weirdly
      return <Facebook width={size.width - 2} height={size.height - 2} />;
    }
    if (provider === PROVIDERS.youtube) return <YouTube {...size} />;
    if (provider === PROVIDERS.twitch) return <Twitch {...size} />;
    return null;
  };

  const handleClose = () => {
    onClose();
  };

  const redirectTo = (link: string) => window.open(link, '_blank');

  const handleViewOnDestination = () => {
    switch (destination.provider) {
      case PROVIDERS.facebook: {
        const { id } = destination?.data?.facebookProfile;
        //TODO: we need the specific video id after the stream starts to see the video inside facebook
        redirectTo(`https://www.facebook.com/${id}`);
        break;
      }
      case PROVIDERS.youtube: {
        const { channelId } = destination.data;
        const livePath = isLive ? '/live' : '';
        redirectTo(`https://www.youtube.com/channel/${channelId}${livePath}`);
        break;
      }
      case PROVIDERS.twitch: {
        redirectTo(`https://www.twitch.tv/${destination.account}`);
        break;
      }
    }
  };

  const handleRemoveDestination = async () => {
    try {
      setIsRemoveLoading(true);
      const res = await destinationService.removeDestination(destination);
      if (res.data) {
        dispatch(removeDestination(destination.provider, destination.id));
      } else {
        console.log(res.error); // TODO: handle error?
      }
      setIsRemoveLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Row>
        <Column>
          <Button
            width={50}
            type='button'
            color={colors.surface5}
            onClick={handleClose}
            data-testid='destCloseButton'
          >
            <Text size={12} weight={700} color={colors.text100}>
              CLOSE
            </Text>
          </Button>
        </Column>
        <Column>
          <Button
            width={50}
            type='submit'
            disabled={isSubmitting}
            submitting={isSubmitting ? true : false}
            data-testid='destSaveButton'
          >
            <Text size={12} weight={700} color={colors.text100}>
              SAVE
            </Text>
            <LoadingSpinner size={14} />
          </Button>
          <Button
            width={100}
            style={{ gap: '5px' }}
            onClick={handleViewOnDestination}
            type='button'
            data-testid='destViewOnButton'
          >
            <Text size={12} weight={700} color={colors.text100}>
              VIEW ON
            </Text>
            {renderDestinationBadge(20, 20)}
          </Button>
          <Button
            width={24}
            color={colors.accentPrimary}
            onClick={handleRemoveDestination}
            disabled={isRemoveLoading}
            submitting={isRemoveLoading ? true : false}
            type='button'
            data-testid='destRemoveButton'
          >
            <Trash width={12} height={14} fill={colors.text100} />
            <LoadingSpinner size={14} color={colors.text100} />
          </Button>
        </Column>
      </Row>
    </div>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Button = styled.button<{ width?: number; color?: string; submitting?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width + 'px'};
  height: 24px;
  background-color: ${(props) => props.color || props.theme.colors.accentPrimary};
  color: ${(props) => props.color || props.theme.colors.accentPrimary};
  border-radius: 5px;
  cursor: pointer;
  :active:not(:disabled) {
    box-shadow: 0 0 8px currentColor;
    transform: translateY(2px);
  }
  :disabled {
    cursor: not-allowed;
  }
  > span,
  svg {
    opacity: ${(props) => (props.submitting ? 0 : 1)};
    transition: opacity 0.2s;
  }
  > div {
    position: absolute;
    opacity: ${(props) => (props.submitting ? 1 : 0)};
    transition: opacity 0.2s;
  }
`;

export default ButtonRow;
