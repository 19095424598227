import styled, { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { getPrivacyPolicyUrl } from 'store/slices/siteConfig';
import { Help } from 'components/shared/Icons';
import { Text } from 'components/shared';

const PrivacyPolicyLink = () => {
  const privacyPolicyUrl = useSelector(getPrivacyPolicyUrl);
  const { colors } = useTheme();

  return (
    <PrivacyPolicyContainer>
      <Help width={13} height={13} fill={colors.highlightPrimary} />
      <Text weight={500} size={14} color={colors.highlightPrimary}>
        <CustomLink target='_blank' rel='noreferrer' href={privacyPolicyUrl}>
          Privacy policy
        </CustomLink>
      </Text>
    </PrivacyPolicyContainer>
  );
};

const PrivacyPolicyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  gap: 4px;
`;

const CustomLink = styled.a`
  :visited {
    color: ${({ theme }) => theme.colors.highlightPrimary};
  }
  text-decoration: underline;
`;

export default PrivacyPolicyLink;
