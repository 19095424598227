import axios from 'axios';
import store from 'store';
import { ENDPOINTS } from 'constants/endpoints';

const errorTrackingHttp = axios.create({
  baseURL: ENDPOINTS.SESSION_SERVICE_BASE_URL,
});

export const trackError = async (error: any) => {
  const state = store.getState();
  await errorTrackingHttp
    .post('error', {
      error,
      sessionId: state.session?.sessionId,
      channelId: state.session?.channelId,
      clientUrl: state.session?.clientUrl,
      timestamp: Date.now(),
    })
    .catch(() => {
      // what do you do when even the error tracking fails? 😢
    });
};

export const setupErrorTracking = () => {
  const originalError = console.error;

  console.error = (...args: any[]) => {
    trackError(args.length === 1 ? args[0] : args);
    originalError(...args);
  };

  window.addEventListener('error', ({ error }) => {
    if (error && ('message' in error || 'stack' in error)) {
      trackError({
        message: String(error.message),
        stack: String(error.stack),
      });
    } else {
      trackError(String(error));
    }
  });
};
