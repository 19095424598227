import { combineReducers } from '@reduxjs/toolkit';

import participants from 'store/slices/participants';
import ui from 'store/slices/ui';
import destinations from 'store/slices/destinations';
import session from 'store/slices/session';
import siteConfig from 'store/slices/siteConfig';
import user from 'store/slices/user';

const rootReducer = combineReducers({
  session,
  ui,
  destinations,
  participants,
  siteConfig,
  user,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
