import React, { useContext } from 'react';
import { VideoCardResizeContext } from 'components/VideoCard/VideoCardResizeProvider';

const MAX_CARD_SIZE = 1200;

const linearFunction = (a: number, b: number) => (x: number) => a * x + b;

const getSizeByCardSize = (maxSize: number, minSize: number) => (cardSize: number) =>
  Math.min(maxSize, linearFunction((2.2 * maxSize) / MAX_CARD_SIZE, minSize)(cardSize));

const getFontSizeByCardSize = getSizeByCardSize(20, 6);

const getIconSizeByCardSize = getSizeByCardSize(18, 4);

export const useVideoCardSize = (ref: React.RefObject<HTMLDivElement>) => {
  const { observerRef, sizeMap } = useContext(VideoCardResizeContext);
  const cardSize = sizeMap[ref.current?.id ?? ''] ?? 0;

  React.useEffect(() => {
    const element = ref.current;
    const observer = observerRef.current;
    if (observer && element) observer.observe(element);
    return () => {
      if (observer && element) observer.unobserve(element);
    };
  }, [observerRef, ref]);

  return {
    cardSize,
    fontSize: getFontSizeByCardSize(cardSize),
    iconSize: getIconSizeByCardSize(cardSize),
  };
};
