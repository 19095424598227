import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { RootState } from 'store/slices';
import { AppDispatch, AppThunk } from 'store';
import { MediaConstraints, SessionStore } from 'interfaces/session';
import { Layout, LAYOUTS } from 'constants/layouts';
import { setAllDestinations } from 'store/slices/destinations';
import { changeDestinationsStatus } from 'utils/destination';
import { TMaestroJwt, TSecretToken } from 'interfaces/newType';
import { RESOLUTION_OPTIONS } from 'utils/webrtc';
import { StatusType } from 'interfaces/destinations';

// Initial state
const initialState: SessionStore = {
  uid: uuidv4(),
  displayName: '',
  isHost: false,
  isOnStage: false,
  //TODO: we may need to change this when we start allowing people with no audio/video
  mediaConstraints: {
    audio: { echoCancellation: true, channelCount: 1, deviceId: 'default' },
    // NOTE: what about mobile? does the resolution need to be changed?
    video: RESOLUTION_OPTIONS[1].value,
  },
  hideDisplayNames: false,
  order: -1,
  // TODO: move this to a different slice, probably UI
  outputDeviceId: '',
  sessionId: '',
  isLive: false,
  layout: LAYOUTS.LANDSCAPE,
  secretToken: '' as TSecretToken,
  inviteCode: '',
  isScreenSharing: false,
  playbackUrl: '',
  isAskingScreenShare: false,
  maestroJwt: '' as TMaestroJwt,
  forceTurnRelay: false,
  channelId: '',
  mediaEnabled: {
    audio: true,
    video: true,
  },
  clientUrl: '',
};

// Initializing slice
const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionUid(state, action: PayloadAction<string>) {
      state.uid = action.payload;
    },
    setSessionId(state, action: PayloadAction<string>) {
      state.sessionId = action.payload;
    },
    setSessionIsHost(state, action: PayloadAction<boolean>) {
      state.isHost = action.payload;
    },
    setSessionIsLive(state, action: PayloadAction<boolean>) {
      state.isLive = action.payload;
    },
    setSessionOrder(state, action: PayloadAction<number>) {
      state.order = action.payload;
    },
    setSessionLayout(state, action: PayloadAction<Layout>) {
      state.layout = action.payload;
    },
    setSessionDisplayName(state, action: PayloadAction<string>) {
      state.displayName = action.payload;
    },
    setSessionMediaConstraints(state, action: PayloadAction<MediaConstraints>) {
      state.mediaConstraints = action.payload;
    },
    setSessionOutputDeviceId(state, action: PayloadAction<string>) {
      state.outputDeviceId = action.payload;
    },
    setSessionSecretToken(state, action: PayloadAction<TSecretToken>) {
      state.secretToken = action.payload;
    },
    setSessionInviteCode(state, action: PayloadAction<string>) {
      state.inviteCode = action.payload;
    },
    setSessionIsScreenSharing(state, action: PayloadAction<boolean>) {
      state.isScreenSharing = action.payload;
    },
    setSessionHideDisplayNames(state, action: PayloadAction<boolean>) {
      state.hideDisplayNames = action.payload;
    },
    setSessionPlaybackUrl(state, action: PayloadAction<string>) {
      state.playbackUrl = action.payload;
    },
    setMaestroChannelId(state, action: PayloadAction<string>) {
      state.channelId = action.payload;
    },
    setSessionClientUrl(state, action: PayloadAction<string>) {
      state.clientUrl = action.payload;
    },
    setSessionIsAskingScreenShare(state, action: PayloadAction<boolean>) {
      state.isAskingScreenShare = action.payload;
    },
    setMaestroJwt(state, action: PayloadAction<TMaestroJwt>) {
      state.maestroJwt = action.payload;
    },
    setSessionMediaEnabled(state, action: PayloadAction<SessionStore['mediaEnabled']>) {
      state.mediaEnabled = action.payload;
    },
  },
});

// Selectors
const selectState = (state: RootState) => state.session;

export const getSessionId = createSelector(selectState, (state: SessionStore) => state.sessionId);
export const getSessionIsHost = createSelector(selectState, (state: SessionStore) => state.isHost);
export const getSessionIsLive = createSelector(selectState, (state: SessionStore) => state.isLive);
export const getSessionLayout = createSelector(selectState, (state: SessionStore) => state.layout);
export const getMaestroJwt = createSelector(selectState, (state: SessionStore) => state.maestroJwt);
export const getSessionDisplayName = createSelector(
  selectState,
  (state: SessionStore) => state.displayName
);
export const getSessionTransmissionId = createSelector(
  selectState,
  (state: SessionStore) => state.sessionId
);
export const getSessionMediaConstraints = createSelector(
  selectState,
  (state: SessionStore) => state.mediaConstraints
);
export const getSessionOutputDeviceId = createSelector(
  selectState,
  (state: SessionStore) => state.outputDeviceId
);
export const getSessionSecretToken = createSelector(
  selectState,
  (state: SessionStore) => state.secretToken
);
const getSessionInviteCode = createSelector(selectState, (state: SessionStore) => state.inviteCode);
export const getSessionClientUrl = createSelector(
  selectState,
  (state: SessionStore) => state.clientUrl
);
export const getSessionIsScreenSharing = createSelector(
  selectState,
  (state: SessionStore) => state.isScreenSharing
);
export const getHideDisplayNames = createSelector(
  selectState,
  (state: SessionStore) => state.hideDisplayNames
);
export const getMaestroChannelId = createSelector(
  selectState,
  (state: SessionStore) => state.channelId
);

export const getIsAskingScreenShare = createSelector(
  selectState,
  (state: SessionStore) => state.isAskingScreenShare
);

export const getMaestroGuestInviteLink = createSelector(
  [getSessionClientUrl, getSessionInviteCode],
  (watchUrl, inviteCode) => {
    if (watchUrl && inviteCode) {
      const guestUrl = new URL(watchUrl);
      guestUrl.searchParams.set('studio_invite', inviteCode);
      return guestUrl.href;
    }
    return watchUrl!;
  }
);

// Actions
const { actions } = sessionSlice;

export const setSessionUid = (uid: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionUid(uid));
  };
};

export const setSessionId = (id: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionId(id));
  };
};

export const setSessionIsHost = (isHost: boolean): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionIsHost(isHost));
  };
};

export const setSessionIsLive = (isLive: boolean): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    const {
      destinations: { destinations },
    } = getState();

    if (isLive) {
      dispatch(
        setAllDestinations(
          changeDestinationsStatus(
            destinations,
            [StatusType.ready, StatusType.initializing],
            StatusType.live
          )
        )
      );
    } else {
      dispatch(
        setAllDestinations(
          changeDestinationsStatus(
            destinations,
            [StatusType.initializing, StatusType.live],
            StatusType.ready
          )
        )
      );
    }
    dispatch(actions.setSessionIsLive(isLive));
  };
};

export const setSessionOrder = (order: number): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionOrder(order));
  };
};

export const setSessionLayout = (layout: Layout): AppThunk => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { session } = getState();
    if (session.layout !== layout) {
      dispatch(actions.setSessionLayout(layout));
    }
  };
};

export const setSessionDisplayName = (displayName: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionDisplayName(displayName));
  };
};

export const setSessionMediaConstraints = (constraints: MediaConstraints) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionMediaConstraints(constraints));
  };
};

export const setSessionOutputDeviceId = (deviceId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionOutputDeviceId(deviceId));
  };
};

export const setSessionSecretToken = (secretToken: TSecretToken) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionSecretToken(secretToken));
  };
};

export const setSessionInviteCode = (inviteCode: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionInviteCode(inviteCode));
  };
};

export const setSessionIsScreenSharing = (isScreenSharing: boolean) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionIsScreenSharing(isScreenSharing));
  };
};

export const setSessionHideDisplayNames = (hide: boolean) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionHideDisplayNames(hide));
  };
};

export const setSessionPlaybackUrl = (playbackUrl: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionPlaybackUrl(playbackUrl));
  };
};

export const setMaestroChannelId = (channelId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setMaestroChannelId(channelId));
  };
};

export const setSessionIsAskingScreenShare = (isAsking: boolean) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionIsAskingScreenShare(isAsking));
  };
};

export const setMaestroJwt = (jwt: TMaestroJwt) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setMaestroJwt(jwt));
  };
};

export const setSessionMediaEnabled = (mediaEnabled: SessionStore['mediaEnabled']) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionMediaEnabled(mediaEnabled));
  };
};

export const setSessionClientUrl = (clientUrl: SessionStore['clientUrl']) => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setSessionClientUrl(clientUrl));
  };
};

export default sessionSlice.reducer;
