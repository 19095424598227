import React from 'react';
import ReactSelect, { MenuPlacement, StylesConfig } from 'react-select';
import styled, { useTheme } from 'styled-components';

import { DataTest } from 'interfaces/dataTestId';

export interface Option<TValue = any> {
  label: string;
  value: TValue;
}

interface Props extends DataTest {
  options: Option[] | undefined;
  name: string;
  value: any;
  isSearchable?: boolean;
  isDisabled?: boolean;
  menuPlacement?: MenuPlacement;
  onChange: (value: Option | null) => void;
  onBlur?: any;
  fontSize?: number;
  height?: number;
  isLastChildStyle?: boolean;
}

const CustomSelect: React.FC<Props> = ({
  options,
  name,
  value,
  isSearchable = false,
  isDisabled = false,
  menuPlacement = 'auto',
  onBlur,
  onChange,
  fontSize = 11,
  height = 29,
  isLastChildStyle,
  'data-testid': dataTestId,
}) => {
  const { colors } = useTheme();
  const selectCustomStyles: StylesConfig<Option, false> = {
    container: (provided) => ({
      ...provided,
      color: colors.text200,
      fontSize: `${fontSize}px`,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: colors.text200,
    }),
    input: (provided) => ({
      ...provided,
      color: colors.text200,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: colors.text200,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: colors.surface4,
      zIndex: 10,
    }),
    //style menuList for styling scrollbar
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: provided.isSelected || state.isFocused ? colors.surface5 : colors.surface4,
      ':last-child': isLastChildStyle
        ? {
            height: '40px',
          }
        : undefined,
    }),
    control: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      borderStyle: 'none',
      backgroundColor: state.menuIsOpen ? colors.surface2 : colors.surface5,
      borderColor: colors.accentPrimary,
      height: `${height}px`,
      minHeight: `${height}px`,
      boxShadow: state.isFocused ? `0 0 0 1px ${colors.accentPrimary} !important` : 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      '[class*=indicatorSeparator]': {
        display: 'none',
      },
      '[class*=indicatorContainer]': {
        color: `${colors.text200}`,
        padding: '0 8px 0 8px',
      },
      '[class*=indicatorContainer]:hover': {
        filter: 'grayscale(50%)',
      },
    }),
  };

  return (
    <SelectContainer data-testid={dataTestId}>
      <ReactSelect
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        options={options}
        styles={selectCustomStyles}
        key={name}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        menuPlacement={menuPlacement}
      ></ReactSelect>
    </SelectContainer>
  );
};

const SelectContainer = styled.div``;

export default CustomSelect;
