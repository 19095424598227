import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css, useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { getSessionIsHost, getSessionMediaConstraints } from 'store/slices/session';
import { getIsBackstageVisible, getIsPanelsVisible, openModal } from 'store/slices/ui';
import { Cam, CamOff, Layout as LayoutIcon, Mic, MicOff, Settings } from 'components/shared/Icons';
import ControlButton from 'views/shared/ControlButton';
import DrawerButton from 'views/shared/DrawerButton';
import { MODALS } from 'constants/modals';

import BackstageButton from './BackstageButton';
import StageControlsShareButton from './StageControlsShareButton';
import { getIsDeviceAuthorized } from 'components/DeviceNotAuthorizedAlert/DeviceNotAuthorizedAlert';
import { useRoomContext } from '@livekit/components-react';
import { getParticipants } from 'store/slices/participants';
import { useReactiveRoom } from 'contexts/LiveKitContext';

const StageControls: React.FC = () => {
  const dispatch = useDispatch();
  const { localParticipant } = useRoomContext();
  const { localParticipantId, setOwnCameraEnabled, setOwnMicEnabled } = useReactiveRoom();
  const participants = useSelector(getParticipants);

  const participant = useMemo(
    () => participants.find(({ id }) => id === localParticipantId),
    [localParticipantId, participants]
  );

  const isHost = useSelector(getSessionIsHost);
  const isBackstageVisible = useSelector(getIsBackstageVisible);
  const isPanelsVisible = useSelector(getIsPanelsVisible);
  const mediaConstraints = useSelector(getSessionMediaConstraints);
  const isDeviceAuthorized = getIsDeviceAuthorized(mediaConstraints);
  const { colors } = useTheme();

  if (!participant) {
    return null;
  }

  const { audio, video } = participant;

  const toggleOwnAudio = async () => {
    const { isAllowed } = audio;
    if (!isDeviceAuthorized.audio) return;

    if (!isAllowed) {
      dispatch(
        openModal({
          id: `${MODALS.MEDIA_NOT_ALLOWED}::audio`,
          component: MODALS.MEDIA_NOT_ALLOWED,
          props: {
            media: 'audio',
            type: 'cant_start',
            hideClose: false,
          },
        })
      );
      return;
    }

    setOwnMicEnabled(!localParticipant.isMicrophoneEnabled);
  };

  const toggleOwnVideo = async () => {
    const { isAllowed } = video;
    if (!isDeviceAuthorized.video) return;

    if (!isAllowed) {
      dispatch(
        openModal({
          id: `${MODALS.MEDIA_NOT_ALLOWED}::video`,
          component: MODALS.MEDIA_NOT_ALLOWED,
          props: {
            media: 'video',
            type: 'cant_start',
            hideClose: false,
          },
        })
      );
      return;
    }

    setOwnCameraEnabled(!localParticipant.isCameraEnabled);
  };

  const showSettingsModal = (e: any) => {
    e.preventDefault();
    dispatch(
      openModal({
        component: MODALS.AUDIO_CAM_SETTINGS,
        props: {
          type: 'config',
          hideClose: false,
        },
        locked: true, //FIXME: not working without it
        hideBackdrop: false,
      })
    );
  };

  return (
    <ControlsContainer backstage={isBackstageVisible} panels={isPanelsVisible} isHidden={false}>
      <ButtonsSectionContainer>
        {isHost ? (
          <BackstageButton />
        ) : (
          <ControlButton data-testid='settingsButton' text='Settings' onClick={showSettingsModal}>
            <Settings width={22} height={22} fill={colors.text100} />
          </ControlButton>
        )}
        <StageControlsShareButton />
      </ButtonsSectionContainer>

      <MediaControlsContainer>
        <ControlButton
          data-testid='micButton'
          text={audio.isEnabled ? 'Mute Mic' : 'Unmute Mic'}
          onClick={toggleOwnAudio}
        >
          {audio.isAllowed && audio.isEnabled ? (
            <Mic width={22} height={22} fill={colors.text100} />
          ) : (
            <MicOff width={22} height={22} fill={colors.text100} />
          )}
        </ControlButton>

        {/* Center button */}
        {isHost && (
          <DrawerButton data-testid='layoutsButton' type='LAYOUT' text='Layouts'>
            <LayoutIcon width={22} height={22} fill={colors.text100} />
          </DrawerButton>
        )}

        <ControlButton
          data-testid='videoButton'
          text={video.isEnabled ? 'Stop Video' : 'Start Video'}
          onClick={toggleOwnVideo}
        >
          {video.isAllowed && video.isEnabled ? (
            <Cam width={22} height={22} fill={colors.text100} stroke={colors.text100} />
          ) : (
            <CamOff width={22} height={22} fill={colors.text100} />
          )}
        </ControlButton>
      </MediaControlsContainer>

      <ButtonsSectionContainer>
        {isHost ? (
          <React.Fragment>
            <Spacer />
            <ControlButton data-testid='settingsButton' text='Settings' onClick={showSettingsModal}>
              <Settings width={22} height={22} fill={colors.text100} />
            </ControlButton>
          </React.Fragment>
        ) : (
          <React.Fragment>{!isMobile && <Spacer />}</React.Fragment>
        )}
      </ButtonsSectionContainer>
    </ControlsContainer>
  );
};

const ControlsContainer = styled.div<{ backstage: boolean; panels: boolean; isHidden: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'space-evenly' : 'space-between'};
  flex-direction: row;
  gap: 15px;
  width: 100%;
  flex: 0 0 auto;
  height: ${isMobile ? 'auto' : '70px'};
  padding: ${isMobile ? '15px 0 15px 0' : '15px'};
  background-color: ${({ theme }) => (isMobile ? theme.colors.surface2 : 'black')};
  border-top: ${({ theme }) => (isMobile ? 'none' : `0.5px solid ${theme.colors.surface6}`)};
  box-sizing: border-box;
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
  @media (orientation: landscape) {
    // NOTE: this ensure that this query only runs in mobile devices;
    ${isMobile &&
    css`
      display: none;
    `};
  }
`;

const MediaControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
`;

const ButtonsSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const Spacer = styled.div`
  width: 44px;
  height: 44px;
`;

export default StageControls;
